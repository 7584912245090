import { get, orderBy } from 'lodash-es'

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc',
}

interface SortBaseOptions {
    dataType: 'unix' | 'string' | 'number'
}

export const sortByBase = <T>(
    collection: T[],
    path: string,
    order: SortOrder,
    options: SortBaseOptions = { dataType: 'string' },
) => {
    const getter =
        options?.dataType === 'string'
            ? (document: T, path: string) => get(document, path)
            : (document: T, path: string) => parseInt(get(document, path))

    return orderBy(collection, document => getter(document, path), order)
}

export const sortByString = <T>(collection: T[], path: string, order: SortOrder) => sortByBase(collection, path, order)
export const sortByTimeStamp = <T>(collection: T[], path: string, order: SortOrder) =>
    sortByBase(collection, path, order, { dataType: 'unix' })
export const sortByNumber = <T>(collection: T[], path: string, order: SortOrder) =>
    sortByBase(collection, path, order, { dataType: 'number' })
