import { useEffect, useState, type UIEvent, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { Table, TableBody, TableContainer, TableCell, TableHead, TableRow } from '@material-ui/core'
import { Card, CardHeader } from 'src/ui-kit/card/Card'
import ExpandTableFooter from 'src/ui-kit/expandTableFooter/ExpandTableFooter'
import { BatteryInfo, TaskEventInfo } from 'src/components/parts/history/hunter/task-event-info/TaskEventInfo'
import { UserEmailLink } from 'src/components/parts/history/hunter/user-email-link/UserEmailLink'
import { useTableStyles, TableSortLabel } from 'src/ui-kit/materialTable/MaterialTable'
import * as taskUtils from 'src/utils/tasks/tasksUtils'
import * as timeUtils from 'src/utils/time/timeUtils'
import {
    getHunterHistory,
    getHunterTaskHistoryNextPage,
    resetHunterHistoryForHunter,
    selectHunterAccountId,
    selectCurrentHunterHistory,
    selectHunterHistorySortParam,
    sortHunterHistory,
    selectIsFetchingHunterHistory,
} from 'src/redux/hunter'
import {
    closeShowParkingPhotoForTask,
    resetHunterParkingPhotoState,
    selectShownHunterParkingPhotoTask,
} from 'src/redux/hunterParkingPhotos'
import { TaskStatusInfo } from 'src/components/parts/history/hunter/task-status-info/TaskStatusInfo'
import { SortOrder } from 'src/utils/sort/sort'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import { type TaskWithScooterShort } from 'src/api/fm/tasks/tasks.model'
import { ColumnContainer } from 'src/ui-kit/containers/ColumnContainer'
import { HunterParkingPhotoInfo } from 'src/components/hunterParkingPhoto/HunterParkingPhotoInfo'
import { HunterParkingPhotoModal } from 'src/components/hunterParkingPhoto/HunterParkingPhotoModal'

export const HunterHistory = () => {
    const [historyExpanded, setHistoryExpanded] = useState(false)
    const tableContainerRef = useRef<HTMLDivElement>(null)
    const hunterId = useAppSelector(selectHunterAccountId)
    const history = useAppSelector(selectCurrentHunterHistory)
    const isFetchingHistory = useAppSelector(selectIsFetchingHunterHistory)
    const sortParam = useAppSelector(selectHunterHistorySortParam)
    const hunterParkingPhotoTask = useAppSelector(selectShownHunterParkingPhotoTask)
    const isHunterParkingPhotoShown = !!hunterParkingPhotoTask
    const dispatch = useAppDispatch()
    const classes = useTableStyles()

    useEffect(() => {
        if (hunterId) {
            dispatch(getHunterHistory())
        }

        return () => {
            dispatch(resetHunterHistoryForHunter())
            dispatch(resetHunterParkingPhotoState())
        }
    }, [hunterId, dispatch])

    const infiniteScroll = (e: UIEvent<HTMLElement>) => {
        if (e.currentTarget.clientHeight + e.currentTarget.scrollTop >= e.currentTarget.scrollHeight - 3) {
            // Avoid getting next history page if fetching already is in progress
            if (isFetchingHistory) {
                return
            }

            dispatch(getHunterTaskHistoryNextPage())
        }
    }

    const handleClickSort = (param: string) => {
        tableContainerRef.current?.scrollTo(0, 0) // Scroll table to top to avoid triggering infinite scroll events while switching sort filter
        dispatch(sortHunterHistory(param))
    }

    const hunterTaskHistory = !historyExpanded ? history.slice(0, 3) : history

    const isSortedByHeader = (header: string, sortParam: string) => {
        return sortParam.substring(1) === header || sortParam.substring(3) === header
    }

    const getSortDirection = (sortParam: string): SortOrder => {
        return sortParam.charAt(0) === '-' ? SortOrder.Desc : SortOrder.Asc
    }

    return (
        <Card>
            {isHunterParkingPhotoShown && (
                <HunterParkingPhotoModal
                    task={hunterParkingPhotoTask}
                    close={() => dispatch(closeShowParkingPhotoForTask())}
                />
            )}
            <CardHeader title='Hunter history' />
            {hunterTaskHistory && hunterTaskHistory.length ? (
                <>
                    <TableContainer
                        className={classes.containerScrollable}
                        onScroll={historyExpanded ? (e: UIEvent<HTMLElement>) => infiniteScroll(e) : undefined}
                        data-testid='hunter-history-table-container'
                        ref={tableContainerRef}
                    >
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow className={classes.row}>
                                    <TableCell className={classes.headerCell}>VOI&nbsp;ID</TableCell>
                                    <TableCell className={classes.headerCell} style={{ minWidth: '100px' }}>
                                        Type
                                    </TableCell>
                                    <TableCell
                                        className={classes.headerCell}
                                        style={{ minWidth: '200px' }}
                                        onClick={() => handleClickSort('created_at')}
                                    >
                                        <TableSortLabel
                                            active={isSortedByHeader('created_at', sortParam)}
                                            direction={getSortDirection(sortParam)}
                                        >
                                            Created at
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={classes.headerCell}
                                        style={{ minWidth: '200px' }}
                                        onClick={() => handleClickSort('collect_time')}
                                    >
                                        <TableSortLabel
                                            active={isSortedByHeader('collect_time', sortParam)}
                                            direction={getSortDirection(sortParam)}
                                        >
                                            Collected
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell
                                        className={classes.headerCell}
                                        style={{ minWidth: '200px' }}
                                        onClick={() => handleClickSort('drop_time')}
                                    >
                                        <TableSortLabel
                                            active={isSortedByHeader('drop_time', sortParam)}
                                            direction={getSortDirection(sortParam)}
                                        >
                                            Dropped
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell className={classes.headerCell}>Description</TableCell>
                                    <TableCell className={classes.headerCell} style={{ minWidth: '200px' }}>
                                        Internal&nbsp;note
                                    </TableCell>
                                    <TableCell className={classes.headerCell}>Parking&nbsp;Photos</TableCell>
                                    <TableCell className={classes.headerCell}>Manually&nbsp;ended</TableCell>
                                    <TableCell className={classes.headerCell} style={{ minWidth: '100px' }}>
                                        Status
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hunterTaskHistory.map((task, i) => {
                                    const {
                                        auditLog,
                                        scooterShort,
                                        createdAt,
                                        creationReason,
                                        collectState,
                                        type,
                                        dropState,
                                        info,
                                        infoInternal,
                                        forceClosed,
                                        hasPhoto,
                                        id,
                                    } = task as TaskWithScooterShort
                                    const creator = auditLog && taskUtils.getTaskCreator(auditLog)
                                    const collector = auditLog && taskUtils.getTaskCollector(auditLog)
                                    const dropper = auditLog && taskUtils.getTaskDropper(auditLog)

                                    return (
                                        <TableRow className={classes.row} key={i}>
                                            <TableCell className={classes.cell}>
                                                <Link to={'/vehicles/' + scooterShort}>{scooterShort}</Link>
                                            </TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '100px' }}>
                                                {taskUtils.prettifyTaskType(type)}
                                            </TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                                <TableDataWrapper>
                                                    <CreatedInfo
                                                        time={createdAt}
                                                        creator={creator}
                                                        creationReason={creationReason}
                                                        battery={collectState?.battery}
                                                    />
                                                </TableDataWrapper>
                                            </TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                                <TableDataWrapper>
                                                    {collector && collector.id === hunterId && collectState && (
                                                        <TaskEventInfo taskState={collectState} />
                                                    )}
                                                </TableDataWrapper>
                                            </TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                                <TableDataWrapper>
                                                    {dropper && dropper.id === hunterId && dropState && (
                                                        <TaskEventInfo taskState={dropState} />
                                                    )}
                                                </TableDataWrapper>
                                            </TableCell>
                                            <TableCell className={classes.cell}>{info}</TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                                {infoInternal}
                                            </TableCell>
                                            <TableCell className={classes.cell}>
                                                {hasPhoto && dropState && (
                                                    <HunterParkingPhotoInfo taskId={id} dropTime={dropState.time} />
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.cell}>{forceClosed ? 'Yes' : 'No'}</TableCell>
                                            <TableCell className={classes.cell} style={{ minWidth: '100px' }}>
                                                <TaskStatusInfo task={task}></TaskStatusInfo>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <ExpandTableFooter
                        breakpoint={3}
                        currentRows={history.length}
                        expanded={historyExpanded}
                        setExpanded={setHistoryExpanded}
                    />
                </>
            ) : (
                <p style={{ paddingLeft: '24px' }}>No tasks</p>
            )}
        </Card>
    )
}

interface CreatedInfoProps {
    time: string
    creator?: taskUtils.Operator
    creationReason: string
    battery?: number
}

export function CreatedInfo({ time, creator, creationReason, battery }: CreatedInfoProps) {
    const datetime = timeUtils.formatUnixDate(Number(time))

    return (
        <ColumnContainer>
            <span>{datetime}</span>
            {creator ? (
                <UserEmailLink email={creator.email} href={`/riders/${creator.id}`} operatorType='user' />
            ) : (
                <span>Automated</span>
            )}
            {creationReason && (
                <span>
                    <ReasonLabelStyled>Reason </ReasonLabelStyled>
                    {firstLetterUpperCase(creationReason)}
                </span>
            )}
            <BatteryInfo batteryPct={battery} />
        </ColumnContainer>
    )
}

const TableDataWrapper = styled.div`
    margin-bottom: 10px;
`

const ReasonLabelStyled = styled.span`
    font-weight: 600;
`
