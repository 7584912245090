import {
    SET_CURRENT_REPAIR_ACCOUNT,
    SET_CURRENT_REPAIR_VEHICLE,
    SET_SPARE_PARTS,
    RESET_CURRENT_REPAIR_VEHICLE,
    SET_REPAIR_ISSUES,
    type RepairState,
    type RepairActions,
} from 'src/redux/repair/repair.types'

export const INITIAL_STATE: RepairState = {
    currentRepairAccount: null,
    currentRepairVehicle: null,
    spareParts: [],
    repairIssues: [],
}

export function repairReducer(state: RepairState = INITIAL_STATE, action: RepairActions): RepairState {
    switch (action.type) {
        case SET_CURRENT_REPAIR_ACCOUNT:
            return { ...state, currentRepairAccount: action.payload }
        case SET_CURRENT_REPAIR_VEHICLE:
            return { ...state, currentRepairVehicle: action.payload }
        case SET_SPARE_PARTS:
            return { ...state, spareParts: action.payload }
        case SET_REPAIR_ISSUES:
            return { ...state, repairIssues: action.payload }
        case RESET_CURRENT_REPAIR_VEHICLE:
            return {
                ...state,
                currentRepairVehicle: null,
                spareParts: [],
                repairIssues: [],
            }

        default:
            return state
    }
}
