import { GeoJsonLayer } from '@deck.gl/layers'
import { type Area, type AreaType } from 'src/api/fm/zones/zones.model'
import { getLayerStyleByAreaType } from 'src/utils/map/mapUtils'
import { MAP_AREA_TYPES } from 'src/redux/mapSettings/mapSettings.types'

export const createAreasLayers = (data: Record<AreaType, Area[]>, visibleAreas: AreaType[], onHover: any) =>
    (Object.entries(data) as [AreaType, Area[]][])
        .sort(([areaTypeA], [areaTypeB]) => MAP_AREA_TYPES.indexOf(areaTypeA) - MAP_AREA_TYPES.indexOf(areaTypeB))
        .filter(([areaType]) => visibleAreas.includes(areaType))
        .map(([areaType, features]) => {
            const style = getLayerStyleByAreaType(areaType)
            return new GeoJsonLayer<Area>({
                id: `${areaType}-layer`,
                data: features,
                pickable: true,
                extruded: false,
                wireframe: true,
                onHover,
                opacity: style.opacity,
                stroked: style.stroked,
                filled: style.filled,
                getFillColor: style.fill,
                getLineColor: style.line,
            })
        })
