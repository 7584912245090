import { useHover } from 'react-use'
import styled from 'styled-components'

import { IconButton } from 'src/ui-kit/iconButton/IconButton'
import TextTooltip from 'src/ui-kit/textTooltip/TextTooltip'

interface Props {
    size: string
    alt: string
    disabled: boolean
    src: string
    dataTestId?: string
    onClick: (event: React.MouseEvent<any, MouseEvent>) => void
    tooltip: string
    tooltipLeft: string
    tooltipTop: string
    shape?: 'circle' | 'square'
}

export function IconButtonHover({
    size,
    alt,
    disabled,
    src,
    dataTestId,
    onClick,
    tooltip,
    tooltipLeft,
    tooltipTop,
    shape,
}: Props) {
    const buttonElement = (
        <IconButton
            size={size}
            alt={alt}
            disabled={disabled}
            shape={shape}
            src={src}
            dataTestId={dataTestId}
            onClick={onClick}
        />
    )
    const [hoverable, isHovering] = useHover(buttonElement)

    return (
        <Wrapper $size={size}>
            {hoverable}
            {tooltip && isHovering && (
                <TextTooltip top={tooltipTop} left={tooltipLeft}>
                    {tooltip}
                </TextTooltip>
            )}
        </Wrapper>
    )
}

const Wrapper = styled.div<{ $size: string }>`
    position: relative;
    height: ${({ $size }) => $size};
    width: ${({ $size }) => $size};
`
