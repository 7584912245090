import {
    GET_NEXT_PHOTO_TO_LABEL,
    LABEL_PHOTO,
    type PhotoToLabel,
    SET_NEXT_PHOTO_TO_LABEL,
    type LabelPhotoAction,
    type GetNextPhotoToLabelAction,
    type SetNextPhotoToLabelAction,
} from 'src/redux/photoLabels/photoLabels.types'

export const labelPhoto = (photoRef: string, labels: string[]): LabelPhotoAction => ({
    type: LABEL_PHOTO,
    payload: { photoRef, labels },
})

export const getNextPhotoToLabelAction = (): GetNextPhotoToLabelAction => ({
    type: GET_NEXT_PHOTO_TO_LABEL,
})

export const setNextPhotoToLabelAction = (photoToLabel: PhotoToLabel | null): SetNextPhotoToLabelAction => ({
    type: SET_NEXT_PHOTO_TO_LABEL,
    payload: photoToLabel,
})
