import {
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID,
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS,
    APPEND_TASK_IDS_WITH_PARKING_PHOTO,
    SHOW_PARKING_PHOTO_FOR_TASK,
    CLOSE_SHOW_PARKING_PHOTO_FOR_TASK,
    SHOW_NEXT_HUNTER_PARKING_PHOTO,
    SHOW_PREVIOUS_HUNTER_PARKING_PHOTO,
    SET_IS_FETCHING_MORE_TASKS_START,
    SET_IS_FETCHING_MORE_TASKS_DONE,
    RESET_HUNTER_PARKING_PHOTO_STATE,
    type HunterParkingPhotosCurrentPage,
    type GetHunterParkingPhotoByTaskId,
    type GetHunterParkingPhotoByTaskIdSuccess,
    type ShowParkingPhotoForTask,
    type CloseShowParkingPhotoForTask,
    type ShowNextHunterParkingPhoto,
    type ShowPreviousHunterParkingPhoto,
    type SetIsFetchingMoreTasksStart,
    type SetIsFetchingMoreTasksDone,
    type ResetHunterParkingPhotoState,
    type AppendTaskIdsWithParkingPhoto,
} from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.types'

export const getHunterParkingPhotoByTaskId = (taskId: string): GetHunterParkingPhotoByTaskId => ({
    type: GET_HUNTER_PARKING_PHOTO_BY_TASK_ID,
    payload: taskId,
})

export const getHunterParkingPhotoByTaskIdSuccess = (
    taskId: string,
    photoUrl: string,
): GetHunterParkingPhotoByTaskIdSuccess => ({
    type: GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS,
    payload: { taskId, photoUrl },
})

export const appendTaskIdsWithParkingPhoto = (
    taskIds: string[],
    currentPage: HunterParkingPhotosCurrentPage,
): AppendTaskIdsWithParkingPhoto => ({
    type: APPEND_TASK_IDS_WITH_PARKING_PHOTO,
    payload: { taskIds, currentPage },
})

export const showParkingPhotoForTask = (taskId: string): ShowParkingPhotoForTask => ({
    type: SHOW_PARKING_PHOTO_FOR_TASK,
    payload: { taskId },
})

export const closeShowParkingPhotoForTask = (): CloseShowParkingPhotoForTask => ({
    type: CLOSE_SHOW_PARKING_PHOTO_FOR_TASK,
})

export const showNextHunterParkingPhoto = (): ShowNextHunterParkingPhoto => ({
    type: SHOW_NEXT_HUNTER_PARKING_PHOTO,
})

export const showPreviousHunterParkingPhoto = (): ShowPreviousHunterParkingPhoto => ({
    type: SHOW_PREVIOUS_HUNTER_PARKING_PHOTO,
})

export const setIsFetchingMoreTasksStart = (): SetIsFetchingMoreTasksStart => ({
    type: SET_IS_FETCHING_MORE_TASKS_START,
})

export const setIsFetchingMoreTasksDone = (): SetIsFetchingMoreTasksDone => ({
    type: SET_IS_FETCHING_MORE_TASKS_DONE,
})

export const resetHunterParkingPhotoState = (): ResetHunterParkingPhotoState => ({
    type: RESET_HUNTER_PARKING_PHOTO_STATE,
})
