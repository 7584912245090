import { useState, useEffect, type FormEvent } from 'react'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { every, isEqual } from 'lodash-es'
import { Popup } from 'src/ui-kit/popup/Popup'
import { TextArea } from 'src/ui-kit/forms/Forms'
import ToggleSwitchButton from 'src/ui-kit/toggleSwitchButton/ToggleSwitchButton'
import { blockRider, selectCurrentRider } from 'src/redux/rider'
import { Button } from 'src/ui-kit/button/Button'

export interface BlockTypes {
    payment: boolean
    user: boolean
    device: boolean
    license: boolean
}

const getInitialValues = (rider: Record<string, any>) => ({
    payment: rider.paymentInfo.blocked,
    user: rider.userInfo.isBlocked,
    device: rider.userInfo.blockedByDevice,
    license: !!(rider.drivingLicenceInfo && rider.drivingLicenceInfo.blocked),
})

interface Props {
    closeModal: () => void
}

export const BlockUserModal = (props: Props) => {
    const [blockDesc, setBlockDesc] = useState('')
    const [blockTypes, setBlockTypes] = useState({} as BlockTypes)

    const rider = useAppSelector(selectCurrentRider)

    const dispatch = useAppDispatch()

    const initialValues = rider ? getInitialValues(rider) : {}
    const hasToggledChanges = !isEqual(initialValues, blockTypes)
    const hasDescription = blockDesc.length > 0
    const isFullyUnblocked = every(blockTypes, value => !value)
    const isReasonDisabled = !hasToggledChanges || isFullyUnblocked
    const isValid = hasToggledChanges && (hasDescription || isFullyUnblocked)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        dispatch(blockRider({ id: rider?.userInfo.id, blockDesc, blockTypes }))
        props.closeModal()
    }

    useEffect(() => {
        if (rider) {
            if (rider.userInfo.blockedReason) {
                setBlockDesc(rider.userInfo.blockedReason)
            } else if (rider.drivingLicenceInfo && rider.drivingLicenceInfo.reason) {
                setBlockDesc(rider.drivingLicenceInfo.reason)
            } else if (rider.paymentInfo.blockMessage) {
                setBlockDesc(rider.paymentInfo.blockMessage)
            }

            setBlockTypes(getInitialValues(rider))
        }
    }, [rider])

    return (
        <>
            {rider?.userInfo && blockTypes ? (
                <Popup title='Choose what to block' close={() => props.closeModal()}>
                    <form onSubmit={e => handleSubmit(e)}>
                        <BlockTypesContainer>
                            <ToggleSwitchButton
                                label='PAYMENT'
                                checked={blockTypes.payment}
                                onChange={() => setBlockTypes({ ...blockTypes, payment: !blockTypes.payment })}
                            />
                            <ToggleSwitchButton
                                label='USER'
                                checked={blockTypes.user}
                                onChange={() => setBlockTypes({ ...blockTypes, user: !blockTypes.user })}
                            />
                            <ToggleSwitchButton
                                label='DEVICE'
                                checked={blockTypes.device}
                                onChange={() => setBlockTypes({ ...blockTypes, device: !blockTypes.device })}
                            />
                            <ToggleSwitchButton
                                label='DRIVERS LICENSE'
                                checked={blockTypes.license}
                                disabled={!rider.drivingLicenceInfo}
                                onChange={() => setBlockTypes({ ...blockTypes, license: !blockTypes.license })}
                            />
                        </BlockTypesContainer>
                        <TextArea
                            required={!isReasonDisabled}
                            label='Block reason'
                            dataTestId='block-reason-input'
                            value={blockDesc}
                            onChange={e => setBlockDesc(e.target.value)}
                            placeholder={!isReasonDisabled ? 'Rider behaves fraudulent...' : undefined}
                            disabled={isReasonDisabled}
                        />
                        <Button dataTestId='confirm-block-button' isStretched isDisabled={!isValid} actionType='submit'>
                            SAVE
                        </Button>
                    </form>
                </Popup>
            ) : null}
        </>
    )
}

const BlockTypesContainer = styled.div`
    display: flex;
    padding-bottom: 20px;

    > * {
        min-width: 75px;
    }
`
