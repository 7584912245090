import { call, put, select, takeEvery } from 'redux-saga/effects'
import { toggleAreaType, setMapSettings, selectMapSettings } from 'src/redux/mapSettings'
import { setLocalStorageMapSettings } from 'src/redux/mapSettings/mapSettings.localStorage'

export function* toggleAreaTypeHandler(action: ReturnType<typeof toggleAreaType>) {
    const areaType = action.payload
    const mapSettings: ReturnType<typeof selectMapSettings> = yield select(selectMapSettings)
    const isAreaToggled = mapSettings.toggledAreaTypes.includes(areaType)
    const toggledAreaTypes = isAreaToggled
        ? mapSettings.toggledAreaTypes.filter(area => area !== areaType)
        : [...mapSettings.toggledAreaTypes, areaType]
    const newMapSettings = { toggledAreaTypes }

    yield call(setLocalStorageMapSettings, newMapSettings)
    yield put(setMapSettings(newMapSettings))
}

export default function* watcher() {
    yield takeEvery(toggleAreaType.toString(), toggleAreaTypeHandler)
}
