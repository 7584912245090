import {
    SET_CURRENT_VEHICLE,
    GET_VEHICLE_ERROR,
    RESET_SCOOTER_RIDE_HISTORY,
    SET_VEHICLE_RIDE_HISTORY,
    SET_VEHICLE_RIDE_HISTORY_DATE_FILTER,
    RESET_VEHICLE_TASK_HISTORY,
    SET_VEHICLE_TASK_HISTORY,
    SET_CURRENT_VEHICLE_RIDER,
    RESET_VEHICLE_STATE,
    GET_VEHICLE_LICENSE_PLATE_SUCCESS,
    INCREMENT_TASK_HISTORY_PAGINATION,
    SET_END_OF_TASK_HISTORY_PAGINATION,
    type ScooterState,
    SET_VEHICLE_DECOMMISSIONED_INFO,
    SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER,
    SET_IS_FETCHING_VEHICLE_TASK_HISTORY,
} from 'src/redux/scooter/scooter.types'
import { type VehicleAction } from 'src/redux/scooter/scooter.actions'
import { RIDER_SOURCE } from 'src/api'

export const INITIAL_STATE: ScooterState = {
    currentScooter: null,
    currentScooterLicensePlate: null,
    currentScooterError: null,
    rideHistory: null,
    rideHistoryDateFilter: null,
    rideHistorySourceFilter: RIDER_SOURCE,
    taskHistory: [],
    isFetchingTaskHistory: false,
    taskHistoryPageNum: 1,
    taskHistoryEndOfPagination: false,
    currentRider: null,
    decommissionedInfo: null,
}

export function scooterReducer(state: ScooterState = INITIAL_STATE, action: VehicleAction): ScooterState {
    switch (action.type) {
        case SET_CURRENT_VEHICLE:
            return { ...state, currentScooterError: null, currentScooter: action.payload }

        case GET_VEHICLE_ERROR:
            return {
                ...INITIAL_STATE,
                currentScooterError: action.payload,
            }

        case SET_VEHICLE_RIDE_HISTORY:
            return { ...state, rideHistory: action.payload }

        case RESET_SCOOTER_RIDE_HISTORY:
            return { ...state, rideHistory: null, rideHistoryDateFilter: null }

        case SET_VEHICLE_RIDE_HISTORY_DATE_FILTER:
            return { ...state, rideHistoryDateFilter: action.payload }

        case SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER:
            return { ...state, rideHistorySourceFilter: action.payload }

        case SET_VEHICLE_TASK_HISTORY:
            return { ...state, taskHistory: action.payload }

        case SET_IS_FETCHING_VEHICLE_TASK_HISTORY:
            return { ...state, isFetchingTaskHistory: action.payload }

        case RESET_VEHICLE_TASK_HISTORY:
            return { ...state, taskHistory: [] }

        case SET_CURRENT_VEHICLE_RIDER:
            return { ...state, currentRider: action.payload }

        case GET_VEHICLE_LICENSE_PLATE_SUCCESS: {
            const { licensePlate } = action.payload

            return { ...state, currentScooterLicensePlate: licensePlate }
        }

        case SET_VEHICLE_DECOMMISSIONED_INFO: {
            return { ...state, decommissionedInfo: action.payload }
        }

        case INCREMENT_TASK_HISTORY_PAGINATION: {
            const taskHistoryPageNum = state.taskHistoryPageNum + 1
            return { ...state, taskHistoryPageNum }
        }

        case SET_END_OF_TASK_HISTORY_PAGINATION: {
            return { ...state, taskHistoryEndOfPagination: true }
        }

        case RESET_VEHICLE_STATE: {
            return INITIAL_STATE
        }

        default:
            return state
    }
}
