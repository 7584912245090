import { type RefObject, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToTopOnNavigate = (ref: RefObject<HTMLElement>) => {
    const { pathname } = useLocation()

    useEffect(() => {
        if (ref && ref.current) {
            ref.current.scrollTo(0, 0)
        }
    }, [ref, pathname])
}
