import {
    type ChildStatusKey,
    type VmapBatteryFilter,
    type VmapFilters,
    type VmapVehicleModel,
    type VmapVehicleStatusFilterKey,
    type VmapVehicleStatusFilterWithChild,
    type VmapZoneFilter,
} from 'src/components/vmap/filters/types'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { type ZoneBoundariesPolygon } from 'src/api/fm/zones/zones.model'
import { type ExecutionPriority } from 'src/api/fm/tasks/tasks.model'

export const SET_VEHICLES_VMAP = 'SET_VEHICLES_VMAP'
export const UPDATE_VEHICLE_MAP = 'UPDATE_VEHICLE_MAP'
export const TOGGLE_MAP_LOADING_VMAP = 'TOGGLE_MAP_LOADING_VMAP'
export const TOGGLE_VMAP_ALL_STATES = 'TOGGLE_VMAP_ALL_STATES'
export const TOGGLE_VMAP_STATE_PARENT = 'TOGGLE_VMAP_STATE_PARENT'
export const TOGGLE_VMAP_STATE_CHILD = 'TOGGLE_VMAP_STATE_CHILD'
export const RESET_VMAP_STATES = 'RESET_VMAP_STATES'
export const ADD_ZONE_FILTER_VMAP = 'ADD_ZONE_FILTER_VMAP'
export const ADD_BATTERY_FILTER_VMAP = 'ADD_BATTERY_FILTER_VMAP'
export const REMOVE_ZONE_FILTER_VMAP = 'REMOVE_ZONE_FILTER_VMAP'
export const REMOVE_STATUS_FILTER_VMAP = 'REMOVE_STATUS_FILTER_VMAP'
export const REMOVE_BATTERY_FILTER_VMAP = 'REMOVE_BATTERY_FILTER_VMAP'
export const GET_INITIAL_VMAP_FILTERS = 'GET_INITIAL_VMAP_FILTERS'
export const SET_INITIAL_VMAP_FILTERS = 'SET_INITIAL_VMAP_FILTERS'
export const SET_CURRENT_ZONE_VMAP = 'SET_CURRENT_ZONE_VMAP'
export const SET_MAP_INTERVAL = 'SET_MAP_INTERVAL'
export const TOGGLE_VMAP_MODEL_FILTER = 'TOGGLE_VMAP_MODEL_FILTER'
export const TOGGLE_VMAP_ALL_MODEL_FILTERS = 'TOGGLE_VMAP_ALL_MODEL_FILTERS'
export const RESET_VMAP_MODEL_FILTERS = 'RESET_VMAP_MODEL_FILTERS'
export const SET_ALL_VMAP_MODELS = 'SET_ALL_VMAP_MODELS'
export const TOGGLE_TASK_PRIORITY = 'TOGGLE_TASK_PRIORITY'
export const TOGGLE_TASK_PRIORITY_FILTER = 'TOGGLE_TASK_PRIORITY_FILTER'
export const SET_ORGANIZATION_VMAP_FILTER = 'SET_ORGANIZATION_VMAP_FILTER'

export interface VmapState {
    filters: VmapFilters
    vehicles: Vehicle[]
    allModels: VmapVehicleModel[]
    mapInterval: string
    mapLoading: boolean
    currentZone: VmapCurrentZone | null
}

export interface VmapCurrentZone {
    id: string
    boundaries: ZoneBoundariesPolygon
    isSleeping: boolean
}

export interface AddZoneFilter {
    type: typeof ADD_ZONE_FILTER_VMAP
    payload: VmapZoneFilter
}

export interface AddBatteryFilter {
    type: typeof ADD_BATTERY_FILTER_VMAP
    payload: VmapBatteryFilter
}

export interface RemoveZoneFilter {
    type: typeof REMOVE_ZONE_FILTER_VMAP
}

export interface RemoveStatusFilter {
    type: typeof REMOVE_STATUS_FILTER_VMAP
}

export interface SetVehicles {
    type: typeof SET_VEHICLES_VMAP
    payload: Vehicle[]
}

export interface UpdateVehicleMap {
    type: typeof UPDATE_VEHICLE_MAP
}

export interface SetMapLoading {
    type: typeof TOGGLE_MAP_LOADING_VMAP
    payload: boolean
}

export interface GetInitialVmapFilters {
    type: typeof GET_INITIAL_VMAP_FILTERS
}
export interface SetInitialVmapFilters {
    type: typeof SET_INITIAL_VMAP_FILTERS
    payload: VmapFilters
}

export interface SetCurrentZoneVmap {
    type: typeof SET_CURRENT_ZONE_VMAP
    payload: VmapCurrentZone
}

export interface ToggleVmapAllStates {
    type: typeof TOGGLE_VMAP_ALL_STATES
}

export interface ToggleVmapStateParent {
    type: typeof TOGGLE_VMAP_STATE_PARENT
    payload: VmapVehicleStatusFilterKey
}

export interface ToggleVmapStateChild {
    type: typeof TOGGLE_VMAP_STATE_CHILD
    payload: { parent: VmapVehicleStatusFilterWithChild; child: ChildStatusKey }
}

export interface ResetVmapStates {
    type: typeof RESET_VMAP_STATES
}

export interface SetMapInterval {
    type: typeof SET_MAP_INTERVAL
    payload: string
}

export interface ToggleVmapModelFilter {
    type: typeof TOGGLE_VMAP_MODEL_FILTER
    payload: string
}

export interface ToggleVmapAllModelFilters {
    type: typeof TOGGLE_VMAP_ALL_MODEL_FILTERS
}

export interface ResetVmapModelFilters {
    type: typeof RESET_VMAP_MODEL_FILTERS
}

export interface SetAllVmapModels {
    type: typeof SET_ALL_VMAP_MODELS
    payload: VmapVehicleModel[]
}

export interface ToggleTaskPriority {
    type: typeof TOGGLE_TASK_PRIORITY
    payload: ExecutionPriority
}

export interface ToggleTaskPriorityFilter {
    type: typeof TOGGLE_TASK_PRIORITY_FILTER
}

export interface SetOrganizationVmapFilter {
    type: typeof SET_ORGANIZATION_VMAP_FILTER
    payload: string | undefined
}

export type VmapActions =
    | AddZoneFilter
    | AddBatteryFilter
    | RemoveZoneFilter
    | RemoveStatusFilter
    | SetVehicles
    | UpdateVehicleMap
    | SetMapLoading
    | GetInitialVmapFilters
    | SetInitialVmapFilters
    | SetCurrentZoneVmap
    | ToggleVmapAllStates
    | ToggleVmapStateParent
    | ToggleVmapStateChild
    | ResetVmapStates
    | SetMapInterval
    | ToggleVmapModelFilter
    | ToggleVmapAllModelFilters
    | ResetVmapModelFilters
    | SetAllVmapModels
    | ToggleTaskPriority
    | ToggleTaskPriorityFilter
    | SetOrganizationVmapFilter
