import { Wrapper as ToolTipWrapper } from 'src/components/parts/map/tooltip/tooltipUtils'
import { Pill } from 'src/ui-kit/pill/Pill'
import {
    prettifyVehicleAvailability,
    getColorForStatusFilter,
    getVehiclePageTitle,
} from 'src/utils/vehicles/vehiclesUtils'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'

interface Props {
    x: number
    y: number
    vehicles: BulkListVehicle[] | Vehicle[]
}

export function HoveredVehiclesTooltip({ x, y, vehicles }: Props) {
    return (
        <ToolTipWrapper $pointerEvents='none' $overflowY='hidden' style={{ left: x, top: y }}>
            {vehicles.length === 1 && (
                <>
                    <strong>{getVehiclePageTitle(vehicles[0])}</strong>
                    <Pill
                        label={prettifyVehicleAvailability(vehicles[0].availability)}
                        color={getColorForStatusFilter(vehicles[0].availability.status)}
                    />
                </>
            )}
            {vehicles.length > 1 && (
                <>
                    <strong>Number of vehicles: {vehicles.length}</strong>
                    {vehicles.slice(0, 10).map(vehicle => (
                        <p key={vehicle.id}>{vehicle.short}</p>
                    ))}
                </>
            )}
        </ToolTipWrapper>
    )
}
