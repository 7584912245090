import { createSlice, createAction, type PayloadAction } from '@reduxjs/toolkit'
import { type AppState } from 'src/redux/app/app.store'

interface VehicleSearchState {
    query: string
}

const initialState: VehicleSearchState = {
    query: '',
}

const vehicleSearchSlice = createSlice({
    name: 'vehicleSearch',
    initialState,
    reducers: {
        setSearchQuery(_, action: PayloadAction<string>) {
            return {
                query: action.payload,
            }
        },
        resetVehicleSearch() {
            return initialState
        },
    },
})

// Actions
export const { setSearchQuery, resetVehicleSearch } = vehicleSearchSlice.actions
export const searchVehicles = createAction<(url: string) => void>('vehicleSearch/requestFetchAreas')

// Selectors
export const selectSearchQuery = (state: AppState): string => state.vehicleSearch.query

// Reducer
export const { reducer: vehicleSearchReducer } = vehicleSearchSlice
