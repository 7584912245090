import { combineReducers } from '@reduxjs/toolkit'
import { areasReducer } from 'src/redux/areas/areas.reducer'
import { zoneReducer } from 'src/redux/zone/zone.reducer'
import { hunterReducer } from 'src/redux/hunter/hunter.reducer'
import { organizationReducer } from 'src/redux/organization/organization.reducer'
import { riderReducer } from 'src/redux/rider/rider.reducer'
import { authReducer } from 'src/redux/auth/auth.reducer'
import { scooterReducer } from 'src/redux/scooter/scooter.reducer'
import { scooterBulkReducer } from 'src/redux/scooterBulk/scooterBulk.reducer'
import { vmapReducer } from 'src/redux/vmap/vmap.reducer'
import { navReducer } from 'src/redux/nav/nav.reducer'
import { noteReducer } from 'src/redux/note/note.reducer'
import { taskReducer } from 'src/redux/task/task.reducer'
import { campaignsReducer } from 'src/redux/campaigns/campaigns.reducer'
import { repairReducer } from 'src/redux/repair/repair.reducer'
import { vehicleIdsReducer } from 'src/redux/vehicleIds/vehicleIds.reducer'
import { maasUsersReducer } from 'src/redux/maasUsers/maasUsers.reducer'
import { activeRidesReducer } from 'src/redux/activeRides/activeRides.reducer'
import { permissionsReducer } from 'src/redux/permissions/permissions.reducer'
import { permissionsForRolesReducer } from 'src/redux/permissionsForRoles/permissionsForRoles.reducer'
import { warehouseReducer } from 'src/redux/warehouse/warehouse.reducer'
import { accidentsReducer } from 'src/redux/accidents/accidents.reducer'
import { vehicleReportReducer } from 'src/redux/vehicleReport/vehicleReport.reducer'
import { hunterParkingPhotosReducer } from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.reducer'
import { vehicleModelReducer } from 'src/redux/vehicleModel/vehicleModel.reducer'
import { safetyControlSystemReducer } from 'src/redux/safetyControlSystem/safetyControlSystem.reducer'
import { zoneAreasReducer } from 'src/redux/zoneAreas'
import { vehicleSearchReducer } from 'src/redux/vehicleSearch'
import { mapSettingsReducer } from 'src/redux/mapSettings'
import { photoLabelReducer as photoLabelsReducer } from 'src/redux/photoLabels/photoLabels.reducer'
import { maintenanceNeedReducer } from 'src/redux/maintenanceNeed/maintenanceNeed.reducer'
import { receiptsReducer } from 'src/redux/receipts/receipts.reducers'
import { violationsReducer } from 'src/redux/violations/violations.reducer'
import { workAreaReducer } from 'src/redux/workArea'

export const appReducer = combineReducers({
    areas: areasReducer,
    zone: zoneReducer,
    hunter: hunterReducer,
    organization: organizationReducer,
    rider: riderReducer,
    auth: authReducer,
    scooter: scooterReducer,
    scooterBulk: scooterBulkReducer,
    vmap: vmapReducer,
    nav: navReducer,
    notes: noteReducer,
    activeTasks: taskReducer,
    campaigns: campaignsReducer,
    repairManagement: repairReducer,
    vehicleIds: vehicleIdsReducer,
    maasUsers: maasUsersReducer,
    activeRides: activeRidesReducer,
    photoLabels: photoLabelsReducer,
    hunterParkingPhotos: hunterParkingPhotosReducer,
    permissions: permissionsReducer,
    permissionsForRoles: permissionsForRolesReducer,
    warehouse: warehouseReducer,
    accidents: accidentsReducer,
    vehicleReport: vehicleReportReducer,
    vehicleModel: vehicleModelReducer,
    safetyControlSystem: safetyControlSystemReducer,
    zoneAreas: zoneAreasReducer,
    vehicleSearch: vehicleSearchReducer,
    mapSettings: mapSettingsReducer,
    maintenanceNeed: maintenanceNeedReducer,
    receipts: receiptsReducer,
    violations: violationsReducer,
    workArea: workAreaReducer,
})
