import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { type ZoneFeatures, type UpdateZoneFeaturesPayload } from 'src/api/fm/features/features.model'

export const getZoneFeatures = async (zoneId: string | number): Promise<ZoneFeatures | Error> => {
    const link = encodeURI(`/v1/fm/features/zone/${zoneId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const updateZoneFeatures = async (
    zoneId: string | number,
    data: UpdateZoneFeaturesPayload,
): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/features/zone/${zoneId}`)
    const headers = await getHeaders()
    return ins
        .put(link, data, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
