import styled from 'styled-components'

export const ColumnContainer = styled.div<{ $columnSpace?: string }>`
    display: flex;
    flex-direction: column;

    & > *:not(:first-child) {
        margin-top: ${props => props.$columnSpace || '10px'};
    }
`
