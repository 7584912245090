import { alpha } from '@material-ui/core'
import { type Vehicle, type Availability, type VehicleStatus } from 'src/api/fm/vehicles/vehicles.model'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'
import { snakeCaseToDisplayName, firstLetterUpperCase } from 'src/utils/string/stringUtils'
import * as colors from 'src/ui-kit/colors/colors'

const VEHICLE_STATUS_DEFAULT_COLOR = alpha(colors.coreBlack, 0.2)
const VEHICLE_STATUS_COLOR_MAPPING: Partial<Record<VehicleStatus, string>> = {
    ready: alpha(colors.alertSuccess, 0.2),
    ready_need: alpha(colors.alertSuccess, 0.2),
    bounty: alpha(colors.alertError, 0.2),
    off_market: alpha(colors.alertWarning, 0.2),
    riding: alpha(colors.alertInfo, 0.2),
    booked: alpha(colors.alertInfo, 0.2),
}

export function getColorForStatusFilter(status: VehicleStatus) {
    return VEHICLE_STATUS_COLOR_MAPPING[status] ?? VEHICLE_STATUS_DEFAULT_COLOR
}

export function prettifyVehicleAvailability(availability: Availability): string {
    const { status, tasks } = availability
    const availabilityStatus = snakeCaseToDisplayName(status)

    if (tasks) {
        const isSingleTaskVehicle = tasks.length === 1
        const isMultiTasksVehicle = tasks.length === 2

        if (isSingleTaskVehicle) {
            const prettifiedSingleTaskType = snakeCaseToDisplayName(String(tasks[0].type))

            if (status === 'ready_need') {
                return `Ready + ${prettifiedSingleTaskType}`
            } else if (status === 'bounty') {
                return `Bounty + ${prettifiedSingleTaskType}`
            } else if (status === 'off_market') {
                return `In Progress + ${prettifiedSingleTaskType}`
            } else if (status === 'unavailable') {
                return `Unavailable + ${prettifiedSingleTaskType}`
            } else {
                return availabilityStatus
            }
        }

        if (isMultiTasksVehicle) {
            const prettifiedMultiTasksType = `${snakeCaseToDisplayName(tasks[0].type)} + ${snakeCaseToDisplayName(
                tasks[1].type,
            )}`
            const hasPendingTask = !!tasks.find(task => task.status === 'pending')

            if (status === 'ready_need') {
                return `Ready + ${prettifiedMultiTasksType}`
            } else if (status === 'bounty') {
                return `Bounty + ${prettifiedMultiTasksType}`
            } else if (status === 'unavailable') {
                return `Unavailable + ${prettifiedMultiTasksType}`
            } else if (hasPendingTask) {
                const prettifiedTaskOne =
                    tasks[0].status === 'pending'
                        ? `In Progress + ${snakeCaseToDisplayName(tasks[0].type)}`
                        : snakeCaseToDisplayName(tasks[0].type)
                const prettifiedTaskTwo =
                    tasks[1].status === 'pending'
                        ? `In Progress + ${snakeCaseToDisplayName(tasks[1].type)}`
                        : snakeCaseToDisplayName(tasks[1].type)

                return `${prettifiedTaskOne} | ${prettifiedTaskTwo}`
            } else {
                return availabilityStatus
            }
        }
    }

    return snakeCaseToDisplayName(status)
}

export const getVehicleCategory = (vehicle: BulkListVehicle | Vehicle): string | undefined => {
    return 'category' in vehicle ? vehicle.category : vehicle.modelSpecification.category
}

export function getVehiclePageTitle(vehicle: Vehicle | BulkListVehicle): string {
    const category = getVehicleCategory(vehicle)
    if (!category) {
        return vehicle.short
    }

    return `${firstLetterUpperCase(category)}: ${vehicle.short}`
}
