export function shortenEmail(email: string): string {
    if (email.length <= 24) {
        return email
    }

    const charsToRemove = email.length - 24

    const [preAt, postAt] = email.split('@')
    const newPreAt = preAt.slice(0, preAt.length - charsToRemove)

    return `${newPreAt}…@${postAt}`
}
