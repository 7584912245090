import { type AppState } from 'src/redux/app/app.store'

export const selectVmapVehicles = (state: AppState) => state.vmap.vehicles
export const selectVmapFilters = (state: AppState) => state.vmap.filters
export const selectVmapInterval = (state: AppState) => state.vmap.mapInterval
export const selectIsVmapLoading = (state: AppState) => state.vmap.mapLoading
export const selectVmapCurrentZone = (state: AppState) => state.vmap.currentZone
export const selectAllVmapModels = (state: AppState) => state.vmap.allModels
export const selectIsPriorityFilterActive = (state: AppState) => state.vmap.filters.priorities.isFilterActive
export const selectToggledPriorities = (state: AppState) => state.vmap.filters.priorities.toggledPriorities
