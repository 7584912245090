export const firstLetterUpperCase = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}

export function snakeCaseToDisplayName(str: string): string {
    return str.split('_').map(firstLetterUpperCase).join(' ')
}

export function pluralizeAndStringify(value: number, word: string, suffix: string = 's'): string {
    return value === 1 ? word : `${word}${suffix}`
}

/**
 * @param email E.g. 'john.doe.smith@voiapp.io'
 * @returns E.g. 'John Doe Smith'
 */
export const nameByEmail = (email: string) => {
    try {
        return email.split('@')[0].split('.').map(firstLetterUpperCase).join(' ')
    } catch (_) {}

    return email
}

/**
 * @param str e.g. someCamelCaseString
 * @returns string e.g. Some Camel Case String
 */
export const camelCaseToProperCase = (str: string): string =>
    str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim()

/**
 * @param str e.g. some-kebab-case-string
 * returns string e.g. someKebabCaseString
 */
export const kebabToCamelCase = (str: string): string => str.replace(/-./g, match => match.charAt(1).toUpperCase())
