import { type PropsWithChildren } from 'react'
import { ErrorBoundary, type FallbackRender } from '@sentry/react'

interface Props {
    fallback: FallbackRender
}

export const BaseErrorBoundary = ({ fallback, children }: PropsWithChildren<Props>) => (
    <ErrorBoundary fallback={fallback}>{children}</ErrorBoundary>
)
