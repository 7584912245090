import { type VmapVehicleStatusFilter } from 'src/components/vmap/filters/types'
import { snakeCaseToDisplayName } from 'src/utils/string/stringUtils'

export const intervalOptions = [
    { value: '5000', label: '5 sec' },
    { value: '15000', label: '15 sec' },
    { value: '30000', label: '30 sec' },
    { value: '60000', label: '1 min' },
]

export function getAllActiveStates(state: VmapVehicleStatusFilter): string[] {
    const singleKeys = Object.entries(state).reduce(
        (prev, [key, value]) => (typeof value === 'boolean' && value ? [...prev, key] : prev),
        [] as string[],
    )

    const multiKeys = Object.entries(state).reduce(
        (prev, [key, value]) =>
            typeof value === 'object' && Object.values(value).includes(true) ? [...prev, key] : prev,
        [] as string[],
    )

    return [...singleKeys, ...multiKeys]
}

export function getStatusPlaceholder(state: VmapVehicleStatusFilter): string {
    if (isAllStatesInactive(state)) {
        return 'Search...'
    }
    if (isAllStatesActive(state)) {
        return 'All States'
    }
    const statuses = getAllActiveStates(state)
    if (!statuses || statuses.length === 0) {
        return ''
    }

    return statuses.length > 2
        ? `${snakeCaseToDisplayName(statuses[0])}, ${snakeCaseToDisplayName(statuses[1])} + ${
              statuses.length - 2
          } more..`
        : statuses.map(s => snakeCaseToDisplayName(s)).join(', ')
}

export function isAllStatesActive(filter: VmapVehicleStatusFilter): boolean {
    return (
        isAllSubOptionsActive(filter.bounty) &&
        isAllSubOptionsActive(filter.ready_need) &&
        isAllSubOptionsActive(filter.off_market) &&
        filter.storage &&
        filter.ready &&
        filter.booked &&
        filter.in_repair &&
        filter.riding &&
        filter.lost &&
        filter.unavailable &&
        filter.home
    )
}

export function isAllSubOptionsActive(filter: Record<string, boolean>): boolean {
    return Object.keys(filter).every(i => filter[i])
}

function isAllStatesInactive(filter: VmapVehicleStatusFilter): boolean {
    return (
        isAllSubOptionsInactive(filter.bounty) &&
        isAllSubOptionsInactive(filter.ready_need) &&
        isAllSubOptionsInactive(filter.off_market) &&
        !filter.storage &&
        !filter.ready &&
        !filter.booked &&
        !filter.in_repair &&
        !filter.riding &&
        !filter.lost &&
        !filter.unavailable &&
        !filter.home
    )
}

function isAllSubOptionsInactive(filter: Record<string, boolean>): boolean {
    return Object.keys(filter).every(i => filter[i] === false)
}
