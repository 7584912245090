import { type VehicleStatus, type VehicleCategory } from 'src/api/fm/vehicles/vehicles.model'
import { type ServerError } from 'src/utils/scooterBulk/types'

export interface Task {
    accountId?: string
    auditLog: TaskAuditLog[]
    collectState?: TaskEventState
    createdAt: string
    createdBattery: number
    creationReason: string
    deletionReason: string
    dropState?: TaskEventState
    executionPriority: ExecutionPriorityApi
    forceClosed?: boolean
    riderIncentivized?: boolean
    hasPhoto: boolean
    id: string
    info?: string
    infoInternal?: string
    ownerId?: string
    ownerType?: TaskOwnerType
    status: TaskStatus
    type: TaskType
    vehicle?: TaskVehicle
    vehicleId: string
    zoneId: string
}

export interface TaskWithScooterShort extends Task {
    scooterShort: string
}

export type TaskType =
    | 'transport'
    | 'battery_swap'
    | 'license_plate_swap'
    | 'rebalance'
    | 'repark'
    | 'deploy'
    | 'storage'
    | 'rescue'
    | 'in_field_quality_check'
    | 'in_field_repair'

export interface TaskAuditLog {
    createdAt: string
    eventType: TaskAuditLogEventType
    id: string
    operatorEmail: string
    operatorId: string
    taskId: string
}

type TaskAuditLogEventType =
    | 'battery_swap_started'
    | 'battery_swap_finalized'
    | 'created'
    | 'collected'
    | 'declined'
    | 'deleted'
    | 'dropped'
    | 'fulfilled'

export interface TaskEventState {
    battery: number
    batterySerial?: string
    lat: number
    lng: number
    time: string
    nodeId?: string
}

export interface TaskVehicle {
    battery: number
    category: VehicleCategory
    id: string
    lastSeen: string
    location: {
        lat: number
        lng: number
    }
    name: string
    short: string
    status: VehicleStatus
}

// FIXME: Investigate possible values and see if we can deprecate this field
type TaskOwnerType = 'hunter' | 'organization'

export type TaskStatus = 'new' | 'pending' | 'declined' | 'deleted' | 'fulfilled'

export type CloseTaskStatus = 'fulfilled' | 'declined'

interface ExecutionPriorityApi {
    name: ExecutionPriority
    order: number
}

export type ExecutionPriority = 'normal' | 'high' | 'low'
export const EXECUTION_PRIORITY_OPTIONS: ExecutionPriority[] = ['normal', 'high', 'low']
export const EXECUTION_PRIORITY_OPTIONS_WITHOUT_LOW: ExecutionPriority[] = ['normal', 'high']
export const EXECUTION_PRIORITY_LABELS: Record<ExecutionPriority, string> = {
    normal: 'Normal',
    high: 'Urgent (High)',
    low: 'Bonus (Low)',
}

export type ParkingPhotoSettings = ParkingPhotoSetting[]

interface ParkingPhotoSetting {
    name: string
    enabled: boolean
}

export interface GetParkingPhotoSettingsResponse {
    data: {
        flows: ParkingPhotoSettings
    }
}

export interface UpdateParkingPhotoSettingsResponse {
    data: {
        flows: ParkingPhotoSettings
    }
}

export interface DeleteTasksBulkResponse {
    errors: ServerError[]
}

export interface GetTaskTypesResponse {
    taskTypes: TaskType[]
}
