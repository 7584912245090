import {
    ADD_VEHICLE_IDS,
    type AddVehicleIds,
    FETCH_VEHICLE_IDS,
    type FetchVehicleIds,
    type VehicleId,
} from 'src/redux/vehicleIds/vehicleIds.types'

export function addVehicleIds(vehicleIds: VehicleId[]): AddVehicleIds {
    return {
        type: ADD_VEHICLE_IDS,
        payload: vehicleIds,
    }
}

export function fetchVehicleIds(vehicleIds: string[]): FetchVehicleIds {
    return {
        type: FETCH_VEHICLE_IDS,
        payload: vehicleIds,
    }
}
