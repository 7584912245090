import {
    SET_MAINTENANCE_NEED_VEHICLES,
    type MaintenanceNeedAction,
    type MaintenanceNeedState,
} from 'src/redux/maintenanceNeed/maintenanceNeed.types'

export const INITIAL_STATE: MaintenanceNeedState = {
    maintenanceNeedVehicles: [],
}

export const maintenanceNeedReducer = (state = INITIAL_STATE, action: MaintenanceNeedAction): MaintenanceNeedState => {
    switch (action.type) {
        case SET_MAINTENANCE_NEED_VEHICLES:
            return {
                ...state,
                maintenanceNeedVehicles: action.payload,
            }
        default:
            return state
    }
}
