import { call, put, type SagaReturnType, select, takeEvery } from 'redux-saga/effects'
import { getPhotoUrlByTaskId } from 'src/api/fm/vehiclePhotos/vehiclePhotos'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    getHunterParkingPhotoByTaskIdSuccess,
    setIsFetchingMoreTasksStart,
    selectIsFetchingMoreTasks,
    selectHunterParkingPhotoCurrentPage,
    selectIsLastFiveIndex,
} from 'src/redux/hunterParkingPhotos'
import { getNextHistoryPage } from 'src/redux/organization'
import { getVehicleTaskHistoryNextPage } from 'src/redux/scooter'
import { getHunterTaskHistoryNextPage } from 'src/redux/hunter'
import {
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID,
    type GetHunterParkingPhotoByTaskId,
    type HunterParkingPhotosCurrentPage,
    SHOW_NEXT_HUNTER_PARKING_PHOTO,
} from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.types'

type GetPhotoUrlByTaskId = SagaReturnType<typeof getPhotoUrlByTaskId>

export function* getHunterParkingPhotoByTaskId(action: GetHunterParkingPhotoByTaskId) {
    const taskId = action.payload

    const photoUrl: GetPhotoUrlByTaskId = yield call(getPhotoUrlByTaskId, taskId)

    if (photoUrl instanceof Error) {
        yield call(notifyUser, photoUrl, 'error')
        return
    }

    yield put(getHunterParkingPhotoByTaskIdSuccess(taskId, photoUrl))
}

function* showNextHunterParkingPhoto() {
    const isFetchingMoreTasks: boolean = yield select(selectIsFetchingMoreTasks)
    if (isFetchingMoreTasks) {
        return
    }

    const currentPage: HunterParkingPhotosCurrentPage = yield select(selectHunterParkingPhotoCurrentPage)
    const isLastFiveIndex: boolean = yield select(selectIsLastFiveIndex)

    if (isLastFiveIndex) {
        if (currentPage === 'organization') {
            yield put(setIsFetchingMoreTasksStart())
            yield put(getNextHistoryPage())
        }
        if (currentPage === 'vehicle') {
            yield put(setIsFetchingMoreTasksStart())
            yield put(getVehicleTaskHistoryNextPage())
        }
        if (currentPage === 'hunter') {
            yield put(setIsFetchingMoreTasksStart())
            yield put(getHunterTaskHistoryNextPage())
        }
    }
}

export default function* watcher() {
    yield takeEvery(GET_HUNTER_PARKING_PHOTO_BY_TASK_ID, getHunterParkingPhotoByTaskId)
    yield takeEvery(SHOW_NEXT_HUNTER_PARKING_PHOTO, showNextHunterParkingPhoto)
}
