import { type SagaIterator } from 'redux-saga'
import { put, takeEvery, call, type SagaReturnType } from 'redux-saga/effects'
import { setVehicleReport, setIsLoading } from 'src/redux/vehicleReport/vehicleReport.actions'
import { GET_VEHICLE_REPORT, type GetVehicleReport } from 'src/redux/vehicleReport/vehicleReport.types'
import { getVehicleReport } from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'

type GetVehicleReportRes = SagaReturnType<typeof getVehicleReport>

export function* fetchVehicleReport(action: GetVehicleReport): SagaIterator {
    try {
        const id = action.payload
        yield put(setIsLoading(true))
        const res: GetVehicleReportRes = yield call(getVehicleReport, id)
        if (res instanceof Error) {
            throw res
        }
        yield put(setVehicleReport(res))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        yield put(setIsLoading(false))
    }
}

export default function* watcher(): any {
    yield takeEvery(GET_VEHICLE_REPORT, fetchVehicleReport)
}
