import { type Theme, withStyles, Tooltip } from '@material-ui/core'
import * as colors from 'src/ui-kit/colors/colors'

export const TooltipDark = withStyles({
    tooltip: {
        backgroundColor: colors.black,
        color: colors.white,
        fontSize: 11,
        maxWidth: '190px',
        padding: '10px 5px',
        textAlign: 'center',
    },
    arrow: {
        color: colors.black,
    },
})(Tooltip)

export const TooltipLight = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: colors.white,
        color: colors.black,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: '190px',
        padding: '10px 5px',
        textAlign: 'center',
    },
    arrow: {
        color: colors.white,
    },
}))(Tooltip)
