import {
    SET_PINNED_WAREHOUSES,
    SET_WAREHOUSES,
    type WarehouseState,
    type WarehouseAction,
} from 'src/redux/warehouse/warehouse.types'

export const INITIAL_STATE: WarehouseState = {
    warehouses: [],
    pinnedWarehouses: [],
}

export const warehouseReducer = (state = INITIAL_STATE, action: WarehouseAction): WarehouseState => {
    switch (action.type) {
        case SET_WAREHOUSES:
            return {
                ...state,
                warehouses: action.payload,
            }
        case SET_PINNED_WAREHOUSES:
            return {
                ...state,
                pinnedWarehouses: action.payload,
            }
        default:
            return state
    }
}
