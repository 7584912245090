import styled from 'styled-components'
import { zIndex } from 'src/constants/zIndex'

const Wrapper = styled.div<{ $pointerEvents?: string; $overflowY?: string }>`
    pointer-events: ${props => (props.$pointerEvents ? props.$pointerEvents : 'all')};
    background: #fcfcfc;
    color: #444;
    overflow-y: ${props => (props.$overflowY ? props.$overflowY : 'auto')};
    z-index: ${zIndex.lowest};
    font-size: 12px;
    padding: 14px 8px 8px;
    min-width: 160px;
    max-height: 240px;
    position: absolute;
    box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
`

export default Wrapper
