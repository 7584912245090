import { createSelector } from 'reselect'
import { type AppState } from 'src/redux/app/app.store'
import { type Ride } from 'src/api/fm/rentals/rentals.model'
import { type ActiveRidesColumnType } from 'src/redux/activeRides/activeRides.types'
import { sortByString, sortByTimeStamp, sortByNumber, type SortOrder } from 'src/utils/sort/sort'
import { formatUnixDate, unixToMoment } from 'src/utils/time/timeUtils'
import { getFormattedRideDuration } from 'src/utils/riders/riders'

export const selectActiveSortColumn = (state: AppState) => state.activeRides.active
export const selectColumnSortOrders = (state: AppState) => state.activeRides.sorting
export const selectSearchTerm = (state: AppState) => state.activeRides.searchTerm
export const selectRides = (state: AppState) => state.activeRides.rides

export const selectFilteredRides = createSelector([selectRides, selectSearchTerm], (rides, searchTerm) =>
    filterRides(rides, searchTerm),
)

export const selectFilteredAndSortedRides = createSelector(
    [selectActiveSortColumn, selectColumnSortOrders, selectFilteredRides],
    (column, sortOrders, filteredRides) => sortRides(filteredRides, column, sortOrders[column]),
)

const sortRides = (rides: Ride[], column: ActiveRidesColumnType, order: SortOrder) => {
    switch (column) {
        case 'qr':
            return sortByString(rides, 'vehicle_code', order)
        case 'zone':
            return sortByString(rides, 'zone_name', order)
        case 'lockAttempts':
            return sortByNumber(rides, 'lock_count', order)
        case 'firstLockAttempt':
            return sortByTimeStamp(rides, 'first_lock_attempt', order)
        case 'startTime':
            return sortByTimeStamp(rides, 'start_time', order)
        case 'minuteDuration':
            return sortByNumber(rides, 'minute_duration', order)
        default:
            return rides
    }
}

const filterRides = (rides: Ride[], searchTerm: string) => {
    if (searchTerm === '') {
        return rides
    }

    return rides.filter(
        r =>
            r.vehicle_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            r.zone_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            r.lock_count.toString() === searchTerm.toString() ||
            getFormattedRideDuration(r.start_time).includes(searchTerm) ||
            unixToMoment(r.first_lock_attempt).format('HH:mm').includes(searchTerm) ||
            formatUnixDate(r.start_time)?.includes(searchTerm),
    )
}
