import { type NoteActions, type NoteState, SET_NOTES } from 'src/redux/note/note.types'

const INITIAL_STATE: NoteState = {
    notes: [],
}

export function noteReducer(state: NoteState = INITIAL_STATE, action: NoteActions): NoteState {
    switch (action.type) {
        case SET_NOTES:
            return { ...state, notes: action.payload }
        default:
            return state
    }
}
