import { createAction, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { type AppState } from 'src/redux/app/app.store'
import { type Area, type AreaType, type FeatureCollection } from 'src/api/fm/zones/zones.model'

export enum LoadState {
    NotStarted = 'NotStarted',
    InProgress = 'InProgress',
    Success = 'Success',
    Failed = 'Failed',
}

interface ZoneAreasState {
    areas: FeatureCollection | undefined
    loadState: LoadState
}

export const requestFetchAreas = createAction<string>('zoneAreas/requestFetchAreas')

const initialState: ZoneAreasState = { areas: undefined, loadState: LoadState.NotStarted }

const zoneAreasSlice = createSlice({
    name: 'zoneAreas',
    initialState,
    reducers: {
        fetchAreasStart() {
            return {
                areas: undefined,
                loadState: LoadState.InProgress,
            }
        },
        fetchAreasSuccess(_, action: PayloadAction<FeatureCollection>) {
            return {
                areas: action.payload,
                loadState: LoadState.Success,
            }
        },
        fetchAreasFailed(_) {
            return {
                areas: undefined,
                loadState: LoadState.Failed,
            }
        },
        resetAreas(_) {
            return initialState
        },
    },
})

export const { fetchAreasStart, fetchAreasSuccess, fetchAreasFailed, resetAreas } = zoneAreasSlice.actions

const selectZoneAreas = (state: AppState): FeatureCollection | undefined => state.zoneAreas.areas

const DEFAULT_AREAS_BY_TYPE: Record<AreaType, Area[]> = {
    operations: [],
    bounty: [],
    'parking-spot': [],
    'no-parking': [],
    'slow-zone': [],
    'no-riding': [],
    incentive: [],
    'fleet-capacity': [],
    'soft-mpz': [],
    'free-floating': [],
}

export const selectZoneAreasByType = createSelector([selectZoneAreas], zoneAreas => {
    if (!zoneAreas) {
        return DEFAULT_AREAS_BY_TYPE
    }

    return zoneAreas.features.reduce((acc, area) => {
        const { area_type } = area.properties
        const newAreasForType = [...acc[area_type], area]

        return {
            ...acc,
            [area_type]: newAreasForType,
        }
    }, DEFAULT_AREAS_BY_TYPE)
})

export const { reducer: zoneAreasReducer } = zoneAreasSlice
