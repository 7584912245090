import { FormControlLabel, Typography, Checkbox as MaterialCheckbox, type CheckboxProps } from '@material-ui/core'
import {
    CheckBox,
    CheckBoxOutlineBlankOutlined,
    CheckBoxOutlined,
    IndeterminateCheckBoxOutlined,
} from '@material-ui/icons'
import styled from 'styled-components'
import * as colors from 'src/ui-kit/colors/colors'

const LABEL_FONT_FAMILY = ['montserrat', 'monospace'].join(',')
const LABEL_FONT_SMALL = { fontSize: '10px', fontWeight: '600' }
const LABEL_FONT_MEDIUM = { fontSize: '12px', fontWeight: '400' }

const CHECKBOX_FONT_SMALL = { width: '20px', height: '20px' }
const CHECKBOX_FONT_MEDIUM = { width: '28px', height: '28px' }

type Size = 'small' | 'medium'
type CheckboxType = 'outlined' | 'filled'

interface Props {
    label?: string
    labelColor?: string
    labelSize?: Size

    checkboxColor?: string
    checkboxSize?: Size
    checkboxType?: CheckboxType
}

export const Checkbox = (props: Props & CheckboxProps) => {
    const {
        label,
        labelColor = colors.black,
        labelSize = 'medium',

        checkboxColor = colors.coral,
        checkboxSize = 'medium',
        checkboxType = 'outlined',

        hidden = false,
        disabled = false,
        className,
        ...rest
    } = props

    if (hidden) {
        return null
    }

    const colorForLabel = disabled ? 'inherit' : labelColor
    const fontForLabel = labelSize === 'small' ? LABEL_FONT_SMALL : LABEL_FONT_MEDIUM

    const colorForCheckbox = disabled ? 'inherit' : checkboxColor
    const fontForCheckbox = checkboxSize === 'small' ? CHECKBOX_FONT_SMALL : CHECKBOX_FONT_MEDIUM

    return label ? (
        <FormControlLabel
            className={className}
            disabled={disabled}
            label={
                <Typography style={{ color: colorForLabel, fontFamily: LABEL_FONT_FAMILY, ...fontForLabel }}>
                    {label}
                </Typography>
            }
            control={
                <MaterialCheckbox
                    checkedIcon={CheckboxIcon(colorForCheckbox, fontForCheckbox, checkboxType)}
                    icon={CheckboxIcon(colorForCheckbox, fontForCheckbox)}
                    indeterminateIcon={InterminatedIcon(colorForCheckbox, fontForCheckbox)}
                    disableRipple
                    {...rest}
                />
            }
        />
    ) : (
        <MaterialCheckbox
            checkedIcon={CheckboxIcon(colorForCheckbox, fontForCheckbox, checkboxType)}
            icon={CheckboxIcon(colorForCheckbox, fontForCheckbox)}
            indeterminateIcon={InterminatedIcon(colorForCheckbox, fontForCheckbox)}
            disableRipple
            className={className}
            disabled={disabled}
            {...rest}
        />
    )
}

const CheckboxIcon = (color: string, fontForCheckbox: typeof CHECKBOX_FONT_SMALL, checkboxType?: CheckboxType) => {
    if (checkboxType === 'outlined') {
        return <CheckBoxOutlined style={{ color, ...fontForCheckbox }} />
    }

    if (checkboxType === 'filled') {
        return <CheckBox style={{ color, ...fontForCheckbox }} />
    }

    return <CheckBoxOutlineBlankOutlined style={{ color, ...fontForCheckbox }} />
}

const InterminatedIcon = (color: string, fontForCheckbox: typeof CHECKBOX_FONT_SMALL) => {
    return <IndeterminateCheckBoxOutlined style={{ color, ...fontForCheckbox }} />
}

export const CheckboxHoverable = styled(Checkbox)`
    &:hover {
        background-color: ${colors.dividerGrey};
        border-radius: 8px;
    }
`
