import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { appReducer } from 'src/redux/app/app.reducer'
import { appSagas } from 'src/redux/app/app.sagas'

const sagaMiddleware = createSagaMiddleware()

const devTools = process.env.NODE_ENV === 'development'

const store = configureStore({
    reducer: appReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            thunk: false,
            /*
                TODO: Enable the 2 checks below once our redux store is in a better shape.
                Currently they're affecting development experience too much by throwing a lot of errors and sometimes even resulting in 'Maximum call stack size exceeded'
            */
            immutableCheck: false,
            serializableCheck: false,
        }).concat(sagaMiddleware),
    devTools,
})

export const initializeAppStore = () => {
    sagaMiddleware.run(appSagas)
    return store
}

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
