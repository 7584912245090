import { ins } from 'src/api/axios'
import type { Violations, ViolationsResponse } from 'src/api/confighub/violations/violations.model'
import { getHeaders } from 'src/api/headers'

/**
 * Related {@link https://warf.voiapp.io/catalog/confighub/api/confighub-api/definition#/Violations/getViolations API documentation}
 */

const mapViolations = (data: ViolationsResponse): Violations => {
    return {
        missingConfigs: data.missing_configs,
        missingProperties: data.missing_properties,
    }
}

export const getViolations = async (): Promise<Violations | Error> => {
    const headers = await getHeaders()
    const link = encodeURI('/v1/confighub/violations')

    return ins
        .get<ViolationsResponse>(link, { headers })
        .then(res => mapViolations(res.data))
        .catch(e => new Error(`Failed to get violations: ${e}`))
}
