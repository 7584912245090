import type { MaintenanceNeed, MaintenanceNeedVehicle } from 'src/api/fm/maintenanceNeed/maintenanceNeed.model'
import {
    GET_MAINTENANCE_NEED_VEHICLES,
    SET_MAINTENANCE_NEED_VEHICLES,
    type GetMaintenanceNeedVehicles,
    type SetMaintenanceNeedVehicles,
    DELETE_MAINTENANCE_NEEDS,
    type DeleteMaintenanceNeeds,
    type CreateMaintenanceNeeds,
    CREATE_MAINTENANCE_NEEDS,
    type UpdateMaintenanceNeed,
    UPDATE_MAINTENANCE_NEED,
} from 'src/redux/maintenanceNeed/maintenanceNeed.types'

export const getMaintenanceNeedVehicles = (vehicleIds: string[]): GetMaintenanceNeedVehicles => {
    return {
        type: GET_MAINTENANCE_NEED_VEHICLES,
        payload: vehicleIds,
    }
}

export const setMaintenanceNeedVehicles = (
    maintenanceNeedVehicles: MaintenanceNeedVehicle[],
): SetMaintenanceNeedVehicles => {
    return {
        type: SET_MAINTENANCE_NEED_VEHICLES,
        payload: maintenanceNeedVehicles,
    }
}

export const createMaintenanceNeeds = (maintenanceNeed: MaintenanceNeed): CreateMaintenanceNeeds => {
    return {
        type: CREATE_MAINTENANCE_NEEDS,
        payload: maintenanceNeed,
    }
}

export const updateMaintenanceNeed = (maintenanceNeed: MaintenanceNeed): UpdateMaintenanceNeed => {
    return {
        type: UPDATE_MAINTENANCE_NEED,
        payload: maintenanceNeed,
    }
}

export const deleteMaintenanceNeeds = (maintenanceNeedIds: string[]): DeleteMaintenanceNeeds => {
    return {
        type: DELETE_MAINTENANCE_NEEDS,
        payload: maintenanceNeedIds,
    }
}
