import {
    type Area,
    type AreaType,
    type AreaGeometry,
    type AreaProperties,
    type FeatureCollection,
    type CreateNewAreasFeatureCollection,
} from 'src/api/fm/zones/zones.model'

export const ADD_NEW_AREA = 'ADD_NEW_AREA'
export const ADD_NEW_AREAS_SUCCESS = 'ADD_NEW_AREAS_SUCCESS'
export const SET_CURRENT_AREAS = 'SET_CURRENT_AREAS'
export const SET_AREA_FILTER = 'SET_AREA_FILTER'
export const TOGGLE_AREA_EXPANDED = 'TOGGLE_AREA_EXPANDED'
export const TOGGLE_ALL_AREAS_EXPANDED = 'TOGGLE_ALL_AREAS_EXPANDED'
export const SAVE_EDITING_AREA_PROPERTIES = 'SAVE_EDITING_AREA_PROPERTIES'
export const SAVE_EDITING_AREA_PROPERTIES_SUCCESS = 'SAVE_EDITING_AREA_PROPERTIES_SUCCESS'
export const DELETE_AREA = 'DELETE_AREA'
export const DELETE_AREA_SUCCESS = 'DELETE_AREA_SUCCESS'
export const DELETE_AREAS_SUCCESS = 'DELETE_AREAS_SUCCESS'
export const DELETE_AREAS = 'DELETE_AREAS'
export const SET_EDITING_AREA_POLYGON = 'SET_EDITING_AREA_POLYGON'
export const CANCEL_EDITING_AREA_POLYGON = 'CANCEL_EDITING_AREA_POLYGON'
export const HANDLE_EDITING_AREA_POLYGON = 'HANDLE_EDITING_AREA_POLYGON'
export const SAVE_EDITING_AREA_POLYGON = 'SAVE_EDITING_AREA_POLYGON'
export const SAVE_EDITING_AREA_POLYGON_SUCCESS = 'SAVE_EDITING_AREA_POLYGON_SUCCESS'
export const SAVE_EDITING_AREA_POLYGON_FAILURE = 'SAVE_EDITING_AREA_POLYGON_FAILURE'

export interface AreasState {
    areas: EditableAreasByType
}

export interface EditableArea {
    originalFeatureCollection: FeatureCollection
    draftFeatureCollection: FeatureCollection | null
    selectedFeatureIndexes: number[]
    selectedFeatureId: string | null
    isChanged: boolean
    expandedAreaIds: Record<string, string>
    filter: string
    mapMode: MapMode
}

export type EditableAreasByType = Record<AreaType, EditableArea | null>

export type MapMode = 'view' | 'edit'

export interface AddNewArea {
    type: typeof ADD_NEW_AREA
    payload: { area: CreateNewAreasFeatureCollection }
}

export interface AddNewAreasSuccess {
    type: typeof ADD_NEW_AREAS_SUCCESS
    payload: { areas: Area[] }
}

export interface SetCurrentAreas {
    type: typeof SET_CURRENT_AREAS
    payload: { areas: FeatureCollection }
}

export interface SetAreaFilter {
    type: typeof SET_AREA_FILTER
    payload: { areaType: AreaType; filter: string }
}

export interface ToggleAreaExpanded {
    type: typeof TOGGLE_AREA_EXPANDED
    payload: { areaType: AreaType; areaId: string }
}

export interface ToggleAllAreasExpanded {
    type: typeof TOGGLE_ALL_AREAS_EXPANDED
    payload: { areaType: AreaType }
}

export interface SaveEditingAreaProperties {
    type: typeof SAVE_EDITING_AREA_PROPERTIES
    payload: { properties: AreaProperties; id: string; geometry: AreaGeometry }
}

export interface SaveEditingAreaPropertiesSuccess {
    type: typeof SAVE_EDITING_AREA_PROPERTIES_SUCCESS
    payload: { area: Area }
}

export interface DeleteArea {
    type: typeof DELETE_AREA
    payload: { areaType: AreaType; areaId: string }
}

export interface DeleteAreas {
    type: typeof DELETE_AREAS
    payload: { areaType: AreaType; areaIds: string[] }
}

export interface DeleteAreaSuccess {
    type: typeof DELETE_AREA_SUCCESS
    payload: { areaType: AreaType; areaId: string }
}

export interface DeleteAreasSuccess {
    type: typeof DELETE_AREAS_SUCCESS
    payload: { areaType: AreaType; areaIds: string[] }
}

export interface SetEditingAreaPolygon {
    type: typeof SET_EDITING_AREA_POLYGON
    payload: {
        areaType: AreaType
        selectedFeatureIndexes: number[]
        selectedFeatureId: string
    }
}

export interface CancelEditingAreaPolygon {
    type: typeof CANCEL_EDITING_AREA_POLYGON
    payload: { areaType: AreaType }
}

export interface HandleEditingAreaPolygon {
    type: typeof HANDLE_EDITING_AREA_POLYGON
    payload: { areaType: AreaType; featureCollection: FeatureCollection }
}

export interface SaveEditingAreaPolygon {
    type: typeof SAVE_EDITING_AREA_POLYGON
    payload: { areaType: AreaType }
}

export interface SaveEditingAreaPolygonSuccess {
    type: typeof SAVE_EDITING_AREA_POLYGON_SUCCESS
    payload: { areaType: AreaType; newArea: Area }
}

export interface SaveEditingAreaPolygonFailure {
    type: typeof SAVE_EDITING_AREA_POLYGON_FAILURE
    payload: { areaType: AreaType }
}

export type AreasActions =
    | AddNewArea
    | AddNewAreasSuccess
    | SetCurrentAreas
    | SetAreaFilter
    | ToggleAreaExpanded
    | ToggleAllAreasExpanded
    | SaveEditingAreaProperties
    | SaveEditingAreaPropertiesSuccess
    | DeleteArea
    | DeleteAreas
    | DeleteAreaSuccess
    | DeleteAreasSuccess
    | SetEditingAreaPolygon
    | CancelEditingAreaPolygon
    | HandleEditingAreaPolygon
    | SaveEditingAreaPolygon
    | SaveEditingAreaPolygonSuccess
    | SaveEditingAreaPolygonFailure
