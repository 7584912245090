import { type User } from 'firebase/auth'

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'
export const SET_ROLES = 'SET_ROLES'
export const SET_USER = 'SET_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const LOGIN_USER = 'LOGIN_USER'

export type Roles = string[]

export interface AuthState {
    isAuthenticated: boolean | null
    authError: string | null
    roles: Roles | null
    user: CurrentUser | null
}

export interface CurrentUser {
    userId: string
    email: string
    photoUrl: string | null
}

export interface SetAuth {
    type: typeof IS_AUTHENTICATED
    payload: boolean | null
}

export interface SetAuthError {
    type: typeof SET_AUTH_ERROR
    payload: string
}

export interface SetRoles {
    type: typeof SET_ROLES
    payload: string[] | null
}

export interface SetUser {
    type: typeof SET_USER
    payload: CurrentUser | null
}

export interface LogoutUser {
    type: typeof LOGOUT_USER
}

export interface LoginUser {
    type: typeof LOGIN_USER
    payload: User
}

export type AuthActions = SetAuth | SetAuthError | SetRoles | SetUser | LogoutUser | LoginUser
