import {
    APPLY_CAMPAIGN_FILTERS,
    CREATE_CAMPAIGN,
    EDIT_CAMPAIGN,
    GET_CAMPAIGNS,
    SEARCH_CAMPAIGNS,
    SET_CAMPAIGNS,
    SET_CAMPAIGN_FILTERS,
    SET_OG_CAMPAIGNS,
    type CampaignsFilters,
    type ApplyCampaignFilters,
    type SearchCampaigns,
    type EditCampaign,
    type CreateCampaign,
    type GetCampaigns,
    type SetOgCampaigns,
    type SetCampaigns,
    type SetCampaignFilters,
} from 'src/redux/campaigns/campaigns.types'
import { type Campaign } from 'src/api/fm/promotions/promotions.model'

export const editCampaign = (campaignId: string, campaign: Campaign): EditCampaign => ({
    type: EDIT_CAMPAIGN,
    payload: { campaignId, campaign },
})

export const createCampaign = (campaign: Campaign): CreateCampaign => ({
    type: CREATE_CAMPAIGN,
    payload: campaign,
})

export const applyCampaignFilters = (payload: CampaignsFilters): ApplyCampaignFilters => ({
    type: APPLY_CAMPAIGN_FILTERS,
    payload,
})

export const searchCampaigns = (payload: string): SearchCampaigns => ({
    type: SEARCH_CAMPAIGNS,
    payload,
})

export const getCampaigns = (): GetCampaigns => ({
    type: GET_CAMPAIGNS,
})

export const setOgCampaigns = (payload: Campaign[]): SetOgCampaigns => ({
    type: SET_OG_CAMPAIGNS,
    payload,
})

export const setCampaigns = (payload: Campaign[]): SetCampaigns => ({
    type: SET_CAMPAIGNS,
    payload,
})

export const setCampaignFilters = (payload: CampaignsFilters): SetCampaignFilters => ({
    type: SET_CAMPAIGN_FILTERS,
    payload,
})
