import { call, put, select, takeEvery } from 'redux-saga/effects'
import { searchVehicles, resetVehicleSearch, selectSearchQuery } from 'src/redux/vehicleSearch'
import {
    clearBulkListErrors,
    clearVehicleBulkList,
    fetchBulkListVehicles,
    setDuplicatedSearchItems,
    setVehiclesNotFound,
} from 'src/redux/scooterBulk'
import { handleDuplicatedSearch } from 'src/components/scooters/vehicles-search/utils'
import { notifyUser } from 'src/components/parts/notifications/notifications'

const vehicleImeiRegex = new RegExp(/^[0-9]{15}$/)

export function* handleVehicleSearch(action: ReturnType<typeof searchVehicles>) {
    const historyPush = action.payload
    const query: string = yield select(selectSearchQuery)

    if (query.length === 0) {
        return
    }

    yield put(clearBulkListErrors())
    if (query.length > 4) {
        const searchArray = query
            .split(/[\s,]+/)
            .map(e => e.replace(/^\s+|\s+$/g, ''))
            .filter(e => e.length >= 4)

        if (searchArray.length === 1) {
            yield put(resetVehicleSearch())
            historyPush(`/vehicles/${searchArray[0]}`)
        } else {
            if (isImeiBulkSearch(searchArray)) {
                yield put(setDuplicatedSearchItems([]))
                yield put(setVehiclesNotFound([]))
                yield put(clearVehicleBulkList())
                yield call(
                    notifyUser,
                    {
                        message: 'Search by IMEI is only supported for single vehicles',
                    },
                    'error',
                )
            } else {
                const { uniqText, duplicatedText } = handleDuplicatedSearch(searchArray.join(','))
                yield put(setDuplicatedSearchItems(duplicatedText))
                yield put(fetchBulkListVehicles(uniqText))
            }

            historyPush('/vehicles')
        }
    } else {
        yield put(resetVehicleSearch())
        yield put(setDuplicatedSearchItems([]))
        historyPush(`/vehicles/${query}`)
    }
}

export default function* watcher() {
    yield takeEvery(searchVehicles.toString(), handleVehicleSearch)
}

const isImeiBulkSearch = (searchArray: string[]): boolean => {
    return vehicleImeiRegex.test(searchArray[0])
}
