import type { PermissionsForRoles } from 'src/api/fm/permissions/permissions.model'
import {
    GET_PERMISSIONS_FOR_ROLES_SUCCESS,
    type PermissionsForRolesActions,
} from 'src/redux/permissionsForRoles/permissionsForRoles.types'

interface PermissionsForRolesState {
    permissions: PermissionsForRoles
}

const INITIAL_STATE = { permissions: {} }

export function permissionsForRolesReducer(
    state: PermissionsForRolesState = INITIAL_STATE,
    action: PermissionsForRolesActions,
) {
    switch (action.type) {
        case GET_PERMISSIONS_FOR_ROLES_SUCCESS: {
            return { ...state, permissions: action.payload.permissions }
        }

        default:
            return state
    }
}
