import {
    type OrganizationState,
    INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM,
    SET_CURRENT_ORGANIZATION,
    SET_END_OF_PAGINATION,
    SET_ORGANIZATION_HISTORY_FILTER,
    SET_ORGANIZATION_HISTORY_TASKS,
    type OrganizationActions,
    SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS,
    SET_ORGANIZATION_ALLOWED_AREAS,
} from 'src/redux/organization/organization.types'

export const INITIAL_STATE: OrganizationState = {
    organization: null,
    allowedAreas: [],
    history: [],
    isFetchingHistory: false,
    filters: null,
    pageNum: 1,
    endOfPagination: false,
}

export function organizationReducer(
    state: OrganizationState = INITIAL_STATE,
    action: OrganizationActions,
): OrganizationState {
    switch (action.type) {
        case SET_CURRENT_ORGANIZATION:
            return {
                ...state,
                organization: action.payload,
                history: [],
            }

        case SET_ORGANIZATION_HISTORY_TASKS:
            return {
                ...state,
                history: action.payload,
            }

        case SET_IS_FETCHING_ORGANIZATION_HISTORY_TASKS:
            return {
                ...state,
                isFetchingHistory: action.payload,
            }

        case SET_ORGANIZATION_HISTORY_FILTER:
            return {
                ...state,
                pageNum: 1,
                history: [],
                filters: action.payload,
                endOfPagination: false,
            }

        case INCREMENT_ORGANIZATION_HISTORY_PAGE_NUM:
            return {
                ...state,
                pageNum: state.pageNum + 1,
            }

        case SET_END_OF_PAGINATION:
            return {
                ...state,
                endOfPagination: true,
            }

        case SET_ORGANIZATION_ALLOWED_AREAS:
            return {
                ...state,
                allowedAreas: action.payload,
            }

        default:
            return state
    }
}
