import type { Violations } from 'src/api/confighub/violations/violations.model'
import { SET_VIOLATIONS, type GetViolations, type SetViolations } from 'src/redux/violations/violations.types'

export const getViolationsAction = (): GetViolations => ({
    type: 'GET_VIOLATIONS',
})

export const setViolationsAction = (payload: Violations): SetViolations => ({
    type: SET_VIOLATIONS,
    payload,
})
