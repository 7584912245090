import { DEFAULT_MAP_SETTINGS, type MapSettings, MAP_AREA_TYPES } from 'src/redux/mapSettings/mapSettings.types'

export const MAP_SETTINGS_LOCAL_STORAGE_KEY = 'mapSettings' as const

export function getLocalStorageMapSettings(): MapSettings {
    const string = localStorage.getItem(MAP_SETTINGS_LOCAL_STORAGE_KEY)
    if (!string) {
        return DEFAULT_MAP_SETTINGS
    }

    try {
        const json = JSON.parse(string)
        const mapSettings = mapObjectToMapSettings(json)

        return mapSettings ?? DEFAULT_MAP_SETTINGS
    } catch (_) {
        return DEFAULT_MAP_SETTINGS
    }
}

export function setLocalStorageMapSettings(mapSettings: MapSettings) {
    localStorage.setItem(MAP_SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(mapSettings))
}

function mapObjectToMapSettings(object: Record<any, any>): MapSettings | null {
    const { toggledAreaTypes } = object

    if (!Array.isArray(toggledAreaTypes)) {
        return null
    }

    const verifiedToggledAreas = toggledAreaTypes.filter(area => MAP_AREA_TYPES.includes(area))

    return {
        toggledAreaTypes: verifiedToggledAreas,
    }
}
