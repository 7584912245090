import {
    type ACTIONS,
    type UserInfo,
    type UserParkingHistory,
    type UserStats,
} from 'src/api/fm/safetyControlSystem/safetyControlSystem.model'

export const SAFETY_CONTROL_SYSTEM_INFO = 'safety_control/GET_USER_INFO'
export const SAFETY_CONTROL_USER_STATS = 'safety_control/SET_USER_STATS'
export const SAFETY_CONTROL_USER_PARKING_HISTORY = 'safety_control/SET_USER_PARKING_HISTORY'
export const SAFETY_CONTROL_CLEAR = 'safety_control/CLEAR'
export const SAFETY_CONTROL_SYSTEM_DATA = 'safety_control/GET_USER_DATA'
export const SET_SAFETY_CONTROL_SYSTEM_INFO = 'safety_control/SET_SCS_DATA'
export const USER_INFO_MARK_NO_MORE = 'safety_control/MARK_NO_MORE'
export const SEND_ACTION_REVIEW = 'safety_control/SEND_ACTION_REVIEW'
export const GET_USER_PARKING_HISTORY = 'safety_control/GET_USER_PARKING_HISTORY'
export const CLEAR_USER_PARKING_HISTORY = 'safety_control/CLEAR_USER_PARKING_HISTORY'

export interface SafetyControlSystemState {
    areAllReviewed: boolean
    userStats: UserStats | null
    userParkingHistory: UserParkingHistory[]
    userInfo: UserInfo | null
}

export interface GetSafetyControlInfo {
    type: typeof SAFETY_CONTROL_SYSTEM_INFO
}

export interface MarkNoMoreUsers {
    type: typeof USER_INFO_MARK_NO_MORE
}

export interface GetSafetyControlUserStats {
    type: typeof SAFETY_CONTROL_SYSTEM_DATA
    payload: { userId: string; zoneId: string }
}

export interface SetSafetyControlSystemInfo {
    type: typeof SET_SAFETY_CONTROL_SYSTEM_INFO
    payload: UserInfo
}

export interface SetSafetyControlUserStats {
    type: typeof SAFETY_CONTROL_USER_STATS
    payload: UserStats
}

export interface SetSafetyControlUserParkingHistory {
    type: typeof SAFETY_CONTROL_USER_PARKING_HISTORY
    payload: UserParkingHistory[]
}

export interface ClearSafetyControlInfo {
    type: typeof SAFETY_CONTROL_CLEAR
}

export interface SendUserReviewAction {
    type: typeof SEND_ACTION_REVIEW
    payload: { backlogId: string; actionReview: ACTIONS }
}

export interface GetUserParkingHistory {
    type: typeof GET_USER_PARKING_HISTORY
    payload: { userId: string; zoneId: string }
}

export interface ClearUserParkingHistory {
    type: typeof CLEAR_USER_PARKING_HISTORY
}

export type SafetyControlSystemActions =
    | GetSafetyControlInfo
    | MarkNoMoreUsers
    | GetSafetyControlUserStats
    | SetSafetyControlSystemInfo
    | SetSafetyControlUserStats
    | SetSafetyControlUserParkingHistory
    | ClearSafetyControlInfo
    | SendUserReviewAction
    | GetUserParkingHistory
    | ClearUserParkingHistory
