import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'

export const GET_VEHICLE_MODELS = 'GET_VEHICLE_MODELS'
export const SET_VEHICLE_MODELS = 'SET_VEHICLE_MODELS'

export interface VehicleModelState {
    vehicleModels: VehicleModel[]
}

export interface GetVehicleModels {
    type: typeof GET_VEHICLE_MODELS
}

export interface SetVehicleModels {
    type: typeof SET_VEHICLE_MODELS
    payload: VehicleModel[]
}

export type VehicleModelsActions = GetVehicleModels | SetVehicleModels
