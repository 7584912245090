import { createSlice, createAction, type PayloadAction } from '@reduxjs/toolkit'
import type { WorkArea, WorkAreaZoneActivationStatus } from 'src/api/fm/workArea/workArea.model'
import { type AppState } from 'src/redux/app/app.store'

interface WorkAreaState {
    workAreas: WorkArea[]
    workAreaZoneActivationStatus: WorkAreaZoneActivationStatus | undefined
}

export const initialState: WorkAreaState = {
    workAreas: [],
    workAreaZoneActivationStatus: undefined,
}

const workAreaSlice = createSlice({
    name: 'workArea',
    initialState,
    reducers: {
        setWorkAreas(state, action: PayloadAction<WorkArea[]>) {
            return {
                ...state,
                workAreas: action.payload,
            }
        },
        setWorkAreaZoneActivationStatus(state, action: PayloadAction<WorkAreaZoneActivationStatus | undefined>) {
            return {
                ...state,
                workAreaZoneActivationStatus: action.payload,
            }
        },
    },
})

// Actions
export const { setWorkAreas, setWorkAreaZoneActivationStatus } = workAreaSlice.actions
export const createWorkArea = createAction<WorkArea>(`${workAreaSlice.name}/createWorkArea`)
export const updateWorkArea = createAction<WorkArea>(`${workAreaSlice.name}/updateWorkArea`)
export const deleteWorkArea = createAction<WorkArea>(`${workAreaSlice.name}/deleteWorkArea`)
export const getWorkAreas = createAction<string>(`${workAreaSlice.name}/getWorkAreas`)
export const createWorkAreaZoneActivation = createAction<string>(`${workAreaSlice.name}/createWorkAreaZoneActivation`)
export const deleteWorkAreaZoneActivation = createAction<string>(`${workAreaSlice.name}/deleteWorkAreaZoneActivation`)
export const getWorkAreaZoneActivationStatus = createAction<string>(
    `${workAreaSlice.name}/getWorkAreaZoneActivationStatus`,
)

// Selectors
export const selectWorkAreas = (state: AppState): WorkArea[] => state.workArea.workAreas
export const selectWorkAreaZoneActivationStatus = (state: AppState): WorkAreaZoneActivationStatus | undefined =>
    state.workArea.workAreaZoneActivationStatus

// Reducer
export const { reducer: workAreaReducer } = workAreaSlice
