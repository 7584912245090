import { type Vehicle, type UpdateVehicleRequest } from 'src/api/fm/vehicles/vehicles.model'
import { type RideHistoryItem } from 'src/api/fm/rentals/rentals.model'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import {
    GET_VEHICLE,
    GET_VEHICLE_ERROR,
    RESET_VEHICLE_STATE,
    UPDATE_VEHICLE_INFO,
    SET_CURRENT_VEHICLE,
    SET_VEHICLE_RIDE_HISTORY,
    SET_VEHICLE_TASK_HISTORY,
    REMOVE_VEHICLE_FROM_REPAIR,
    BIKE_UNARM_LOCK,
    GET_VEHICLE_TASK_HISTORY,
    GET_VEHICLE_TASK_HISTORY_NEXT_PAGE,
    RESET_VEHICLE_TASK_HISTORY,
    GET_VEHICLE_RIDE_HISTORY,
    SET_VEHICLE_RIDE_HISTORY_DATE_FILTER,
    SET_CURRENT_VEHICLE_RIDER,
    RESET_SCOOTER_RIDE_HISTORY,
    GET_VEHICLE_LICENSE_PLATE_SUCCESS,
    DECOMMISSION_VEHICLE,
    SET_END_OF_TASK_HISTORY_PAGINATION,
    INCREMENT_TASK_HISTORY_PAGINATION,
    type VehicleCurrentRider,
    GET_VEHICLE_DECOMMISSIONED_INFO,
    SET_VEHICLE_DECOMMISSIONED_INFO,
    UNDO_DECOMMISSION_VEHICLE,
    SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER,
    SET_IS_FETCHING_VEHICLE_TASK_HISTORY,
} from 'src/redux/scooter/scooter.types'
import { type VehicleDecommissionedInfo, type DecommissionReason } from 'src/api/fm/decommission/decommission.model'

export type GetVehicleAction<T extends VehicleAction['type']> = Extract<VehicleAction, { type: T }>

export type VehicleAction =
    | ReturnType<typeof getVehicle>
    | ReturnType<typeof getVehicleError>
    | ReturnType<typeof setCurrentVehicle>
    | ReturnType<typeof setRideHistory>
    | ReturnType<typeof setVehicleTaskHistory>
    | ReturnType<typeof setIsFetchingVehicleTaskHistory>
    | ReturnType<typeof resetVehicleState>
    | ReturnType<typeof updateVehicleInfo>
    | ReturnType<typeof removeVehicleFromRepair>
    | ReturnType<typeof bikeUnarmLock>
    | ReturnType<typeof getVehicleTaskHistory>
    | ReturnType<typeof getVehicleTaskHistoryNextPage>
    | ReturnType<typeof resetVehicleTaskHistory>
    | ReturnType<typeof getVehicleRideHistory>
    | ReturnType<typeof setVehicleRideHistoryDateFilter>
    | ReturnType<typeof setVehicleRideHistorySourceFilter>
    | ReturnType<typeof setCurrentVehicleRider>
    | ReturnType<typeof resetScooterRideHistory>
    | ReturnType<typeof incrementTaskHistoryPagination>
    | ReturnType<typeof setEndOfTaskHistoryPagination>
    | ReturnType<typeof getVehicleLicensePlateSuccess>
    | ReturnType<typeof decommissionVehicle>
    | ReturnType<typeof undoDecommissionVehicle>
    | ReturnType<typeof getVehicleDecommissionedInfo>
    | ReturnType<typeof setVehicleDecommissionedInfo>

export const getVehicle = (id: string) => {
    return {
        type: GET_VEHICLE,
        payload: id,
    }
}

export const getVehicleError = (error: string) => {
    return {
        type: GET_VEHICLE_ERROR,
        payload: error,
    }
}

export const setCurrentVehicle = (vehicle: Vehicle) => {
    return {
        type: SET_CURRENT_VEHICLE,
        payload: vehicle,
    }
}

export const setRideHistory = (data: RideHistoryItem[]) => {
    return {
        type: SET_VEHICLE_RIDE_HISTORY,
        payload: data,
    }
}

export const setVehicleTaskHistory = (data: Task[]) => {
    return {
        type: SET_VEHICLE_TASK_HISTORY,
        payload: data,
    }
}

export const setIsFetchingVehicleTaskHistory = (isFetching: boolean) => {
    return {
        type: SET_IS_FETCHING_VEHICLE_TASK_HISTORY,
        payload: isFetching,
    }
}

export const resetVehicleState = () => {
    return {
        type: RESET_VEHICLE_STATE,
    }
}

export const updateVehicleInfo = (vehicleId: string, data: UpdateVehicleRequest) => {
    return {
        type: UPDATE_VEHICLE_INFO,
        payload: {
            vehicleId,
            data,
        },
    }
}

export const removeVehicleFromRepair = (scooter: { [key: string]: any }) => {
    return {
        type: REMOVE_VEHICLE_FROM_REPAIR,
        payload: { scooter },
    }
}

export const bikeUnarmLock = (bikeId: string) => {
    return {
        type: BIKE_UNARM_LOCK,
        payload: bikeId,
    }
}

export const getVehicleTaskHistory = () => ({
    type: GET_VEHICLE_TASK_HISTORY,
})

export const getVehicleTaskHistoryNextPage = () => ({
    type: GET_VEHICLE_TASK_HISTORY_NEXT_PAGE,
})

export const resetVehicleTaskHistory = () => ({
    type: RESET_VEHICLE_TASK_HISTORY,
})

export const getVehicleRideHistory = (payload: number) => ({
    type: GET_VEHICLE_RIDE_HISTORY,
    payload,
})

export const setVehicleRideHistoryDateFilter = (payload: string) => ({
    type: SET_VEHICLE_RIDE_HISTORY_DATE_FILTER,
    payload,
})

export const setVehicleRideHistorySourceFilter = (payload: string) => ({
    type: SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER,
    payload,
})

export const setCurrentVehicleRider = (payload: VehicleCurrentRider | null) => ({
    type: SET_CURRENT_VEHICLE_RIDER,
    payload,
})

export const resetScooterRideHistory = () => ({
    type: RESET_SCOOTER_RIDE_HISTORY,
})

export const incrementTaskHistoryPagination = () => ({
    type: INCREMENT_TASK_HISTORY_PAGINATION,
})

export const setEndOfTaskHistoryPagination = () => ({
    type: SET_END_OF_TASK_HISTORY_PAGINATION,
})

export const getVehicleLicensePlateSuccess = (licensePlate: string | null) => ({
    type: GET_VEHICLE_LICENSE_PLATE_SUCCESS,
    payload: { licensePlate },
})

export const decommissionVehicle = (vehicleId: string, reason: DecommissionReason) => ({
    type: DECOMMISSION_VEHICLE,
    payload: { vehicleId, reason },
})

export const undoDecommissionVehicle = (vehicleId: string) => ({
    type: UNDO_DECOMMISSION_VEHICLE,
    payload: { vehicleId },
})

export const getVehicleDecommissionedInfo = (vehicleId: string) => ({
    type: GET_VEHICLE_DECOMMISSIONED_INFO,
    payload: { vehicleId },
})

export const setVehicleDecommissionedInfo = (vehicleDecommissionedInfo: VehicleDecommissionedInfo) => ({
    type: SET_VEHICLE_DECOMMISSIONED_INFO,
    payload: vehicleDecommissionedInfo,
})
