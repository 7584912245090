import { createAction, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type AppState } from 'src/redux/app/app.store'
import { type AreaType } from 'src/api/fm/zones/zones.model'
import { getLocalStorageMapSettings } from 'src/redux/mapSettings/mapSettings.localStorage'
import { type MapSettings } from 'src/redux/mapSettings/mapSettings.types'

export const toggleAreaType = createAction<AreaType>('mapSettings/toggleAreaType')

const mapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState: getLocalStorageMapSettings(),
    reducers: {
        setMapSettings(_, action: PayloadAction<MapSettings>) {
            return action.payload
        },
    },
})

export const { setMapSettings } = mapSettingsSlice.actions

export const { reducer: mapSettingsReducer } = mapSettingsSlice

export const selectMapSettings = (state: AppState): MapSettings => state.mapSettings
export const selectToggledAreaTypes = (state: AppState): AreaType[] => state.mapSettings.toggledAreaTypes
