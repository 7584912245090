import styled from 'styled-components'
import * as colors from 'src/ui-kit/colors/colors'
import { zIndex } from 'src/constants/zIndex'

type ArrowPosition = 'left' | 'right'

type Position = 'top' | 'bottom'

interface Props {
    position?: Position
    top?: string
    right?: string
    bottom?: string
    left?: string
    width?: string
    children: any
    arrowPosition?: ArrowPosition
}

function TextTooltip({ children, top, right, bottom, left, width, position = 'top', arrowPosition }: Props) {
    return (
        <TextTooltipWrapper $top={top} $right={right} $bottom={bottom} $left={left} $width={width}>
            <TextWrapper data-testid={'text-tooltip-content'}>{children}</TextWrapper>
            <ToolTipArrow $arrowPosition={arrowPosition} $position={position} />
        </TextTooltipWrapper>
    )
}

export default TextTooltip

const TextTooltipWrapper = styled.div<{
    $top: string | undefined
    $bottom: string | undefined
    $left: string | undefined
    $right: string | undefined
    $width: string | undefined
}>`
    background: ${colors.black};
    border-radius: 8px;
    color: ${colors.white};
    position: absolute;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.16px;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    top: ${props => props.$top};
    right: ${props => props.$right};
    bottom: ${props => props.$bottom};
    left: ${props => props.$left};
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    width: ${props => (props.$width ? props.$width : 'max-content')};
    z-index: ${zIndex.high};
`

const TextWrapper = styled.div`
    box-sizing: border-box;
    padding: 9px 16px;
    text-align: center;
`

const ToolTipArrow = styled.div<{ $position: Position; $arrowPosition?: ArrowPosition }>`
    position: absolute;
    top: ${props => (props.$position === 'top' ? '100%' : '-8px')};
    left: ${props =>
        props.$arrowPosition === 'left' ? '10%' : props.$arrowPosition === 'right' ? '90%' : 'calc(50% - 8px)'};
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    ${props =>
        props.$position === 'top'
            ? `border-top: 9px solid ${colors.black}`
            : `border-bottom: 9px solid ${colors.black}`};
`
