import { type LicensePlatesDeactivationResult } from 'src/models/licensePlate'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'

export const CLEAR_VEHICLE_BULK_LIST = 'CLEAR_VEHICLE_BULK_LIST' as const
export const CLEAR_VEHICLE_BULK_LIST_ERRORS = 'CLEAR_VEHICLE_BULK_LIST_ERRORS' as const
export const REMOVE_VEHICLES_FROM_BULK_LIST = 'REMOVE_VEHICLES_FROM_BULK_LIST' as const
export const SET_HAS_HANDLED_REPAIRS = 'SET_HAS_HANDLED_REPAIRS' as const
export const SET_VEHICLE_BULK_LIST = 'SET_VEHICLE_BULK_LIST' as const
export const SET_VEHICLE_BULK_LIST_ERRORS = 'SET_VEHICLE_BULK_LIST_ERRORS' as const
export const SET_VEHICLES_NOT_FOUND = 'SET_VEHICLES_NOT_FOUND' as const
export const SET_INVALID_QRS = 'SET_INVALID_QRS' as const
export const REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST = 'REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST' as const
export const UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS = 'UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS' as const
export const SET_BULK_ACTION_LOADING = 'SET_BULK_ACTION_LOADING' as const
export const SET_BULK_ACTION_DONE = 'SET_BULK_ACTION_DONE' as const
export const SET_BULK_LIST_FILTER = 'SET_BULK_LIST_FILTER' as const
export const BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT = 'BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT' as const
export const BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT = 'BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT' as const
export const BULK_CREATE_SCOOTER_TASKS = 'BULK_CREATE_SCOOTER_TASKS' as const
export const BULK_DELETE_SCOOTER_TASKS = 'BULK_DELETE_SCOOTER_TASKS' as const
export const BULK_UPDATE_SCOOTER_TASK_STATUS = 'BULK_UPDATE_SCOOTER_TASK_STATUS' as const
export const BULK_UPDATE_SCOOTER_ZONE = 'BULK_UPDATE_SCOOTER_ZONE' as const
export const BULK_UPDATE_TASK_PRIORITY = 'BULK_UPDATE_TASK_PRIORITY' as const
export const BULK_DEACTIVATE_LICENSE_PLATES = 'BULK_DEACTIVATE_LICENSE_PLATES' as const
export const BULK_DECOMMISSION_VEHICLES = 'BULK_DECOMMISSION_VEHICLES' as const
export const BULK_UNDO_DECOMMISSION_VEHICLES = 'BULK_UNDO_DECOMMISSION_VEHICLES' as const
export const BULK_SELL_VEHICLES = 'BULK_SELL_VEHICLES' as const
export const BULK_UNDO_SELL_VEHICLES = 'BULK_UNDO_SELL_VEHICLES' as const
export const FETCH_BULK_LIST_VEHICLES = 'FETCH_BULK_LIST_VEHICLES' as const
export const SET_DUPLICATED_SEARCH_ITEMS = 'SET_DUPLICATED_SEARCH_ITEMS' as const
export const FETCH_BULK_LIST_BY_QUERY_QRS = 'FETCH_BULK_LIST_BY_QUERY_QRS' as const
export const SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS = 'SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS' as const
export const INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS =
    'INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS' as const
export const CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS = 'CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS' as const

export interface BulkActionLoading {
    totalRequests: number
    finishedRequests: number
}

export interface ScooterBulkState {
    hasHandledRepairs: boolean
    vehicleBulkList: BulkListVehicle[]
    vehicleBulkListErrors: Record<string, string>
    licensePlatesDeactivationResult: LicensePlatesDeactivationResult
    filter: string
    loadingState: BulkActionLoading | null
    vehiclesNotFound: string[]
    invalidQRs: string[]
    duplicatedSearchItems: string[]
}
