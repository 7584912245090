import moment from 'moment'
import { unixToMoment } from 'src/utils/time/timeUtils'
import { type Campaign, type CampaignDiscountType } from 'src/api/fm/promotions/promotions.model'

export const PROMOTION_TYPE_OPTIONS: Record<CampaignDiscountType, string> = {
    credit: 'Credits',
    free_ride: 'Free ride',
    free_unlock: 'Free unlock',
    flat: 'Discount',
    voi_pass: 'Daypass',
}

export const filterCampaigns = (
    campaigns: Campaign[],
    filters: {
        [key: string]: any
    },
): Campaign[] => {
    const { active, available } = filters
    let filtered: Campaign[] = [...campaigns]
    if (available) {
        filtered = filtered.filter(campaign => campaign.timesUsed! - campaign.maxUses !== 0)
    }
    if (active) {
        filtered = filtered.filter(
            c => moment().isSameOrAfter(unixToMoment(c.validFrom)) && moment().isSameOrBefore(unixToMoment(c.validTo)),
        )
    }

    return filtered
}

export const searchCampaigns = (campaigns: Campaign[], searchTerm: string): Campaign[] => {
    return campaigns.filter(
        campaign =>
            campaign.internalName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            campaign.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            campaign.code.toLowerCase().includes(searchTerm.toLowerCase()),
    )
}

export const sanitizeCampaignName = (value: string) => value.replace(/[^A-Za-z0-9_ ]/g, '')
export const sanitizeCampaignCode = (value: string) => value.replace(/[^A-Za-z0-9_+#]/g, '')
