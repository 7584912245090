import { center, type Feature, type FeatureCollection, type MultiPolygon, type Point } from '@turf/turf'

export function isValidGeoJsonFeatureCollection(geojson: FeatureCollection): boolean {
    if (geojson.type !== 'FeatureCollection') {
        return false
    }

    if (!geojson.features?.length) {
        return false
    }

    return geojson.features.every(isValidGeoJsonFeature)
}

export function isValidGeoJsonFeature(geojson: Feature): boolean {
    if (geojson.type !== 'Feature') {
        return false
    }

    if (!geojson.geometry) {
        return false
    }

    const { coordinates } = geojson.geometry as Point
    return !!coordinates?.length
}

export const getGeoJsonCenter = (geoJson: Feature<MultiPolygon> | FeatureCollection<MultiPolygon>) => {
    const centerPoint = center(geoJson)

    return {
        lng: centerPoint.geometry.coordinates[0],
        lat: centerPoint.geometry.coordinates[1],
    }
}
