import { uniq } from 'lodash-es'
import {
    SEARCH_USER,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_ERROR,
    RESET_USER,
    TOGGLE_USER_ROLE,
    UPDATE_USER_ROLES_SUCCESS,
    FM_ROLE_PREFIX,
    BACKEND_ADMIN_ROLE_NAME,
    type PermissionsState,
    type PermissionsActions,
} from 'src/redux/permissions/permissions.types'

export const INITIAL_STATE: PermissionsState = {
    user: null,
    isUserChanged: false,
}

export function permissionsReducer(
    state: PermissionsState = INITIAL_STATE,
    action: PermissionsActions,
): PermissionsState {
    switch (action.type) {
        case SEARCH_USER: {
            return {
                ...state,
                isUserChanged: false,
            }
        }

        case SEARCH_USER_SUCCESS: {
            const { user } = action.payload

            return {
                ...state,
                user,
                isUserChanged: false,
            }
        }

        case SEARCH_USER_ERROR: {
            return {
                ...state,
                user: null,
                isUserChanged: false,
            }
        }

        case RESET_USER: {
            return {
                ...state,
                user: null,
                isUserChanged: false,
            }
        }

        case TOGGLE_USER_ROLE: {
            const { role } = action.payload

            if (!state.user) {
                return state
            }
            const previousRoles = state.user.roles.global
            const isToggled = previousRoles.includes(role)
            const newGlobalRoles = isToggled
                ? previousRoles.filter(prevRole => prevRole !== role)
                : [...previousRoles, role]

            const newRolesHasVoiOrgRole = newGlobalRoles.some(role => role.includes(FM_ROLE_PREFIX))
            const newGlobalRolesWithAdmin = newRolesHasVoiOrgRole
                ? uniq([...newGlobalRoles, BACKEND_ADMIN_ROLE_NAME])
                : newGlobalRoles.filter(role => role !== BACKEND_ADMIN_ROLE_NAME)

            const user = {
                ...state.user,
                roles: {
                    ...state.user.roles,
                    global: newGlobalRolesWithAdmin,
                },
            }
            return {
                ...state,
                user,
                isUserChanged: true,
            }
        }

        case UPDATE_USER_ROLES_SUCCESS: {
            return {
                ...state,
                user: null,
                isUserChanged: false,
            }
        }

        default:
            return state
    }
}
