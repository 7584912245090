import { type ExecutionPriority } from 'src/api/fm/tasks/tasks.model'
import {
    type ChildStatusKey,
    type VmapZoneFilter,
    type VmapBatteryFilter,
    type VmapFilters,
    type VmapVehicleModel,
    type VmapVehicleStatusFilterKey,
    type VmapVehicleStatusFilterWithChild,
} from 'src/components/vmap/filters/types'
import {
    ADD_ZONE_FILTER_VMAP,
    ADD_BATTERY_FILTER_VMAP,
    REMOVE_ZONE_FILTER_VMAP,
    REMOVE_STATUS_FILTER_VMAP,
    SET_INITIAL_VMAP_FILTERS,
    GET_INITIAL_VMAP_FILTERS,
    SET_CURRENT_ZONE_VMAP,
    TOGGLE_VMAP_MODEL_FILTER,
    TOGGLE_VMAP_ALL_MODEL_FILTERS,
    SET_VEHICLES_VMAP,
    UPDATE_VEHICLE_MAP,
    TOGGLE_MAP_LOADING_VMAP,
    TOGGLE_VMAP_ALL_STATES,
    TOGGLE_VMAP_STATE_PARENT,
    TOGGLE_VMAP_STATE_CHILD,
    RESET_VMAP_STATES,
    SET_MAP_INTERVAL,
    SET_ALL_VMAP_MODELS,
    RESET_VMAP_MODEL_FILTERS,
    TOGGLE_TASK_PRIORITY,
    TOGGLE_TASK_PRIORITY_FILTER,
    type VmapCurrentZone,
    type AddZoneFilter,
    type AddBatteryFilter,
    type RemoveZoneFilter,
    type RemoveStatusFilter,
    type SetVehicles,
    type UpdateVehicleMap,
    type SetMapLoading,
    type GetInitialVmapFilters,
    type SetInitialVmapFilters,
    type SetCurrentZoneVmap,
    type ToggleVmapAllStates,
    type ToggleVmapStateParent,
    type ToggleVmapStateChild,
    type ResetVmapStates,
    type SetMapInterval,
    type ToggleVmapModelFilter,
    type ToggleVmapAllModelFilters,
    type ResetVmapModelFilters,
    type SetAllVmapModels,
    type ToggleTaskPriority,
    type ToggleTaskPriorityFilter,
    type SetOrganizationVmapFilter,
    SET_ORGANIZATION_VMAP_FILTER,
} from 'src/redux/vmap/vmap.types'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'

export const addZoneFilter = (filter: VmapZoneFilter): AddZoneFilter => {
    return {
        type: ADD_ZONE_FILTER_VMAP,
        payload: filter,
    }
}

export const addBatteryFilter = (filter: VmapBatteryFilter): AddBatteryFilter => {
    return {
        type: ADD_BATTERY_FILTER_VMAP,
        payload: filter,
    }
}

export const removeZoneFilter = (): RemoveZoneFilter => {
    return {
        type: REMOVE_ZONE_FILTER_VMAP,
    }
}

export const removeStatusFilter = (): RemoveStatusFilter => {
    return {
        type: REMOVE_STATUS_FILTER_VMAP,
    }
}

export const setVehicles = (vehicles: Vehicle[]): SetVehicles => {
    return {
        type: SET_VEHICLES_VMAP,
        payload: vehicles,
    }
}

export const updateVehicleMap = (): UpdateVehicleMap => {
    return {
        type: UPDATE_VEHICLE_MAP,
    }
}

export const setMapLoading = (value: boolean): SetMapLoading => {
    return {
        type: TOGGLE_MAP_LOADING_VMAP,
        payload: value,
    }
}

export const getInitialVmapFilters = (): GetInitialVmapFilters => {
    return {
        type: GET_INITIAL_VMAP_FILTERS,
    }
}
export const setInitialVmapFilters = (filters: VmapFilters): SetInitialVmapFilters => {
    return {
        type: SET_INITIAL_VMAP_FILTERS,
        payload: filters,
    }
}

export const setCurrentZoneVmap = (zoneData: VmapCurrentZone): SetCurrentZoneVmap => ({
    type: SET_CURRENT_ZONE_VMAP,
    payload: zoneData,
})

export const toggleVmapAllStates = (): ToggleVmapAllStates => ({
    type: TOGGLE_VMAP_ALL_STATES,
})

export const toggleVmapStateParent = (parent: VmapVehicleStatusFilterKey): ToggleVmapStateParent => ({
    type: TOGGLE_VMAP_STATE_PARENT,
    payload: parent,
})

export const toggleVmapStateChild = (
    parent: VmapVehicleStatusFilterWithChild,
    child: ChildStatusKey,
): ToggleVmapStateChild => ({
    type: TOGGLE_VMAP_STATE_CHILD,
    payload: {
        parent,
        child,
    },
})

export const resetVmapStates = (): ResetVmapStates => ({
    type: RESET_VMAP_STATES,
})

export const setMapInterval = (mapInterval: string): SetMapInterval => ({
    type: SET_MAP_INTERVAL,
    payload: mapInterval,
})

export const toggleVmapModelFilter = (modelId: string): ToggleVmapModelFilter => ({
    type: TOGGLE_VMAP_MODEL_FILTER,
    payload: modelId,
})

export const toggleVmapAllModelFilters = (): ToggleVmapAllModelFilters => ({
    type: TOGGLE_VMAP_ALL_MODEL_FILTERS,
})

export const resetVmapModelFilters = (): ResetVmapModelFilters => ({
    type: RESET_VMAP_MODEL_FILTERS,
})

export const setAllVmapModels = (models: VmapVehicleModel[]): SetAllVmapModels => ({
    type: SET_ALL_VMAP_MODELS,
    payload: models,
})

export const toggleTaskPriority = (priority: ExecutionPriority): ToggleTaskPriority => ({
    type: TOGGLE_TASK_PRIORITY,
    payload: priority,
})

export const toggleTaskPriorityFilter = (): ToggleTaskPriorityFilter => ({
    type: TOGGLE_TASK_PRIORITY_FILTER,
})

export const setOrganizationVmapFilter = (organizationId: string | undefined): SetOrganizationVmapFilter => {
    return {
        type: SET_ORGANIZATION_VMAP_FILTER,
        payload: organizationId,
    }
}
