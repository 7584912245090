import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { makeStyles, TableSortLabel as TableSortLabelMaterial } from '@material-ui/core'
import * as colors from 'src/ui-kit/colors/colors'

export const useTableStyles = makeStyles(() => ({
    headerCell: {
        border: 0,
        backgroundColor: colors.lightGrey,
        color: colors.black,
        fontFamily: "'Overpass Mono', monospace",
        fontWeight: 700,
        textTransform: 'uppercase',
        fontSize: '13px',
        lineHeight: '13px',
        letterSpacing: 0,
    },
    cell: {
        backgroundColor: colors.white,
        color: colors.black,
        fontFamily: "'Montserrat', monospace",
        fontSize: '13px',
        borderColor: colors.dividerGrey,
        verticalAlign: 'middle',
    },
    stickyColumnHeaderCell: {
        position: 'sticky',
        left: 0,
        // Sticky header has z-index: 2 in Material UI, this needs to be higher
        // for the column header cell so that other columns scrolls beneath it
        zIndex: 3,
    },
    stickyColumnCell: {
        position: 'sticky',
        left: 0,
    },
    containerScrollable: {
        maxHeight: '680px',
        overflowY: 'auto',
    },
    containerScrollableSmall: {
        maxHeight: '500px',
        overflowY: 'auto',
    },
    row: {
        '& > :first-child': {
            paddingLeft: '24px',
        },
        '& > :last-child': {
            paddingRight: '24px',
        },
    },
    actions: {
        '& .MuiTablePagination-caption, & .MuiTablePagination-selectRoot': {
            fontFamily: "'Montserrat', monospace",
        },
    },
    isRowDisabled: {
        opacity: '0.5',
    },
}))

interface Props {
    active: boolean
    direction: 'asc' | 'desc'
}

export const TableSortLabel = (props: PropsWithChildren<Props>) => {
    const { children, ...restProps } = props

    return (
        <TableSortLabelMaterial {...restProps}>
            <TableSortLabelContent>{children}</TableSortLabelContent>
        </TableSortLabelMaterial>
    )
}

// Fix alignment of header text and sort icon in Firefox
const TableSortLabelContent = styled.div`
    @supports (-moz-appearance: none) {
        margin-top: 1px;
    }
`
