import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import {
    type UserInfo,
    type UserStats,
    type UserParkingHistory,
    type ACTIONS,
    type GetSafetyControlSystemDataResponse,
    type GetUserStatsResponse,
    type GetUserParkingHistoryResponse,
} from 'src/api/fm/safetyControlSystem/safetyControlSystem.model'

export const getSafetyControlSystemData = async (): Promise<UserInfo | null | Error> => {
    const link = encodeURI(`/v1/fm/safety-control/backlog/next`)
    const headers = await getHeaders()

    return ins
        .get<GetSafetyControlSystemDataResponse>(link, { headers })
        .then(res => mapGetUserInfo(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getUserStats = async (userId: string, zoneId: string): Promise<UserStats | Error> => {
    const link = encodeURI(`/v1/fm/safety-control/stats/${userId}/${zoneId}`)
    const headers = await getHeaders()

    return ins
        .get<GetUserStatsResponse>(link, { headers })
        .then(res => mapGetUserStats(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getUserParkingHistory = async (userId: string, zoneId: string): Promise<UserParkingHistory[] | Error> => {
    const link = encodeURI(`v1/fm/safety-control/parking-reviews/${userId}/${zoneId}`)
    const headers = await getHeaders()

    return ins
        .get<GetUserParkingHistoryResponse>(link, { headers })
        .then(res => mapGetUserParkingHistory(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const persistUserReview = async (backlogId: string, actionReview: ACTIONS): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/safety-control/backlog/${backlogId}/complete`)
    const headers = await getHeaders()

    return ins
        .post(link, { action: actionReview }, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetUserInfo = (res: GetSafetyControlSystemDataResponse): UserInfo | null => {
    if (res.data) {
        return {
            action: res.data.action,
            agentId: res.data.agent_id,
            assignedAt: res.data.assigned_at,
            completedAt: res.data.completed_at,
            countedAt: res.data.counted_at,
            eventType: res.data.event_type,
            externalId: res.data.external_id,
            id: res.data.id,
            isAutomaticAction: res.data.is_automatic_action,
            occurredAt: res.data.occurred_at,
            receivedAt: res.data.received_at,
            status: res.data.status,
            userId: res.data.user_id,
            zoneId: res.data.zone_id,
        }
    }

    return null
}

const mapGetUserStats = (res: GetUserStatsResponse): UserStats => {
    return {
        userId: res.data.user_id,
        zoneId: res.data.zone_id,
        badPhotoCounter: res.data.bad_photo_counter,
        badParkingCounter: res.data.bad_parking_counter,
        noPhotoCounter: res.data.no_photo_counter,
        abandonedVehicleCounter: res.data.abandoned_vehicle_counter,
        totalBadPhoto: res.data.total_bad_photo,
        totalBadParking: res.data.total_bad_parking,
        totalNoPhoto: res.data.total_no_photo,
        totalAbandonedVehicle: res.data.total_abandoned_vehicle,
        totalReviews: res.data.total_reviews,
    }
}

const mapGetUserParkingHistory = (res: GetUserParkingHistoryResponse): UserParkingHistory[] => {
    return res.data.map(item => ({
        action: item.action,
        badParking: item.bad_parking,
        badPhoto: item.bad_photo,
        noPhoto: item.no_photo,
        abandonedVehicle: item.abandoned_vehicle,
        photoUrl: item.photo_url,
        reviewCompletedAt: item.review_completed_at,
        reviewId: item.review_id,
        reviewedBy: item.reviewed_by,
        rideId: item.ride_id,
        userId: item.user_id,
        zoneId: item.user_id,
    }))
}
