import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type ReceiptsBulkURLResponse, type ReceiptsGetBulkURLParams } from 'src/api/fm/receipts/receipts.model'

export async function receiptsGetBulkUrl(params: ReceiptsGetBulkURLParams): Promise<ReceiptsBulkURLResponse | Error> {
    const link = '/v1/fm/receipts/bulk-url'
    const headers = await getHeaders()
    return ins
        .get(link, { params, headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
