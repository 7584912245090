import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { type VehicleReportResponseData } from 'src/api/fm/vehicleReports/vehicleReports.model'

export const getVehicleReport = async (id: string) => {
    const link = encodeURI(`/v1/fm/vehicle-reports/${id}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(result => mapGetVehicleReport(result.data))
        .catch(error => error)
}

const mapGetVehicleReport = (data: VehicleReportResponseData) => {
    const {
        id,
        createdAt,
        QRCode,
        latitude,
        longitude,
        photos,
        reportCategory,
        reportReason,
        reporter,
        reporterCategory,
    } = data
    return {
        id,
        createdAt,
        qrCode: QRCode || null,
        latitude: latitude || null,
        longitude: longitude || null,
        photos,
        reportCategory: reportCategory || null,
        reportReason: reportReason || null,
        reporter: reporter || null,
        reporterCategory: reporterCategory || null,
    }
}
