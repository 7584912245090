import { type PropsWithChildren, useRef } from 'react'
import styled from 'styled-components'
import { useScrollToTopOnNavigate } from 'src/components/app/utils/hooks/useScrollToTopOnNavigate'
import * as colors from 'src/ui-kit/colors/colors'
import * as breakpoints from 'src/constants/breakpoints'
import { fontPrimary } from 'src/ui-kit/fonts/fontFamily'

export const ContentWrapper = ({ children }: PropsWithChildren<unknown>) => {
    const ref = useRef(null)
    useScrollToTopOnNavigate(ref)

    return <Wrapper ref={ref}>{children}</Wrapper>
}

const Wrapper = styled.div`
    padding: 30px;
    overflow: auto;
    flex-grow: 2;
    ${fontPrimary}
    background-color: ${colors.bgColor};

    @media (max-width: ${breakpoints.css.mobile}) {
        padding: 30px 20px;
    }
`
