import { type ZoneFeatures } from 'src/api/fm/features/features.model'
import { type Zone, type LegalEntity, type ParkingAssistantSettings } from 'src/api/fm/zones/zones.model'
import { type ParkingPhotoSettings } from 'src/api/fm/tasks/tasks.model'
import { type VehiclePolicy } from 'src/api/fm/vehiclePolicy/vehiclePolicy.model'

export const EDIT_COUNTRY_INTENT = 'EDIT_COUNTRY_INTENT' as const
export const CREATE_COUNTRY = 'CREATE_COUNTRY' as const
export const EDIT_COUNTRY = 'EDIT_COUNTRY' as const
export const FETCH_ALL_COUNTRIES = 'FETCH_ALL_COUNTRIES' as const
export const SET_ZONE_OPTIONS = 'SET_ZONE_OPTIONS' as const
export const DELETE_ZONE_INTENT = 'DELETE_ZONE_INTENT' as const
export const DELETE_ZONE = 'DELETE_ZONE' as const
export const GET_ZONES_BY_COUNTRY = 'GET_ZONES_BY_COUNTRY' as const
export const SET_ZONES = 'SET_ZONES' as const
export const SET_CURRENT_ZONE = 'SET_CURRENT_ZONE' as const
export const SET_CURRENT_ZONE_ERROR = 'SET_CURRENT_ZONE_ERROR' as const
export const GET_ZONE = 'GET_ZONE' as const
export const CREATE_ZONE = 'CREATE_ZONE' as const
export const UPDATE_ZONE_DETAILS = 'UPDATE_ZONE_DETAILS' as const
export const UPDATE_ZONE_THRESHOLDS = 'UPDATE_ZONE_THRESHOLDS' as const
export const SET_ZONE_THRESHOLDS = 'SET_ZONE_THRESHOLDS' as const
export const SET_ALL_COUNTRIES = 'SET_ALL_COUNTRIES' as const
export const ADD_COUNTRY_SUCCESS = 'ADD_COUNTRY_SUCCESS' as const
export const EDIT_COUNTRY_SUCCESS = 'EDIT_COUNTRY_SUCCESS' as const
export const SET_ALL_ZONES = 'SET_ALL_ZONES' as const
export const GET_ZONES_AND_COUNTRIES = 'GET_ZONES_AND_COUNTRIES' as const
export const GET_ZONE_FEATURES = 'GET_ZONE_FEATURES' as const
export const SET_ZONE_FEATURES = 'SET_ZONE_FEATURES' as const
export const GET_VEHICLE_POLICY = 'GET_VEHICLE_POLICY' as const
export const SET_VEHICLE_POLICY = 'SET_VEHICLE_POLICY' as const
export const UPDATE_VEHICLE_POLICY = 'UPDATE_VEHICLE_POLICY' as const
export const UPDATE_ZONE_FEATURES = 'UPDATE_ZONE_FEATURES' as const
export const RESET_CURRENT_ZONE = 'RESET_CURRENT_ZONE' as const
export const GET_ZONE_PARKING_ASSISTANT_SETTINGS = 'GET_ZONE_PARKING_ASSISTANT_SETTINGS' as const
export const SET_ZONE_PARKING_ASSISTANT_SETTINGS = 'SET_ZONE_PARKING_ASSISTANT_SETTINGS' as const
export const UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS = 'UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS' as const
export const GET_PARKING_PHOTO_SETTINGS = 'GET_PARKING_PHOTO_SETTINGS' as const
export const GET_PARKING_PHOTO_SETTINGS_SUCCESS = 'GET_PARKING_PHOTO_SETTINGS_SUCCESS' as const
export const EDIT_PARKING_PHOTO_SETTINGS = 'EDIT_PARKING_PHOTO_SETTINGS' as const

export interface ZoneOption {
    value: string
    label: string
    currency: string
}

export interface ZoneStringOption {
    value: string
    label: string
}

export interface ZoneState {
    zones: Zone[] | null
    editCountry: LegalEntity | null
    zoneOptions: ZoneOption[]
    deleteZone: string | null
    allZones: Zone[]
    allCountries: LegalEntity[]
    currentZone: {
        data: Zone | null
        error: string | null
    }
    zoneFeatures: ZoneFeatures | null
    vehiclePolicy: VehiclePolicy | null
    zoneParkingAssistantSettings: ParkingAssistantSettings | null
    parkingPhotoSettings: ParkingPhotoSettings
}
