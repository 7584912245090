import { type ReactNode } from 'react'
import styled from 'styled-components'
import { H3 } from 'src/ui-kit/headings/Headings'
import { lightGrey } from 'src/ui-kit/colors/colors'
import * as breakpoints from 'src/constants/breakpoints'

export { CardTabsWrapper, CardTab } from 'src/ui-kit/card/cardTabs/CardTabs'

interface Props {
    children?: ReactNode
    title?: string
    dataTestId?: string
    subHeader?: string
}

export const CardHeader = (props: Props) => (
    <Header $subHeader={props.subHeader}>
        <HeaderCol $subHeader={props.subHeader}>
            {props.title && <H3 data-testid={props.dataTestId}>{props.title}</H3>}
            {props.subHeader && <SubHeader>{props.subHeader}</SubHeader>}
        </HeaderCol>
        {props.children && <HeaderCol $alignRight={true}>{props.children}</HeaderCol>}
    </Header>
)

export const ExpandContentBtn = styled.div`
    cursor: pointer;
    margin-top: 10px;
    width: 100%;
    padding: 4px;
    text-align: center;
    border-top: 1px solid #ccc;
`

export const Card = styled.div<{
    $position?: string
    $marginTop?: string
    $marginBottom?: string
    $paddingBottom?: string
}>`
    box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
    background-color: #fff;
    margin-top: ${props => props.$marginTop ?? 'auto'};
    margin-bottom: ${props => props.$marginBottom ?? '20px'};
    padding-bottom: ${props => props.$paddingBottom ?? '20px'};
    border-radius: 12px;
    position: ${props => (props.$position ? props.$position : 'auto')};
`

export const CardHeaderActions = styled.div`
    min-height: 40px;
    display: flex;
    align-items: center;
`

const Header = styled.div<{ $subHeader: string | undefined }>`
    padding: 24px;
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    ${props => props.$subHeader && `border-bottom: 1px solid ${lightGrey};`}
`

const HeaderCol = styled.div<{ $subHeader?: string; $alignRight?: true }>`
    display: flex;
    flex: 1;
    flex-direction: ${props => (props.$subHeader ? 'column' : 'row')};
    justify-content: ${props => (props.$alignRight ? 'flex-end' : '')};
    align-items: ${props => (props.$subHeader ? '' : 'center')};
`

export const CardBody = styled.div<{ $padding?: string; $scrollable?: boolean }>`
    padding: ${props => (props.$padding ? props.$padding : '24px')};
    ${props => props.$scrollable && 'max-height: 60vh;'}
    overflow-y: ${props => (props.$scrollable ? 'auto' : 'visible')};

    @media (height <= 700px) {
        ${props => props.$scrollable && 'max-height: 400px;'}
    }
`

export const CardContent = styled.div`
    display: flex;

    @media (max-width: ${breakpoints.css.tablet}) {
        flex-direction: column;
    }
`

export const CardSectionLeft = styled.div`
    width: 235px;
    flex-shrink: 0;
    padding: 0 25px 20px 0;

    @media (max-width: ${breakpoints.css.tablet}) {
        padding-right: 0;
        display: flex;
        width: 100%;
        align-items: flex-end;
    }
`

const SubHeader = styled.div`
    font-size: 12px;
    padding-top: 7px;
`
