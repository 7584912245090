import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/default/api/users-api/definition#/admin/unlinkPhone API documentation}
 */
export const unlinkUserPhone = async (riderId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/users/admin/unlink/phone/${riderId}`)
    const headers = await getHeaders()
    return ins
        .post(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
