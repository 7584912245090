import { type Color, type MapViewState, type ViewStateChangeParameters } from '@deck.gl/core'
import * as colors from 'src/ui-kit/colors/colors'
import { type AreaType } from 'src/api/fm/zones/zones.model'

type LayerStyle = {
    fill: Color
    line: Color
    opacity: number
    stroked: boolean
    filled: boolean
}

const defaultStyle = {
    opacity: 0.3,
    stroked: false,
    filled: true,
}

const areaTypeTolayerStyle: Record<AreaType, LayerStyle> = {
    operations: {
        fill: colors.OPERATIONS_COLOR,
        line: colors.OPERATIONS_COLOR,
        ...defaultStyle,
    },
    bounty: {
        fill: colors.BOUNTY_COLOR,
        line: colors.BOUNTY_COLOR,
        ...defaultStyle,
    },
    'fleet-capacity': {
        fill: colors.FLEET_CAPACITY_COLOR,
        line: colors.FLEET_CAPACITY_COLOR,
        ...defaultStyle,
    },
    incentive: {
        fill: colors.INCENTIVE_PARKING_COLOR,
        line: colors.INCENTIVE_PARKING_COLOR,
        ...defaultStyle,
    },
    'no-parking': {
        fill: colors.NO_PARKING_COLOR,
        line: colors.NO_PARKING_COLOR,
        ...defaultStyle,
    },
    'no-riding': {
        fill: colors.NO_RIDING_COLOR,
        line: colors.NO_RIDING_COLOR,
        ...defaultStyle,
    },
    'parking-spot': {
        fill: colors.PARKING_SPOT_COLOR,
        line: colors.PARKING_SPOT_COLOR,
        ...defaultStyle,
    },
    'slow-zone': {
        fill: colors.SLOW_ZONE_COLOR,
        line: colors.SLOW_ZONE_COLOR,
        ...defaultStyle,
    },
    'soft-mpz': {
        fill: colors.SOFT_MPZ_FILL_COLOR,
        line: colors.SOFT_MPZ_BORDER_COLOR,
        opacity: 0.1,
        stroked: true,
        filled: true,
    },
    'free-floating': {
        fill: colors.FREE_FLOATING_FILL_COLOR,
        line: colors.FREE_FLOATING_BORDER_COLOR,
        opacity: 0.1,
        stroked: true,
        filled: true,
    },
}

export const getLayerStyleByAreaType = (areaType: AreaType): LayerStyle => {
    const style = areaTypeTolayerStyle[areaType]
    if (!style) {
        return {
            fill: colors.DEFAULT_COLOR,
            line: colors.DEFAULT_COLOR,
            ...defaultStyle,
        }
    }
    return style
}

export const getTransitionDuration = (params: ViewStateChangeParameters<MapViewState>) => {
    // Hack to fix zoom bug for trackpad and apple mouse. See https://github.com/visgl/deck.gl/issues/7158#issuecomment-2305388963
    return params.interactionState.isZooming ? 0 : params.viewState.transitionDuration
}
