import {
    SET_CURRENT_AREAS,
    SET_AREA_FILTER,
    TOGGLE_AREA_EXPANDED,
    TOGGLE_ALL_AREAS_EXPANDED,
    ADD_NEW_AREA,
    ADD_NEW_AREAS_SUCCESS,
    SAVE_EDITING_AREA_PROPERTIES,
    SAVE_EDITING_AREA_PROPERTIES_SUCCESS,
    DELETE_AREA,
    DELETE_AREA_SUCCESS,
    SET_EDITING_AREA_POLYGON,
    CANCEL_EDITING_AREA_POLYGON,
    HANDLE_EDITING_AREA_POLYGON,
    SAVE_EDITING_AREA_POLYGON,
    SAVE_EDITING_AREA_POLYGON_SUCCESS,
    SAVE_EDITING_AREA_POLYGON_FAILURE,
    type AddNewArea,
    type AddNewAreasSuccess,
    type SetCurrentAreas,
    type SetAreaFilter,
    type ToggleAllAreasExpanded,
    type ToggleAreaExpanded,
    type SaveEditingAreaProperties,
    type SaveEditingAreaPropertiesSuccess,
    type DeleteArea,
    type DeleteAreaSuccess,
    type SetEditingAreaPolygon,
    type CancelEditingAreaPolygon,
    type HandleEditingAreaPolygon,
    type SaveEditingAreaPolygon,
    type SaveEditingAreaPolygonSuccess,
    type SaveEditingAreaPolygonFailure,
    DELETE_AREAS,
    DELETE_AREAS_SUCCESS,
    type DeleteAreasSuccess,
    type DeleteAreas,
} from 'src/redux/areas/areas.types'
import {
    type Area,
    type AreaType,
    type AreaProperties,
    type AreaGeometry,
    type FeatureCollection,
    type CreateNewAreasFeatureCollection,
} from 'src/api/fm/zones/zones.model'

export const addNewArea = (area: CreateNewAreasFeatureCollection): AddNewArea => ({
    type: ADD_NEW_AREA,
    payload: { area },
})

export const addNewAreasSuccess = (areas: Area[]): AddNewAreasSuccess => ({
    type: ADD_NEW_AREAS_SUCCESS,
    payload: { areas },
})

export const setCurrentAreas = (areas: FeatureCollection): SetCurrentAreas => ({
    type: SET_CURRENT_AREAS,
    payload: { areas },
})

export const setAreaFilter = (areaType: AreaType, filter: string): SetAreaFilter => ({
    type: SET_AREA_FILTER,
    payload: { areaType, filter },
})

export const toggleAreaExpanded = (areaType: AreaType, areaId: string): ToggleAreaExpanded => ({
    type: TOGGLE_AREA_EXPANDED,
    payload: { areaType, areaId },
})

export const toggleAllAreasExpanded = (areaType: AreaType): ToggleAllAreasExpanded => ({
    type: TOGGLE_ALL_AREAS_EXPANDED,
    payload: { areaType },
})

export const saveEditingAreaProperties = (
    properties: AreaProperties,
    id: string,
    geometry: AreaGeometry,
): SaveEditingAreaProperties => ({
    type: SAVE_EDITING_AREA_PROPERTIES,
    payload: { properties, id, geometry },
})

export const saveEditingAreaPropertiesSuccess = (area: Area): SaveEditingAreaPropertiesSuccess => ({
    type: SAVE_EDITING_AREA_PROPERTIES_SUCCESS,
    payload: { area },
})

export const deleteArea = (areaType: AreaType, areaId: string): DeleteArea => ({
    type: DELETE_AREA,
    payload: { areaType, areaId },
})

export const deleteAreaSuccess = (areaType: AreaType, areaId: string): DeleteAreaSuccess => ({
    type: DELETE_AREA_SUCCESS,
    payload: { areaType, areaId },
})

export const deleteAreas = (areaType: AreaType, areaIds: string[]): DeleteAreas => ({
    type: DELETE_AREAS,
    payload: { areaType, areaIds },
})

export const deleteAreasSuccess = (areaType: AreaType, areaIds: string[]): DeleteAreasSuccess => ({
    type: DELETE_AREAS_SUCCESS,
    payload: { areaType, areaIds },
})

export const setEditingAreaPolygon = (
    areaType: AreaType,
    selectedFeatureIndexes: number[],
    selectedFeatureId: string,
): SetEditingAreaPolygon => ({
    type: SET_EDITING_AREA_POLYGON,
    payload: { areaType, selectedFeatureIndexes, selectedFeatureId },
})

export const cancelEditingAreaPolygon = (areaType: AreaType): CancelEditingAreaPolygon => ({
    type: CANCEL_EDITING_AREA_POLYGON,
    payload: { areaType },
})

export const handleEditingAreaPolygon = (
    areaType: AreaType,
    featureCollection: FeatureCollection,
): HandleEditingAreaPolygon => ({
    type: HANDLE_EDITING_AREA_POLYGON,
    payload: { areaType, featureCollection },
})

export const saveEditingAreaPolygon = (areaType: AreaType): SaveEditingAreaPolygon => ({
    type: SAVE_EDITING_AREA_POLYGON,
    payload: { areaType },
})

export const saveEditingAreaPolygonSuccess = (areaType: AreaType, newArea: Area): SaveEditingAreaPolygonSuccess => ({
    type: SAVE_EDITING_AREA_POLYGON_SUCCESS,
    payload: { areaType, newArea },
})

export const saveEditingAreaPolygonFailure = (areaType: AreaType): SaveEditingAreaPolygonFailure => ({
    type: SAVE_EDITING_AREA_POLYGON_FAILURE,
    payload: { areaType },
})
