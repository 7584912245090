import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'
import {
    GET_VEHICLE_MODELS,
    type GetVehicleModels,
    SET_VEHICLE_MODELS,
    type SetVehicleModels,
} from 'src/redux/vehicleModel/vehicleModel.types'

export const getVehicleModels = (): GetVehicleModels => {
    return {
        type: GET_VEHICLE_MODELS,
    }
}

export const setVehicleModels = (vehicleModels: VehicleModel[]): SetVehicleModels => {
    return {
        type: SET_VEHICLE_MODELS,
        payload: vehicleModels,
    }
}
