import { type PropsWithChildren, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { type User } from 'firebase/auth'
import { loginUser } from 'src/redux/auth'
import AuthUserContext from 'src/contexts/authUserContext'
import useFirebaseAuthentication from 'src/components/app/utils/hooks/useFirebaseAuth'
import * as routes from 'src/constants/routes'
import { useAppDispatch } from 'src/redux/app/app.hooks'

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const currentUser = useFirebaseAuthentication()

    useEffect(() => {
        if (currentUser === null) {
            navigate(routes.LOGIN)
        }
    }, [currentUser, navigate])

    useEffect(() => {
        if (currentUser && currentUser !== 'loading') {
            dispatch(loginUser(currentUser as User))
        }
    }, [currentUser, dispatch])

    if (currentUser === 'loading') {
        return <>Loading...</>
    }

    return <AuthUserContext.Provider value={currentUser as User}>{children}</AuthUserContext.Provider>
}
