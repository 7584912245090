import styled from 'styled-components'
import ErrorImg from 'src/assets/images/img_error_message_404.svg'
import { H2, H3 } from 'src/ui-kit/headings/Headings'
import { SmallLabel } from 'src/ui-kit/text/Text'
import { Link } from 'src/ui-kit/link/Link'

const NotFound = () => {
    return (
        <ErrorContainer data-testid='404-container'>
            <img src={ErrorImg} alt='error' />
            <H2>404</H2>
            <H3>Sorry! We couldn&apos;t find this page.</H3>
            <SmallLabel style={{ marginBottom: '40px' }}>Please verify the link and try again</SmallLabel>
            <Link to='/'>RETURN TO HOMEPAGE</Link>
        </ErrorContainer>
    )
}

export default NotFound

const ErrorContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 170px);
`
