import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type ParkingPhoto } from 'src/redux/rider/rider.types'

export const getRideParkingPhoto = async (rideId: string): Promise<ParkingPhoto | Error> => {
    const link = encodeURI(`/v1/fm/parking-photo/${rideId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
