import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { type ChangeEvent, type CSSProperties, type Ref, useState } from 'react'
import styled from 'styled-components'
import DropDown from 'src/assets/images/ic_dropdown.svg'
import * as colors from 'src/ui-kit/colors/colors'
import { SELECT_WIDTH } from 'src/ui-kit/forms/Forms'

interface SelectProps<T extends string> {
    inputRef?: Ref<HTMLInputElement>
    isClearable?: boolean
    title?: string
    value: T | undefined | null // TODO: Remove 'undefined' in order to avoid local 'uncontrolled component' console warning
    options: T[]
    required?: boolean
    disabled?: boolean
    className?: string
    dataTestId?: string
    name?: string
    placeholder?: string
    onChange: (event: ChangeEvent<unknown>, value: T) => void
    getOptionLabel?: (option: T) => string
    filterOptions?: (options: T[]) => T[]
    style?: CSSProperties
    selectStyle?: CSSProperties
}

export function VoiSelect<T extends string>(props: SelectProps<T>) {
    const [inputValue, setInputValue] = useState('')
    const {
        inputRef,
        isClearable,
        title,
        value,
        options,
        required,
        disabled,
        className,
        dataTestId,
        name,
        placeholder = 'Select...',
        onChange,
        getOptionLabel,
        filterOptions,
        style,
        selectStyle,
    } = props

    const classes = useStyles()

    return (
        <div className={className} style={style}>
            {title && (
                <Label>
                    {title} {required && '*'}
                </Label>
            )}
            <Autocomplete
                classes={{
                    root: classes.root,
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    paper: classes.paper,
                    option: classes.option,
                }}
                style={selectStyle}
                size='small'
                disableClearable={!isClearable}
                disabled={disabled}
                value={value}
                onChange={(event, value) => onChange(event, value as T)}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                options={options}
                getOptionLabel={getOptionLabel}
                filterOptions={filterOptions}
                renderInput={params => (
                    <TextField
                        inputRef={inputRef}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: disabled ? null : (
                                <>
                                    <ArrowDownIcon src={DropDown} alt='dropdown icon' />
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                        variant='outlined'
                        required={required}
                        data-testid={dataTestId}
                        name={name}
                        placeholder={placeholder}
                    />
                )}
            />
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        maxWidth: SELECT_WIDTH,
    },
    inputRoot: {
        '&.MuiOutlinedInput-root': {
            backgroundColor: colors.white,
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #ddd',
            },
            '& .MuiAutocomplete-endAdornment': {
                '& .MuiAutocomplete-popupIndicator': {
                    opacity: 0,
                },
            },
        },
        '&.MuiOutlinedInput-root.Mui-disabled': {
            backgroundColor: colors.lightGrey,
            color: colors.grey,
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
        },
        '&.MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: `1px solid ${colors.coral}`,
            },
            '&>img': {
                borderLeft: `1px solid ${colors.coral}`,
            },
        },
    },
    input: {
        fontFamily: '"Montserrat", monospace',
        fontSize: 14,
    },
    paper: {
        '& > ul.MuiAutocomplete-listbox': {
            padding: 0,
        },
    },
    option: {
        fontFamily: '"Montserrat", monospace',
        fontSize: 14,
        borderBottom: '1px solid #F2F2F2',
        backgroundColor: '#fff',
        '&.MuiAutocomplete-option[data-focus="true"], &.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: '#fff',
        },
        '&.MuiAutocomplete-option:hover': {
            backgroundColor: '#FAFAFA',
        },
    },
})

const Label = styled.label`
    display: flex;
    font-size: 14px;
    color: ${colors.black};
    margin: 0 0 10px 10px;
`

const ArrowDownIcon = styled.img`
    position: absolute;
    right: 0;
    padding: 8px 5px 8px 4px;
    border-left: 1px solid #ddd;
`
