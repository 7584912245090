import {
    type ActiveRidesState,
    type ActiveRidesAction,
    ACTIVE_RIDES_RESET_STATE,
    ACTIVE_RIDES_SET_RIDES,
    ACTIVE_RIDES_SET_SEARCH_TERM,
    ACTIVE_RIDES_TOGGLE_COLUMN,
} from 'src/redux/activeRides/activeRides.types'
import { SortOrder } from 'src/utils/sort/sort'

export const initialState: ActiveRidesState = {
    active: 'minuteDuration',
    sorting: {
        qr: SortOrder.Desc,
        'ride-id': SortOrder.Desc,
        zone: SortOrder.Desc,
        lockAttempts: SortOrder.Desc,
        firstLockAttempt: SortOrder.Desc,
        startTime: SortOrder.Desc,
        minuteDuration: SortOrder.Desc,
    },
    searchTerm: '',
    rides: [],
}

export function activeRidesReducer(
    state: ActiveRidesState = initialState,
    action: ActiveRidesAction,
): ActiveRidesState {
    switch (action.type) {
        case ACTIVE_RIDES_TOGGLE_COLUMN: {
            const column = action.payload

            return {
                ...state,
                active: column,
                sorting: {
                    ...state.sorting,
                    [column]: toggleOrder(state.sorting[column]),
                },
            }
        }

        case ACTIVE_RIDES_SET_RIDES: {
            return {
                ...state,
                rides: action.payload,
            }
        }

        case ACTIVE_RIDES_SET_SEARCH_TERM: {
            return {
                ...state,
                searchTerm: action.payload,
            }
        }

        case ACTIVE_RIDES_RESET_STATE:
            return initialState

        default:
            return state
    }
}

function toggleOrder(prevOrder: SortOrder): SortOrder {
    return prevOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
}
