import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/areas%20gateway/deleteAreas API documentation}
 */
export const deleteAreasByIds = async (areaIds: string[], areaType: string): Promise<void | Error> => {
    const headers = await getHeaders()
    const link = encodeURI(`v1/fm/areas?area_type=${areaType}`)

    return ins
        .delete(link, { data: { areaIds }, headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
