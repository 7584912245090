import {
    SET_CAMPAIGNS,
    SET_CAMPAIGN_FILTERS,
    SET_OG_CAMPAIGNS,
    SEARCH_CAMPAIGNS,
    type CampaignsState,
    type CampaignsActions,
} from 'src/redux/campaigns/campaigns.types'

const INITIAL_STATE: CampaignsState = {
    ogCampaigns: null,
    campaigns: null,
    filters: {
        active: true,
        available: true,
    },
    searchValue: '',
}

export function campaignsReducer(state: CampaignsState = INITIAL_STATE, action: CampaignsActions): CampaignsState {
    switch (action.type) {
        case SET_OG_CAMPAIGNS:
            return { ...state, ogCampaigns: action.payload }

        case SET_CAMPAIGNS:
            return { ...state, campaigns: action.payload }

        case SET_CAMPAIGN_FILTERS:
            return { ...state, filters: action.payload }

        case SEARCH_CAMPAIGNS:
            return {
                ...state,
                searchValue: action.payload,
            }

        default:
            return state
    }
}
