import { SET_RECEIPTS_BULK_URL, type ReceiptsActions, type ReceiptsState } from 'src/redux/receipts/receipts.types'

const INITIAL_STATE: ReceiptsState = {
    bulkUrl: null,
}

export function receiptsReducer(state: ReceiptsState = INITIAL_STATE, action: ReceiptsActions): ReceiptsState {
    switch (action.type) {
        case SET_RECEIPTS_BULK_URL:
            return { ...state, bulkUrl: action.payload }
        default:
            return state
    }
}
