import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type Organization } from 'src/redux/organization/organization.types'
import { type CreateTasksBulkPayload, type CreateTasksBulkResponse } from 'src/api/hunters/hunters.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20tasks%20gateway/adminCreateTasks API documentation}
 */
export const createTasksBulk = async (
    vehicleIds: string[],
    task: CreateTasksBulkPayload,
): Promise<CreateTasksBulkResponse | Error> => {
    const link = encodeURI('v1/hunters/admin/tasks/bulk')
    const body = { vehicleIds, ...task }
    const headers = await getHeaders()

    return ins
        .post(link, body, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const resetHunterPassword = async (email: string): Promise<void | Error> => {
    const body = { email: email }
    const link = '/v1/auth/passwd/admin/reset'
    const headers = await getHeaders()

    return ins
        .post(link, body, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20organizations/adminCreateOrganization API documentation}
 */
export const createOrganization = async (organization: Organization): Promise<Organization | Error> => {
    const link = encodeURI('/v1/hunters/organization/admin')
    const headers = await getHeaders()

    return ins
        .post(link, organization, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20organizations/adminEditOrganization API documentation}
 */
export const editOrganization = async (org: Organization) => {
    const link = encodeURI(`/v1/hunters/organization/admin/${org.id}`)
    const headers = await getHeaders()

    return ins
        .put(link, org, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20organizations/adminGetOrganization API documentation}
 */
export const getOrganization = async (id: string): Promise<Organization | Error> => {
    const link = encodeURI(`/v1/hunters/organization/admin/${id}?join=hunters`)
    const headers = await getHeaders()
    const res = ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
    return res
}

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20organizations/adminListOrganizations API documentation}
 */
export const getAllOrganizations = async (): Promise<Organization[] | Error> => {
    const link = encodeURI('/v1/hunters/organization/admin')
    const headers = await getHeaders()
    const res = ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
    return res
}

/**
 * Related {@link https://warf.voiapp.io/catalog/hunters/api/hunters-api/definition#/admin%20organizations/adminDeleteOrganization API documentation}
 */
export const deleteOrganization = async (id: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/hunters/organization/admin/${id}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
