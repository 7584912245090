import { type Campaign } from 'src/api/fm/promotions/promotions.model'

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS'
export const APPLY_CAMPAIGN_FILTERS = 'APPLY_CAMPAIGN_FILTERS'
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
export const SET_OG_CAMPAIGNS = 'SET_OG_CAMPAIGNS'
export const SET_CAMPAIGN_FILTERS = 'SET_CAMPAIGN_FILTERS'
export const SEARCH_CAMPAIGNS = 'SEARCH_CAMPAIGNS'
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN'
export const EDIT_CAMPAIGN = 'EDIT_CAMPAIGN'

export interface CampaignsState {
    ogCampaigns: Campaign[] | null
    campaigns: Campaign[] | null
    filters: CampaignsFilters
    searchValue: string
}

export interface CampaignsFilters {
    active: boolean
    available: boolean
}

export interface EditCampaign {
    type: typeof EDIT_CAMPAIGN
    payload: {
        campaignId: string
        campaign: Campaign
    }
}

export interface CreateCampaign {
    type: typeof CREATE_CAMPAIGN
    payload: Campaign
}

export interface ApplyCampaignFilters {
    type: typeof APPLY_CAMPAIGN_FILTERS
    payload: CampaignsFilters
}

export interface SearchCampaigns {
    type: typeof SEARCH_CAMPAIGNS
    payload: string
}

export interface GetCampaigns {
    type: typeof GET_CAMPAIGNS
}

export interface SetOgCampaigns {
    type: typeof SET_OG_CAMPAIGNS
    payload: Campaign[]
}

export interface SetCampaigns {
    type: typeof SET_CAMPAIGNS
    payload: Campaign[]
}

export interface SetCampaignFilters {
    type: typeof SET_CAMPAIGN_FILTERS
    payload: CampaignsFilters
}

export type CampaignsActions =
    | EditCampaign
    | CreateCampaign
    | ApplyCampaignFilters
    | SearchCampaigns
    | GetCampaigns
    | SetOgCampaigns
    | SetCampaigns
    | SetCampaignFilters
