import { type Warehouse } from 'src/api/fm/warehouse/warehouse.model'
import {
    GET_WAREHOUSES,
    SET_WAREHOUSES,
    GET_PINNED_WAREHOUSES,
    SET_PINNED_WAREHOUSES,
    TOGGLE_PINNED_WAREHOUSE,
    type GetWarehouses,
    type SetWarehouses,
    type GetPinnedWarehouses,
    type SetPinnedWarehouses,
    type TogglePinnedWarehouse,
    type CreateWarehouse,
    CREATE_WAREHOUSE,
    type UpdateWarehouse,
    UPDATE_WAREHOUSE,
    type DeleteWarehouse,
    DELETE_WAREHOUSE,
} from 'src/redux/warehouse/warehouse.types'

export const getWarehouses = (): GetWarehouses => {
    return {
        type: GET_WAREHOUSES,
    }
}

export const setWarehouses = (warehouses: Warehouse[]): SetWarehouses => {
    return {
        type: SET_WAREHOUSES,
        payload: warehouses,
    }
}

export const createWarehouse = (warehouse: Warehouse): CreateWarehouse => {
    return {
        type: CREATE_WAREHOUSE,
        payload: warehouse,
    }
}

export const updateWarehouse = (warehouse: Warehouse): UpdateWarehouse => {
    return {
        type: UPDATE_WAREHOUSE,
        payload: warehouse,
    }
}

export const deleteWarehouse = (warehouse: Warehouse): DeleteWarehouse => {
    return {
        type: DELETE_WAREHOUSE,
        payload: warehouse,
    }
}

export const getPinnedWarehouses = (): GetPinnedWarehouses => {
    return {
        type: GET_PINNED_WAREHOUSES,
    }
}

export const setPinnedWarehouses = (warehouseIds: string[]): SetPinnedWarehouses => {
    return {
        type: SET_PINNED_WAREHOUSES,
        payload: warehouseIds,
    }
}

export const togglePinnedWarehouse = (warehouseId: string): TogglePinnedWarehouse => {
    return {
        type: TOGGLE_PINNED_WAREHOUSE,
        payload: warehouseId,
    }
}
