import { type TaskAuditLog, type Task } from 'src/api/fm/tasks/tasks.model'
import { type SortParamType } from 'src/components/parts/active-tasks/ActiveTasksTable'
import { sortByString, sortByNumber, sortByTimeStamp } from 'src/utils/sort/sort'
import { snakeCaseToDisplayName } from 'src/utils/string/stringUtils'

export interface Operator {
    id: string
    email: string
}

export function getTaskCreator(auditLog: TaskAuditLog[]): Operator | undefined {
    const event = findEvent('created', auditLog)

    return event ? getIdAndEmail(event) : undefined
}

export function getTaskCollector(auditLog: TaskAuditLog[]): Operator | undefined {
    const collectEvent = findEvent('collected', auditLog)
    if (collectEvent) {
        return getIdAndEmail(collectEvent)
    }

    const swapStartEvent = findEvent('battery_swap_started', auditLog)
    if (swapStartEvent) {
        return getIdAndEmail(swapStartEvent)
    }

    return undefined
}

export function getTaskDropper(auditLog: TaskAuditLog[]): Operator | undefined {
    const dropEvent = findEvent('dropped', auditLog)
    if (dropEvent) {
        return getIdAndEmail(dropEvent)
    }

    const swapFinalizedEvent = findEvent('battery_swap_finalized', auditLog)
    if (swapFinalizedEvent) {
        return getIdAndEmail(swapFinalizedEvent)
    }

    return undefined
}

// Currently the audit-log events `fulfilled` and `declined` will only be set
// when a FM-user manually fulfills or declines a task.
export function getTaskForceCloser(auditLog: TaskAuditLog[]): Operator | undefined {
    const fulfillEvent = findEvent('fulfilled', auditLog)
    if (fulfillEvent) {
        return getIdAndEmail(fulfillEvent)
    }

    const declineEvent = findEvent('declined', auditLog)
    if (declineEvent) {
        return getIdAndEmail(declineEvent)
    }

    return undefined
}

export function findEvent(type: string, auditLog: TaskAuditLog[]): TaskAuditLog | null {
    const event = auditLog.find(({ eventType }) => eventType === type)

    return event || null
}

export function getIdAndEmail(auditLogEvent: TaskAuditLog): Operator | undefined {
    const { operatorId, operatorEmail } = auditLogEvent
    if (!operatorId || !operatorEmail) {
        return undefined
    }

    return {
        id: auditLogEvent.operatorId,
        email: auditLogEvent.operatorEmail,
    }
}

export function prettifyTaskType(taskType: string): string {
    return snakeCaseToDisplayName(taskType)
}

export const sortActiveTasks = (tasks: Task[], sortParam: SortParamType): Task[] => {
    if (!tasks || !tasks.length) {
        return []
    }
    if (sortParam.field === 'collected') {
        return sortByTimeStamp(tasks, 'collectState.time', sortParam.order)
    } else if (sortParam.field === 'createdAt') {
        return sortByTimeStamp(tasks, sortParam.field, sortParam.order)
    } else if (sortParam.field === 'executionPriority') {
        return sortByNumber(tasks, 'executionPriority.order', sortParam.order)
    } else {
        return sortByString(tasks, sortParam.field, sortParam.order)
    }
}
