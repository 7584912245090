import { call, put, takeEvery, type SagaReturnType } from 'redux-saga/effects'
import * as api from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { setVehicleModels } from 'src/redux/vehicleModel/vehicleModel.actions'
import { GET_VEHICLE_MODELS, type GetVehicleModels } from 'src/redux/vehicleModel/vehicleModel.types'

type VehicleModelsRes = SagaReturnType<typeof api.getVehicleModels>

export function* getVehicleModelsSaga(_: GetVehicleModels) {
    try {
        const vehicleModels: VehicleModelsRes = yield call(api.getVehicleModels)

        if (vehicleModels instanceof Error) {
            throw vehicleModels
        }

        yield put(setVehicleModels(vehicleModels))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export default function* watcher(): any {
    yield takeEvery(GET_VEHICLE_MODELS, getVehicleModelsSaga)
}
