import type { MaintenanceNeed, MaintenanceNeedVehicle } from 'src/api/fm/maintenanceNeed/maintenanceNeed.model'

export const GET_MAINTENANCE_NEED_VEHICLES = 'GET_MAINTENANCE_NEED_VEHICLES' as const
export const SET_MAINTENANCE_NEED_VEHICLES = 'SET_MAINTENANCE_NEED_VEHICLES' as const
export const CREATE_MAINTENANCE_NEEDS = 'CREATE_MAINTENANCE_NEEDS' as const
export const UPDATE_MAINTENANCE_NEED = 'UPDATE_MAINTENANCE_NEED' as const
export const DELETE_MAINTENANCE_NEEDS = 'DELETE_MAINTENANCE_NEEDS' as const

export interface MaintenanceNeedState {
    maintenanceNeedVehicles: MaintenanceNeedVehicle[]
}

export interface GetMaintenanceNeedVehicles {
    type: typeof GET_MAINTENANCE_NEED_VEHICLES
    payload: string[]
}

export interface CreateMaintenanceNeeds {
    type: typeof CREATE_MAINTENANCE_NEEDS
    payload: MaintenanceNeed
}

export interface UpdateMaintenanceNeed {
    type: typeof UPDATE_MAINTENANCE_NEED
    payload: MaintenanceNeed
}

export interface SetMaintenanceNeedVehicles {
    type: typeof SET_MAINTENANCE_NEED_VEHICLES
    payload: MaintenanceNeedVehicle[]
}

export interface DeleteMaintenanceNeeds {
    type: typeof DELETE_MAINTENANCE_NEEDS
    payload: string[]
}

export type MaintenanceNeedAction =
    | GetMaintenanceNeedVehicles
    | SetMaintenanceNeedVehicles
    | CreateMaintenanceNeeds
    | UpdateMaintenanceNeed
    | DeleteMaintenanceNeeds
