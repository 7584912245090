import { type ExecutionPriority, type TaskType } from 'src/api/fm/tasks/tasks.model'

export type VmapBatteryFilter = { battery_lt: number; battery_gte: number }

export type VmapZoneFilter = { label: string; value: string }

export interface VmapVehicleModel {
    id: string
    category: string
    name: string
}

export type VmapFilters = {
    city: VmapZoneFilter | null
    status: VmapVehicleStatusFilter
    battery: VmapBatteryFilter
    models: string[]
    priorities: VmapFilterPriorities
    organization: string | undefined
}

export interface VmapFilterPriorities {
    isFilterActive: boolean
    toggledPriorities: ExecutionPriority[]
}

type ReadyNeedKeys = Extract<
    TaskType,
    'battery_swap' | 'license_plate_swap' | 'repark' | 'rebalance' | 'transport' | 'in_field_quality_check'
>
export type ReadyNeedType = Record<ReadyNeedKeys, boolean>

type BountyKeys = Extract<
    TaskType,
    'battery_swap' | 'license_plate_swap' | 'rebalance' | 'transport' | 'deploy' | 'rescue' | 'in_field_quality_check'
>
export type BountyType = Record<BountyKeys, boolean>

type OffMarketKeys = Extract<
    TaskType,
    | 'battery_swap'
    | 'license_plate_swap'
    | 'rebalance'
    | 'transport'
    | 'deploy'
    | 'rescue'
    | 'repark'
    | 'in_field_quality_check'
    | 'in_field_repair'
>
export type OffMarketType = Record<OffMarketKeys, boolean>

export type ChildStatusKey = ReadyNeedKeys | BountyKeys | OffMarketKeys

export type VmapVehicleStatusFilterKey = keyof VmapVehicleStatusFilter

export type VmapVehicleStatusFilterWithChild = 'ready_need' | 'bounty' | 'off_market'

export interface VmapVehicleStatusFilter {
    ready: boolean
    booked: boolean
    in_repair: boolean
    storage: boolean
    riding: boolean
    lost: boolean
    home: boolean
    unavailable: boolean
    ready_need: ReadyNeedType
    bounty: BountyType
    off_market: OffMarketType
}

export const INITIAL_STATUS_FILTER: VmapVehicleStatusFilter = {
    ready: false,
    in_repair: false,
    riding: false,
    lost: false,
    home: false,
    unavailable: false,
    storage: false,
    booked: false,
    ready_need: {
        battery_swap: false,
        license_plate_swap: false,
        repark: false,
        rebalance: false,
        transport: false,
        in_field_quality_check: false,
    },
    bounty: {
        battery_swap: false,
        license_plate_swap: false,
        rebalance: false,
        transport: false,
        deploy: false,
        rescue: false,
        in_field_quality_check: false,
    },
    off_market: {
        battery_swap: false,
        license_plate_swap: false,
        rebalance: false,
        transport: false,
        deploy: false,
        rescue: false,
        repark: false,
        in_field_quality_check: false,
        in_field_repair: false,
    },
}
