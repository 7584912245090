import { call, put, takeEvery, type SagaReturnType } from 'redux-saga/effects'
import {
    createWorkArea,
    createWorkAreaZoneActivation,
    deleteWorkArea,
    getWorkAreaZoneActivationStatus,
    getWorkAreas,
    setWorkAreaZoneActivationStatus,
    setWorkAreas,
    updateWorkArea,
    deleteWorkAreaZoneActivation,
} from 'src/redux/workArea'
import * as api from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'

type CreateWorkAreaRes = SagaReturnType<typeof api.createWorkArea>

export function* createWorkAreaSaga(action: ReturnType<typeof createWorkArea>) {
    try {
        const res: CreateWorkAreaRes | Error = yield call(api.createWorkArea, action.payload)

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully created work area', 'success')
        yield put(getWorkAreas(action.payload.zoneId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type UpdateWorkAreaRes = SagaReturnType<typeof api.updateWorkArea>

export function* updateWorkAreaSaga(action: ReturnType<typeof updateWorkArea>) {
    try {
        const res: UpdateWorkAreaRes | Error = yield call(api.updateWorkArea, action.payload)

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully updated work area', 'success')
        yield put(getWorkAreas(action.payload.zoneId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type DeleteWorkAreaRes = SagaReturnType<typeof api.deleteWorkArea>

export function* deleteWorkAreaSaga(action: ReturnType<typeof deleteWorkArea>) {
    try {
        const res: DeleteWorkAreaRes | Error = yield call(api.deleteWorkArea, action.payload.id)

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully deleted work area', 'success')
        yield put(getWorkAreas(action.payload.zoneId))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type GetWorkAreasByZoneIdRes = SagaReturnType<typeof api.getWorkAreasByZoneId>

export function* getWorkAreasSaga(action: ReturnType<typeof getWorkAreas>) {
    try {
        const res: GetWorkAreasByZoneIdRes | Error = yield call(api.getWorkAreasByZoneId, action.payload)

        if (res instanceof Error) {
            throw res
        }

        yield put(setWorkAreas(res))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type CreateWorkAreaZoneActivationRes = SagaReturnType<typeof api.createWorkAreaZoneActivation>

export function* createWorkAreaZoneActivationSaga(action: ReturnType<typeof createWorkAreaZoneActivation>) {
    try {
        const res: CreateWorkAreaZoneActivationRes | Error = yield call(
            api.createWorkAreaZoneActivation,
            action.payload,
        )

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully activated work areas for zone', 'success')
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        yield put(getWorkAreaZoneActivationStatus(action.payload))
    }
}

type DeleteWorkAreaZoneActivationRes = SagaReturnType<typeof api.deleteWorkAreaZoneActivation>

export function* deleteWorkAreaZoneActivationSaga(action: ReturnType<typeof deleteWorkAreaZoneActivation>) {
    try {
        const res: DeleteWorkAreaZoneActivationRes | Error = yield call(
            api.deleteWorkAreaZoneActivation,
            action.payload,
        )

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully deactivated work areas for zone', 'success')
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        yield put(getWorkAreaZoneActivationStatus(action.payload))
    }
}

type GetWorkAreaZoneActivationStatusByZoneIdRes = SagaReturnType<typeof api.getWorkAreaZoneActivationStatusByZoneId>

export function* getWorkAreaZoneActivationStatusSaga(action: ReturnType<typeof getWorkAreaZoneActivationStatus>) {
    try {
        const res: GetWorkAreaZoneActivationStatusByZoneIdRes | Error = yield call(
            api.getWorkAreaZoneActivationStatusByZoneId,
            action.payload,
        )

        if (res instanceof Error) {
            throw res
        }

        yield put(setWorkAreaZoneActivationStatus(res))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export default function* watcher() {
    yield takeEvery(createWorkArea.toString(), createWorkAreaSaga)
    yield takeEvery(updateWorkArea.toString(), updateWorkAreaSaga)
    yield takeEvery(deleteWorkArea.toString(), deleteWorkAreaSaga)
    yield takeEvery(getWorkAreas.toString(), getWorkAreasSaga)
    yield takeEvery(createWorkAreaZoneActivation.toString(), createWorkAreaZoneActivationSaga)
    yield takeEvery(deleteWorkAreaZoneActivation.toString(), deleteWorkAreaZoneActivationSaga)
    yield takeEvery(getWorkAreaZoneActivationStatus.toString(), getWorkAreaZoneActivationStatusSaga)
}
