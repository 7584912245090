import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'

type CSV = any
type FormData = any

export function downloadCSV(csv: CSV, fileName: string) {
    const url = window.URL.createObjectURL(new Blob([csv]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function transformToFormData(vehicle: VehicleModel): FormData {
    const formData = new FormData()
    const { picture, ...data } = vehicle
    formData.append('metadata', JSON.stringify(data))

    if (picture) {
        formData.append('picture', picture)
    }

    return formData
}

export function downloadJSON(data: any, fileName: string) {
    return new Promise<void>((resolve, reject) => {
        try {
            const json = JSON.stringify(data)
            const blob = new Blob([json], { type: 'text/json' })
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            resolve()
        } catch (e) {
            reject(e)
        }
    })
}
