import { put, takeEvery, call, type SagaReturnType } from 'redux-saga/effects'
import {
    GET_ZONE_FEATURES,
    UPDATE_ZONE_FEATURES,
    GET_ZONE,
    GET_ZONES_BY_COUNTRY,
    CREATE_ZONE,
    UPDATE_ZONE_DETAILS,
    DELETE_ZONE,
    CREATE_COUNTRY,
    EDIT_COUNTRY,
    GET_ZONES_AND_COUNTRIES,
    FETCH_ALL_COUNTRIES,
    GET_ZONE_PARKING_ASSISTANT_SETTINGS,
    UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS,
    GET_PARKING_PHOTO_SETTINGS,
    EDIT_PARKING_PHOTO_SETTINGS,
    GET_VEHICLE_POLICY,
    UPDATE_VEHICLE_POLICY,
    UPDATE_ZONE_THRESHOLDS,
} from 'src/redux/zone/zone.types'
import {
    setCurrentZone,
    setCurrentZoneError,
    getZone,
    setZones,
    getZonesByCountry,
    setZoneFeatures,
    getZoneFeatures as getZoneFeaturesAction,
    setAllCountries,
    addCountrySuccess,
    editCountrySuccess,
    setAllZones,
    setZoneParkingAssistantSettings,
    getParkingPhotoSettingsSuccess,
    setVehiclePolicy,
    type GetZoneAction,
    setZoneThresholds,
} from 'src/redux/zone'
import { setCurrentAreas } from 'src/redux/areas'
import {
    createNewZone,
    getAllZones,
    updateZone,
    getLegalEntities,
    getZoneFeatures,
    updateZoneFeatures,
    getZoneById,
    getParkingAssistantSettingsPerZone,
    getAreasByZoneId,
    createNewLegalEntity,
    editLegalEntity,
    deleteZoneById,
    updateParkingAssistantSettingsPerZone,
    getParkingPhotoSettings as getParkingPhotoSettingsReq,
    updateParkingPhotoSettings as updateParkingPhotoSettingsReq,
    updateThresholdsByZoneId,
} from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { editVehiclePolicy, getVehiclePolicy } from 'src/api/fm/vehiclePolicy/vehiclePolicy'

type GetLegalEntitiesRes = SagaReturnType<typeof getLegalEntities>

export function* fetchAllCountriesHandler() {
    const countries: GetLegalEntitiesRes = yield call(getLegalEntities)

    if (countries instanceof Error) {
        yield call(notifyUser, 'Failed to get countries', 'error')
    } else {
        yield put(setAllCountries(countries))
    }
}

type CreateLegalEntityRes = SagaReturnType<typeof createNewLegalEntity>

export function* createCountry(action: GetZoneAction<typeof CREATE_COUNTRY>) {
    const legalEntity = action.payload
    const result: CreateLegalEntityRes = yield call(createNewLegalEntity, legalEntity)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield put(addCountrySuccess(result))
        yield call(notifyUser, 'Successfully added legal-entity', 'success')
    }
}

type EditLegalEntityRes = SagaReturnType<typeof editLegalEntity>

export function* editCountry(action: GetZoneAction<typeof EDIT_COUNTRY>) {
    const legalEntity = action.payload
    const result: EditLegalEntityRes = yield call(editLegalEntity, legalEntity)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield put(editCountrySuccess(result))
        yield call(notifyUser, 'Successfully edited legal-entity', 'success')
    }
}

type GetZoneByIdRes = SagaReturnType<typeof getZoneById>
type GetAreasByZoneIdRes = SagaReturnType<typeof getAreasByZoneId>

// Call this on page mount, gets zone by id
export function* getZoneData(action: GetZoneAction<typeof GET_ZONE>) {
    const id = action.payload
    const zone: GetZoneByIdRes = yield call(getZoneById, id)
    if (zone instanceof Error) {
        yield put(setCurrentZoneError('Zone not found'))
        return
    }
    // FIXME: Dispatch other saga to get areas and place in areas/sagas?
    const areas: GetAreasByZoneIdRes = yield call(getAreasByZoneId, id)
    if (areas instanceof Error) {
        yield put(setCurrentZoneError('Error when fetching areas'))
        return
    }
    yield put(setCurrentZone(zone))
    yield put(setCurrentAreas(areas))
}

type CreateZoneRes = SagaReturnType<typeof createNewZone>

export function* createZone(action: GetZoneAction<typeof CREATE_ZONE>) {
    const zone = action.payload
    const result: CreateZoneRes = yield call(createNewZone, zone)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield call(notifyUser, 'Successfully created zone', 'success')
        yield put(getZonesByCountry(zone.country))
    }
}

type DeleteZoneRes = SagaReturnType<typeof deleteZoneById>

export function* deleteZone(action: GetZoneAction<typeof DELETE_ZONE>) {
    const { zoneId, country } = action.payload
    const result: DeleteZoneRes = yield call(deleteZoneById, zoneId)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield call(notifyUser, 'Successfully deleted zone', 'success')
        yield put(getZonesByCountry(country))
    }
}

type GetAllZonesRes = SagaReturnType<typeof getAllZones>

export function* fetchZonesByCountry(action: GetZoneAction<typeof GET_ZONES_BY_COUNTRY>) {
    try {
        const zones: GetAllZonesRes = yield call(getAllZones)
        if (zones instanceof Error) {
            throw zones
        }
        const filteredZones = zones.filter(zone => zone.country === action.payload.toUpperCase())
        yield put(setZones(filteredZones))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type UpdateZoneRes = SagaReturnType<typeof updateZone>

export function* updateZoneDetailsSaga(action: GetZoneAction<typeof UPDATE_ZONE_DETAILS>) {
    const zone = action.payload
    const updatedZoneData: UpdateZoneRes = yield call(updateZone, zone)

    if (updatedZoneData instanceof Error) {
        yield call(notifyUser, updatedZoneData, 'error')
    } else {
        yield call(notifyUser, 'Successfully updated zone details', 'success')
        yield put(getZone(zone.zone_id))
    }
}

type UpdateZoneThresholdsRes = SagaReturnType<typeof updateThresholdsByZoneId>

function* updateZoneThresholds(action: GetZoneAction<typeof UPDATE_ZONE_THRESHOLDS>) {
    const { zoneId, thresholds } = action.payload
    const updateZoneThresholdsRes: UpdateZoneThresholdsRes = yield call(updateThresholdsByZoneId, zoneId, thresholds)

    if (updateZoneThresholdsRes instanceof Error) {
        yield call(notifyUser, updateZoneThresholdsRes, 'error')
    } else {
        yield call(notifyUser, 'Successfully updated zone thresholds', 'success')
        yield put(setZoneThresholds(updateZoneThresholdsRes))
    }
}

export function* getZonesAndCountries() {
    const zones: GetAllZonesRes = yield call(getAllZones)
    const countries: GetLegalEntitiesRes = yield call(getLegalEntities)

    if (zones instanceof Error) {
        yield call(notifyUser, 'Failed to get zones', 'error')
    } else if (countries instanceof Error) {
        yield call(notifyUser, 'Failed to get countries', 'error')
    } else {
        yield put(setAllZones(zones))
        yield put(setAllCountries(countries))
    }
}

type GetZoneFeaturesRes = SagaReturnType<typeof getZoneFeatures>

export function* fetchZoneFeatures(action: GetZoneAction<typeof GET_ZONE_FEATURES>) {
    const zoneId = action.payload
    const zoneFeatures: GetZoneFeaturesRes = yield call(getZoneFeatures, zoneId)

    if (zoneFeatures instanceof Error) {
        yield call(notifyUser, zoneFeatures, 'error')
        return
    }

    yield put(setZoneFeatures(zoneFeatures))
}

type UpdateZoneFeaturesRes = SagaReturnType<typeof updateZoneFeatures>

export function* toggleZoneFeatures(action: GetZoneAction<typeof UPDATE_ZONE_FEATURES>) {
    const { zoneId, data } = action.payload
    const result: UpdateZoneFeaturesRes = yield call(updateZoneFeatures, zoneId, data)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield call(notifyUser, 'Zone feature settings have been successfully updated', 'success')
    }

    yield put(getZoneFeaturesAction(zoneId))
}

type GetVehiclePolicyRes = SagaReturnType<typeof getVehiclePolicy>

function* fetchVehiclePolicy(action: GetZoneAction<typeof GET_VEHICLE_POLICY>) {
    const zoneId = action.payload
    const vehiclePolicy: GetVehiclePolicyRes = yield call(getVehiclePolicy, zoneId)

    if (vehiclePolicy instanceof Error) {
        yield call(notifyUser, vehiclePolicy, 'error')
        return
    }

    yield put(setVehiclePolicy(vehiclePolicy))
}

type UpdateVehiclePolicyRes = SagaReturnType<typeof editVehiclePolicy>

function* updateVehiclePolicy(action: GetZoneAction<typeof UPDATE_VEHICLE_POLICY>) {
    const { zoneId, data } = action.payload
    const result: UpdateVehiclePolicyRes = yield call(editVehiclePolicy, zoneId, data)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
    } else {
        yield call(notifyUser, 'Vehicle policy has been successfully updated', 'success')
    }

    yield put(getZoneFeaturesAction(zoneId))
}

type GetParkingAssistantSettingsRes = SagaReturnType<typeof getParkingAssistantSettingsPerZone>

export function* getZoneParkingAssistantSettings(action: GetZoneAction<typeof GET_ZONE_PARKING_ASSISTANT_SETTINGS>) {
    const id = action.payload

    const result: GetParkingAssistantSettingsRes = yield call(getParkingAssistantSettingsPerZone, id)
    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield put(setZoneParkingAssistantSettings(result))
}

type GetParkingPhotoSettingsRes = SagaReturnType<typeof getParkingPhotoSettingsReq>

export function* getParkingPhotoSettings(action: GetZoneAction<typeof GET_PARKING_PHOTO_SETTINGS>) {
    const { zoneId } = action.payload
    const result: GetParkingPhotoSettingsRes = yield call(getParkingPhotoSettingsReq, zoneId)

    if (result instanceof Error) {
        yield call(notifyUser, { message: 'Failed to fetch zone task settings' }, 'error')
        return
    }

    yield put(getParkingPhotoSettingsSuccess(result))
}

type UpdateParkingAssistantSettingsRes = SagaReturnType<typeof updateParkingAssistantSettingsPerZone>

export function* updateZoneParkingAssistantSettings(
    action: GetZoneAction<typeof UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS>,
) {
    const settings = action.payload
    const result: UpdateParkingAssistantSettingsRes = yield call(updateParkingAssistantSettingsPerZone, settings)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Successfully updated the Parking Assistant Settings', 'success')
}

type UpdateParkingPhotoSettingsRes = SagaReturnType<typeof updateParkingPhotoSettingsReq>

export function* updateParkingPhotoSettings(action: GetZoneAction<typeof EDIT_PARKING_PHOTO_SETTINGS>) {
    const { zoneId, parkingPhotoSettings } = action.payload
    const result: UpdateParkingPhotoSettingsRes = yield call(
        updateParkingPhotoSettingsReq,
        zoneId,
        parkingPhotoSettings,
    )

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield put(getParkingPhotoSettingsSuccess(result))
    yield call(notifyUser, 'Successfully updated zone task settings', 'success')
}

export default function* watcher() {
    yield takeEvery(FETCH_ALL_COUNTRIES, fetchAllCountriesHandler)
    yield takeEvery(CREATE_COUNTRY, createCountry)
    yield takeEvery(EDIT_COUNTRY, editCountry)
    yield takeEvery(GET_ZONES_BY_COUNTRY, fetchZonesByCountry)
    yield takeEvery(GET_ZONE, getZoneData)
    yield takeEvery(CREATE_ZONE, createZone)
    yield takeEvery(DELETE_ZONE, deleteZone)
    yield takeEvery(UPDATE_ZONE_DETAILS, updateZoneDetailsSaga)
    yield takeEvery(UPDATE_ZONE_THRESHOLDS, updateZoneThresholds)
    yield takeEvery(GET_ZONES_AND_COUNTRIES, getZonesAndCountries)
    yield takeEvery(GET_ZONE_FEATURES, fetchZoneFeatures)
    yield takeEvery(GET_VEHICLE_POLICY, fetchVehiclePolicy)
    yield takeEvery(UPDATE_VEHICLE_POLICY, updateVehiclePolicy)
    yield takeEvery(UPDATE_ZONE_FEATURES, toggleZoneFeatures)
    yield takeEvery(GET_ZONE_PARKING_ASSISTANT_SETTINGS, getZoneParkingAssistantSettings)
    yield takeEvery(UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS, updateZoneParkingAssistantSettings)
    yield takeEvery(GET_PARKING_PHOTO_SETTINGS, getParkingPhotoSettings)
    yield takeEvery(EDIT_PARKING_PHOTO_SETTINGS, updateParkingPhotoSettings)
}
