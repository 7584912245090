import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import Img from 'src/assets/images/IconFilter.svg'
import * as colors from 'src/ui-kit/colors/colors'
import closeIcon from 'src/assets/images/ic_clear_text.svg'
import { fontPrimary } from 'src/ui-kit/fonts/fontFamily'

type Props = {
    value?: string | number
    placeholder: string | number
    onChange?: (value: any) => void
    dataTestId?: string
    width?: string
    margin?: string
    type?: string
    icon?: string
    onSearch?: (searchTerm: string) => void
}

export const SearchInput = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const { onSearch } = props

    useEffect(() => {
        const listener = (e: KeyboardEvent) => e.key === 'Enter' && onSearch!(inputRef!.current!.value)
        const ref = inputRef.current
        if (onSearch && ref) {
            ref.addEventListener('keyup', listener)
        }
        return () => ref?.removeEventListener('keyup', listener)
    }, [onSearch, inputRef])

    const clearSearch = () => {
        inputRef!.current!.value = ''

        onSearch!('')
    }

    return (
        <Container $width={props.width}>
            <Input
                ref={inputRef}
                type={props.type}
                $margin={props.margin}
                $icon={props.icon}
                width={props.width}
                data-testid={props.dataTestId}
                placeholder={props.placeholder as string}
                onChange={props.onChange}
                value={props.value}
            />
            {onSearch && <CloseIcon alt='Clear' src={closeIcon} onClick={clearSearch} />}
        </Container>
    )
}

const Input = styled.input<{ $icon?: string; $margin?: string }>`
    width: ${props => (props.width ? props.width : 'calc(100% - 80px)')};
    height: 44px;
    color: ${colors.black};
    background: ${props => `url("${props.$icon || Img}")`};
    background-size: 24px 24px;
    flex: 1;
    display: flex;
    background-repeat: no-repeat;
    padding: 0 40px;
    background-position: 10px center;
    ${fontPrimary}
    font-size: 14px;
    border: 1px solid ${colors.mediumGrey};
    margin: ${props => (props.$margin ? props.$margin : '0')};

    &:focus {
        outline: none;
        border: 1px solid ${colors.coral};
    }
`

const CloseIcon = styled.img`
    cursor: pointer;
    position: absolute;
    right: 15px;
`

const Container = styled.div<{ $width?: string }>`
    display: flex;
    max-width: ${props => (props.$width ? props.$width : '260px')};
    position: relative;
    align-items: center;
`
