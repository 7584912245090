import { type AppState } from 'src/redux/app/app.store'
import { type Task } from 'src/api/fm/tasks/tasks.model'

export function selectOrganizationHistoryTasks(state: AppState): Task[] {
    const historyTasks = state.organization.history

    return historyTasks || []
}
export const selectIsFetchingOrganizationHistoryTasks = (state: AppState) => state.organization.isFetchingHistory
export const selectCurrentOrganizationState = (state: AppState) => state.organization
export const selectOrganizationAllowedAreas = (state: AppState) => state.organization.allowedAreas
