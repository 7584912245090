import { call, put, type SagaReturnType, takeEvery } from 'redux-saga/effects'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { addMaasUser, deleteMaasUser, editMaasUser, getMaasUsers } from 'src/api'
import { getMaasUsers as getMaasUsersAction, setMaasUsers } from 'src/redux/maasUsers/maasUsers.actions'
import {
    ADD_MAAS_USER,
    type AddMaasUser,
    DELETE_MAAS_USER,
    type DeleteMaasUser,
    EDIT_MAAS_USER,
    type EditMaasUser,
    GET_MAAS_USERS,
} from 'src/redux/maasUsers/maasUsers.types'

type GetMaasUsersRes = SagaReturnType<typeof getMaasUsers>

export function* fetchMaasUsers() {
    const maasUsers: GetMaasUsersRes = yield call(getMaasUsers)

    if (maasUsers instanceof Error) {
        yield call(notifyUser, maasUsers, 'error')
        return
    }

    yield put(setMaasUsers(maasUsers))
}

type AddMaasUserRes = SagaReturnType<typeof addMaasUser>

export function* createMaasUser(action: AddMaasUser) {
    const maasUser = action.payload
    const result: AddMaasUserRes = yield call(addMaasUser, maasUser)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Created MaaS user', 'success')
    yield put(getMaasUsersAction())
}

type EditMaasUserRes = SagaReturnType<typeof editMaasUser>

export function* updateMaasUser(action: EditMaasUser) {
    const maasUser = action.payload
    const result: EditMaasUserRes = yield call(editMaasUser, maasUser)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Updated MaaS user', 'success')
    yield put(getMaasUsersAction())
}

type DeleteMaasUserRes = SagaReturnType<typeof deleteMaasUser>

export function* deleteMaasUserSaga(action: DeleteMaasUser) {
    const maasUser = action.payload
    const result: DeleteMaasUserRes = yield call(deleteMaasUser, maasUser)

    if (result instanceof Error) {
        yield call(notifyUser, result, 'error')
        return
    }

    yield call(notifyUser, 'Deleted MaaS user', 'success')
    yield put(getMaasUsersAction())
}

export default function* watcher() {
    yield takeEvery(GET_MAAS_USERS, fetchMaasUsers)
    yield takeEvery(ADD_MAAS_USER, createMaasUser)
    yield takeEvery(EDIT_MAAS_USER, updateMaasUser)
    yield takeEvery(DELETE_MAAS_USER, deleteMaasUserSaga)
}
