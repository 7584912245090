import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

export function notifyUser(notification: any, status?: 'success' | 'warn' | 'error'): void {
    if (notification instanceof Error || status === 'error') {
        toast.error(notification.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            theme: 'colored',
            icon: false,
        })
    } else if (status === 'warn') {
        toast.warn(notification, { position: toast.POSITION.BOTTOM_RIGHT, theme: 'colored', icon: false })
    } else if (status === 'success') {
        toast.success(notification, { position: toast.POSITION.BOTTOM_RIGHT, theme: 'colored', icon: false })
    }
}
