import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getFirebaseConfig } from 'src/firebase/config'
import { APP_ENV } from 'src/utils/env'

const config = getFirebaseConfig(APP_ENV)
const firebaseApp = initializeApp(config)
const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)

export { db, auth }
