import { call, put, takeEvery } from 'redux-saga/effects'
import { getPermissionsForRoles } from 'src/api'
import type { PermissionsForRoles } from 'src/api/fm/permissions/permissions.model'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { GET_PERMISSIONS_FOR_ROLES } from 'src/redux/permissionsForRoles/permissionsForRoles.types'
import { getPermissionsForRolesSuccess } from 'src/redux/permissionsForRoles/permissionsForRoles.actions'

export function* getPermissionsForRolesSaga() {
    const permissions: PermissionsForRoles = yield call(getPermissionsForRoles)
    if (permissions instanceof Error) {
        yield call(notifyUser, permissions, 'error')
        return
    }

    yield put(getPermissionsForRolesSuccess(permissions))
}

export default function* watcher() {
    yield takeEvery(GET_PERMISSIONS_FOR_ROLES, getPermissionsForRolesSaga)
}
