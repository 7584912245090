import { useWindowSize } from 'src/components/app/utils/hooks/useWindowSize'
import { MobileHeader } from 'src/components/parts/navigation/MobileHeader'
import { DesktopHeader } from 'src/components/parts/navigation/DesktopHeader'
import * as breakpoints from 'src/constants/breakpoints'

const PageHeader = () => {
    const windowSize = useWindowSize()

    return <>{windowSize.width > breakpoints.pixels.mobile ? <DesktopHeader /> : <MobileHeader />}</>
}

export default PageHeader
