import {
    SAFETY_CONTROL_SYSTEM_INFO,
    SAFETY_CONTROL_USER_STATS,
    SAFETY_CONTROL_USER_PARKING_HISTORY,
    SAFETY_CONTROL_CLEAR,
    SAFETY_CONTROL_SYSTEM_DATA,
    SET_SAFETY_CONTROL_SYSTEM_INFO,
    USER_INFO_MARK_NO_MORE,
    SEND_ACTION_REVIEW,
    GET_USER_PARKING_HISTORY,
    CLEAR_USER_PARKING_HISTORY,
    type GetSafetyControlInfo,
    type MarkNoMoreUsers,
    type GetSafetyControlUserStats,
    type SetSafetyControlSystemInfo,
    type SetSafetyControlUserStats,
    type SetSafetyControlUserParkingHistory,
    type ClearSafetyControlInfo,
    type SendUserReviewAction,
    type GetUserParkingHistory,
    type ClearUserParkingHistory,
} from 'src/redux/safetyControlSystem/safetyControlSystem.types'
import {
    type UserInfo,
    type UserStats,
    type UserParkingHistory,
    type ACTIONS,
} from 'src/api/fm/safetyControlSystem/safetyControlSystem.model'

export const getSafetyControlInfo = (): GetSafetyControlInfo => ({
    type: SAFETY_CONTROL_SYSTEM_INFO,
})

export const markNoMoreUsers = (): MarkNoMoreUsers => ({
    type: USER_INFO_MARK_NO_MORE,
})

export const getSafetyControlUserStats = (userId: string, zoneId: string): GetSafetyControlUserStats => ({
    type: SAFETY_CONTROL_SYSTEM_DATA,
    payload: { userId, zoneId },
})

export const setSafetyControlSystemInfo = (data: UserInfo): SetSafetyControlSystemInfo => ({
    type: SET_SAFETY_CONTROL_SYSTEM_INFO,
    payload: data,
})

export const setSafetyControlUserStats = (userStats: UserStats): SetSafetyControlUserStats => ({
    type: SAFETY_CONTROL_USER_STATS,
    payload: userStats,
})

export const setSafetyControlUserParkingHistory = (
    userParkingHistory: UserParkingHistory[],
): SetSafetyControlUserParkingHistory => ({
    type: SAFETY_CONTROL_USER_PARKING_HISTORY,
    payload: userParkingHistory,
})

export const clearSafetyControlInfo = (): ClearSafetyControlInfo => ({
    type: SAFETY_CONTROL_CLEAR,
})

export const sendUserReviewAction = (backlogId: string, actionReview: ACTIONS): SendUserReviewAction => ({
    type: SEND_ACTION_REVIEW,
    payload: { backlogId, actionReview },
})

export const getUserParkingHistory = (userId: string, zoneId: string): GetUserParkingHistory => ({
    type: GET_USER_PARKING_HISTORY,
    payload: { userId, zoneId },
})

export const clearUserParkingHistory = (): ClearUserParkingHistory => ({
    type: CLEAR_USER_PARKING_HISTORY,
})
