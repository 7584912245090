import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { SortOrder } from 'src/utils/sort/sort'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import { addAllActiveTasksToBulk, removeAllActiveTasksFromBulk, selectTaskBulkDropList } from 'src/redux/task'
import { TableSortLabel, useTableStyles } from 'src/ui-kit/materialTable/MaterialTable'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { BUTTON_TYPES } from 'src/ui-kit/button/constants'
import { Button } from 'src/ui-kit/button/Button'
import { type SortParamField, type SortParamType } from 'src/components/parts/active-tasks/ActiveTasksTable'

interface Props {
    bulkDropEnabled: boolean
    tasks: Task[]
    toggleBulkDropForm: (value: true) => void
    showShort: boolean
    sortParam: SortParamType
    setSortParam: (sortParam: SortParamType) => void
}

export const Headers = (props: Props) => {
    const { bulkDropEnabled, tasks, toggleBulkDropForm, showShort, sortParam, setSortParam } = props

    const bulkDropList = useAppSelector(selectTaskBulkDropList)

    const dispatch = useAppDispatch()

    const classes = useTableStyles()
    const handleChange = (checked: boolean) => {
        if (checked) {
            dispatch(addAllActiveTasksToBulk(tasks))
        } else {
            dispatch(removeAllActiveTasksFromBulk())
        }
    }

    const handleSort = (value: SortParamField) => {
        setSortParam({
            field: value,
            order: sortParam.order === SortOrder.Desc && value === sortParam.field ? SortOrder.Asc : SortOrder.Desc,
        })
    }

    return (
        <TableHead>
            <TableRow className={classes.row}>
                {showShort && <TableCell className={classes.headerCell}>Voi&nbsp;ID</TableCell>}
                <TableCell
                    onClick={() => handleSort('type')}
                    className={classes.headerCell}
                    style={{ minWidth: '100px' }}
                >
                    <TableSortLabel active={sortParam.field === 'type'} direction={sortParam.order}>
                        Type
                    </TableSortLabel>
                </TableCell>
                <TableCell onClick={() => handleSort('executionPriority')} className={classes.headerCell}>
                    <TableSortLabel active={sortParam.field === 'executionPriority'} direction={sortParam.order}>
                        Priority
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    onClick={() => handleSort('createdAt')}
                    className={classes.headerCell}
                    style={{ minWidth: '200px' }}
                >
                    <TableSortLabel active={sortParam.field === 'createdAt'} direction={sortParam.order}>
                        Created at
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    onClick={() => handleSort('collected')}
                    className={classes.headerCell}
                    style={{ minWidth: '200px' }}
                >
                    <TableSortLabel active={sortParam.field === 'collected'} direction={sortParam.order}>
                        Collected
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    onClick={() => handleSort('info')}
                    className={classes.headerCell}
                    style={{ minWidth: '200px' }}
                >
                    <TableSortLabel active={sortParam.field === 'info'} direction={sortParam.order}>
                        Description
                    </TableSortLabel>
                </TableCell>
                <TableCell
                    onClick={() => handleSort('infoInternal')}
                    className={classes.headerCell}
                    style={{ minWidth: '200px' }}
                >
                    <TableSortLabel active={sortParam.field === 'infoInternal'} direction={sortParam.order}>
                        Internal&nbsp;note
                    </TableSortLabel>
                </TableCell>
                <TableCell className={classes.headerCell} style={{ minWidth: '150px' }}>
                    Status
                </TableCell>
                {!showShort && <TableCell className={classes.headerCell}>Assignee</TableCell>}
                <TableCell className={classes.headerCell}>Actions</TableCell>
                {bulkDropEnabled && (
                    <TableCell className={classes.headerCell} style={{ minWidth: '120px', display: 'flex' }}>
                        <input type='checkbox' onChange={e => handleChange(e.target.checked)} />
                        {bulkDropList.length ? (
                            <ButtonBulkDrop type={BUTTON_TYPES.TEXT_REGULAR} onClick={() => toggleBulkDropForm(true)}>
                                BULK DROP
                            </ButtonBulkDrop>
                        ) : null}
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    )
}

const ButtonBulkDrop = styled(Button)`
    margin-left: 16px;
`
