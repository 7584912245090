import { TableCell, TableRow, TableBody, Table } from '@material-ui/core'
import { isEmpty } from 'lodash-es'
import { type FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { EXECUTION_PRIORITY_LABELS, type CloseTaskStatus, type Task } from 'src/api/fm/tasks/tasks.model'
import TrashIcon from 'src/assets/images/bin_hollow.svg'
import EditIcon from 'src/assets/images/edit_hollow.svg'
import MoreIcon from 'src/assets/images/ic_more.svg'
import { AddToBulk } from 'src/components/parts/active-tasks/AddToBulk'
import { TASK_STATUS_OPTIONS } from 'src/components/parts/active-tasks/createTaskForm/task.types'
import {
    DropScooter,
    type UpdateTaskData,
    type UpdateTaskPayload,
} from 'src/components/parts/active-tasks/dropScooter/DropScooter'
import { type EditedTaskPayload, EditTaskForm } from 'src/components/parts/active-tasks/editTaskForm/EditTaskForm'
import { getTaskOwnerLink } from 'src/components/parts/active-tasks/Utils'
import { TaskEventInfo } from 'src/components/parts/history/hunter/task-event-info/TaskEventInfo'
import { CreatedInfo } from 'src/pages/hunterDetails/history/HunterHistory'
import { Button } from 'src/ui-kit/button/Button'
import { VoiIcon } from 'src/ui-kit/voiIcon/VoiIcon'
import { Row } from 'src/ui-kit/layout/Layout'
import { useTableStyles } from 'src/ui-kit/materialTable/MaterialTable'
import { Popup } from 'src/ui-kit/popup/Popup'
import { TooltipMenu } from 'src/ui-kit/tooltipMenu/TooltipMenu'
import { VoiSelect } from 'src/ui-kit/voiSelect/VoiSelect'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import { getTaskCollector, getTaskCreator, prettifyTaskType, sortActiveTasks } from 'src/utils/tasks/tasksUtils'
import styled from 'styled-components'
import {
    bulkDropActiveTasksOrg,
    type BulkDropActiveTasksOrganizationData,
    closeTaskForOrganization,
    closeTaskForVehicle,
    deleteTaskForOrganization,
    deleteTaskForVehicle,
    editTaskOrganization,
    editTaskVehicle,
    selectTaskBulkDropList,
    selectTasks,
} from 'src/redux/task'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { Headers } from 'src/components/parts/active-tasks/Headers'
import { EmptyTablePlaceholder } from 'src/ui-kit/table/Table'
import { SortOrder } from 'src/utils/sort/sort'
import { DropScootersBulk } from 'src/components/parts/active-tasks/DropScootersBulk'
import { permissions } from 'src/components/app/accessControl.permissions'
import { selectCurrentUserRoles } from 'src/redux/auth'
import { checkIfRolesHavePermission } from 'src/components/app/utils/accessControl/accessControlUtils'
import { selectPermissionsForRoles } from 'src/redux/permissionsForRoles'

export type SortParamField = 'createdAt' | 'collected' | 'type' | 'infoInternal' | 'info' | 'executionPriority'
export interface SortParamType {
    field: SortParamField
    order: SortOrder
}
const INITIAL_SORT_PARAMS: SortParamType = { field: 'createdAt', order: SortOrder.Desc }

interface Props {
    accountId?: string
}

export function ActiveTasksTable(props: Props) {
    const { accountId } = props
    const isOrgAccount = !!accountId

    const [updateTask, setUpdateTask] = useState<UpdateTaskData | null>(null)
    const [editTask, setEditTask] = useState<Task | null>(null)
    const [deleteTask, toggleDeletePopup] = useState<Task | null>(null)
    const [sortParam, setSortParam] = useState<SortParamType>(INITIAL_SORT_PARAMS)
    const [showBulkDropForm, toggleBulkDropForm] = useState(false)

    const tasks = useAppSelector(selectTasks)
    const bulkDropList = useAppSelector(selectTaskBulkDropList)
    const roles = useAppSelector(selectCurrentUserRoles)
    const permissionsForRoles = useAppSelector(selectPermissionsForRoles)

    const allowSingleTaskEditing = checkIfRolesHavePermission({
        roles,
        allow: permissions.tasks_editor,
        permissionsForRoles,
    })
    const allowBulkTaskEditing = checkIfRolesHavePermission({
        roles,
        allow: permissions.tasks_bulk_editor,
        permissionsForRoles,
    })
    const hasActiveTasks = Array.isArray(tasks) && tasks.length > 0

    const dispatch = useAppDispatch()

    const handleDelete = (e: FormEvent, task: Task) => {
        e.preventDefault()

        if (isOrgAccount) {
            dispatch(deleteTaskForOrganization(task))
        } else {
            dispatch(deleteTaskForVehicle(task))
        }

        toggleDeletePopup(null)
    }

    const handleSubmitEditTask = (task: EditedTaskPayload) => {
        if (isOrgAccount) {
            dispatch(editTaskOrganization(task, accountId))
        } else {
            dispatch(editTaskVehicle(task, task.vehicle.id))
        }

        setEditTask(null)
    }

    const handleSubmitUpdateTask = (task: UpdateTaskPayload) => {
        if (isOrgAccount) {
            dispatch(closeTaskForOrganization(task))
        } else {
            dispatch(closeTaskForVehicle(task))
        }
    }

    const handleBulkDropTasksOrg = (tasks: BulkDropActiveTasksOrganizationData) => {
        dispatch(bulkDropActiveTasksOrg(tasks))
    }

    const classes = useTableStyles()

    return (
        <>
            {editTask && (
                <EditTaskForm
                    task={editTask}
                    close={() => setEditTask(null)}
                    onSubmit={task => handleSubmitEditTask(task)}
                />
            )}
            {updateTask && (
                <DropScooter updateTask={handleSubmitUpdateTask} data={updateTask} close={() => setUpdateTask(null)} />
            )}
            {deleteTask && (
                <Popup title='Delete task' close={() => toggleDeletePopup(null)}>
                    <form onSubmit={e => handleDelete(e, deleteTask)}>
                        <p>Are you sure you want to delete this task?</p>
                        <Button dataTestId='confirm-btn-delete-task' isStretched actionType='submit'>
                            CONFIRM
                        </Button>
                    </form>
                </Popup>
            )}
            {showBulkDropForm && isOrgAccount && (
                <DropScootersBulk
                    accountId={accountId}
                    close={() => toggleBulkDropForm(false)}
                    bulkDrop={handleBulkDropTasksOrg}
                    tasks={bulkDropList}
                />
            )}
            <Table>
                <Headers
                    showShort={isOrgAccount}
                    tasks={Array.isArray(tasks) ? tasks : []}
                    bulkDropEnabled={isOrgAccount && allowBulkTaskEditing}
                    toggleBulkDropForm={toggleBulkDropForm}
                    sortParam={sortParam}
                    setSortParam={setSortParam}
                />
                {hasActiveTasks && (
                    <TableBody>
                        {sortActiveTasks(tasks, sortParam).map(task => (
                            <TableRow key={task.id} className={classes.row} data-testid='active-tasks-table-row'>
                                {isOrgAccount && task.vehicle ? (
                                    <TableCell className={classes.cell}>
                                        <Link to={`/vehicles/${task.vehicle.short}`}>{task.vehicle.short}</Link>
                                    </TableCell>
                                ) : null}
                                <TableCell
                                    className={classes.cell}
                                    style={{ minWidth: '100px' }}
                                    data-testid='active-tasks-table-row-type'
                                >
                                    {prettifyTaskType(task.type)}
                                    {task.riderIncentivized && (
                                        <>
                                            <br />
                                            Incentivized
                                        </>
                                    )}
                                </TableCell>
                                <TableCell className={classes.cell} data-testid='active-tasks-table-row-priority'>
                                    {EXECUTION_PRIORITY_LABELS[task.executionPriority.name]}
                                </TableCell>
                                <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                    <CreatedInfo
                                        time={task.createdAt}
                                        creator={task.auditLog && getTaskCreator(task.auditLog)}
                                        creationReason={task.creationReason}
                                        battery={task.createdBattery}
                                    />
                                </TableCell>
                                <TableCell className={classes.cell} style={{ minWidth: '200px' }}>
                                    {!isEmpty(task.collectState) ? (
                                        <TaskEventInfo
                                            taskState={task.collectState!}
                                            hunter={task.auditLog && getTaskCollector(task.auditLog)}
                                        />
                                    ) : (
                                        <p> - </p>
                                    )}
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    style={{ minWidth: '200px' }}
                                    data-testid='active-tasks-table-row-info'
                                >
                                    {task.info}
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    style={{ minWidth: '200px' }}
                                    data-testid='active-tasks-table-row-info-internal'
                                >
                                    {task.infoInternal}
                                </TableCell>
                                <TableCell
                                    className={classes.cell}
                                    style={{ minWidth: '150px' }}
                                    data-testid='active-tasks-table-row-status'
                                >
                                    {allowSingleTaskEditing ? (
                                        <VoiSelect
                                            options={Object.keys(TASK_STATUS_OPTIONS)}
                                            value={task.status}
                                            onChange={(_, value) =>
                                                setUpdateTask({ task, status: value as CloseTaskStatus })
                                            }
                                            getOptionLabel={key =>
                                                TASK_STATUS_OPTIONS[key as keyof typeof TASK_STATUS_OPTIONS]
                                            }
                                            filterOptions={filterOptions}
                                            dataTestId='select-active-task-status'
                                        />
                                    ) : (
                                        <span>{firstLetterUpperCase(task.status)}</span>
                                    )}
                                </TableCell>
                                {!isOrgAccount && (
                                    <TableCell className={classes.cell}>
                                        {task.accountId ? getTaskOwnerLink(task.accountId, task.ownerType!) : '-'}
                                    </TableCell>
                                )}
                                <TableCell className={classes.cell}>
                                    {allowSingleTaskEditing && (
                                        <TooltipMenu
                                            placement='left'
                                            anchor={<VoiIcon src={MoreIcon} />}
                                            data-testid='task-action-tooltip'
                                        >
                                            <Row
                                                onClick={() => setEditTask(task)}
                                                style={{ alignItems: 'flex-start' }}
                                                data-testid='edit-task-button'
                                            >
                                                <VoiIcon src={EditIcon} />
                                                <MenuText>EDIT</MenuText>
                                            </Row>
                                            <Row
                                                onClick={() => toggleDeletePopup(task)}
                                                style={{ alignItems: 'flex-start' }}
                                                data-testid='delete-task-button'
                                            >
                                                <VoiIcon src={TrashIcon} />
                                                <MenuText>DELETE</MenuText>
                                            </Row>
                                        </TooltipMenu>
                                    )}
                                </TableCell>
                                {isOrgAccount && allowBulkTaskEditing && <AddToBulk classes={classes} task={task} />}
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
            {!hasActiveTasks && <EmptyTablePlaceholder>No tasks assigned to this vehicle.</EmptyTablePlaceholder>}
        </>
    )
}

const filterOptions = (options: string[]): string[] =>
    options.filter(option => option !== 'new' && option !== 'pending')

const MenuText = styled.span`
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Overpass Mono', monospace;
`
