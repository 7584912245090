import {
    SET_ACTIVE_TASKS,
    RESET_ACTIVE_TASKS,
    ADD_ACTIVE_TASK_TO_BULK,
    REMOVE_ACTIVE_TASK_FROM_BULK,
    ADD_ALL_ACTIVE_TASKS_TO_BULK,
    REMOVE_ALL_ACTIVE_TASKS_FROM_BULK,
    SET_TASK_TYPES,
    type TaskState,
    type TaskActions,
} from 'src/redux/task/task.types'
import { type Task } from 'src/api/fm/tasks/tasks.model'

function addTaskToBulk(task: Task, bulkList: Task[]) {
    return [...bulkList, task]
}

function removeTaskFromBulk(task: Task, bulkList: Task[]) {
    return bulkList.filter(item => item.id !== task.id)
}

export const INITIAL_STATE: TaskState = {
    tasks: null,
    bulkDropList: [],
    taskTypes: [],
}

export function taskReducer(state: TaskState = INITIAL_STATE, action: TaskActions): TaskState {
    switch (action.type) {
        case SET_ACTIVE_TASKS:
            return { ...state, tasks: action.payload }

        case RESET_ACTIVE_TASKS:
            return { ...state, tasks: null }

        case ADD_ACTIVE_TASK_TO_BULK:
            return { ...state, bulkDropList: addTaskToBulk(action.payload, state.bulkDropList) }

        case REMOVE_ACTIVE_TASK_FROM_BULK:
            return { ...state, bulkDropList: removeTaskFromBulk(action.payload, state.bulkDropList) }

        case ADD_ALL_ACTIVE_TASKS_TO_BULK:
            return { ...state, bulkDropList: action.payload }

        case REMOVE_ALL_ACTIVE_TASKS_FROM_BULK:
            return { ...state, bulkDropList: [] }

        case SET_TASK_TYPES:
            return { ...state, taskTypes: action.payload }

        default:
            return state
    }
}
