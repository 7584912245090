import { type Task, type TaskType } from 'src/api/fm/tasks/tasks.model'
import { type TaskFormProps } from 'src/components/parts/active-tasks/createTaskForm/task.types'
import { type EditedTaskPayload } from 'src/components/parts/active-tasks/editTaskForm/EditTaskForm'
import {
    type BulkDropActiveTasksOrganizationData,
    type CloseTaskOrganizationData,
    type CloseTaskVehicleData,
} from 'src/redux/task/task.actions'

export const GET_ACTIVE_TASKS_FOR_VEHICLE = 'GET_ACTIVE_TASKS_FOR_VEHICLE'
export const GET_ACTIVE_TASKS_ORG = 'GET_ACTIVE_TASKS_ORG'
export const SET_ACTIVE_TASKS = 'SET_ACTIVE_TASKS'
export const RESET_ACTIVE_TASKS = 'RESET_ACTIVE_TASKS'
export const DELETE_TASK_FOR_ORGANIZATION = 'DELETE_TASK_FOR_ORGANIZATION'
export const DELETE_TASK_FOR_VEHICLE = 'DELETE_TASK_FOR_VEHICLE'
export const CLOSE_TASK_FOR_VEHICLE = 'CLOSE_TASK_FOR_VEHICLE'
export const CLOSE_TASK_FOR_ORGANIZATION = 'CLOSE_TASK_FOR_ORGANIZATION'
export const ADD_ACTIVE_TASK_TO_BULK = 'ADD_ACTIVE_TASK_TO_BULK'
export const REMOVE_ACTIVE_TASK_FROM_BULK = 'REMOVE_ACTIVE_TASK_FROM_BULK'
export const ADD_ALL_ACTIVE_TASKS_TO_BULK = 'ADD_ALL_ACTIVE_TASKS_TO_BULK'
export const REMOVE_ALL_ACTIVE_TASKS_FROM_BULK = 'REMOVE_ALL_ACTIVE_TASKS_FROM_BULK'
export const BULK_DROP_ACTIVE_TASKS_ORG = 'BULK_DROP_ACTIVE_TASKS_ORG'
export const CREATE_TASK_FOR_VEHICLE = 'CREATE_TASK_FOR_VEHICLE'
export const CREATE_TASK_FOR_ORGANIZATION = 'CREATE_TASK_FOR_ORGANIZATION'
export const EDIT_TASK_VEHICLE = 'EDIT_TASK_VEHICLE'
export const EDIT_TASK_ORGANIZATION = 'EDIT_TASK_ORGANIZATION'
export const GET_TASK_TYPES = 'GET_TASK_TYPES'
export const SET_TASK_TYPES = 'SET_TASK_TYPES'

export interface TaskState {
    tasks: Task[] | null
    bulkDropList: Task[]
    taskTypes: TaskType[]
}

export interface CreateTaskForVehicle {
    type: typeof CREATE_TASK_FOR_VEHICLE
    payload: { task: TaskFormProps; vehicleId: string }
}

export interface CreateTaskForOrganization {
    type: typeof CREATE_TASK_FOR_ORGANIZATION
    payload: { task: TaskFormProps; organizationId: string }
}

export interface GetActiveTasksForVehicle {
    type: typeof GET_ACTIVE_TASKS_FOR_VEHICLE
    payload: { vehicleId: string }
}

export interface GetActiveTasksOrg {
    type: typeof GET_ACTIVE_TASKS_ORG
    payload: { id: string | undefined; email?: string }
}

export interface SetActiveTasks {
    type: typeof SET_ACTIVE_TASKS
    payload: Task[]
}

export interface ResetActiveTasks {
    type: typeof RESET_ACTIVE_TASKS
}

export interface DeleteTaskForVehicle {
    type: typeof DELETE_TASK_FOR_VEHICLE
    payload: Task
}

export interface DeleteTaskForOrganization {
    type: typeof DELETE_TASK_FOR_ORGANIZATION
    payload: Task
}

export interface CloseTaskForVehicle {
    type: typeof CLOSE_TASK_FOR_VEHICLE
    payload: CloseTaskVehicleData
}

export interface CloseTaskForOrganization {
    type: typeof CLOSE_TASK_FOR_ORGANIZATION
    payload: CloseTaskOrganizationData
}

export interface AddActiveTaskToBulk {
    type: typeof ADD_ACTIVE_TASK_TO_BULK
    payload: Task
}

export interface RemoveActiveTaskFromBulk {
    type: typeof REMOVE_ACTIVE_TASK_FROM_BULK
    payload: Task
}

export interface AddAllActiveTasksToBulk {
    type: typeof ADD_ALL_ACTIVE_TASKS_TO_BULK
    payload: Task[]
}

export interface RemoveAllActiveTasksFromBulk {
    type: typeof REMOVE_ALL_ACTIVE_TASKS_FROM_BULK
}

export interface BulkDropActiveTasksOrg {
    type: typeof BULK_DROP_ACTIVE_TASKS_ORG
    payload: BulkDropActiveTasksOrganizationData
}

export interface EditTaskVehicle {
    type: typeof EDIT_TASK_VEHICLE
    payload: { task: EditedTaskPayload; vehicleId: string }
}

export interface EditTaskOrganization {
    type: typeof EDIT_TASK_ORGANIZATION
    payload: { task: EditedTaskPayload; organizationId: string }
}

export interface GetTaskTypes {
    type: typeof GET_TASK_TYPES
}

export interface SetTaskTypes {
    type: typeof SET_TASK_TYPES
    payload: TaskType[]
}

export type TaskActions =
    | CreateTaskForVehicle
    | CreateTaskForOrganization
    | GetActiveTasksForVehicle
    | GetActiveTasksOrg
    | SetActiveTasks
    | ResetActiveTasks
    | DeleteTaskForVehicle
    | DeleteTaskForOrganization
    | CloseTaskForVehicle
    | CloseTaskForOrganization
    | AddActiveTaskToBulk
    | RemoveActiveTaskFromBulk
    | AddAllActiveTasksToBulk
    | RemoveAllActiveTasksFromBulk
    | BulkDropActiveTasksOrg
    | EditTaskVehicle
    | EditTaskOrganization
    | GetTaskTypes
    | SetTaskTypes
