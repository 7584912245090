import {
    type AccidentReportsState,
    type AccidentsAction,
    SET_ACCIDENT_REPORTS,
    SET_ACCIDENT_REASONS,
    SET_CURRENT_ACCIDENT_REPORT,
} from 'src/redux/accidents/accidents.types'

const INITIAL_STATE: AccidentReportsState = {
    accidents: [],
    totalCount: 0,
    currentAccident: null,
    reasons: [],
}

export function accidentsReducer(
    state: AccidentReportsState = INITIAL_STATE,
    action: AccidentsAction,
): AccidentReportsState {
    switch (action.type) {
        case SET_ACCIDENT_REPORTS:
            return { ...state, accidents: action.payload.data, totalCount: action.payload.totalCount }

        case SET_ACCIDENT_REASONS:
            return { ...state, reasons: action.payload.data }

        case SET_CURRENT_ACCIDENT_REPORT:
            return { ...state, currentAccident: action.payload }

        default:
            return state
    }
}
