import {
    type GetVoucherDetailsResponse,
    type RiderVoucherDetails,
    VoucherAvailability,
} from 'src/api/fm/discounts/discounts.model'
import { unixToMoment } from 'src/utils/time/timeUtils'
export const mapRiderVoucherDetails = (xhrResponse: GetVoucherDetailsResponse): RiderVoucherDetails => {
    const { data } = xhrResponse
    const { discounts, availability } = data
    return {
        canUseVouchers: checkCanUseVoucher(availability),
        vouchers: discounts.map(voucher => {
            const { name, id, amount, status, code, discount_type, expires_at, voipass_discount, times_used } = voucher
            return {
                name,
                id,
                amount,
                timesUsed: times_used || 0,
                code,
                status,
                expiresAt: unixToMoment(expires_at as number),
                discountType: discount_type,
                voiPassDiscount: voipass_discount,
            }
        }),
    }
}

const checkCanUseVoucher = (availability: VoucherAvailability): boolean => {
    switch (availability) {
        case VoucherAvailability.Available:
            return true
        default:
            return false
    }
}
