import { useLocation } from 'react-router-dom'
import { useWindowSize } from 'src/components/app/utils/hooks/useWindowSize'
import { DesktopNav } from 'src/components/parts/navigation/DesktopNav'
import { MobileNav } from 'src/components/parts/navigation/MobileNav'
import * as breakpoints from 'src/constants/breakpoints'

function Navigation() {
    const { pathname } = useLocation()
    const windowSize = useWindowSize()

    return windowSize.width > breakpoints.pixels.mobile ? (
        <DesktopNav activeLink={pathname} />
    ) : (
        <MobileNav activeLink={pathname} />
    )
}

export default Navigation
