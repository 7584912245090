import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { type RideHistoryItem } from 'src/api/fm/rentals/rentals.model'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { type VehicleDecommissionedInfo } from 'src/api/fm/decommission/decommission.model'

export const SET_CURRENT_VEHICLE = 'SET_CURRENT_VEHICLE' as const
export const GET_VEHICLE = 'GET_VEHICLE' as const
export const GET_VEHICLE_ERROR = 'GET_VEHICLE_ERROR' as const
export const RESET_SCOOTER_RIDE_HISTORY = 'RESET_RIDE_HISTORY' as const
export const SET_VEHICLE_RIDE_HISTORY = 'SET_VEHICLE_RIDE_HISTORY' as const
export const SET_VEHICLE_RIDE_HISTORY_DATE_FILTER = 'SET_VEHICLE_RIDE_HISTORY_DATE_FILTER' as const
export const SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER = 'SET_VEHICLE_RIDE_HISTORY_SOURCE_FILTER' as const
export const GET_VEHICLE_TASK_HISTORY = 'GET_VEHICLE_TASK_HISTORY' as const
export const SET_VEHICLE_TASK_HISTORY = 'SET_VEHICLE_TASK_HISTORY' as const
export const SET_IS_FETCHING_VEHICLE_TASK_HISTORY = 'SET_IS_FETCHING_VEHICLE_TASK_HISTORY' as const
export const GET_VEHICLE_TASK_HISTORY_NEXT_PAGE = 'GET_VEHICLE_TASK_HISTORY_NEXT_PAGE' as const
export const GET_VEHICLE_DECOMMISSIONED_INFO = 'GET_VEHICLE_DECOMMISSIONED_INFO' as const
export const SET_VEHICLE_DECOMMISSIONED_INFO = 'SET_VEHICLE_DECOMMISSIONED_INFO' as const
export const RESET_VEHICLE_TASK_HISTORY = 'RESET_VEHICLE_TASK_HISTORY' as const
export const SET_CURRENT_VEHICLE_RIDER = 'SET_CURRENT_VEHICLE_RIDER' as const
export const UPDATE_VEHICLE_INFO = 'UPDATE_VEHICLE_INFO' as const
export const RESET_VEHICLE_STATE = 'RESET_VEHICLE_STATE' as const
export const REMOVE_VEHICLE_FROM_REPAIR = 'REMOVE_VEHICLE_FROM_REPAIR' as const
export const GET_VEHICLE_RIDE_HISTORY = 'GET_VEHICLE_RIDE_HISTORY' as const
export const BIKE_UNARM_LOCK = 'BIKE_UNARM_LOCK' as const
export const GET_VEHICLE_LICENSE_PLATE_SUCCESS = 'GET_VEHICLE_LICENSE_PLATE_SUCCESS' as const
export const DECOMMISSION_VEHICLE = 'DECOMMISSION_VEHICLE' as const
export const UNDO_DECOMMISSION_VEHICLE = 'UNDO_DECOMMISSION_VEHICLE' as const
export const SET_END_OF_TASK_HISTORY_PAGINATION = 'SET_END_OF_TASK_HISTORY_PAGINATION' as const
export const INCREMENT_TASK_HISTORY_PAGINATION = 'INCREMENT_TASK_HISTORY_PAGINATION' as const

export interface ScooterState {
    currentScooter: Vehicle | null
    currentScooterLicensePlate: string | null
    currentScooterError: string | null
    rideHistory: RideHistoryItem[] | null
    rideHistoryDateFilter: string | null
    rideHistorySourceFilter: string | null
    taskHistory: Task[]
    isFetchingTaskHistory: boolean
    taskHistoryPageNum: number
    taskHistoryEndOfPagination: boolean
    currentRider: VehicleCurrentRider | null
    decommissionedInfo: VehicleDecommissionedInfo | null
}

export interface VehicleCurrentRider {
    id: string
    email: string
}
