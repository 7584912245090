import { type AxiosError } from 'axios'
import { get, has } from 'lodash-es'

interface BackendError {
    status: string
    code: string
}

export interface BackendErrors {
    errors?: BackendError[]
}

export function getError(error: AxiosError<BackendErrors>, path?: string): Error {
    // Custom backend error
    if (path && has(error, path)) {
        const { status, code }: BackendError = get(error, path)

        return new Error(`${status} ${code}`)
    }

    // Generic Axios error
    if (error.response?.status && error.response.statusText) {
        return new Error(`${error.response.status} ${error.response.statusText}`)
    }

    return error as Error
}

export const isNotFoundError = (error: AxiosError<BackendErrors>) => {
    const responseErrors: BackendError[] | undefined = get(error, 'response.data.errors')

    return (
        Array.isArray(responseErrors) && responseErrors.every(responseError => get(responseError, 'status') === '404')
    )
}
