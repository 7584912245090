import { takeEvery, type SagaReturnType, put, call, select, fork, take, cancel, cancelled } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import {
    getMaintenanceNeedVehicles,
    setMaintenanceNeedVehicles,
} from 'src/redux/maintenanceNeed/maintenanceNeed.actions'
import {
    GET_MAINTENANCE_NEED_VEHICLES,
    type CreateMaintenanceNeeds,
    type GetMaintenanceNeedVehicles,
    type UpdateMaintenanceNeed,
    CREATE_MAINTENANCE_NEEDS,
    UPDATE_MAINTENANCE_NEED,
    DELETE_MAINTENANCE_NEEDS,
    type DeleteMaintenanceNeeds,
} from 'src/redux/maintenanceNeed/maintenanceNeed.types'
import * as api from 'src/api'
import { selectMaintenanceNeedVehicles } from 'src/redux/maintenanceNeed/maintenanceNeed.selectors'
import type { MaintenanceNeed, MaintenanceNeedVehicle } from 'src/api/fm/maintenanceNeed/maintenanceNeed.model'
import { CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS } from 'src/redux/scooterBulk/scooterBulk.types'
import {
    incrementBulkActionLoadingMultipleRequests,
    setBulkActionDone,
    setBulkActionLoading,
    setBulkActionLoadingMultipleRequests,
} from 'src/redux/scooterBulk'
import { chunk } from 'src/utils/arrayChunk/arrayChunk'

export const MAINTENANCE_NEEDS_BATCH_SIZE = 30

type GetMaintenanceNeedVehiclesRes = SagaReturnType<typeof api.getMaintenanceNeedVehicles>
export function* getMaintenanceNeedVehiclesSaga(action: GetMaintenanceNeedVehicles) {
    try {
        let maintenanceNeedVehicles: GetMaintenanceNeedVehiclesRes

        const firstVehicleIdentifier = action.payload[0]
        const identifierType = firstVehicleIdentifier.length === 4 ? ('short' as const) : ('id' as const)

        switch (identifierType) {
            case 'id':
                maintenanceNeedVehicles = yield call(api.getMaintenanceNeedVehicles, action.payload)
                break
            case 'short':
                maintenanceNeedVehicles = yield call(api.getMaintenanceNeedVehicles, undefined, action.payload)
                break
        }

        if (maintenanceNeedVehicles instanceof Error) {
            throw maintenanceNeedVehicles
        }

        yield put(setMaintenanceNeedVehicles(maintenanceNeedVehicles))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* createMaintenanceNeedsSaga(action: CreateMaintenanceNeeds): SagaIterator {
    yield put(setBulkActionLoading())
    const createMaintenanceNeedsRequestsTask = yield fork(createMaintenanceNeedsRequests, action.payload)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(createMaintenanceNeedsRequestsTask)
}

type CreateMaintenanceNeedsRes = SagaReturnType<typeof api.createMaintenanceNeeds>

export function* createMaintenanceNeedsRequests(maintenanceNeed: MaintenanceNeed): SagaIterator {
    let vehicleIds: string[] = []
    let successfulCreationCount = 0
    let fatalError = false

    try {
        const maintenanceNeedsVehicles: MaintenanceNeedVehicle[] = yield select(selectMaintenanceNeedVehicles)
        vehicleIds = maintenanceNeedsVehicles.map(m => m.vehicleId)

        const batches = chunk(vehicleIds, MAINTENANCE_NEEDS_BATCH_SIZE)

        if (batches.length > 1) {
            yield put(setBulkActionLoadingMultipleRequests(batches.length))
        }

        for (const batch of batches) {
            const results: CreateMaintenanceNeedsRes = yield call(api.createMaintenanceNeeds, batch, maintenanceNeed)

            // Break the loop and the saga if one request fails
            if (results instanceof Error) {
                throw new Error(`Something went wrong with one of the requests: ${results.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            successfulCreationCount += results.successful.length
        }
    } catch (e) {
        fatalError = true
        yield call(notifyUser, e, 'error')
    } finally {
        if (!fatalError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled creating maintenance needs' }, 'error')
            } else {
                switch (successfulCreationCount) {
                    case vehicleIds.length:
                        yield call(notifyUser, 'Successfully created maintenance needs', 'success')
                        break
                    case 0:
                        yield call(notifyUser, { message: 'Failed to create maintenance needs' }, 'error')
                        break
                    default:
                        yield call(notifyUser, { message: 'Failed to create maintenance needs' }, 'error')
                        yield call(notifyUser, 'Successfully created maintenance needs', 'success')
                        break
                }
            }
        }

        yield put(getMaintenanceNeedVehicles(vehicleIds))
        yield put(setBulkActionDone())
    }
}

type UpdateMaintenanceNeedRes = SagaReturnType<typeof api.updateMaintenanceNeed>
export function* updateMaintenanceNeedSaga(action: UpdateMaintenanceNeed) {
    try {
        const response: UpdateMaintenanceNeedRes = yield call(api.updateMaintenanceNeed, action.payload)

        if (response instanceof Error) {
            throw response
        }

        yield call(notifyUser, 'Successfully updated maintenance need', 'success')
        const maintenanceNeedsVehicles: MaintenanceNeedVehicle[] = yield select(selectMaintenanceNeedVehicles)
        const vehicleIds = maintenanceNeedsVehicles.map(m => m.vehicleId)
        yield put(getMaintenanceNeedVehicles(vehicleIds))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* deleteMaintenanceNeedsSaga(action: DeleteMaintenanceNeeds): SagaIterator {
    yield put(setBulkActionLoading())
    const deleteMaintenanceNeedsRequestsTask = yield fork(deleteMaintenanceNeedsRequests, action.payload)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(deleteMaintenanceNeedsRequestsTask)
}

type DeleteMaintenanceNeedsRes = SagaReturnType<typeof api.createMaintenanceNeeds>

export function* deleteMaintenanceNeedsRequests(maintenanceNeedIds: string[]): SagaIterator {
    let successfulDeletionCount = 0
    let fatalError = false

    try {
        const batches = chunk(maintenanceNeedIds, MAINTENANCE_NEEDS_BATCH_SIZE)

        if (batches.length > 1) {
            yield put(setBulkActionLoadingMultipleRequests(batches.length))
        }

        for (const batch of batches) {
            const results: DeleteMaintenanceNeedsRes = yield call(api.deleteMaintenanceNeeds, batch)

            // Break the loop and the saga if one request fails
            if (results instanceof Error) {
                throw new Error(`Something went wrong with one of the requests: ${results.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            successfulDeletionCount += results.successful.length
        }
    } catch (e) {
        fatalError = true
        yield call(notifyUser, e, 'error')
    } finally {
        if (!fatalError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled deleting maintenance needs' }, 'error')
            } else {
                switch (successfulDeletionCount) {
                    case maintenanceNeedIds.length:
                        yield call(notifyUser, 'Successfully deleted maintenance needs', 'success')
                        break
                    case 0:
                        yield call(notifyUser, { message: 'Failed to delete maintenance needs' }, 'error')
                        break
                    default:
                        yield call(notifyUser, { message: 'Failed to delete maintenance needs' }, 'error')
                        yield call(notifyUser, 'Successfully deleted maintenance needs', 'success')
                        break
                }
            }
        }

        const maintenanceNeedVehicles: MaintenanceNeedVehicle[] = yield select(selectMaintenanceNeedVehicles)
        const vehicleIds = maintenanceNeedVehicles.map(m => m.vehicleId)
        yield put(getMaintenanceNeedVehicles(vehicleIds))
        yield put(setBulkActionDone())
    }
}

export default function* watcher(): any {
    yield takeEvery(GET_MAINTENANCE_NEED_VEHICLES, getMaintenanceNeedVehiclesSaga)
    yield takeEvery(CREATE_MAINTENANCE_NEEDS, createMaintenanceNeedsSaga)
    yield takeEvery(UPDATE_MAINTENANCE_NEED, updateMaintenanceNeedSaga)
    yield takeEvery(DELETE_MAINTENANCE_NEEDS, deleteMaintenanceNeedsSaga)
}
