import {
    type AccidentReport,
    type AccidentReportForm,
    type AccidentReports,
    type AccidentReasons,
    type AccidentReason,
} from 'src/api/fm/accidents/accidents.model'

export const GET_ACCIDENT_REPORTS = 'GET_ACCIDENT_REPORTS'
export const SET_ACCIDENT_REPORTS = 'SET_ACCIDENT_REPORTS'
export const GET_ACCIDENT_REPORT = 'GET_ACCIDENT_REPORT'
export const GET_ACCIDENT_REASONS = 'GET_ACCIDENT_REASONS'
export const SET_ACCIDENT_REASONS = 'SET_ACCIDENT_REASONS'
export const CREATE_ACCIDENT_REASON = 'CREATE_ACCIDENT_REASON'
export const EDIT_ACCIDENT_REASON = 'EDIT_ACCIDENT_REASON'
export const DELETE_ACCIDENT_REASON = 'DELETE_ACCIDENT_REASON'
export const SET_CURRENT_ACCIDENT_REPORT = 'SET_CURRENT_ACCIDENT_REPORT'
export const CREATE_ACCIDENT_REPORT = 'CREATE_ACCIDENT_REPORT'
export const EDIT_ACCIDENT_REPORT = 'EDIT_ACCIDENT_REPORT'
export const DELETE_ACCIDENT_REPORT = 'DELETE_ACCIDENT_REPORT'

export interface AccidentReportsState {
    accidents: AccidentReport[]
    totalCount: number
    currentAccident: AccidentReport | null
    reasons: AccidentReason[]
}

export interface GetAccidentReports {
    type: typeof GET_ACCIDENT_REPORTS
    payload: {
        limit: number
        offset: number
        query: string
    }
}

export interface SetAccidentReports {
    type: typeof SET_ACCIDENT_REPORTS
    payload: AccidentReports
}

export interface GetAccidentReport {
    type: typeof GET_ACCIDENT_REPORT
    payload: string
}

export interface GetAccidentReasons {
    type: typeof GET_ACCIDENT_REASONS
}

export interface SetAccidentReasons {
    type: typeof SET_ACCIDENT_REASONS
    payload: AccidentReasons
}

export interface CreateAccidentReason {
    type: typeof CREATE_ACCIDENT_REASON
    payload: AccidentReason
}

export interface EditAccidentReason {
    type: typeof EDIT_ACCIDENT_REASON
    payload: {
        reasonId: string
        reason: AccidentReason
    }
}

export interface DeleteAccidentReason {
    type: typeof DELETE_ACCIDENT_REASON
    payload: {
        reasonId: string
    }
}

export interface SetCurrentAccidentReport {
    type: typeof SET_CURRENT_ACCIDENT_REPORT
    payload: AccidentReport
}

export interface CreateAccidentReport {
    type: typeof CREATE_ACCIDENT_REPORT
    payload: {
        reportForm: AccidentReportForm
        pushToRoute: (reportId: string) => void
    }
}

export interface EditAccidentReport {
    type: typeof EDIT_ACCIDENT_REPORT
    payload: {
        reportId: string
        reportForm: AccidentReportForm
        pushToRoute: (reportId: string) => void
    }
}

export interface DeleteAccidentReport {
    type: typeof DELETE_ACCIDENT_REPORT
    payload: {
        reportId: string
        pushToRoute: () => void
    }
}

export type AccidentsAction =
    | GetAccidentReports
    | GetAccidentReasons
    | SetAccidentReasons
    | CreateAccidentReason
    | EditAccidentReason
    | DeleteAccidentReason
    | SetAccidentReports
    | GetAccidentReport
    | SetCurrentAccidentReport
    | CreateAccidentReport
    | EditAccidentReport
    | DeleteAccidentReport
