import { type AppState } from 'src/redux/app/app.store'
import { type ParkingReview } from 'src/api/fm/rentals/rentals.model'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { type Task } from 'src/api/fm/tasks/tasks.model'

export const selectCurrentScooter = (state: AppState): Vehicle | null => state.scooter.currentScooter
export const selectCurrentScooterLicensePlate = (state: AppState): string | null =>
    state.scooter.currentScooterLicensePlate
export const selectCurrentScooterError = (state: AppState): string | null => state.scooter.currentScooterError
export const selectCurrentScooterId = (state: AppState) => state.scooter.currentScooter?.id
export const selectScooterRideHistory = (state: AppState) => state.scooter.rideHistory
export const selectScooterRideHistoryDateFilter = (state: AppState) => state.scooter.rideHistoryDateFilter
export const selectScooterRideHistorySourceFilter = (state: AppState) => state.scooter.rideHistorySourceFilter
export const selectVehicleTaskHistory = (state: AppState): Task[] => state.scooter.taskHistory
export const selectIsFetchingVehicleTaskHistory = (state: AppState) => state.scooter.isFetchingTaskHistory
export const selectScooterCurrentRider = (state: AppState) => state.scooter.currentRider
export const selectScooterParkingReviewById = (state: AppState, rentalId: string): ParkingReview | undefined =>
    state.scooter.rideHistory?.find(item => item.rentalId === rentalId)?.parkingReview
export const selectVehicleTaskHistoryEndOfPagination = (state: AppState): boolean =>
    state.scooter.taskHistoryEndOfPagination
export const selectVehicleTaskHistoryPageNum = (state: AppState): number => state.scooter.taskHistoryPageNum
export const selectVehicleDecommissionedInfo = (state: AppState) => state.scooter.decommissionedInfo
