export const GET_NOTES = 'GET_NOTES'
export const SET_NOTES = 'SET_NOTES'
export const CREATE_NOTE = 'CREATE_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'

export interface NoteState {
    notes: Record<string, any>[]
}

export interface CreateNote {
    type: typeof CREATE_NOTE
    payload: Record<string, any>
}

export interface DeleteNote {
    type: typeof DELETE_NOTE
    payload: { noteId: string; id: string }
}

export interface SetNotes {
    type: typeof SET_NOTES
    payload: Record<string, any>[]
}

export interface GetNotes {
    type: typeof GET_NOTES
    payload: string
}

export type NoteActions = CreateNote | DeleteNote | SetNotes | GetNotes
