import styled from 'styled-components'
import SupportIcon from 'src/assets/images/nav/ic_support.svg'
import KnowledgeBaseIcon from 'src/assets/images/nav/ic_knowledge_base.svg'
import ExternalLinkIcon from 'src/assets/images/nav/ic_external_link.svg'
import { ButtonText } from 'src/ui-kit/text/Text'
import { NavItem, NavIcon } from 'src/components/parts/navigation/DesktopNav'
import * as colors from 'src/ui-kit/colors/colors'

interface Props {
    expanded: boolean
}

export default function SupportSection({ expanded }: Props) {
    return (
        <>
            <ButtonText $color={colors.coral} style={{ margin: '33px 0 0 20px', height: '20px' }}>
                {expanded && 'HELP & SUPPORT'}
            </ButtonText>
            <ServiceDesk expanded={expanded} />
            <KnowledgeBase expanded={expanded} />
        </>
    )
}

function KnowledgeBase({ expanded }: Props) {
    return (
        <a
            href='https://voidev.atlassian.net/wiki/spaces/SDESK/overview?homepageId=281706820'
            target='_blank'
            rel='noopener noreferrer'
            data-testid='visit-knowledge-base-link'
        >
            <NavItem $align='start' $expanded={expanded}>
                <NavIcon $expanded={expanded} src={KnowledgeBaseIcon} alt='Knowledge base' />
                {expanded && (
                    <TitleSubtitleWrapper>
                        <ButtonText $color={colors.white}>
                            KNOWLEDGE BASE <ExternalLinkImg src={ExternalLinkIcon} />
                        </ButtonText>
                        <Subtitle>Useful information of the system and features</Subtitle>
                    </TitleSubtitleWrapper>
                )}
            </NavItem>
        </a>
    )
}

function ServiceDesk({ expanded }: Props) {
    return (
        <a
            href='https://voidev.atlassian.net/servicedesk/customer/portals'
            target='_blank'
            rel='noopener noreferrer'
            data-testid='visit-service-desk-link'
        >
            <NavItem $align='start' $expanded={expanded}>
                <NavIcon $expanded={expanded} src={SupportIcon} alt='Support' />
                {expanded && (
                    <TitleSubtitleWrapper>
                        <ButtonText $color={colors.white}>
                            SERVICE DESK <ExternalLinkImg src={ExternalLinkIcon} />
                        </ButtonText>
                        <Subtitle>Issue report, technical support and feature requests</Subtitle>
                    </TitleSubtitleWrapper>
                )}
            </NavItem>
        </a>
    )
}

const TitleSubtitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const Subtitle = styled.div`
    margin-top: 8px;
    font-size: 13px;
`

const ExternalLinkImg = styled.img`
    height: 11px;
    width: 11px;
`
