import { type VehicleModel } from 'src/api/fm/vehicles/vehicles.model'
import { type RepairAccount } from 'src/api/fm/repairs/repairs.model'
import { type SparePart } from 'src/api/fm/warehouseInventory/warehouseInventory.model'
import type { NavigateFunction } from 'react-router-dom'

export const GET_REPAIR_VEHICLE = 'GET_REPAIR_VEHICLE'
export const SET_CURRENT_REPAIR_VEHICLE = 'SET_CURRENT_REPAIR_VEHICLE'
export const RESET_CURRENT_REPAIR_VEHICLE = 'RESET_CURRENT_REPAIR_VEHICLE'

export const CREATE_REPAIR_ACCOUNT = 'CREATE_REPAIR_ACCOUNT'
export const DELETE_REPAIR_ACCOUNT = 'DELETE_REPAIR_ACCOUNT'
export const GET_REPAIR_ACCOUNT = 'GET_REPAIR_ACCOUNT'
export const SET_CURRENT_REPAIR_ACCOUNT = 'SET_CURRENT_REPAIR_ACCOUNT'
export const SEARCH_REPAIR_ACCOUNT = 'SEARCH_REPAIR_ACCOUNT'

export const CREATE_VEHICLE_SPECS = 'CREATE_VEHICLE_SPECS'
export const UPDATE_VEHICLE_SPECS = 'UPDATE_VEHICLE_SPECS'

export const CREATE_REPAIR_ISSUE = 'CREATE_REPAIR_ISSUE'
export const UPDATE_REPAIR_ISSUE = 'UPDATE_REPAIR_ISSUE'
export const DELETE_REPAIR_ISSUE = 'DELETE_REPAIR_ISSUE'
export const SET_REPAIR_ISSUES = 'SET_REPAIR_ISSUES'
export const GET_REPAIR_ISSUES = 'GET_REPAIR_ISSUES'

export const SET_SPARE_PARTS = 'SET_SPARE_PARTS'

export const REMOVE_VEHICLES_FROM_REPAIR = 'REMOVE_VEHICLES_FROM_REPAIR'

export const DELETE_VEHICLE_IMG = 'DELETE_VEHICLE_IMG'

export interface RepairState {
    currentRepairAccount: RepairAccount | null
    currentRepairVehicle: VehicleModel | null
    spareParts: SparePart[]
    repairIssues: RepairIssue[]
}

export interface RepairIssue {
    id: string
    modelId: string
    name: string
    processNumber: string
    type: string
    targetTime: number
    parts: RepairIssuePart[]
}

export interface RepairIssuePart {
    partId: string
    quantity: number
}

export interface RemoveFromRepairs {
    type: typeof REMOVE_VEHICLES_FROM_REPAIR
    payload: { scooterIds: string[] }
}

export interface ResetCurrentRepairVehicle {
    type: typeof RESET_CURRENT_REPAIR_VEHICLE
}

export interface GetRepairVehicle {
    type: typeof GET_REPAIR_VEHICLE
    payload: string
}

export interface GetRepairIssues {
    type: typeof GET_REPAIR_ISSUES
    payload: string
}

export interface CreateVehicleSpecs {
    type: typeof CREATE_VEHICLE_SPECS
    payload: VehicleModel
}

export interface CreateRepairIssue {
    type: typeof CREATE_REPAIR_ISSUE
    payload: RepairIssue
}

export interface UpdateRepairIssue {
    type: typeof UPDATE_REPAIR_ISSUE
    payload: RepairIssue
}

export interface DeleteRepairIssue {
    type: typeof DELETE_REPAIR_ISSUE
    payload: { vehicleModelId: string; repairIssueId: string }
}

export interface CreateRepairAccount {
    type: typeof CREATE_REPAIR_ACCOUNT
    payload: string
}

export interface DeleteRepairAccount {
    type: typeof DELETE_REPAIR_ACCOUNT
    payload: { id: string; navigateFn: NavigateFunction }
}

export interface GetRepairAccount {
    type: typeof GET_REPAIR_ACCOUNT
    payload: string
}

export interface SetCurrentRepairAccount {
    type: typeof SET_CURRENT_REPAIR_ACCOUNT
    payload: RepairAccount | null
}

export interface SearchRepairAccount {
    type: typeof SEARCH_REPAIR_ACCOUNT
    payload: { searchTerm: string; navigateFn: NavigateFunction }
}

export interface UpdateVehicleSpecs {
    type: typeof UPDATE_VEHICLE_SPECS
    payload: VehicleModel
}

export interface DeleteVehicleImg {
    type: typeof DELETE_VEHICLE_IMG
    payload: VehicleModel
}

export interface SetCurrentRepairVehicle {
    type: typeof SET_CURRENT_REPAIR_VEHICLE
    payload: VehicleModel | null
}

export interface SetSpareParts {
    type: typeof SET_SPARE_PARTS
    payload: SparePart[]
}

export interface SetRepairIssues {
    type: typeof SET_REPAIR_ISSUES
    payload: RepairIssue[]
}

export type RepairActions =
    | RemoveFromRepairs
    | ResetCurrentRepairVehicle
    | GetRepairVehicle
    | CreateVehicleSpecs
    | CreateRepairIssue
    | UpdateRepairIssue
    | DeleteRepairIssue
    | CreateRepairAccount
    | DeleteRepairAccount
    | GetRepairAccount
    | SetCurrentRepairAccount
    | SearchRepairAccount
    | UpdateVehicleSpecs
    | DeleteVehicleImg
    | SetCurrentRepairVehicle
    | SetSpareParts
    | SetRepairIssues
    | GetRepairIssues
