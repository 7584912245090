import { uniqBy } from 'lodash-es'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'

export function handleBulkVehicles(bulkList: BulkListVehicle[], vehicles: BulkListVehicle[]) {
    const allIdsExist = vehicles.every(v => bulkList.some(b => b.id === v.id))

    return allIdsExist
        ? bulkList.filter(b => !vehicles.some(v => v.id === b.id))
        : uniqBy([...bulkList, ...vehicles], v => v.id)
}

export function isVehicleSelected(vehicle: BulkListVehicle, bulkList: BulkListVehicle[]) {
    return bulkList.some(v => v.id === vehicle.id)
}
