import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/vehicle%20parking%20photo/getPhotoURL API documentation}
 */
export const getPhotoUrlByTaskId = async (taskId: string): Promise<string | Error> => {
    const link = encodeURI(`/v1/fm/vehicle-photos/${taskId}`)
    const headers = await getHeaders()

    return ins
        .get(link, { headers })
        .then(res => res.data.data.url)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
