import { type SortOrder } from 'src/utils/sort/sort'
import { type Ride } from 'src/api/fm/rentals/rentals.model'

export const ACTIVE_RIDES_RESET_STATE = 'active_rides/RESET_STATE'
export const ACTIVE_RIDES_SET_RIDES = 'active_rides/SET_RIDES'
export const ACTIVE_RIDES_SET_SEARCH_TERM = 'active_rides/SET_SEARCH_TERM'
export const ACTIVE_RIDES_TOGGLE_COLUMN = 'active_rides/TOGGLE_COLUMN'
export const ACTIVE_RIDES_GET_ACTIVE_RIDES = 'active_rides/GET_ACTIVE_RIDES'

export interface ToggleColumnAction {
    type: typeof ACTIVE_RIDES_TOGGLE_COLUMN
    payload: ActiveRidesColumnType
}

export interface SetSearchTermAction {
    type: typeof ACTIVE_RIDES_SET_SEARCH_TERM
    payload: string
}

export interface GetRidesAction {
    type: typeof ACTIVE_RIDES_GET_ACTIVE_RIDES
}

export interface SetRidesAction {
    type: typeof ACTIVE_RIDES_SET_RIDES
    payload: Ride[]
}

export interface ResetStateAction {
    type: typeof ACTIVE_RIDES_RESET_STATE
}

export type ActiveRidesAction =
    | ToggleColumnAction
    | SetSearchTermAction
    | GetRidesAction
    | SetRidesAction
    | ResetStateAction

export type ActiveRidesColumnType =
    | 'qr'
    | 'ride-id'
    | 'zone'
    | 'lockAttempts'
    | 'firstLockAttempt'
    | 'startTime'
    | 'minuteDuration'

export interface ActiveRidesState {
    active: ActiveRidesColumnType
    sorting: Record<ActiveRidesColumnType, SortOrder>
    searchTerm: string
    rides: Ride[]
}
