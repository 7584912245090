import { type MaasUser, type DraftMaasUser } from 'src/components/app/utils/maas/types'

export const GET_MAAS_USERS = 'GET_MAAS_USERS'
export const SET_MAAS_USERS = 'SET_MAAS_USERS'
export const ADD_MAAS_USER = 'ADD_MAAS_USER'
export const EDIT_MAAS_USER = 'EDIT_MAAS_USER'
export const DELETE_MAAS_USER = 'DELETE_MAAS_USER'

export interface MaasUsersState {
    maasUsers: MaasUser[] | undefined
}

export interface GetMaasUsers {
    type: typeof GET_MAAS_USERS
}

export interface SetMaasUsers {
    type: typeof SET_MAAS_USERS
    payload: MaasUser[]
}

export interface AddMaasUser {
    type: typeof ADD_MAAS_USER
    payload: DraftMaasUser
}

export interface EditMaasUser {
    type: typeof EDIT_MAAS_USER
    payload: MaasUser
}

export interface DeleteMaasUser {
    type: typeof DELETE_MAAS_USER
    payload: MaasUser
}

export type MaasUsersActions = GetMaasUsers | SetMaasUsers | AddMaasUser | EditMaasUser | DeleteMaasUser
