import { getVehicleStatusIcon } from 'src/components/app/utils/scooters/scootersUtils'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'
import { getVehicleCategory } from 'src/utils/vehicles/vehiclesUtils'

// Returns either a vehicle, or the number of vehicles in the cluster
export function getCluster<T>(vehicle: T, clusterSize: number): T | number {
    if (clusterSize > 1) {
        return clusterSize
    } else {
        return vehicle
    }
}

export function getIconSize(size: number): number {
    return Math.min(100, size) / 100 + 1
}

export function getIconName(
    vehicleOrClusterSize: Vehicle | BulkListVehicle | number | undefined | null,
    isSelected?: boolean,
    isSleeping?: boolean,
    isHovered?: boolean,
): string {
    if (!vehicleOrClusterSize) {
        return ''
    }

    if (isSelected) {
        return 'selected-marker'
    }

    if (typeof vehicleOrClusterSize === 'number') {
        if (vehicleOrClusterSize < 10) {
            return `marker-${vehicleOrClusterSize}`
        }
        if (vehicleOrClusterSize < 100) {
            return `marker-${Math.floor(vehicleOrClusterSize / 10)}0`
        }
        if (vehicleOrClusterSize < 200) {
            return 'marker-100'
        }
        if (vehicleOrClusterSize < 300) {
            return 'marker-200'
        }
        if (vehicleOrClusterSize < 400) {
            return 'marker-300'
        }
        if (vehicleOrClusterSize < 500) {
            return 'marker-400'
        }
        if (vehicleOrClusterSize < 1000) {
            return 'marker-500'
        }
        return 'marker-1000'
    }

    const vehicleCategory = getVehicleCategory(vehicleOrClusterSize)
    const { availability } = vehicleOrClusterSize

    return getVehicleStatusIcon(vehicleCategory, availability, isSleeping, isHovered)
}
