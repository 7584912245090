import { type ServerBulkError, type BulkListVehicle, type ServerError } from 'src/utils/scooterBulk/types'
import { type Task } from 'src/api/fm/tasks/tasks.model'

export function collectVehicleMissingTaskErrors(vehicleIds: string[], tasks: Task[]): Record<string, string> {
    return vehicleIds.reduce((acc, vehicleId) => {
        const task = tasks.find(t => t.vehicleId === vehicleId)

        if (!task) {
            return {
                ...acc,
                [vehicleId]: 'Vehicle has no active task',
            }
        }

        return acc
    }, {})
}

export function getServerErrors(
    vehicles: BulkListVehicle[],
    serverErrors: ServerError[],
): {
    [scooterId: string]: string
} {
    if (serverErrors.length === 0) {
        return {}
    }

    return serverErrors.reduce((acc, serverError) => {
        const error = serverError['error']
        if (!error) {
            return acc
        }

        const scooterId = getVehicleIdWithError(vehicles, serverError)
        if (!scooterId) {
            return acc
        }

        return {
            ...acc,
            [scooterId]: formatBulkError(error),
        }
    }, {})
}

function getVehicleIdWithError(vehicles: BulkListVehicle[], error: ServerError): string | null {
    const { sourceType, sourceId } = error

    if (sourceType === 'task') {
        const vehicle = vehicles.find(vehicle => vehicle.availability.tasks?.find(task => task.id === sourceId))

        return vehicle ? vehicle.id : null
    }

    if (sourceType === 'vehicle') {
        const vehicle = vehicles.find(vehicle => vehicle.id === sourceId)

        return vehicle ? vehicle.id : null
    }

    return null
}

export function getVehicleMissingTasksErrors(vehicles: BulkListVehicle[]): Record<string, string> {
    return vehicles.reduce((acc, vehicle) => {
        const hasTasks = vehicle.availability.tasks && vehicle.availability.tasks.length > 0

        if (!hasTasks) {
            return {
                ...acc,
                [vehicle.id]: 'Vehicle has no active task',
            }
        }

        return acc
    }, {})
}

export function serverErrorsByVehicleIds(
    vehicleIds: string[],
    tasks: Task[],
    serverErrors: ServerError[],
): {
    [scooterId: string]: string
} {
    if (serverErrors.length === 0) {
        return {}
    }

    return serverErrors.reduce((acc, serverError) => {
        const error = serverError['error']
        if (!error) {
            return acc
        }

        const scooterId = findVehicleIdWithError(vehicleIds, tasks, serverError)
        if (!scooterId) {
            return acc
        }

        return {
            ...acc,
            [scooterId]: formatBulkError(error),
        }
    }, {})
}

function findVehicleIdWithError(vehicleIds: string[], tasks: Task[], error: ServerError): string | null {
    const { sourceType, sourceId } = error

    if (sourceType === 'task') {
        const task = tasks.find(t => t.id === sourceId)

        return task ? task.vehicleId : null
    }

    if (sourceType === 'vehicle') {
        const vehicleId = vehicleIds.find(id => id === sourceId)

        return vehicleId ? vehicleId : null
    }

    return null
}

export const BULK_ERROR_MAPPING: Record<ServerBulkError, string> = {
    ErrTaskExists: 'This vehicle already has a task',
    ErrVehicleAlreadyOwned: 'Not possible to create task',
    ErrTaskNotFound: 'The task was not found',
    ErrVehicleNotPresent: 'The vehicle was not found',
    ErrInvalidInput: 'Invalid input',
    ErrTaskInProgress: 'This vehicle already has a pending task',
    ErrTaskNotInProgress: 'The task is not in progress',
    ErrTaskNotNewExecutionPriorityChange: 'The task is not new',
}

export function formatBulkError(error: ServerBulkError): string {
    const formattedError = BULK_ERROR_MAPPING[error]

    return formattedError ? formattedError : error
}
