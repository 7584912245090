import type { PermissionsForRoles } from 'src/api/fm/permissions/permissions.model'

export const GET_PERMISSIONS_FOR_ROLES = 'GET_PERMISSIONS_FOR_ROLES'
export const GET_PERMISSIONS_FOR_ROLES_SUCCESS = 'GET_PERMISSIONS_FOR_ROLES_SUCCESS'

export interface GetPermissionsForRoles {
    type: typeof GET_PERMISSIONS_FOR_ROLES
}

export interface PermissionsForRolesSuccess {
    type: typeof GET_PERMISSIONS_FOR_ROLES_SUCCESS
    payload: { permissions: PermissionsForRoles }
}
export type PermissionsForRolesActions = GetPermissionsForRoles | PermissionsForRolesSuccess
