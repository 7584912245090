import { type PropsWithChildren } from 'react'
import { Fab, type FabProps, ThemeProvider, createTheme, makeStyles } from '@material-ui/core'
import * as colors from 'src/ui-kit/colors/colors'

interface Props extends PropsWithChildren<FabProps> {
    rootStyle: FloatingActionButtonRootStyle
}

export const FloatingActionButton = (props: Props) => {
    const { children, rootStyle, ...restProps } = props

    const classes = useStyles(rootStyle)

    return (
        <ThemeProvider theme={theme}>
            <Fab classes={{ root: classes.root }} {...restProps}>
                {children}
            </Fab>
        </ThemeProvider>
    )
}

export interface FloatingActionButtonRootStyle {
    position: 'absolute' | 'relative'
    top: string
    left?: string
    right?: string
}

const useStyles = makeStyles({
    root: (style: FloatingActionButtonRootStyle) => ({
        ...style,
    }),
})

const theme = createTheme({
    palette: {
        primary: { main: colors.coral },
        secondary: { main: colors.white },
    },
})
