import VoiLogo from 'src/assets/images/nav/ic_voi.svg'
import MenuClose from 'src/assets/images/nav/menu-icon-mobile-close.svg'
import Menu from 'src/assets/images/nav/menu-icon-mobile.svg'
import { RolesAndLogout } from 'src/components/parts/navigation/RolesAndLogout'
import { zIndex } from 'src/constants/zIndex'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { selectIsMobileNavOpen, toggleMobileNav } from 'src/redux/nav'
import * as colors from 'src/ui-kit/colors/colors'
import styled from 'styled-components'

export const MobileHeader = () => {
    const isMobileNavOpen = useAppSelector(selectIsMobileNavOpen)
    const dispatch = useAppDispatch()

    return (
        <MobileNavHeader>
            <MenuIconWrapper>
                <MenuIcon
                    src={isMobileNavOpen ? MenuClose : Menu}
                    alt='menu'
                    onClick={() => dispatch(toggleMobileNav(!isMobileNavOpen))}
                />
                <HeaderIcon src={VoiLogo} alt='VOI' />
            </MenuIconWrapper>
            <RolesAndLogout />
        </MobileNavHeader>
    )
}

const MobileNavHeader = styled.div`
    background-color: ${colors.coral};
    padding: 15px;
    position: fixed;
    width: calc(100% - 30px);
    left: 0;
    top: 0;
    height: 40px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    z-index: ${zIndex.priority};
`

const HeaderIcon = styled.img`
    margin-left: 15px;
`

const MenuIcon = styled.img`
    cursor: pointer;
`

const MenuIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
