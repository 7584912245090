import {
    type PhotoLabelReducerActions,
    type PhotoLabelsState,
    SET_NEXT_PHOTO_TO_LABEL,
} from 'src/redux/photoLabels/photoLabels.types'

const INITIAL_STATE: PhotoLabelsState = {
    photo: null,
}

export function photoLabelReducer(
    state: PhotoLabelsState = INITIAL_STATE,
    action: PhotoLabelReducerActions,
): PhotoLabelsState {
    switch (action.type) {
        case SET_NEXT_PHOTO_TO_LABEL:
            return { ...state, photo: action.payload }
        default:
            return state
    }
}
