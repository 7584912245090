import {
    SET_IS_LOADING,
    SET_VEHICLE_REPORT,
    type VehicleReportState,
    type VehicleReportActions,
} from 'src/redux/vehicleReport/vehicleReport.types'

const INITIAL_STATE: VehicleReportState = {
    id: null,
    createdAt: null,
    reportCategory: null,
    reportReason: null,
    latitude: null,
    longitude: null,
    qrCode: null,
    photos: [],
    isLoading: true,
}

export const vehicleReportReducer = (
    state: VehicleReportState = INITIAL_STATE,
    action: VehicleReportActions,
): VehicleReportState => {
    switch (action.type) {
        case SET_IS_LOADING: {
            const { isLoading } = action.payload
            return {
                ...state,
                isLoading,
            }
        }

        case SET_VEHICLE_REPORT: {
            const { id, createdAt, reportReason, reportCategory, latitude, longitude, qrCode, photos } = action.payload
            return {
                ...state,
                id,
                createdAt,
                reportReason,
                reportCategory,
                latitude,
                longitude,
                qrCode,
                photos,
            }
        }

        default:
            return state
    }
}
