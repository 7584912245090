import { useState, useEffect } from 'react'
import { debounce } from 'lodash-es'

const DEBOUNCE_TIMEOUT = 100

const getSize = () => {
    const isClient = typeof window === 'object'

    return {
        width: isClient ? window.innerWidth : 0,
    }
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(getSize())

    useEffect(() => {
        const isClient = typeof window === 'object'
        if (!isClient) {
            return
        }

        const handleResize = () => setWindowSize(getSize())
        const handleResizeDebounced = debounce(handleResize, DEBOUNCE_TIMEOUT, { trailing: true })

        window.addEventListener('resize', handleResizeDebounced)

        return () => window.removeEventListener('resize', handleResizeDebounced)
    }, [])

    return windowSize
}
