import { type MaasUsersActions, type MaasUsersState, SET_MAAS_USERS } from 'src/redux/maasUsers/maasUsers.types'

const INITIAL_STATE: MaasUsersState = {
    maasUsers: undefined,
}

export function maasUsersReducer(state: MaasUsersState = INITIAL_STATE, action: MaasUsersActions): MaasUsersState {
    switch (action.type) {
        case SET_MAAS_USERS:
            return { ...state, maasUsers: action.payload }

        default:
            return state
    }
}
