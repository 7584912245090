import { ins } from 'src/api/axios'
import {
    type DecommissionReason,
    type DecommissionVehicleResult,
    type DecommissionVehiclesResponse,
    type GetVehicleDecommissionedInfoResponse,
    type UndoDecommissionVehicleResult,
    type UndoDecommissionVehiclesResponse,
    type VehicleDecommissionedInfo,
} from 'src/api/fm/decommission/decommission.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/decommission/getDecommissionedVehicleInfo API documentation}
 */
export async function getVehicleDecommissionedInfo(vehicleId: string): Promise<VehicleDecommissionedInfo | Error> {
    const link = `/v1/fm/decommission/decommissions/${vehicleId}`
    const headers = await getHeaders()

    return ins
        .get<GetVehicleDecommissionedInfoResponse>(link, { headers })
        .then(res => mapGetVehicleDecommissionedInfo(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/decommission/decommissionVehicles API documentation}
 */
export const decommissionVehicles = async (
    vehicleIds: string[],
    reason: DecommissionReason,
): Promise<DecommissionVehicleResult[] | Error> => {
    const url = '/v1/fm/decommission/decommissions'
    const headers = await getHeaders()
    const data = { vehicleIDs: vehicleIds, reason }

    return ins
        .post<DecommissionVehiclesResponse>(url, data, { headers })
        .then(res => mapDecommissionVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/vehicle%20models/listModels API documentation}
 */
export const undoDecommissionVehicles = async (vehicleIds: string[]): Promise<DecommissionVehicleResult[] | Error> => {
    const url = '/v1/fm/decommission/decommissions'
    const headers = await getHeaders()
    const data = { vehicleIds: vehicleIds }

    return ins
        .delete<UndoDecommissionVehiclesResponse>(url, { data, headers })
        .then(res => mapUndoDecommissionVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetVehicleDecommissionedInfo = (response: GetVehicleDecommissionedInfoResponse): VehicleDecommissionedInfo => {
    const { vehicleId, reason } = response.data

    return {
        vehicleId,
        reason,
    }
}

const mapDecommissionVehicles = (response: DecommissionVehiclesResponse): DecommissionVehicleResult[] => {
    return response.data.map(d => ({
        vehicleId: d.vehicleID,
        isSuccessful: d.status === 'OK',
    }))
}

const mapUndoDecommissionVehicles = (response: UndoDecommissionVehiclesResponse): UndoDecommissionVehicleResult[] => {
    return response.data.map(d => ({
        vehicleId: d.vehicleId,
        isSuccessful: d.code === 'OK',
    }))
}
