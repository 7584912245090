/**
 * Icon mapping for 'vehicle_state_icons.png' sprite image.
 *
 * IMPORTANT: Be aware that Android devices (currently) only supports the above sprite image to have a max height
 * or width of 4096 pixels (https://groups.google.com/a/chromium.org/g/graphics-dev/c/E3V--C7OPpc/m/fKGhQr5RCgAJ).
 * It's therefore important to not exceed any of these dimensions when adding new icons to the sprite image.
 * Otherwise the sprite image can't be loaded and icons will be displayed as black squares (= broken).
 */
export const VEHICLE_ICON_MAPPING = {
    // Row 1: y = 0
    ready_scooter: {
        x: 0,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_bike: {
        x: 128,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_rebalance_scooter_normal: {
        x: 256,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_battery_swap_scooter_normal: {
        x: 384,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_battery_swap_bike_normal: {
        x: 384,
        y: 0,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 2: y = 128
    bounty_transport_scooter_normal: {
        x: 128,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_transport_bike_normal: {
        x: 128,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_deploy_scooter_normal: {
        x: 256,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_deploy_bike_normal: {
        x: 256,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rebalance_scooter_normal: {
        x: 384,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rebalance_bike_normal: {
        x: 384,
        y: 128,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 3: y = 256
    bounty_rescue_scooter_normal: {
        x: 0,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rescue_bike_normal: {
        x: 0,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_scooter_normal: {
        x: 128,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_bike_normal: {
        x: 128,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_rebalance_bike_normal: {
        x: 256,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_rebalance_scooter_normal: {
        x: 256,
        y: 256,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 4: y = 384
    off_market_transport_scooter_normal: {
        x: 0,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_transport_bike_normal: {
        x: 0,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_deploy_scooter_normal: {
        x: 128,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_deploy_bike_normal: {
        x: 128,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    riding_scooter: {
        x: 256,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    riding_bike: {
        x: 384,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    booked_scooter: {
        x: 256,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    booked_bike: {
        x: 384,
        y: 384,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 5: y = 512
    in_repair_scooter: {
        x: 0,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    in_repair_bike: {
        x: 0,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    unavailable_scooter: {
        x: 0,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    unavailable_bike: {
        x: 0,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    home_scooter: {
        x: 128,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    home_bike: {
        x: 128,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    lost_scooter: {
        x: 256,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    lost_bike: {
        x: 256,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    storage_scooter: {
        x: 384,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    storage_bike: {
        x: 384,
        y: 512,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 6: y = 640
    sleep_scooter: {
        x: 0,
        y: 640,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    sleep_bike: {
        x: 0,
        y: 640,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'selected-marker': {
        x: 128,
        y: 640,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'hover-scooter': {
        x: 256,
        y: 640,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'hover-bike': {
        x: 384,
        y: 640,
        width: 128,
        height: 128,
        anchorY: 128,
    },

    // Bulk markers
    // row 7: y = 768
    'marker-2': {
        x: 0,
        y: 768,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-3': {
        x: 128,
        y: 768,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-4': {
        x: 256,
        y: 768,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-5': {
        x: 384,
        y: 768,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // row 8: y=896
    'marker-6': {
        x: 0,
        y: 896,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-7': {
        x: 128,
        y: 896,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-8': {
        x: 256,
        y: 896,
        width: 128,
        height: 128,
        anchorY: 128,
    },

    'marker-9': {
        x: 384,
        y: 896,
        width: 128,
        height: 128,
        anchorY: 128,
    },

    // row 9: y = 1024
    'marker-10': {
        x: 0,
        y: 1024,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-20': {
        x: 128,
        y: 1024,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-30': {
        x: 256,
        y: 1024,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-40': {
        x: 384,
        y: 1024,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // row 10 y=1152
    'marker-50': {
        x: 0,
        y: 1152,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-60': {
        x: 128,
        y: 1152,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-70': {
        x: 256,
        y: 1152,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-80': {
        x: 384,
        y: 1152,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // row 11 y=1280
    'marker-90': {
        x: 0,
        y: 1280,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-100': {
        x: 128,
        y: 1280,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-200': {
        x: 256,
        y: 1280,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-300': {
        x: 384,
        y: 1280,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // row 12 y=1408
    'marker-400': {
        x: 0,
        y: 1408,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-500': {
        x: 128,
        y: 1408,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-1000': {
        x: 256,
        y: 1408,
        width: 128,
        height: 128,
        anchorY: 128,
    },

    'marker-2000': {
        x: 384,
        y: 1408,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // row 13 y=1536
    'marker-3000': {
        x: 0,
        y: 1536,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-4000': {
        x: 128,
        y: 1536,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-5000': {
        x: 256,
        y: 1536,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    'marker-10000': {
        x: 384,
        y: 1536,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 14 y=1664
    ready_need_battery_swap_scooter_normal: {
        x: 0,
        y: 1664,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_battery_swap_bike_normal: {
        x: 128,
        y: 1664,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_repark_scooter_normal: {
        x: 256,
        y: 1664,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_repark_bike_normal: {
        x: 384,
        y: 1664,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 15 y=1792, fix 'y'
    off_market_repark_bike_normal: {
        x: 0,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_repark_scooter_normal: {
        x: 0,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_in_field_quality_check_scooter_normal: {
        x: 128,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_in_field_quality_check_bike_normal: {
        x: 256,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_in_field_quality_check_scooter_normal: {
        x: 256,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check
    off_market_in_field_repair_bike_normal: {
        x: 256,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check
    off_market_in_field_repair_scooter_normal: {
        x: 256,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_in_field_quality_check_bike_normal: {
        x: 384,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_in_field_quality_check_scooter_normal: {
        x: 384,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_license_plate_swap_scooter_normal: {
        x: 512,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_license_plate_swap_bike_normal: {
        x: 512,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_license_plate_swap_scooter_normal: {
        x: 640,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_license_plate_swap_bike_normal: {
        x: 640,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_license_plate_swap_scooter_normal: {
        x: 768,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_license_plate_swap_bike_normal: {
        x: 768,
        y: 1788,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 16 y=1920, fix 'y'
    bounty_repark_normal: {
        x: 0,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_repark_high: {
        x: 128,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_rebalance_scooter_high: {
        x: 256,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_battery_swap_scooter_high: {
        x: 384,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_battery_swap_bike_high: {
        x: 384,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_license_plate_swap_scooter_high: {
        x: 512,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_license_plate_swap_bike_high: {
        x: 512,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_license_plate_swap_scooter_high: {
        x: 640,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_license_plate_swap_bike_high: {
        x: 640,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_license_plate_swap_scooter_high: {
        x: 768,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_license_plate_swap_bike_high: {
        x: 768,
        y: 1915,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 17 y=2048, fix 'y'
    bounty_transport_scooter_high: {
        x: 128,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_transport_bike_high: {
        x: 128,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_deploy_scooter_high: {
        x: 256,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_deploy_bike_high: {
        x: 256,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rebalance_scooter_high: {
        x: 384,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rebalance_bike_high: {
        x: 384,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_transport_scooter_normal: {
        x: 512,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_transport_scooter_high: {
        x: 640,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_transport_bike_normal: {
        x: 768,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_transport_bike_high: {
        x: 896,
        y: 2043,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 18 y=2176, fix 'y'
    bounty_rescue_scooter_high: {
        x: 0,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_rescue_bike_high: {
        x: 0,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_scooter_high: {
        x: 128,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_bike_high: {
        x: 128,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_rebalance_scooter_high: {
        x: 256,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_rebalance_bike_high: {
        x: 256,
        y: 2171,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 19 y=2304, fix 'y'
    off_market_transport_scooter_high: {
        x: 0,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_transport_bike_high: {
        x: 0,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_deploy_scooter_high: {
        x: 128,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_deploy_bike_high: {
        x: 128,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_in_field_quality_check_scooter_high: {
        x: 256,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_in_field_quality_check_bike_high: {
        x: 256,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check
    off_market_in_field_repair_scooter_high: {
        x: 256,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check
    off_market_in_field_repair_bike_high: {
        x: 256,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_in_field_quality_check_scooter_high: {
        x: 384,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_in_field_quality_check_bike_high: {
        x: 384,
        y: 2299,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 20 y=2432, fix 'y'
    ready_need_battery_swap_scooter_high: {
        x: 0,
        y: 2427,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_battery_swap_bike_high: {
        x: 128,
        y: 2427,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_repark_scooter_high: {
        x: 256,
        y: 2427,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_repark_bike_high: {
        x: 384,
        y: 2427,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 21 y=2560, fix 'y'
    off_market_repark_scooter_high: {
        x: 0,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_repark_bike_high: {
        x: 0,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_in_field_quality_check_scooter_high: {
        x: 128,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_normal_new_rebalance_high_new: {
        x: 256,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_normal_new_rebalance_high_new: {
        x: 256,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_high_new_rebalance_high_new: {
        x: 384,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_high_new_rebalance_high_new: {
        x: 384,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_normal_new_in_field_quality_check_high_new: {
        x: 768,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_normal_new_in_field_quality_check_high_new: {
        x: 768,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_high_new_in_field_quality_check_high_new: {
        x: 896,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_high_new_in_field_quality_check_high_new: {
        x: 896,
        y: 2555,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 22 y=2683,
    bounty_scooter_battery_swap_normal_new_rebalance_normal_new: {
        x: 0,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_normal_new_rebalance_normal_new: {
        x: 0,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_high_new_rebalance_normal_new: {
        x: 128,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_high_new_rebalance_normal_new: {
        x: 128,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_normal_new_rebalance_high_new: {
        x: 256,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_high_new_rebalance_high_new: {
        x: 384,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_normal_new_in_field_quality_check_normal_new: {
        x: 512,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_normal_new_in_field_quality_check_normal_new: {
        x: 512,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_high_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_high_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_normal_new_in_field_quality_check_high_new: {
        x: 768,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_high_new_in_field_quality_check_high_new: {
        x: 896,
        y: 2683,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 23 y=2811,
    ready_need_scooter_battery_swap_normal_new_rebalance_normal_new: {
        x: 0,
        y: 2811,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_rebalance_bike_normal: {
        x: 128,
        y: 2811,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_in_field_quality_check_bike_high: {
        x: 256,
        y: 2811,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_rebalance_bike_high: {
        x: 384,
        y: 2811,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_normal_new_in_field_quality_check_normal_new: {
        x: 512,
        y: 2811,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 24 y=2939,
    ready_need_bike_battery_swap_normal_new_rebalance_normal_new: {
        x: 0,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_high_new_rebalance_normal_new: {
        x: 128,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_normal_new_rebalance_high_new: {
        x: 256,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_high_new_rebalance_high_new: {
        x: 384,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_normal_new_in_field_quality_check_normal_new: {
        x: 512,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_high_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_normal_new_in_field_quality_check_high_new: {
        x: 768,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_high_new_in_field_quality_check_high_new: {
        x: 896,
        y: 2939,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 25 y=3067,
    ready_need_bike_battery_swap_high_rebalance_normal: {
        x: 0,
        y: 3067,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_in_field_quality_check_bike_normal: {
        x: 128,
        y: 3067,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_high_new_in_field_quality_check_normal_new: {
        x: 512,
        y: 3067,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 26 y=3195,
    off_market_scooter_battery_swap_normal_pending_rebalance_normal_new: {
        x: 0,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_rebalance_normal_new: {
        x: 0,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_new_rebalance_normal_pending: {
        x: 128,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_new_rebalance_normal_pending: {
        x: 128,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_pending_normal_rebalance_normal_pending: {
        x: 256,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_pending_normal_rebalance_normal_pending: {
        x: 256,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_rebalance_high_pending: {
        x: 384,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_rebalance_high_pending: {
        x: 384,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_in_field_quality_check_normal_new: {
        x: 512,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_in_field_quality_check_normal_new: {
        x: 512,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_new_in_field_quality_check_normal_pending: {
        x: 640,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_new_in_field_quality_check_normal_pending: {
        x: 640,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_normal_new_in_field_repair_normal_pending: {
        x: 640,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_normal_new_in_field_repair_normal_pending: {
        x: 640,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_in_field_quality_check_normal_pending: {
        x: 768,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_in_field_quality_check_normal_pending: {
        x: 768,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_normal_pending_in_field_repair_normal_pending: {
        x: 768,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_normal_pending_in_field_repair_normal_pending: {
        x: 768,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_in_field_quality_check_high_pending: {
        x: 896,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_in_field_quality_check_high_pending: {
        x: 896,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_high_pending_in_field_repair_high_pending: {
        x: 896,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_high_pending_in_field_repair_high_pending: {
        x: 896,
        y: 3195,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 27 y=3323,
    off_market_scooter_battery_swap_high_pending_rebalance_normal_new: {
        x: 0,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_rebalance_normal_new: {
        x: 0,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_rebalance_high_new: {
        x: 128,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_rebalance_high_new: {
        x: 128,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_rebalance_high_new: {
        x: 256,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_rebalance_high_new: {
        x: 256,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_rebalance_normal_pending: {
        x: 384,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_rebalance_normal_pending: {
        x: 384,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_in_field_quality_check_normal_new: {
        x: 512,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_in_field_quality_check_normal_new: {
        x: 512,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_in_field_quality_check_high_new: {
        x: 640,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_in_field_quality_check_high_new: {
        x: 640,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_in_field_quality_check_high_new: {
        x: 768,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_in_field_quality_check_high_new: {
        x: 768,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_pending_in_field_quality_check_normal_pending: {
        x: 896,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_pending_in_field_quality_check_normal_pending: {
        x: 896,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_high_pending_in_field_repair_normal_pending: {
        x: 896,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_high_pending_in_field_repair_normal_pending: {
        x: 896,
        y: 3323,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 28 y=3451,
    off_market_scooter_battery_swap_high_new_rebalance_normal_pending: {
        x: 0,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_new_rebalance_normal_pending: {
        x: 0,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_new_rebalance_high_pending: {
        x: 128,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_new_rebalance_high_pending: {
        x: 128,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_new_rebalance_high_pending: {
        x: 256,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_new_rebalance_high_pending: {
        x: 256,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_rebalance_high_pending: {
        x: 384,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_rebalance_high_pending: {
        x: 384,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_pending_in_field_quality_check_high_pending: {
        x: 512,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_pending_in_field_quality_check_high_pending: {
        x: 512,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_normal_pending_in_field_repair_high_pending: {
        x: 512,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_normal_pending_in_field_repair_high_pending: {
        x: 512,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_normal_new_in_field_quality_check_high_pending: {
        x: 640,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_normal_new_in_field_quality_check_high_pending: {
        x: 640,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_normal_new_in_field_repair_high_pending: {
        x: 640,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_normal_new_in_field_repair_high_pending: {
        x: 640,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_new_in_field_quality_check_normal_pending: {
        x: 768,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_new_in_field_quality_check_normal_pending: {
        x: 768,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_high_new_in_field_repair_normal_pending: {
        x: 768,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_high_new_in_field_repair_normal_pending: {
        x: 768,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_high_new_in_field_quality_check_high_pending: {
        x: 896,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_high_new_in_field_quality_check_high_pending: {
        x: 896,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_high_new_in_field_repair_high_pending: {
        x: 896,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_high_new_in_field_repair_high_pending: {
        x: 896,
        y: 3451,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 29 y=3579,
    bounty_battery_swap_scooter_low: {
        x: 0,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_battery_swap_bike_low: {
        x: 0,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_scooter_low: {
        x: 128,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_battery_swap_bike_low: {
        x: 128,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_battery_swap_scooter_low: {
        x: 256,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_battery_swap_bike_low: {
        x: 384,
        y: 3579,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 30 y=3707,
    bounty_scooter_battery_swap_low_new_rebalance_high_new: {
        x: 0,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_low_new_rebalance_high_new: {
        x: 0,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_low_new_rebalance_normal_new: {
        x: 128,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_low_new_rebalance_normal_new: {
        x: 128,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_low_new_rebalance_high_new: {
        x: 256,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_low_new_rebalance_normal_new: {
        x: 384,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_low_new_in_field_quality_check_high_new: {
        x: 512,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_low_new_in_field_quality_check_high_new: {
        x: 512,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_scooter_battery_swap_low_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    bounty_bike_battery_swap_low_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_low_new_in_field_quality_check_high_new: {
        x: 768,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_scooter_battery_swap_low_new_in_field_quality_check_normal_new: {
        x: 896,
        y: 3707,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 31 y=3835,
    ready_need_bike_battery_swap_low_new_rebalance_high_new: {
        x: 0,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_low_new_rebalance_normal_new: {
        x: 128,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_pending_rebalance_high_pending: {
        x: 256,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_pending_rebalance_high_pending: {
        x: 256,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_rebalance_normal_new_battery_swap_low_pending: {
        x: 384,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_rebalance_normal_new_battery_swap_low_pending: {
        x: 384,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_low_new_in_field_quality_check_high_new: {
        x: 512,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    ready_need_bike_battery_swap_low_new_in_field_quality_check_normal_new: {
        x: 640,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_pending_in_field_quality_check_high_pending: {
        x: 768,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_pending_in_field_quality_check_high_pending: {
        x: 768,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_low_pending_in_field_repair_high_pending: {
        x: 768,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_low_pending_in_field_repair_high_pending: {
        x: 768,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_pending_in_field_quality_check_normal_new: {
        x: 896,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_pending_in_field_quality_check_normal_new: {
        x: 896,
        y: 3835,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Row 32 y=3963,
    off_market_scooter_rebalance_high_new_battery_swap_low_pending: {
        x: 0,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_rebalance_high_new_battery_swap_low_pending: {
        x: 0,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_rebalance_normal_pending_battery_swap_low_pending: {
        x: 128,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_rebalance_normal_pending_battery_swap_low_pending: {
        x: 128,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_rebalance_high_pending_battery_swap_low_new: {
        x: 256,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_rebalance_high_pending_battery_swap_low_new: {
        x: 256,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_pending_in_field_quality_check_high_new: {
        x: 512,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_pending_in_field_quality_check_high_new: {
        x: 512,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_pending_in_field_quality_check_normal_pending: {
        x: 640,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_pending_in_field_quality_check_normal_pending: {
        x: 640,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_low_pending_in_field_repair_normal_pending: {
        x: 640,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_low_pending_in_field_repair_normal_pending: {
        x: 640,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_new_in_field_quality_check_high_pending: {
        x: 768,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_new_in_field_quality_check_high_pending: {
        x: 768,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_low_new_in_field_repair_high_pending: {
        x: 768,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_low_new_in_field_repair_high_pending: {
        x: 768,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_scooter_battery_swap_low_new_in_field_quality_check_normal_pending: {
        x: 896,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    off_market_bike_battery_swap_low_new_in_field_quality_check_normal_pending: {
        x: 896,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_scooter_battery_swap_low_new_in_field_repair_normal_pending: {
        x: 896,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
    // Use the same icon as for In field quality check + Battery swap
    off_market_bike_battery_swap_low_new_in_field_repair_normal_pending: {
        x: 896,
        y: 3963,
        width: 128,
        height: 128,
        anchorY: 128,
    },
}
