import { all } from 'redux-saga/effects'
import areaSagas from 'src/redux/areas/areas.sagas'
import scooterSagas from 'src/redux/scooter/scooter.sagas'
import scooterBulkSagas from 'src/redux/scooterBulk/scooterBulk.sagas'
import zoneSagas from 'src/redux/zone/zone.sagas'
import vmapSagas from 'src/redux/vmap/vmap.sagas'
import hunterSagas from 'src/redux/hunter/hunter.sagas'
import riderSagas from 'src/redux/rider/rider.sagas'
import organizationSagas from 'src/redux/organization/organization.sagas'
import noteSagas from 'src/redux/note/note.sagas'
import campaignsSagas from 'src/redux/campaigns/campaigns.sagas'
import authSagas from 'src/redux/auth/auth.sagas'
import vehicleIdsSagas from 'src/redux/vehicleIds/vehicleIds.sagas'
import taskSagas from 'src/redux/task/task.sagas'
import repairSagas from 'src/redux/repair/repair.sagas'
import maasUsersSagas from 'src/redux/maasUsers/maasUsers.sagas'
import activeRidesSagas from 'src/redux/activeRides/activeRides.sagas'
import permissionsSagas from 'src/redux/permissions/permissions.sagas'
import permissionsForRolesSagas from 'src/redux/permissionsForRoles/permissionsForRoles.sagas'
import warehouseSagas from 'src/redux/warehouse/warehouse.sagas'
import accidentsSagas from 'src/redux/accidents/accidents.sagas'
import vehicleReportSagas from 'src/redux/vehicleReport/vehicleReport.sagas'
import hunterParkingPhotosSagas from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.sagas'
import vehicleModelSagas from 'src/redux/vehicleModel/vehicleModel.sagas'
import safetyControlSystem from 'src/redux/safetyControlSystem/safetyControlSystem.sagas'
import zoneAreasSagas from 'src/redux/zoneAreas/zoneAreas.sagas'
import vehicleSearchSagas from 'src/redux/vehicleSearch/vehicleSearch.sagas'
import mapSettingsSagas from 'src/redux/mapSettings/mapSettings.sagas'
import getNextPhotoToLabelSaga from 'src/redux/photoLabels/photoLabels.sagas'
import maintenanceNeedSaga from 'src/redux/maintenanceNeed/maintenanceNeed.sagas'
import receiptsSaga from 'src/redux/receipts/receipts.sagas'
import getViolationsSaga from 'src/redux/violations/violations.saga'
import workAreaSagas from 'src/redux/workArea/workArea.sagas'

export function* appSagas() {
    yield all([
        areaSagas(),
        taskSagas(),
        campaignsSagas(),
        noteSagas(),
        organizationSagas(),
        scooterSagas(),
        scooterBulkSagas(),
        vmapSagas(),
        zoneSagas(),
        hunterSagas(),
        riderSagas(),
        authSagas(),
        vehicleIdsSagas(),
        repairSagas(),
        maasUsersSagas(),
        activeRidesSagas(),
        permissionsSagas(),
        permissionsForRolesSagas(),
        warehouseSagas(),
        accidentsSagas(),
        vehicleReportSagas(),
        hunterParkingPhotosSagas(),
        vehicleModelSagas(),
        safetyControlSystem(),
        zoneAreasSagas(),
        vehicleSearchSagas(),
        mapSettingsSagas(),
        getNextPhotoToLabelSaga(),
        maintenanceNeedSaga(),
        receiptsSaga(),
        getViolationsSaga(),
        workAreaSagas(),
    ])
}
