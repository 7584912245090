import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import {
    type GetPromotionsQuery,
    type GetPromotionsResponseItem,
    type Campaign,
} from 'src/api/fm/promotions/promotions.model'
import { getError } from 'src/api/utils'
import * as fileTransfer from 'src/utils/fileTransfer/fileTransferUtils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/promotions/queryPromotions API documentation}
 */
export const getPromotions = async (searchQuery: GetPromotionsQuery = {}) => {
    const link = encodeURI('/v1/fm/promotions/query')
    const headers = await getHeaders()
    return ins
        .post<GetPromotionsResponseItem[]>(link, searchQuery, { headers })
        .then(res => mapGetPromotions(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/promotions/createPromotion API documentation}
 */
export const createPromotion = async (campaign: Campaign): Promise<void | Error> => {
    const link = encodeURI('/v1/fm/promotions')
    const headers = await getHeaders()
    return ins
        .post(link, campaign, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/promotions/updatePromotion API documentation}
 */
export const updatePromotion = async (campaignId: string, campaign: Campaign): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/promotions/${campaignId}`)
    const headers = await getHeaders()
    return ins
        .put(link, campaign, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/promotions/exportPromotionByID API documentation}
 */
export const exportPromotionCSV = async (campaignId: string) => {
    const link = encodeURI(`/v1/fm/promotions/${campaignId}/export`)
    const headers = await getHeaders()
    return ins
        .get(link, {
            responseType: 'blob',
            headers: headers,
        })
        .then(res => {
            fileTransfer.downloadCSV(res.data, `voucher-${campaignId}.csv`)
        })
}

const mapGetPromotions = (response: GetPromotionsResponseItem[]): Campaign[] => {
    return response.map(item => ({
        activeZoneIDs: item.activeZoneIDs,
        code: item.code,
        description: item.description,
        discount: {
            amount: item.discount.amount,
            minutes: item.discount.minutes,
            percent: item.discount.percent,
            type: item.discount.type,
        },
        id: item.id,
        internalName: item.internalName,
        maxUses: item.maxUses,
        name: item.name,
        onlyApplicableForNewUsers: item.onlyApplicableForNewUsers,
        redeemBefore: item.redeemBefore,
        timesUsed: item.timesUsed,
        validFrom: item.validFrom,
        validTo: item.validTo,
    }))
}
