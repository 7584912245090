import { Popup } from 'src/ui-kit/popup/Popup'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { Button } from 'src/ui-kit/button/Button'
import { WarehouseSelectionModal } from 'src/components/warehouseSelectionModal/WarehouseSelectionModal'
import { type BulkDropActiveTasksOrganizationData } from 'src/redux/task'

interface Props {
    close: () => void
    tasks: Task[]
    bulkDrop: (data: BulkDropActiveTasksOrganizationData) => void
    accountId: string
}

export const DropScootersBulk = (props: Props) => {
    const { close, tasks, bulkDrop, accountId } = props

    const handleSubmit = (warehouseId?: string) => {
        bulkDrop({
            // Defaults to fulfilled
            status: 'fulfilled',
            tasks,
            accountId,
            warehouseId,
        })
        close()
    }

    const areSomeTasksOfTransportType = tasks.some(t => t.type === 'transport')

    return areSomeTasksOfTransportType ? (
        <WarehouseSelectionModal select={warehouse => handleSubmit(warehouse.id)} cancel={close} />
    ) : (
        <Popup title='Bulk fulfill tasks' close={close}>
            <Button dataTestId='confirm-btn-drop-vehicle-bulk-tasks' isStretched onClick={() => handleSubmit()}>
                Confirm
            </Button>
        </Popup>
    )
}
