// Button types
const PRIMARY = 'primary'
const OUTLINED = 'outlined'
const TEXT_REGULAR = 'text-regular'
const TEXT_SMALL = 'text-small'

// Colors
const CORAL = 'coral'
const BLACK = 'black'
const WHITE = 'white'

export const BUTTON_TYPES = {
    PRIMARY,
    OUTLINED,
    TEXT_REGULAR,
    TEXT_SMALL,
} as const

export const BUTTON_COLORS = {
    CORAL,
    BLACK,
    WHITE,
} as const
