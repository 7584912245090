export const SEARCH_USER = 'permissions/SEARCH_USER'
export const SEARCH_USER_SUCCESS = 'permissions/SEARCH_USER_SUCCESS'
export const SEARCH_USER_ERROR = 'permissions/SEARCH_USER_ERROR'
export const UPDATE_USER_ROLES = 'permissions/UPDATE_USER_ROLES'
export const UPDATE_USER_ROLES_SUCCESS = 'permissions/UPDATE_USER_ROLES_SUCCESS'
export const RESET_USER = 'permissions/RESET_USER'
export const TOGGLE_USER_ROLE = 'permissions/TOGGLE_USER_ROLE'

export interface PermissionsState {
    user: User | null
    isUserChanged: boolean
}

export interface User {
    id: string
    email: string
    roles: Roles
}

export interface Roles {
    global: string[]
    zones: Record<string, string[]>
}

export const FM_ROLE_PREFIX = 'voiorg-'
export const BACKEND_ADMIN_ROLE_NAME = 'admin'

export interface SearchUser {
    type: typeof SEARCH_USER
    payload: { email: string }
}

export interface SearchUserSuccess {
    type: typeof SEARCH_USER_SUCCESS
    payload: { user: User }
}

export interface SearchUserError {
    type: typeof SEARCH_USER_ERROR
}

export interface ResetUser {
    type: typeof RESET_USER
}

export interface ToggleUserRole {
    type: typeof TOGGLE_USER_ROLE
    payload: { role: string }
}

export interface UpdateUserRoles {
    type: typeof UPDATE_USER_ROLES
    payload: { userId: string; roles: Roles }
}

export interface UpdateUserRolesSuccess {
    type: typeof UPDATE_USER_ROLES_SUCCESS
}

export type PermissionsActions =
    | SearchUser
    | SearchUserSuccess
    | SearchUserError
    | ResetUser
    | ToggleUserRole
    | UpdateUserRoles
    | UpdateUserRolesSuccess
