export const REGULAR_ROLE_OPTIONS: Record<string, string> = {
    'voiorg-fm-read': 'FM read access',
    'voiorg-market': 'Market Ops',
    'voiorg-market-lead': 'Market Ops Lead',
    'voiorg-onboarding': 'Field logistics (deprecated)', // TODO: Deprecate role
    'voiorg-support': 'User support (deprecated)', // TODO: Deprecate role
    'voiorg-offShore-support': 'User Support Agent',
    'voiorg-support-lead': 'User Support Team Lead',
    'voiorg-offShore-support-parking': 'Off shore support + ParkingCop (deprecated)', // TODO: Deprecate role
    'voiorg-marketing': 'Marketing',
    'voiorg-vehicle': 'Vehicle Ops',
    'voiorg-wh-manager': 'Warehouse manager',
    'voiorg-central-warehouse-ops': 'Central warehouse ops',
    'voiorg-engineering': 'Engineering',
    'voiorg-pricing-manager': 'Pricing manager',
    'voiorg-partners-manager': 'Partners manager',
    'voiorg-battery-responsible': 'Battery responsible',
    'voiorg-shipment-responsible': 'Shipment responsible',
    'voiorg-expansion': 'Expansion (deprecated)', // TODO: Deprecate role
    'external-production': 'External factory worker (deprecated)', // TODO: Deprecate role
}

export const OWNER_ROLE_OPTIONS: Record<string, string> = {
    ...REGULAR_ROLE_OPTIONS,
    'voiorg-confighub-editor': 'Config Hub editor',
    'voiorg-superAdmin': 'Super admin',
    'voiorg-owner': 'Owner',
}

export const ADMIN_OWNER_ROLE_OPTIONS: Record<string, string> = {
    ...OWNER_ROLE_OPTIONS,
    admin: 'Admin',
}
