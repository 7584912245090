import { type ZoneOption } from 'src/redux/zone/zone.types'
import { type Zone, type ParkingMode } from 'src/api/fm/zones/zones.model'
import { type SelectOption } from 'src/ui-kit/selectTooltip/types'

export const getNumberOfZones = (country: string, zones: Record<string, any>[]) => {
    const zonesInCountry = zones.filter(z => z.country === country)
    return zonesInCountry.length
}

export const getZoneLabel = (zoneId: string, zones: ZoneOption[]) => {
    const found = zones.filter(z => z.value === zoneId)
    return found[0] ? found[0].label : 'not found'
}

export const parkingSpotOptions: SelectOption<ParkingMode>[] = [
    { label: 'Free-floating', value: 'free-floating' },
    { label: 'Parking spot', value: 'parking-spot' },
    { label: 'Soft Mpz', value: 'soft-mpz' },
]

export const displaySpeedUnitOptions = [
    { label: 'km/h', value: 'km/h' },
    { label: 'mph', value: 'mph' },
]

export const motorPowerOptions = [
    { label: '250W', value: '250' },
    { label: '350W', value: '350' },
    { label: '400W', value: '400' },
]

export const assistLevelOptions = [
    { label: 'No Assist', value: 'NO_ASSIST' },
    { label: 'Very Low', value: 'VERY_LOW' },
    { label: 'Low', value: 'LOW' },
    { label: 'Medium', value: 'MED' },
    { label: 'High', value: 'HIGH' },
    { label: 'Max', value: 'MAX' },
]

export const getSleepStatusForZone = (zoneId: string, zones: Zone[]): boolean => {
    const zone = zones.find(z => z.zone_id === zoneId)
    return zone ? zone.isSleeping : false
}

export const mapZoneIdsToNamesStr = (zones: Zone[], zoneIds: string[]) => {
    return zones
        .filter(zone => zoneIds.includes(zone.zone_id))
        .map(zone => zone.name)
        .join(', ')
}
