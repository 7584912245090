export function chunk<T>(arr: T[], len: number) {
    const chunks: T[][] = []
    let i = 0

    while (i < arr.length) {
        chunks.push(arr.slice(i, (i += len)))
    }

    return chunks
}
