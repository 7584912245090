import { call, put, type SagaReturnType, takeEvery } from 'redux-saga/effects'
import { requestFetchAreas, fetchAreasStart, fetchAreasSuccess, fetchAreasFailed } from 'src/redux/zoneAreas'
import { getAreasByZoneId } from 'src/api'

type GetAreasByZoneId = SagaReturnType<typeof getAreasByZoneId>

export function* handleRequestFetchAreas(action: ReturnType<typeof requestFetchAreas>) {
    yield put(fetchAreasStart())
    const zoneId = action.payload

    const areas: GetAreasByZoneId = yield call(getAreasByZoneId, zoneId)
    if (areas instanceof Error) {
        yield put(fetchAreasFailed())
    } else {
        yield put(fetchAreasSuccess(areas))
    }
}

export default function* watcher() {
    yield takeEvery(requestFetchAreas.toString(), handleRequestFetchAreas)
}
