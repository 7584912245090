import { type AppState } from 'src/redux/app/app.store'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { selectOrganizationHistoryTasks } from 'src/redux/organization'
import { selectVehicleTaskHistory } from 'src/redux/scooter'
import { selectCurrentHunterHistory } from 'src/redux/hunter'

export const selectHunterParkingPhotoByTaskId = (taskId: string) => (state: AppState) =>
    state.hunterParkingPhotos.photoUrlsByTaskId[taskId] || undefined

export const selectHunterParkingPhotoCurrentPage = (state: AppState) => state.hunterParkingPhotos.currentPage

export const selectIsLastFiveIndex = (state: AppState): boolean => {
    const { taskIdsWithParkingPhoto, taskIdsWithParkingPhotoIndex } = state.hunterParkingPhotos
    if (!taskIdsWithParkingPhotoIndex) {
        return false
    }

    return taskIdsWithParkingPhotoIndex >= taskIdsWithParkingPhoto.length - 5
}

export const selectIsFetchingMoreTasks = (state: AppState) => state.hunterParkingPhotos.isFetchingMoreTasks

export const selectShownHunterParkingPhotoTask = (state: AppState): Task | null => {
    const { taskIdsWithParkingPhoto, taskIdsWithParkingPhotoIndex, currentPage } = state.hunterParkingPhotos
    const taskId = taskIdsWithParkingPhoto[taskIdsWithParkingPhotoIndex!]

    let tasks: Task[] = []

    switch (currentPage) {
        case 'organization':
            tasks = selectOrganizationHistoryTasks(state)
            break
        case 'vehicle':
            tasks = selectVehicleTaskHistory(state)
            break
        case 'hunter':
            tasks = selectCurrentHunterHistory(state)
            break
    }

    return tasks.find(t => t.id === taskId) || null
}

export const selectIsFirstHunterParkingPhoto = (state: AppState): boolean =>
    state.hunterParkingPhotos.taskIdsWithParkingPhotoIndex === 0

export const selectIsLastHunterParkingPhoto = (state: AppState): boolean => {
    const { taskIdsWithParkingPhoto, taskIdsWithParkingPhotoIndex } = state.hunterParkingPhotos

    if (!taskIdsWithParkingPhotoIndex) {
        return false
    }

    return taskIdsWithParkingPhoto.length - 1 === taskIdsWithParkingPhotoIndex
}
