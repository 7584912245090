import {
    type ActiveRidesColumnType,
    type ResetStateAction,
    type SetRidesAction,
    type SetSearchTermAction,
    type ToggleColumnAction,
    type GetRidesAction,
    ACTIVE_RIDES_RESET_STATE,
    ACTIVE_RIDES_SET_RIDES,
    ACTIVE_RIDES_SET_SEARCH_TERM,
    ACTIVE_RIDES_TOGGLE_COLUMN,
    ACTIVE_RIDES_GET_ACTIVE_RIDES,
} from 'src/redux/activeRides/activeRides.types'
import { type Ride } from 'src/api/fm/rentals/rentals.model'

export const getRides = (): GetRidesAction => ({
    type: ACTIVE_RIDES_GET_ACTIVE_RIDES,
})

export const setRides = (rides: Ride[]): SetRidesAction => ({
    type: ACTIVE_RIDES_SET_RIDES,
    payload: rides,
})

export const toggleColumn = (column: ActiveRidesColumnType): ToggleColumnAction => ({
    type: ACTIVE_RIDES_TOGGLE_COLUMN,
    payload: column,
})

export const setSearchTerm = (searchTerm: string): SetSearchTermAction => ({
    type: ACTIVE_RIDES_SET_SEARCH_TERM,
    payload: searchTerm,
})

export const resetState = (): ResetStateAction => ({
    type: ACTIVE_RIDES_RESET_STATE,
})
