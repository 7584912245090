import { type BatteryContextState, type BatteryActions } from 'src/contexts/battery/State'

export const batteryReducer = (state: BatteryContextState, action: BatteryActions): BatteryContextState => {
    switch (action.type) {
        case 'SET_PREVIOUS_ROUTE':
            return { ...state, previousRoute: action.payload }
        case 'SET_SERIAL_FILTER':
            return { ...state, serialFilter: action.payload }
        case 'SET_ZONE_FILTER':
            return { ...state, zoneFilter: action.payload }
        case 'SET_STATE_FILTER':
            return { ...state, stateFilter: action.payload }
        case 'SET_WAREHOUSE_FILTER':
            return { ...state, warehouseFilter: action.payload }
        case 'SET_UPDATED_BY_FILTER':
            return { ...state, updatedByFilter: action.payload }
        case 'SET_REASON_FILTER':
            return { ...state, reasonFilter: action.payload }
        case 'SET_FROM_DATE_FILTER':
            return { ...state, fromDateFilter: action.payload }
        case 'SET_TO_DATE_FILTER':
            return { ...state, toDateFilter: action.payload }
        default:
            return state
    }
}
