import type { BatteryReason, BatteryState } from 'src/network/battery/types'

export type BatteryContextState = {
    previousRoute?: string
    serialFilter?: string[]
    zoneFilter?: string
    stateFilter?: BatteryState
    warehouseFilter?: string
    updatedByFilter?: string
    reasonFilter?: BatteryReason
    fromDateFilter?: Date
    toDateFilter?: Date
}

export const initialState: BatteryContextState = {
    previousRoute: undefined,
    serialFilter: undefined,
    zoneFilter: undefined,
    stateFilter: undefined,
    warehouseFilter: undefined,
    updatedByFilter: undefined,
    reasonFilter: undefined,
    fromDateFilter: undefined,
    toDateFilter: undefined,
}

export type SetPreviousRouteAction = {
    type: 'SET_PREVIOUS_ROUTE'
    payload: string | undefined
}
export type SetSerialFilterAction = {
    type: 'SET_SERIAL_FILTER'
    payload: string[] | undefined
}

export type SetZoneFilterAction = {
    type: 'SET_ZONE_FILTER'
    payload: string | undefined
}

export type SetStateFilterAction = {
    type: 'SET_STATE_FILTER'
    payload: BatteryState | undefined
}

export type SetWarehouseFilterAction = {
    type: 'SET_WAREHOUSE_FILTER'
    payload: string | undefined
}

export type SetUpdatedByFilterAction = {
    type: 'SET_UPDATED_BY_FILTER'
    payload: string | undefined
}

export type SetReasonFilterAction = {
    type: 'SET_REASON_FILTER'
    payload: BatteryReason | undefined
}

export type SetFromDateFilterAction = {
    type: 'SET_FROM_DATE_FILTER'
    payload: Date | undefined
}

export type SetToDateFilterAction = {
    type: 'SET_TO_DATE_FILTER'
    payload: Date | undefined
}

export type BatteryActions =
    | SetPreviousRouteAction
    | SetSerialFilterAction
    | SetZoneFilterAction
    | SetStateFilterAction
    | SetWarehouseFilterAction
    | SetUpdatedByFilterAction
    | SetReasonFilterAction
    | SetFromDateFilterAction
    | SetToDateFilterAction
