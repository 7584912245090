import { type MouseEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Popup } from 'src/ui-kit/popup/Popup'
import * as colors from 'src/ui-kit/colors/colors'
import { SearchInput } from 'src/ui-kit/searchInput/SearchInput'
import PinnedIcon from 'src/assets/images/ic_pinned.svg'
import UnpinnedIcon from 'src/assets/images/ic_unpinned.svg'
import { IconButtonHover } from 'src/components/scooters/scooter-bulk/Buttons'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { selectPinnedWarehouses, selectWarehouses } from 'src/redux/warehouse/warehouse.selectors'
import { getPinnedWarehouses, getWarehouses, togglePinnedWarehouse } from 'src/redux/warehouse'
import { Button } from 'src/ui-kit/button/Button'
import { type Warehouse } from 'src/api/fm/warehouse/warehouse.model'
import { useWindowSize } from 'src/components/app/utils/hooks/useWindowSize'
import * as breakpoints from 'src/constants/breakpoints'

interface WarehouseItemProps {
    id: string
    name: string
    isPinned: boolean
    togglePin: (id: string) => void
    click: (id: string) => void
}

const WarehouseItem = (props: WarehouseItemProps) => {
    const { id, name, isPinned, togglePin, click } = props

    const isMobile = useWindowSize().width <= breakpoints.pixels.mobile

    const handleClickPinIcon = (e: MouseEvent) => {
        e.stopPropagation()

        togglePin(id)
    }

    return (
        <Item data-testid={`warehouse-${id}-item`} onClick={() => click(id)}>
            <Initials data-testid={`warehouse-${id}-initials`}>{name.substr(0, 2).toUpperCase()}</Initials>
            <Name>
                <p style={{ fontWeight: 600 }} data-testid={`warehouse-${id}-name`}>
                    {name}
                </p>
            </Name>
            <PinIcon>
                <IconButtonHover
                    size='24px'
                    alt=''
                    disabled={false}
                    onClick={handleClickPinIcon}
                    src={isPinned ? PinnedIcon : UnpinnedIcon}
                    tooltip={isMobile ? '' : isPinned ? 'Unpin' : 'Pin to top'}
                    tooltipLeft={isPinned ? '-23px' : '-46px'}
                    tooltipTop='-45px'
                    dataTestId={`warehouse-${id}-pinned-icon`}
                />
            </PinIcon>
        </Item>
    )
}

interface Props {
    select: (warehouse: Warehouse) => void
    cancel: () => void
}

export const WarehouseSelectionModal = ({ select, cancel }: Props) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>()

    const dispatch = useAppDispatch()

    const warehouses = useAppSelector(selectWarehouses)
    const pinnedWarehouses = useAppSelector(selectPinnedWarehouses)

    const filteredWarehouses = warehouses.filter(
        warehouse => searchTerm.length === 0 || warehouse.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )

    filteredWarehouses.sort(
        (a, b) =>
            Number(!!pinnedWarehouses.includes(b.id)) - Number(!!pinnedWarehouses.includes(a.id)) ||
            a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    )

    const handleClickWarehouse = (id: string) => {
        setSelectedWarehouse(filteredWarehouses.find(w => w.id === id))
    }

    const handleTogglePin = (id: string) => {
        dispatch(togglePinnedWarehouse(id))
    }

    const handleCancelConfirmationDialog = () => {
        setSelectedWarehouse(undefined)
    }

    const handleConfirmConfirmationDialog = (warehouse: Warehouse) => {
        select(warehouse)
        setSelectedWarehouse(undefined)
    }

    useEffect(() => {
        dispatch(getWarehouses())
        dispatch(getPinnedWarehouses())
    }, [dispatch])

    return (
        <>
            {selectedWarehouse ? (
                <Popup
                    close={handleCancelConfirmationDialog}
                    title={'Confirm warehouse'}
                    maxWidth={'380px'}
                    removeCancel={true}
                    dataTestId={'warehouse-selection-confirmation-modal'}
                >
                    <ConfirmationModalWarehouseName data-testid={'warehouse-selection-confirmation-modal-name'}>
                        {selectedWarehouse.name}
                    </ConfirmationModalWarehouseName>
                    <ButtonWrapper>
                        <Button
                            onClick={handleCancelConfirmationDialog}
                            type={'outlined'}
                            color={'black'}
                            isStretched
                            dataTestId={'warehouse-selection-confirmation-modal-cancel-button'}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleConfirmConfirmationDialog(selectedWarehouse)}
                            isStretched
                            dataTestId={'warehouse-selection-confirmation-modal-confirm-button'}
                        >
                            Confirm
                        </Button>
                    </ButtonWrapper>
                </Popup>
            ) : (
                <Popup title='Select warehouse' close={cancel} dataTestId='warehouse-selection-modal'>
                    <SearchInput
                        dataTestId='warehouse-selection-filter-input'
                        width='100%'
                        onChange={e => setSearchTerm(e.target.value)}
                        placeholder='Filter by name'
                    />
                    <Content>
                        {filteredWarehouses.length ? (
                            filteredWarehouses.map(warehouse => (
                                <WarehouseItem
                                    key={warehouse.id}
                                    id={warehouse.id}
                                    name={warehouse.name}
                                    isPinned={pinnedWarehouses.includes(warehouse.id)}
                                    togglePin={handleTogglePin}
                                    click={handleClickWarehouse}
                                />
                            ))
                        ) : (
                            <p>No warehouses found</p>
                        )}
                    </Content>
                </Popup>
            )}
        </>
    )
}

const Item = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    cursor: pointer;
    border-bottom: 1px solid ${colors.lightGrey};
    align-items: center;

    &:hover {
        background-color: ${colors.lightGrey};
    }
`

const Initials = styled.div`
    border-radius: 4px;
    background-color: ${colors.mediumGrey};
    padding: 6px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
`
const Name = styled.div`
    display: flex;
    flex: 7;
    flex-direction: column;
    margin-left: 20px;

    p {
        margin: 0;
    }
`

const PinIcon = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`

const Content = styled.div`
    height: 300px;
    margin-top: 20px;
    border-top: 1px solid ${colors.lightGrey};
`

const ConfirmationModalWarehouseName = styled.div`
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 20px;
`

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-evenly;

    & button:first-child {
        margin-right: 12px;
    }
`
