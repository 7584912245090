import {
    EXPAND_ZONE,
    SET_NAV_EXPANDED,
    TOGGLE_MOBILE_NAV,
    EXPAND_REPAIRS,
    EXPAND_VEHICLES,
    EXPAND_RIDERS,
    EXPAND_BATTERY,
    type ExpandZone,
    type SetNavExpanded,
    type ToggleMobileNav,
    type ExpandRepairs,
    type ExpandRiders,
    type ExpandVehicles,
    type ExpandBattery,
} from 'src/redux/nav/nav.types'

export const expandZone = (condition: boolean): ExpandZone => {
    return {
        type: EXPAND_ZONE,
        payload: condition,
    }
}

export const setNavExpanded = (condition: boolean): SetNavExpanded => {
    return {
        type: SET_NAV_EXPANDED,
        payload: condition,
    }
}

export const toggleMobileNav = (condition: boolean): ToggleMobileNav => {
    return {
        type: TOGGLE_MOBILE_NAV,
        payload: condition,
    }
}

export const expandRepairs = (condition: boolean): ExpandRepairs => ({
    type: EXPAND_REPAIRS,
    payload: condition,
})

export const expandRiders = (condition: boolean): ExpandRiders => ({
    type: EXPAND_RIDERS,
    payload: condition,
})

export const expandVehicles = (condition: boolean): ExpandVehicles => ({
    type: EXPAND_VEHICLES,
    payload: condition,
})

export const expandBattery = (condition: boolean): ExpandBattery => ({
    type: EXPAND_BATTERY,
    payload: condition,
})
