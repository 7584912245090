import {
    EDIT_COUNTRY,
    EDIT_COUNTRY_INTENT,
    CREATE_COUNTRY,
    GET_ZONES_BY_COUNTRY,
    FETCH_ALL_COUNTRIES,
    SET_ZONE_OPTIONS,
    DELETE_ZONE_INTENT,
    DELETE_ZONE,
    SET_CURRENT_ZONE,
    SET_CURRENT_ZONE_ERROR,
    GET_ZONE,
    CREATE_ZONE,
    SET_ZONES,
    RESET_CURRENT_ZONE,
    UPDATE_ZONE_DETAILS,
    GET_ZONE_FEATURES,
    SET_ZONE_FEATURES,
    UPDATE_ZONE_FEATURES,
    GET_ZONES_AND_COUNTRIES,
    SET_ALL_ZONES,
    SET_ALL_COUNTRIES,
    ADD_COUNTRY_SUCCESS,
    EDIT_COUNTRY_SUCCESS,
    GET_ZONE_PARKING_ASSISTANT_SETTINGS,
    SET_ZONE_PARKING_ASSISTANT_SETTINGS,
    UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS,
    GET_PARKING_PHOTO_SETTINGS,
    GET_PARKING_PHOTO_SETTINGS_SUCCESS,
    EDIT_PARKING_PHOTO_SETTINGS,
    type ZoneOption,
    GET_VEHICLE_POLICY,
    SET_VEHICLE_POLICY,
    UPDATE_VEHICLE_POLICY,
    UPDATE_ZONE_THRESHOLDS,
    SET_ZONE_THRESHOLDS,
} from 'src/redux/zone/zone.types'
import {
    type ParkingAssistantSettings,
    type LegalEntity,
    type Zone,
    type Threshold,
} from 'src/api/fm/zones/zones.model'
import { type ParkingPhotoSettings } from 'src/api/fm/tasks/tasks.model'
import { type VehiclePolicy } from 'src/api/fm/vehiclePolicy/vehiclePolicy.model'
import { type ZoneFeatures, type UpdateZoneFeaturesPayload } from 'src/api/fm/features/features.model'

export type GetZoneAction<T extends ZoneAction['type']> = Extract<ZoneAction, { type: T }>

export type ZoneAction =
    | ReturnType<typeof editCountryIntent>
    | ReturnType<typeof createCountry>
    | ReturnType<typeof editCountry>
    | ReturnType<typeof getZonesByCountry>
    | ReturnType<typeof setZoneOptions>
    | ReturnType<typeof deleteZoneIntent>
    | ReturnType<typeof deleteZone>
    | ReturnType<typeof setCurrentZone>
    | ReturnType<typeof setCurrentZoneError>
    | ReturnType<typeof getZone>
    | ReturnType<typeof createZone>
    | ReturnType<typeof setZones>
    | ReturnType<typeof resetCurrentZone>
    | ReturnType<typeof updateZoneDetails>
    | ReturnType<typeof updateZoneThresholds>
    | ReturnType<typeof setZoneThresholds>
    | ReturnType<typeof getZoneFeatures>
    | ReturnType<typeof setZoneFeatures>
    | ReturnType<typeof updateZoneFeatures>
    | ReturnType<typeof getVehiclePolicy>
    | ReturnType<typeof setVehiclePolicy>
    | ReturnType<typeof updateZoneVehiclePolicy>
    | ReturnType<typeof getZonesAndCountries>
    | ReturnType<typeof setAllZones>
    | ReturnType<typeof fetchAllCountries>
    | ReturnType<typeof setAllCountries>
    | ReturnType<typeof addCountrySuccess>
    | ReturnType<typeof editCountrySuccess>
    | ReturnType<typeof getZoneParkingAssistantSettings>
    | ReturnType<typeof setZoneParkingAssistantSettings>
    | ReturnType<typeof updateZoneParkingAssistantSettings>
    | ReturnType<typeof getParkingPhotoSettings>
    | ReturnType<typeof getParkingPhotoSettingsSuccess>
    | ReturnType<typeof editParkingPhotoSettings>

export const editCountryIntent = (country: LegalEntity | null) => {
    return {
        type: EDIT_COUNTRY_INTENT,
        payload: country,
    }
}

export const createCountry = (legalEntity: LegalEntity) => {
    return {
        type: CREATE_COUNTRY,
        payload: legalEntity,
    }
}

export const editCountry = (legalEntity: LegalEntity) => {
    return {
        type: EDIT_COUNTRY,
        payload: legalEntity,
    }
}

export const getZonesByCountry = (country: string) => {
    return {
        type: GET_ZONES_BY_COUNTRY,
        payload: country,
    }
}

export const setZoneOptions = (zones: ZoneOption[]) => {
    return {
        type: SET_ZONE_OPTIONS,
        payload: zones,
    }
}

export const deleteZoneIntent = (zoneId: string | null) => {
    return {
        type: DELETE_ZONE_INTENT,
        payload: zoneId,
    }
}

export const deleteZone = (zoneId: string | number, country: string) => {
    return {
        type: DELETE_ZONE,
        payload: { zoneId, country },
    }
}

export const setCurrentZone = (zone: Zone) => {
    return {
        type: SET_CURRENT_ZONE,
        payload: zone,
    }
}

export const setCurrentZoneError = (error: string) => {
    return {
        type: SET_CURRENT_ZONE_ERROR,
        payload: { error },
    }
}

export const getZone = (id: string) => {
    return {
        type: GET_ZONE,
        payload: id,
    }
}

export const createZone = (zone: Zone) => {
    return {
        type: CREATE_ZONE,
        payload: zone,
    }
}

export const setZones = (zones: Zone[]) => {
    return {
        type: SET_ZONES,
        payload: zones,
    }
}

export const resetCurrentZone = () => ({ type: RESET_CURRENT_ZONE })

export const updateZoneDetails = (zone: Zone) => {
    return {
        type: UPDATE_ZONE_DETAILS,
        payload: zone,
    }
}

export const updateZoneThresholds = (zoneId: string, thresholds: Threshold[]) => ({
    type: UPDATE_ZONE_THRESHOLDS,
    payload: { zoneId, thresholds },
})

export const setZoneThresholds = (thresholds: Threshold[]) => ({
    type: SET_ZONE_THRESHOLDS,
    payload: thresholds,
})

export const getZoneFeatures = (zoneId: string | number) => ({
    type: GET_ZONE_FEATURES,
    payload: zoneId,
})

export const setZoneFeatures = (data: ZoneFeatures) => ({
    type: SET_ZONE_FEATURES,
    payload: data,
})

export const updateZoneFeatures = (zoneId: string | number, data: UpdateZoneFeaturesPayload) => ({
    type: UPDATE_ZONE_FEATURES,
    payload: { zoneId, data },
})

export const getVehiclePolicy = (zoneId: string | number) => ({
    type: GET_VEHICLE_POLICY,
    payload: zoneId,
})

export const setVehiclePolicy = (data: VehiclePolicy) => ({
    type: SET_VEHICLE_POLICY,
    payload: data,
})

export const updateZoneVehiclePolicy = (zoneId: string | number, data: VehiclePolicy) => ({
    type: UPDATE_VEHICLE_POLICY,
    payload: { zoneId, data },
})

export const getZonesAndCountries = () => ({
    type: GET_ZONES_AND_COUNTRIES,
})

export const setAllZones = (zones: Zone[]) => ({
    type: SET_ALL_ZONES,
    payload: zones,
})

export const fetchAllCountries = () => ({
    type: FETCH_ALL_COUNTRIES,
})

export const setAllCountries = (payload: LegalEntity[]) => ({
    type: SET_ALL_COUNTRIES,
    payload,
})

export const addCountrySuccess = (newCountry: LegalEntity) => ({
    type: ADD_COUNTRY_SUCCESS,
    payload: { newCountry },
})

export const editCountrySuccess = (editedCountry: LegalEntity) => ({
    type: EDIT_COUNTRY_SUCCESS,
    payload: { editedCountry },
})

export const getZoneParkingAssistantSettings = (zoneId: string) => ({
    type: GET_ZONE_PARKING_ASSISTANT_SETTINGS,
    payload: zoneId,
})

export const setZoneParkingAssistantSettings = (settings: ParkingAssistantSettings) => {
    return {
        type: SET_ZONE_PARKING_ASSISTANT_SETTINGS,
        payload: settings,
    }
}

export const updateZoneParkingAssistantSettings = (settings: ParkingAssistantSettings) => {
    return {
        type: UPDATE_ZONE_PARKING_ASSISTANT_SETTINGS,
        payload: settings,
    }
}

export const getParkingPhotoSettings = (zoneId: string) => ({
    type: GET_PARKING_PHOTO_SETTINGS,
    payload: { zoneId },
})

export const getParkingPhotoSettingsSuccess = (parkingPhotoSettings: ParkingPhotoSettings) => ({
    type: GET_PARKING_PHOTO_SETTINGS_SUCCESS,
    payload: { parkingPhotoSettings },
})

export const editParkingPhotoSettings = (zoneId: string, parkingPhotoSettings: ParkingPhotoSettings) => ({
    type: EDIT_PARKING_PHOTO_SETTINGS,
    payload: { zoneId, parkingPhotoSettings },
})
