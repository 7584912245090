import {
    GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS,
    APPEND_TASK_IDS_WITH_PARKING_PHOTO,
    SHOW_PARKING_PHOTO_FOR_TASK,
    CLOSE_SHOW_PARKING_PHOTO_FOR_TASK,
    SHOW_NEXT_HUNTER_PARKING_PHOTO,
    SHOW_PREVIOUS_HUNTER_PARKING_PHOTO,
    SET_IS_FETCHING_MORE_TASKS_START,
    SET_IS_FETCHING_MORE_TASKS_DONE,
    RESET_HUNTER_PARKING_PHOTO_STATE,
    type HunterParkingPhotos,
    type HunterParkingPhotosActions,
} from 'src/redux/hunterParkingPhotos/hunterParkingPhotos.types'

const INITIAL_STATE = {
    photoUrlsByTaskId: {},
    taskIdsWithParkingPhoto: [],
    taskIdsWithParkingPhotoIndex: null,
    currentPage: null,
    isFetchingMoreTasks: false,
}

export function hunterParkingPhotosReducer(
    state: HunterParkingPhotos = INITIAL_STATE,
    action: HunterParkingPhotosActions,
): HunterParkingPhotos {
    switch (action.type) {
        case GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS: {
            const { taskId, photoUrl } = action.payload
            const photoUrlsByTaskId = {
                ...state.photoUrlsByTaskId,
                [taskId]: photoUrl,
            }
            return { ...state, photoUrlsByTaskId }
        }

        case APPEND_TASK_IDS_WITH_PARKING_PHOTO: {
            const { taskIds, currentPage } = action.payload
            const taskIdsWithParkingPhoto = state.taskIdsWithParkingPhoto.concat(taskIds)

            return {
                ...state,
                taskIdsWithParkingPhoto,
                currentPage,
            }
        }

        case SHOW_PARKING_PHOTO_FOR_TASK: {
            const { taskId } = action.payload
            const index = state.taskIdsWithParkingPhoto.indexOf(taskId)

            if (index === -1) {
                return state
            }

            return {
                ...state,
                taskIdsWithParkingPhotoIndex: index,
            }
        }

        case CLOSE_SHOW_PARKING_PHOTO_FOR_TASK: {
            return {
                ...state,
                taskIdsWithParkingPhotoIndex: null,
            }
        }

        case SHOW_NEXT_HUNTER_PARKING_PHOTO: {
            if (state.taskIdsWithParkingPhotoIndex === null) {
                return state
            }

            if (state.taskIdsWithParkingPhotoIndex === state.taskIdsWithParkingPhoto.length - 1) {
                return state
            }

            return {
                ...state,
                taskIdsWithParkingPhotoIndex: state.taskIdsWithParkingPhotoIndex + 1,
            }
        }

        case SHOW_PREVIOUS_HUNTER_PARKING_PHOTO: {
            if (state.taskIdsWithParkingPhotoIndex === null) {
                return state
            }

            if (state.taskIdsWithParkingPhotoIndex === 0) {
                return state
            }

            return {
                ...state,
                taskIdsWithParkingPhotoIndex: state.taskIdsWithParkingPhotoIndex - 1,
            }
        }

        case SET_IS_FETCHING_MORE_TASKS_START: {
            return { ...state, isFetchingMoreTasks: true }
        }

        case SET_IS_FETCHING_MORE_TASKS_DONE: {
            return { ...state, isFetchingMoreTasks: false }
        }

        case RESET_HUNTER_PARKING_PHOTO_STATE: {
            return INITIAL_STATE
        }

        default:
            return state
    }
}
