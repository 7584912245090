import { ins } from 'src/api/axios'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { type GetWarehousesResponse, type Warehouse } from 'src/api/fm/warehouse/warehouse.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/warehouse/getWarehouses API documentation}
 */
export const getWarehouses = async (): Promise<Warehouse[] | Error> => {
    const url = '/v1/fm/warehouses'
    const headers = await getHeaders()

    return ins
        .get<GetWarehousesResponse>(url, { headers })
        .then(res => mapGetWarehouses(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/warehouse/createWarehouse API documentation}
 */
export const createWarehouse = async (warehouse: Warehouse): Promise<void | Error> => {
    const url = '/v2/fm/warehouse/warehouses'
    const headers = await getHeaders()

    return ins
        .post(url, warehouse, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/warehouse/updateWarehouse API documentation}
 */
export const updateWarehouse = async (warehouse: Warehouse): Promise<void | Error> => {
    const url = `/v2/fm/warehouse/warehouses/${warehouse.id}`
    const headers = await getHeaders()

    return ins
        .put(url, warehouse, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/warehouse/deleteWarehouses API documentation}
 */
export const deleteWarehouses = async (warehouses: Warehouse[]): Promise<void | Error> => {
    const url = '/v2/fm/warehouse/warehouses/bulk'
    const headers = await getHeaders()

    const warehouseIds = warehouses.map(warehouse => warehouse.id)

    return ins
        .delete(url, { data: { ids: warehouseIds }, headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetWarehouses = (response: GetWarehousesResponse): Warehouse[] => {
    return response.data.map(({ id, name, address, longitude, latitude, zoneIds }) => ({
        id,
        name,
        address,
        longitude,
        latitude,
        zoneIds: zoneIds ?? [],
    }))
}
