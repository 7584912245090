export const GET_VEHICLE_REPORT = 'GET_VEHICLE_REPORT'
export const SET_IS_LOADING = 'SET_IS_LOADING'
export const SET_VEHICLE_REPORT = 'SET_VEHICLE_REPORT'

export interface VehicleReportState {
    isLoading: boolean
    id: string | null
    createdAt: string | null
    reportCategory: string | null
    reportReason: string | null
    latitude: string | null
    longitude: string | null
    qrCode: string | null
    photos: string[] | null
}

export interface GetVehicleReport {
    type: typeof GET_VEHICLE_REPORT
    payload: string
}

export interface SetIsLoading {
    type: typeof SET_IS_LOADING
    payload: {
        isLoading: boolean
    }
}

export interface SetVehicleReport {
    type: typeof SET_VEHICLE_REPORT
    payload: VehicleReportState
}

export type VehicleReportActions = GetVehicleReport | SetIsLoading | SetVehicleReport
