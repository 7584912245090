import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import {
    type CreateMechanicResponse,
    type GetMechanicByEmailResponse,
    type GetMechanicByIdResponse,
    type GetRepairIssuesResponse,
    type RepairAccount,
    type RepairError,
} from 'src/api/fm/repairs/repairs.model'
import { type RepairIssue } from 'src/redux/repair/repair.types'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/adminCloseRepairs API documentation}
 */

export const removeFromRepair = async (
    vehicleIDs: string[],
): Promise<{ data: { vehicleIDs: string[]; errors: RepairError[] } } | Error> => {
    const endpoint = 'v1/fm/repairs/close'
    const body = { vehicleIDs }
    const headers = await getHeaders()

    return ins
        .post(endpoint, body, { headers })
        .then(res => res)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/createRepairType API documentation}
 */
export const createRepairIssue = async (vehicleModelId: string, repairIssue: RepairIssue): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/repairs/vehicle-models/${vehicleModelId}/repair-types`)
    const headers = await getHeaders()
    return ins
        .post(link, repairIssue, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/updateRepairType API documentation}
 */
export const updateRepairIssue = async (repairIssue: RepairIssue): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/repairs/vehicle-models/${repairIssue.modelId}/repair-types/${repairIssue.id}`)
    const headers = await getHeaders()
    return ins
        .put(link, repairIssue, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/deleteRepairType API documentation}
 */
export const deleteRepairIssue = async (vehicleModelId: string, repairIssueId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/repairs/vehicle-models/${vehicleModelId}/repair-types/${repairIssueId}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/getRepairTypesByModelId API documentation}
 */
export const getRepairIssues = async (vehicleModelId: string): Promise<RepairIssue[] | Error> => {
    const link = encodeURI(`/v1/fm/repairs/vehicle-models/${vehicleModelId}/repair-types`)
    const headers = await getHeaders()
    return ins
        .get<GetRepairIssuesResponse>(link, { headers })
        .then(res => mapGetRepairIssues(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/createMechanic API documentation}
 */
export const createMechanic = async (email: string): Promise<RepairAccount | Error> => {
    const link = encodeURI('/v1/fm/repairs/mechanics')
    const headers = await getHeaders()

    return ins
        .post<CreateMechanicResponse>(link, { email }, { headers })
        .then(res => mapCreateMechanic(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/deleteMechanic API documentation}
 */
export const deleteMechanic = async (mechanicId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/repairs/mechanics/${mechanicId}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/getMechanicById API documentation}
 */
export const getMechanicById = async (id: string): Promise<RepairAccount | Error> => {
    const link = encodeURI(`/v1/fm/repairs/mechanics/${id}`)
    const headers = await getHeaders()

    return ins
        .get<GetMechanicByIdResponse>(link, { headers })
        .then(res => mapGetMechanicById(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/repairs%20gateway/getMechanicByEmail API documentation}
 */
export const getMechanicByEmail = async (email: string): Promise<RepairAccount | Error> => {
    const link = encodeURI(`/v1/fm/repairs/mechanics/email/${email}`)
    const headers = await getHeaders()

    return ins
        .get<GetMechanicByEmailResponse>(link, { headers })
        .then(res => mapGetMechanicByEmail(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapCreateMechanic = (response: CreateMechanicResponse): RepairAccount => {
    const { id, email } = response.data

    return { id, email }
}

const mapGetMechanicById = (response: GetMechanicByIdResponse): RepairAccount => {
    const { id, email } = response.data

    return { id, email }
}

const mapGetMechanicByEmail = (response: GetMechanicByEmailResponse): RepairAccount => {
    const { id, email } = response.data

    return { id, email }
}

const mapGetRepairIssues = (response: GetRepairIssuesResponse): RepairIssue[] => {
    return response.data.repairTypes.map(repairType => ({
        id: repairType.id,
        modelId: repairType.modelId,
        name: repairType.name,
        type: repairType.type,
        targetTime: repairType.targetTime,
        processNumber: repairType.processNumber,
        parts: repairType.parts.map(part => ({
            partId: part.partId,
            quantity: part.quantity,
        })),
    }))
}
