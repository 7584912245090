import { omit } from 'lodash-es'
import {
    SET_CURRENT_AREAS,
    ADD_NEW_AREAS_SUCCESS,
    DELETE_AREA_SUCCESS,
    TOGGLE_AREA_EXPANDED,
    TOGGLE_ALL_AREAS_EXPANDED,
    SET_AREA_FILTER,
    SET_EDITING_AREA_POLYGON,
    CANCEL_EDITING_AREA_POLYGON,
    HANDLE_EDITING_AREA_POLYGON,
    SAVE_EDITING_AREA_POLYGON_SUCCESS,
    SAVE_EDITING_AREA_POLYGON_FAILURE,
    SAVE_EDITING_AREA_PROPERTIES_SUCCESS,
    DELETE_AREAS_SUCCESS,
    type AreasState,
    type AreasActions,
    type EditableAreasByType,
} from 'src/redux/areas/areas.types'

export const INITIAL_STATE: AreasState = {
    areas: {
        operations: null,
        bounty: null,
        'parking-spot': null,
        'no-parking': null,
        'slow-zone': null,
        'no-riding': null,
        incentive: null,
        'fleet-capacity': null,
        'soft-mpz': null,
        'free-floating': null,
    },
}

export function areasReducer(state: AreasState = INITIAL_STATE, action: AreasActions): AreasState {
    switch (action.type) {
        case SET_CURRENT_AREAS: {
            const { areas } = action.payload
            const newAreas = areas.features.reduce((acc, feature) => {
                const type = feature.properties.area_type
                const prevFeatures = acc[type]?.originalFeatureCollection?.features || []
                const newFeatures = [...prevFeatures, feature]
                const newAreaType = {
                    originalFeatureCollection: {
                        type: 'FeatureCollection',
                        features: newFeatures,
                    },
                    draftFeatureCollection: null,
                    selectedFeatureIndexes: [],
                    selectedFeatureId: null,
                    isChanged: false,
                    expandedAreaIds: { [feature.id]: feature.id },
                    filter: '',
                    mapMode: 'view',
                }

                return { ...acc, [type]: newAreaType }
            }, {} as EditableAreasByType)

            return {
                ...state,
                areas: {
                    ...INITIAL_STATE.areas,
                    ...newAreas,
                },
            }
        }

        case ADD_NEW_AREAS_SUCCESS: {
            const { areas } = action.payload
            if (areas.length === 0) {
                return state
            }
            const areaIdsKeyValue = areas.reduce(
                (acc, area) => {
                    acc[area.id] = area.id
                    return acc
                },
                {} as Record<string, string>,
            )
            const { area_type } = areas[0].properties
            const prevAreaTypeState = state.areas[area_type]

            if (!prevAreaTypeState) {
                const originalFeatureCollection = {
                    type: 'FeatureCollection',
                    features: areas,
                }

                return {
                    ...state,
                    areas: {
                        ...state.areas,
                        [area_type]: {
                            originalFeatureCollection,
                            draftFeatureCollection: null,
                            selectedFeatureIndexes: [],
                            selectedFeatureId: null,
                            isChanged: false,
                            expandedAreaIds: areaIdsKeyValue,
                            filter: '',
                            mapMode: 'view',
                        },
                    },
                }
            }

            const newAreaTypeState = {
                ...prevAreaTypeState,
                originalFeatureCollection: {
                    type: 'FeatureCollection',
                    features: [...prevAreaTypeState.originalFeatureCollection.features, ...areas],
                },
                draftFeatureCollection: null,
                expandedAreaIds: {
                    ...prevAreaTypeState.expandedAreaIds,
                    ...areaIdsKeyValue,
                },
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [area_type]: newAreaTypeState,
                },
            }
        }

        case DELETE_AREA_SUCCESS: {
            const { areaType, areaId } = action.payload
            const prevAreaTypeState = state.areas[areaType]

            if (!prevAreaTypeState) {
                return state
            }

            const originalFeatureCollection = {
                type: 'FeatureCollection',
                features: prevAreaTypeState.originalFeatureCollection.features.filter(feature => feature.id !== areaId),
            }

            if (originalFeatureCollection.features.length === 0) {
                return {
                    ...state,
                    areas: {
                        ...state.areas,
                        [areaType]: null,
                    },
                }
            }

            const newAreaTypeState = {
                ...prevAreaTypeState,
                originalFeatureCollection,
                draftFeatureCollection: null,
                expandedAreaIds: omit(prevAreaTypeState.expandedAreaIds, areaId),
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: newAreaTypeState,
                },
            }
        }

        case DELETE_AREAS_SUCCESS: {
            const { areaType, areaIds } = action.payload
            const areaByType = state.areas[areaType]

            if (!areaByType) {
                return state
            }

            const updatedOriginalFeatureCollection = {
                type: 'FeatureCollection',
                features: areaByType.originalFeatureCollection.features.filter(
                    feature => !areaIds.includes(feature.id),
                ),
            }

            if (updatedOriginalFeatureCollection.features.length === 0) {
                return {
                    ...state,
                    areas: {
                        ...state.areas,
                        [areaType]: null,
                    },
                }
            }

            const newAreaTypeState = {
                ...areaByType,
                originalFeatureCollection: updatedOriginalFeatureCollection,
                draftFeatureCollection: null,
                expandedAreaIds: omit(areaByType.expandedAreaIds, areaIds),
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: newAreaTypeState,
                },
            }
        }

        case TOGGLE_AREA_EXPANDED: {
            const { areaType, areaId } = action.payload
            const currentArea = state.areas[areaType]
            if (!currentArea) {
                return state
            }

            const isChecked = currentArea.expandedAreaIds[areaId]
            const expandedAreaIds = isChecked
                ? omit(currentArea.expandedAreaIds, areaId)
                : { ...currentArea.expandedAreaIds, [areaId]: areaId }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        expandedAreaIds,
                    },
                },
            }
        }

        case TOGGLE_ALL_AREAS_EXPANDED: {
            const { areaType } = action.payload
            const currentArea = state.areas[areaType]
            if (!currentArea) {
                return state
            }

            const isAllAreasChecked = currentArea.originalFeatureCollection.features.every(feature =>
                Boolean(currentArea.expandedAreaIds[feature.id]),
            )
            const expandedAreaIds = isAllAreasChecked
                ? {}
                : {
                      ...currentArea.expandedAreaIds,
                      ...currentArea.originalFeatureCollection.features.reduce(
                          (acc, feature) => ({ ...acc, [feature.id]: feature.id }),
                          {},
                      ),
                  }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        expandedAreaIds,
                    },
                },
            }
        }

        case SET_AREA_FILTER: {
            const { areaType, filter } = action.payload
            const currentArea = state.areas[areaType]
            if (!currentArea) {
                return state
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        filter,
                    },
                },
            }
        }

        case SET_EDITING_AREA_POLYGON: {
            const { areaType, selectedFeatureIndexes, selectedFeatureId } = action.payload

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        selectedFeatureIndexes,
                        selectedFeatureId,
                        mapMode: 'edit',
                    },
                },
            }
        }

        case HANDLE_EDITING_AREA_POLYGON: {
            const { areaType, featureCollection } = action.payload
            if (!state.areas[areaType]) {
                return state
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        draftFeatureCollection: featureCollection,
                        isChanged: true,
                        mapMode: 'edit',
                    },
                },
            }
        }

        case CANCEL_EDITING_AREA_POLYGON: {
            const { areaType } = action.payload

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        draftFeatureCollection: null,
                        selectedFeatureIndexes: [],
                        selectedFeatureId: null,
                        isChanged: false,
                        mapMode: 'view',
                    },
                },
            }
        }

        case SAVE_EDITING_AREA_POLYGON_SUCCESS: {
            const { areaType, newArea } = action.payload
            const originalFeatures = state.areas[areaType]?.originalFeatureCollection.features ?? []
            const newFeatures = originalFeatures.map(feature => (feature.id === newArea.id ? newArea : feature))
            const newFeatureCollection = {
                type: 'FeatureCollection',
                features: newFeatures,
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        originalFeatureCollection: newFeatureCollection,
                        draftFeatureCollection: null,
                        selectedFeatureIndexes: [],
                        selectedFeatureId: null,
                        isChanged: false,
                        mapMode: 'view',
                    },
                },
            }
        }

        case SAVE_EDITING_AREA_POLYGON_FAILURE: {
            const { areaType } = action.payload

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        draftFeatureCollection: null,
                        selectedFeatureIndexes: [],
                        selectedFeatureId: null,
                        isChanged: false,
                        mapMode: 'view',
                    },
                },
            }
        }

        case SAVE_EDITING_AREA_PROPERTIES_SUCCESS: {
            const { area } = action.payload
            const { properties } = area
            const areaType = properties.area_type

            if (!state.areas[areaType]) {
                return state
            }

            const originalFeatures = state.areas[areaType]?.originalFeatureCollection.features ?? []
            const newFeatures = originalFeatures.map(feature => (feature.id === area.id ? area : feature))
            const newFeatureCollection = {
                type: 'FeatureCollection',
                features: newFeatures,
            }

            return {
                ...state,
                areas: {
                    ...state.areas,
                    [areaType]: {
                        ...state.areas[areaType],
                        originalFeatureCollection: newFeatureCollection,
                        draftFeatureCollection: null,
                        selectedFeatureIndexes: [],
                        selectedFeatureId: null,
                        isChanged: false,
                        mapMode: 'view',
                    },
                },
            }
        }

        default:
            return state
    }
}
