import {
    GET_ACCIDENT_REASONS,
    SET_ACCIDENT_REASONS,
    CREATE_ACCIDENT_REASON,
    EDIT_ACCIDENT_REASON,
    DELETE_ACCIDENT_REASON,
    CREATE_ACCIDENT_REPORT,
    DELETE_ACCIDENT_REPORT,
    EDIT_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORTS,
    SET_ACCIDENT_REPORTS,
    SET_CURRENT_ACCIDENT_REPORT,
    type GetAccidentReports,
    type GetAccidentReport,
    type SetAccidentReports,
    type SetCurrentAccidentReport,
    type EditAccidentReport,
    type DeleteAccidentReport,
    type EditAccidentReason,
    type GetAccidentReasons,
    type CreateAccidentReason,
    type CreateAccidentReport,
    type DeleteAccidentReason,
    type SetAccidentReasons,
} from 'src/redux/accidents/accidents.types'
import {
    type AccidentReason,
    type AccidentReasons,
    type AccidentReport,
    type AccidentReportForm,
    type AccidentReports,
} from 'src/api/fm/accidents/accidents.model'

export const getAccidentReports = (limit: number, offset: number, query: string): GetAccidentReports => ({
    type: GET_ACCIDENT_REPORTS,
    payload: { limit, offset, query },
})

export const setAccidentReports = (reports: AccidentReports): SetAccidentReports => ({
    type: SET_ACCIDENT_REPORTS,
    payload: reports,
})

export const getAccidentReport = (reportId: string): GetAccidentReport => ({
    type: GET_ACCIDENT_REPORT,
    payload: reportId,
})

export const setCurrentAccidentReport = (report: AccidentReport): SetCurrentAccidentReport => ({
    type: SET_CURRENT_ACCIDENT_REPORT,
    payload: report,
})

export const createAccidentReport = (
    reportForm: AccidentReportForm,
    pushToRoute: (reportId: string) => void,
): CreateAccidentReport => ({
    type: CREATE_ACCIDENT_REPORT,
    payload: { reportForm, pushToRoute },
})

export const editAccidentReport = (
    reportId: string,
    reportForm: AccidentReportForm,
    pushToRoute: (reportId: string) => void,
): EditAccidentReport => ({
    type: EDIT_ACCIDENT_REPORT,
    payload: { reportId, reportForm, pushToRoute },
})

export const deleteAccidentReport = (reportId: string, pushToRoute: () => void): DeleteAccidentReport => ({
    type: DELETE_ACCIDENT_REPORT,
    payload: { reportId, pushToRoute },
})

export const getAccidentReasons = (): GetAccidentReasons => ({
    type: GET_ACCIDENT_REASONS,
})

export const setAccidentReasons = (reasons: AccidentReasons): SetAccidentReasons => ({
    type: SET_ACCIDENT_REASONS,
    payload: reasons,
})

export const createAccidentReason = (reason: AccidentReason): CreateAccidentReason => ({
    type: CREATE_ACCIDENT_REASON,
    payload: reason,
})

export const editAccidentReason = (reasonId: string, reason: AccidentReason): EditAccidentReason => ({
    type: EDIT_ACCIDENT_REASON,
    payload: {
        reasonId,
        reason,
    },
})

export const deleteAccidentReason = (reasonId: string): DeleteAccidentReason => ({
    type: DELETE_ACCIDENT_REASON,
    payload: { reasonId },
})
