import { uniq } from 'lodash-es'
import type { PermissionsForRoles } from 'src/api/fm/permissions/permissions.model'

export const getCurrentRoles = (roles: string[] | null): string[] | undefined => {
    const voiOrgRoles = roles?.filter(role => role.startsWith('voiorg-'))

    return voiOrgRoles && voiOrgRoles.length > 0 ? voiOrgRoles : undefined
}

export const checkIfRolesHavePermission = (data: {
    roles: string[] | null
    allow: string
    permissionsForRoles: PermissionsForRoles
}): boolean => {
    const { roles, allow, permissionsForRoles } = data
    const currentRoles = getCurrentRoles(roles)

    if (!currentRoles) {
        return false
    }

    const userPermissions = getPermissionsForRoles(roles, permissionsForRoles)
    return userPermissions.some(permission => permission === allow)
}

const getPermissionsForRoles = (roles: string[] | null, permissionsForRoles: PermissionsForRoles): string[] => {
    let userPermissions: string[] = []

    roles?.forEach(role => {
        const permissionsForRole = permissionsForRoles[role] ?? []
        userPermissions = userPermissions.concat(permissionsForRole)
    })
    return uniq(userPermissions)
}
