import {
    GET_VEHICLE_REPORT,
    SET_IS_LOADING,
    SET_VEHICLE_REPORT,
    type VehicleReportState,
    type SetVehicleReport,
    type GetVehicleReport,
    type SetIsLoading,
} from 'src/redux/vehicleReport/vehicleReport.types'

export const getVehicleReport = (id: string): GetVehicleReport => {
    return {
        type: GET_VEHICLE_REPORT,
        payload: id,
    }
}

export const setIsLoading = (isLoading: boolean): SetIsLoading => {
    return {
        type: SET_IS_LOADING,
        payload: {
            isLoading,
        },
    }
}

export const setVehicleReport = (payload: VehicleReportState): SetVehicleReport => {
    return {
        type: SET_VEHICLE_REPORT,
        payload,
    }
}
