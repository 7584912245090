import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type MaasUser, type DraftMaasUser } from 'src/components/app/utils/maas/types'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/partners/getPartners API documentation}
 */
export const getMaasUsers = async (): Promise<MaasUser[] | Error> => {
    const link = encodeURI(`/v1/fm/partners`)
    const headers = await getHeaders()

    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/partners/createPartner API documentation}
 */
export const addMaasUser = async (maasUser: DraftMaasUser): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/partners`)
    const headers = await getHeaders()

    return ins
        .post(link, maasUser, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/partners/updatePartner API documentation}
 */
export const editMaasUser = async (maasUser: MaasUser): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/partners/${maasUser.ID}`)
    const headers = await getHeaders()

    return ins
        .patch(link, maasUser, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/partners/deletePartner API documentation}
 */
export const deleteMaasUser = async (maasUser: MaasUser): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/partners/${maasUser.ID}`)
    const headers = await getHeaders()

    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
