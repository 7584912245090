import { type Moment } from 'moment'

export enum DiscountStatus {
    Unspecified = 'unspecified',
    Active = 'active',
    Available = 'available',
    Used = 'used',
    Expired = 'expired',
    Disabled = 'disabled',
    Deleted = 'deleted',
}

export enum VoucherAvailability {
    Unspecified = 0,
    Available = 1,
    UnavailableForVoiPass = 2,
    UnavailableForVoi4Business = 3,
}

export enum DiscountType {
    Credit = 'credit',
    Ride = 'ride',
    VoiPass = 'voi_pass',
}

interface RideDiscountResponse {
    amount: number
    cost_per_min?: {
        minutes?: number
        percent?: number
    }
    unlock_fee?: {
        percent?: number
    }
}

interface CreditDiscount {
    amount: number
}

export interface VoiPassDiscount {
    amount: number
    cost?: number
    percent?: number
}

interface RiderVoucherResponse {
    name: string
    id: string
    amount: number
    status: DiscountStatus
    code?: string
    description?: string
    discount_type: DiscountType
    expires_at?: number
    last_used_at?: number
    times_used?: number
    voipass_discount?: VoiPassDiscount
    credit_discount?: CreditDiscount
    ride_discount?: RideDiscountResponse
}

export interface RiderVoucher {
    name: string
    id: string
    amount: number
    timesUsed: number
    status: DiscountStatus
    code?: string
    discountType: DiscountType
    expiresAt?: Moment
    voiPassDiscount?: VoiPassDiscount
}

export interface RiderVoucherDetails {
    canUseVouchers: boolean | undefined
    vouchers: RiderVoucher[] | []
}

export interface GetVoucherDetailsResponse {
    data: {
        availability: VoucherAvailability
        discounts: RiderVoucherResponse[] | []
    }
}
