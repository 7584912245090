import {
    type HunterState,
    RESET_CURRENT_HUNTER,
    RESET_HUNTER_HISTORY_FOR_HUNTER,
    SET_ALL_ORGS,
    SET_CURRENT_HUNTER,
    SET_HUNTER_HISTORY,
    SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER,
    SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION,
    INCREMENT_HUNTER_TASK_HISTORY_PAGINATION,
    type HunterActions,
    SET_IS_FETCHING_HUNTER_HISTORY,
} from 'src/redux/hunter/hunter.types'
import {
    CREATE_ORGANIZATION_SUCCEEDED,
    DELETE_ORGANIZATION_SUCCEEDED,
    EDIT_ORGANIZATION_SUCCEEDED,
    SET_CURRENT_ORGANIZATION,
} from 'src/redux/organization/organization.types'

export const INITIAL_STATE: HunterState = {
    hunter: undefined,
    hunterOrganization: null,
    hunterHistory: [],
    isFetchingHunterHistory: false,
    hunterHistorySortParam: '-created_at',
    hunterHistoryPageNum: 1,
    hunterHistoryEndOfPagination: false,
    organizations: [],
}

export function hunterReducer(state: HunterState = INITIAL_STATE, action: HunterActions): HunterState {
    switch (action.type) {
        case SET_ALL_ORGS:
            return { ...state, organizations: action.payload }

        case SET_CURRENT_HUNTER:
            return {
                ...state,
                hunter: action.payload,
            }

        case RESET_CURRENT_HUNTER:
            return {
                ...state,
                hunter: undefined,
                hunterHistorySortParam: '-created_at',
            }

        case SET_CURRENT_ORGANIZATION:
            return {
                ...state,
                hunterOrganization: action.payload,
            }

        case SET_HUNTER_HISTORY:
            return {
                ...state,
                hunterHistory: action.payload,
            }

        case SET_IS_FETCHING_HUNTER_HISTORY:
            return {
                ...state,
                isFetchingHunterHistory: action.payload,
            }

        case RESET_HUNTER_HISTORY_FOR_HUNTER:
            return {
                ...state,
                hunterHistory: [],
            }

        case INCREMENT_HUNTER_TASK_HISTORY_PAGINATION: {
            const hunterHistoryPageNum = state.hunterHistoryPageNum + 1

            return {
                ...state,
                hunterHistoryPageNum,
            }
        }

        case SET_HUNTER_TASK_HISTORY_END_OF_PAGINATION: {
            return {
                ...state,
                hunterHistoryEndOfPagination: true,
            }
        }

        case SET_HUNTER_HISTORY_SORT_PARAM_FOR_HUNTER:
            return {
                ...state,
                hunterHistorySortParam: action.payload,
                hunterHistoryPageNum: 1,
                hunterHistoryEndOfPagination: false,
            }

        case CREATE_ORGANIZATION_SUCCEEDED: {
            const prevOrganizations = state.organizations || []

            return {
                ...state,
                organizations: [...prevOrganizations, action.payload],
            }
        }

        case DELETE_ORGANIZATION_SUCCEEDED:
            return {
                ...state,
                organizations: state.organizations.filter(organization => organization.id !== action.payload),
            }

        case EDIT_ORGANIZATION_SUCCEEDED:
            return {
                ...state,
                organizations: state.organizations.map(organization => {
                    if (organization.id === action.payload.id) {
                        return action.payload
                    }
                    return organization
                }),
            }

        default:
            return state
    }
}
