import { Link } from 'react-router-dom'
import { type CloseTaskStatus } from 'src/api/fm/tasks/tasks.model'
import { coral } from 'src/ui-kit/colors/colors'
import { ButtonText } from 'src/ui-kit/text/Text'

export const getTaskOwnerLink = (id: string, ownerType: 'hunter' | 'organization') => {
    if (ownerType === 'organization') {
        return (
            <Link to={`/organization/${id}`}>
                <ButtonText $color={coral}>ORG</ButtonText>
            </Link>
        )
    } else {
        return (
            <Link to={`/hunters/${id}`}>
                <ButtonText $color={coral}>HUNTER</ButtonText>
            </Link>
        )
    }
}

export const getDropTaskTitle = (status: CloseTaskStatus): 'Fulfill task' | 'Decline task' => {
    return status === 'fulfilled' ? 'Fulfill task' : 'Decline task'
}
