import { type AppEnv } from 'src/utils/env'

interface FirebaseConfig {
    apiKey: string
    authDomain: string
    projectId: string
    storageBucket: string
    messagingSenderId: string
    appId?: string
    databaseURL?: string
}

const configs: Record<AppEnv, FirebaseConfig> = {
    stage: {
        apiKey: 'AIzaSyDOruhSI1mNwTpHX0-HUC93E_Ak6rhRHko',
        authDomain: 'voi-fm-stage.firebaseapp.com',
        projectId: 'voi-fm-stage',
        storageBucket: '',
        messagingSenderId: '442281873799',
        databaseURL: 'https://voi-fm-stage.firebaseio.com',
    },
    production: {
        apiKey: 'AIzaSyAgUNRk5iWPUcF3KqDKnYmKWvZs8wRAV8s',
        authDomain: 'voi-fleet-management.firebaseapp.com',
        projectId: 'voi-fleet-management',
        storageBucket: '',
        messagingSenderId: '442281873799',
        databaseURL: 'https://voi-fleet-management.firebaseio.com',
    },
}

export const getFirebaseConfig = (env: AppEnv) => {
    return configs[env]
}
