import { type AppState } from 'src/redux/app/app.store'
import { type Organization } from 'src/redux/organization/organization.types'

export const selectHunter = (state: AppState) => state.hunter.hunter
export const selectCurrentHunterHistory = (state: AppState) => state.hunter.hunterHistory
export const selectIsFetchingHunterHistory = (state: AppState) => state.hunter.isFetchingHunterHistory
export const selectHunterAccountId = (state: AppState) => state.hunter?.hunter?.accountId
export const selectHunterOrganization = (state: AppState) => state.hunter.hunterOrganization
export const selectHunterTaskHistoryEndOfPagination = (state: AppState): boolean =>
    state.hunter.hunterHistoryEndOfPagination
export const selectHunterTaskHistoryPageNum = (state: AppState) => state.hunter.hunterHistoryPageNum
export const selectHunterHistorySortParam = (state: AppState) => state.hunter.hunterHistorySortParam
export const selectOrganizations = (state: AppState) => state.hunter.organizations
export const selectOrganizationById = (state: AppState, id: string | undefined): Organization | undefined => {
    return state.hunter.organizations.find(org => org.id === id)
}
