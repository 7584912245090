import { type AppState } from 'src/redux/app/app.store'
import { type ParkingReview, type Ride } from 'src/api/fm/rentals/rentals.model'

export const selectCurrentRider = (state: AppState) => state.rider.currentRider
export const selectCurrentRiderWalletTransactions = (state: AppState) => state.rider.currentRider?.walletTransactions
export const selectCurrentRiderPaymentInfo = (state: AppState) => state.rider.currentRider?.paymentInfo
export const selectRideHistoryDateFilter = (state: AppState) => state.rider.rideHistoryDateFilter
export const selectRideHistorySourceFilter = (state: AppState) => state.rider.rideHistorySourceFilter
export const selectCurrentRideHistory = (state: AppState) => state.rider.rideHistory
export const selectCurrentRideHistoryItem = (state: AppState) => state.rider.currentRideHistoryItem
export const selectCurrentRiderDiscount = (state: AppState) => state.rider.riderDiscount
export const selectCurrentRiderVoiPass = (state: AppState) => state.rider.voiPass
export const selectCurrentRiderPaymentDetails = (state: AppState) => state.rider.paymentDetails
export const selectParkingPhoto = (state: AppState) => state.rider.parkingPhoto
export const selectCurrentRiderUser = (state: AppState) => state.rider.currentRider?.userInfo
export const selectCurrentRiderUserInfoId = (state: AppState) => state.rider.currentRider?.userInfo?.id
export const selectRiderAccountSharingInfo = (state: AppState) => state.rider.accountSharingInfo
export const selectRiderDiscountGroups = (state: AppState) => state.rider.discountGroups
export const selectRiderActiveRides = (state: AppState) => {
    const activeRide = state.rider.activeRide
    const guestRides = state.rider.guestRides || []
    const activeRides: Ride[] = []
    if (activeRide) {
        activeRides.push(activeRide)
    }

    guestRides.forEach(guest => {
        if (guest) {
            activeRides.push(guest)
        }
    })
    return activeRides
}

export const selectRiderWalletTransactions = (state: AppState) => state.rider.currentRider?.walletTransactions
export const selectRiderEndRideModal = (state: AppState) => state.rider.endRideModal
export const selectRiderDriversLicenseStatus = (state: AppState) => state.rider.driversLicenseStatus
export const selectRiderVoucherDetails = (state: AppState) => state.rider.voucherDetails
export const selectRiderParkingReviewById = (state: AppState, rentalId: string): ParkingReview | undefined =>
    state.rider.rideHistory?.find(item => item.rentalId === rentalId)?.parkingReview
export const selectRiderFineHistory = (state: AppState) => state.rider.fineHistory
export const selectDeleteUserFailed = (state: AppState) => state.rider.deleteUserFailed
export const selectRiderActivityScore = (state: AppState) => {
    const rideScore = state.rider.currentRider?.rideScore
    if (rideScore === null || rideScore === undefined) {
        return 'N/A'
    }
    const multipliedScore = rideScore * 100
    return multipliedScore.toFixed(1)
}
