import type { Violations } from 'src/api/confighub/violations/violations.model'

export const GET_VIOLATIONS = 'GET_VIOLATIONS'
export const SET_VIOLATIONS = 'SET_VIOLATIONS'

export type ViolationsState = Violations

export type GetViolations = {
    type: typeof GET_VIOLATIONS
}

export type SetViolations = {
    type: typeof SET_VIOLATIONS
    payload: ViolationsState
}

export type ViolationsActions = SetViolations | GetViolations
