import {
    ADD_VEHICLE_IDS,
    type VehicleIdsState,
    type VehicleId,
    type VehicleActions,
} from 'src/redux/vehicleIds/vehicleIds.types'

export const INITIAL_STATE: VehicleIdsState = {}

// TODO: Do we actually need a separate reducer for this data? We can move it to scooterReducer
export function vehicleIdsReducer(state = INITIAL_STATE, action: VehicleActions) {
    switch (action.type) {
        case ADD_VEHICLE_IDS:
            return handleAddVehicleIds(state, action.payload)

        default:
            return state
    }
}

function handleAddVehicleIds(state: VehicleIdsState, payload: VehicleId[]): VehicleIdsState {
    const newVehiclesById = payload.reduce((acc, { id, short }) => {
        return { ...acc, [id]: short }
    }, {})

    return {
        ...state,
        ...newVehiclesById,
    }
}
