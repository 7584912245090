import { useEffect, useRef } from 'react'

interface HotkeyConfig {
    key: string
    fn: (e: KeyboardEvent) => void
}

/**
 * Simple global hotkey manager
 */
export const useGlobalHotkeys = (hotkeyConfigs: HotkeyConfig[]) => {
    const configStorage = useRef(hotkeyConfigs)

    // Config updates shouldn't update the event listener
    useEffect(() => {
        configStorage.current = hotkeyConfigs
    }, [hotkeyConfigs])

    // Apply event listener once and remove on unmount
    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            configStorage.current
                .filter(config => config.key === e.key)
                .forEach(config => {
                    config.fn(e)
                })
        }

        window.addEventListener('keydown', listener)

        return () => window.removeEventListener('keydown', listener)
    }, [])
}
