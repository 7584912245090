import { ins } from 'src/api/axios'
import {
    type SellVehicleResult,
    type SellVehiclesResponse,
    type UndoSellVehicleResult,
    type UndoSellVehiclesResponse,
} from 'src/api/fm/vehicleSeller/vehicleSeller.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/vehicle-seller/sellVehicles API documentation}
 */
export const sellVehicles = async (vehicleIds: string[]): Promise<SellVehicleResult[] | Error> => {
    const url = '/v1/fm/vehicle-seller/vehicles/sell'
    const headers = await getHeaders()
    const data = { vehicleIds }

    return ins
        .put<SellVehiclesResponse>(url, data, { headers })
        .then(res => mapSellVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/vehicle-seller/undoSellVehicles API documentation}
 */
export const undoSellVehicles = async (vehicleIds: string[]): Promise<UndoSellVehicleResult[] | Error> => {
    const url = '/v1/fm/vehicle-seller/vehicles/undo-sell'
    const headers = await getHeaders()
    const data = { vehicleIds }

    return ins
        .put<UndoSellVehiclesResponse>(url, data, { headers })
        .then(res => mapUndoSellVehicles(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapSellVehicles = (response: UndoSellVehiclesResponse): UndoSellVehicleResult[] => {
    return response.data.map(d => ({
        vehicleId: d.vehicleId,
        isSuccessful: d.status === 'OK',
    }))
}

const mapUndoSellVehicles = (response: UndoSellVehiclesResponse): UndoSellVehicleResult[] => {
    return response.data.map(d => ({
        vehicleId: d.vehicleId,
        isSuccessful: d.status === 'OK',
    }))
}
