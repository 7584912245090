import { orderBy } from 'lodash-es'
import { type Task, type TaskWithScooterShort } from 'src/api/fm/tasks/tasks.model'
import { type SortOrder } from 'src/utils/sort/sort'
import { type Organization } from 'src/redux/organization/organization.types'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'

type PartialVehicle = Pick<Vehicle, 'id' | 'short'>

export const addVehicleShortToTasks = (tasks: Task[], vehicles: PartialVehicle[]) => {
    const tasksWithShorts: TaskWithScooterShort[] = []

    tasks.forEach(task => {
        const filteredVehicle = vehicles.find(v => v.id === task.vehicleId)
        const taskWithShort = {
            ...task,
            scooterShort: filteredVehicle ? filteredVehicle.short : '',
        }
        tasksWithShorts.push(taskWithShort)
    })

    return tasksWithShorts
}

export type FilterOrganizationField = 'name' | 'email' | 'market'

export interface FilterOrganizationsSortParam {
    field: FilterOrganizationField
    order: SortOrder
}

export function filterOrganizationsByKeyword(
    organizations: Organization[],
    sortParam: FilterOrganizationsSortParam,
    keyword: string,
): Record<string, any>[] {
    let filteredOrganizations = organizations

    if (keyword.length > 0) {
        filteredOrganizations = organizations.filter(
            org =>
                org.name.toLowerCase().includes(keyword.toLowerCase()) ||
                org.email.toLowerCase().includes(keyword.toLowerCase()) ||
                org.id.toLowerCase().includes(keyword.toLowerCase()),
        )
    }

    return orderBy(filteredOrganizations, sortParam.field, sortParam.order)
}
