import styled from 'styled-components'
import * as colors from 'src/ui-kit/colors/colors'

type Props = {
    label: string
    color: string
    onClick?: () => void
}

export const Pill = (props: Props) => (
    <PillWrapper $color={props.color} $isClickable={!!props.onClick}>
        {props.label}
    </PillWrapper>
)

const PillWrapper = styled.div<{ $color: string; $isClickable: boolean }>`
    color: ${colors.coreBlack};
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 12px;
    margin: 5px;
    cursor: ${props => (props.$isClickable ? 'pointer' : 'default')};
    max-width: 150px;
    background-color: ${props => props.$color};
`
