import { type Warehouse } from 'src/api/fm/warehouse/warehouse.model'

export const GET_WAREHOUSES = 'GET_WAREHOUSES' as const
export const SET_WAREHOUSES = 'SET_WAREHOUSES' as const
export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE' as const
export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE' as const
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE' as const

export const GET_PINNED_WAREHOUSES = 'GET_PINNED_WAREHOUSES' as const
export const SET_PINNED_WAREHOUSES = 'SET_PINNED_WAREHOUSES' as const
export const TOGGLE_PINNED_WAREHOUSE = 'TOGGLE_PINNED_WAREHOUSE' as const

export interface WarehouseState {
    warehouses: Warehouse[]
    pinnedWarehouses: string[]
}

export interface GetWarehouses {
    type: typeof GET_WAREHOUSES
}

export interface SetWarehouses {
    type: typeof SET_WAREHOUSES
    payload: Warehouse[]
}

export interface CreateWarehouse {
    type: typeof CREATE_WAREHOUSE
    payload: Warehouse
}

export interface UpdateWarehouse {
    type: typeof UPDATE_WAREHOUSE
    payload: Warehouse
}

export interface DeleteWarehouse {
    type: typeof DELETE_WAREHOUSE
    payload: Warehouse
}

export interface GetPinnedWarehouses {
    type: typeof GET_PINNED_WAREHOUSES
}

export interface SetPinnedWarehouses {
    type: typeof SET_PINNED_WAREHOUSES
    payload: string[]
}

export interface TogglePinnedWarehouse {
    type: typeof TOGGLE_PINNED_WAREHOUSE
    payload: string
}

export type WarehouseAction =
    | CreateWarehouse
    | UpdateWarehouse
    | DeleteWarehouse
    | GetWarehouses
    | SetWarehouses
    | GetPinnedWarehouses
    | SetPinnedWarehouses
    | TogglePinnedWarehouse
