import {
    EXPAND_ZONE,
    SET_NAV_EXPANDED,
    TOGGLE_MOBILE_NAV,
    EXPAND_REPAIRS,
    EXPAND_RIDERS,
    EXPAND_VEHICLES,
    type NavState,
    type NavActions,
    EXPAND_BATTERY,
} from 'src/redux/nav/nav.types'

const INITIAL_STATE: NavState = {
    zoneExpanded: false,
    navOpen: true,
    mobileNavOpen: false,
    repairsExpanded: false,
    ridersExpanded: false,
    vehiclesExpanded: false,
    batteryExpanded: false,
}

export function navReducer(state: NavState = INITIAL_STATE, action: NavActions): NavState {
    switch (action.type) {
        case EXPAND_ZONE:
            return { ...state, zoneExpanded: action.payload }
        case SET_NAV_EXPANDED:
            return { ...state, navOpen: action.payload }
        case EXPAND_REPAIRS:
            return { ...state, repairsExpanded: action.payload }
        case EXPAND_RIDERS:
            return { ...state, ridersExpanded: action.payload }
        case EXPAND_VEHICLES:
            return { ...state, vehiclesExpanded: action.payload }
        case TOGGLE_MOBILE_NAV:
            return { ...state, mobileNavOpen: action.payload }
        case EXPAND_BATTERY:
            return { ...state, batteryExpanded: action.payload }
        default:
            return state
    }
}
