import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError, isNotFoundError } from 'src/api/utils'
import { type RiderDiscount, type RiderDiscountGroup } from 'src/redux/rider/rider.types'

export const getDiscountGroups = async (): Promise<RiderDiscountGroup[] | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/groups')
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getRiderDiscount = async (userId: string): Promise<RiderDiscount | Error> => {
    const link = encodeURI(`/v1/fm/pricing/ride-plans/user-groups/user/${userId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data.data)
        .catch(e => {
            if (!isNotFoundError(e)) {
                return getError(e, 'response.data.errors[0]')
            }

            return undefined
        })
}

export const addRiderDiscount = async (data: {
    group_id: string
    user_id: string
    valid_from: number
    valid_to: number | null
}): Promise<RiderDiscount | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/user-groups')
    const headers = await getHeaders()
    return ins
        .post(link, data, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const updateRiderDiscount = async (data: RiderDiscount): Promise<RiderDiscount | Error> => {
    const link = encodeURI('/v1/fm/pricing/ride-plans/user-groups')
    const headers = await getHeaders()
    return ins
        .put(link, data, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const removeRiderDiscount = async (groupId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/pricing/ride-plans/user-groups/${groupId}`)
    const headers = await getHeaders()
    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
