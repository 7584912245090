import {
    CREATE_NOTE,
    DELETE_NOTE,
    GET_NOTES,
    SET_NOTES,
    type GetNotes,
    type SetNotes,
    type DeleteNote,
    type CreateNote,
} from 'src/redux/note/note.types'

export const createNote = (payload: Record<string, any>): CreateNote => {
    return {
        type: CREATE_NOTE,
        payload,
    }
}

export const deleteNote = (payload: { noteId: string; id: string }): DeleteNote => ({
    type: DELETE_NOTE,
    payload,
})

export const setNotes = (payload: Record<string, any>[]): SetNotes => ({
    type: SET_NOTES,
    payload,
})

export const getNotes = (payload: string): GetNotes => ({
    type: GET_NOTES,
    payload,
})
