import type { PermissionsForRoles } from 'src/api/fm/permissions/permissions.model'
import {
    GET_PERMISSIONS_FOR_ROLES,
    GET_PERMISSIONS_FOR_ROLES_SUCCESS,
    type GetPermissionsForRoles,
    type PermissionsForRolesSuccess,
} from 'src/redux/permissionsForRoles/permissionsForRoles.types'

export const getPermissionsForRoles = (): GetPermissionsForRoles => ({
    type: GET_PERMISSIONS_FOR_ROLES,
})

export const getPermissionsForRolesSuccess = (permissions: PermissionsForRoles): PermissionsForRolesSuccess => {
    return {
        type: GET_PERMISSIONS_FOR_ROLES_SUCCESS,
        payload: { permissions },
    }
}
