import { type ReactNode, createContext, useReducer, type Dispatch } from 'react'
import { type BatteryContextState, type BatteryActions, initialState } from 'src/contexts/battery/State'
import { batteryReducer } from 'src/contexts/battery/Reducer'

export type BatteryContextType = {
    state: BatteryContextState
    dispatch: Dispatch<BatteryActions>
}

export const BatteryContext = createContext<BatteryContextType | null>(null)

const BatteryContextProvider = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(batteryReducer, initialState)

    return (
        <BatteryContext.Provider
            value={{
                state,
                dispatch,
            }}
        >
            {children}
        </BatteryContext.Provider>
    )
}

export default BatteryContextProvider
