import { call, put, select, takeEvery, type SagaReturnType } from 'redux-saga/effects'
import * as api from 'src/api'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { getWarehouses, setPinnedWarehouses, setWarehouses } from 'src/redux/warehouse/warehouse.actions'
import { selectPinnedWarehouses } from 'src/redux/warehouse/warehouse.selectors'
import {
    GET_PINNED_WAREHOUSES,
    GET_WAREHOUSES,
    TOGGLE_PINNED_WAREHOUSE,
    type GetWarehouses,
    type GetPinnedWarehouses,
    type TogglePinnedWarehouse,
    type CreateWarehouse,
    CREATE_WAREHOUSE,
    UPDATE_WAREHOUSE,
    type DeleteWarehouse,
    DELETE_WAREHOUSE,
    type UpdateWarehouse,
} from 'src/redux/warehouse/warehouse.types'

const LOCAL_STORAGE_KEY = 'pinned_warehouses_v1'

type GetWarehousesRes = SagaReturnType<typeof api.getWarehouses>

export function* getWarehousesSaga(_: GetWarehouses) {
    try {
        const warehouses: GetWarehousesRes = yield call(api.getWarehouses)

        if (warehouses instanceof Error) {
            throw warehouses
        }

        yield put(setWarehouses(warehouses))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type CreateWarehouseSagaRes = SagaReturnType<typeof api.createWarehouse>

export function* createWarehouseSaga(action: CreateWarehouse) {
    try {
        const res: CreateWarehouseSagaRes = yield call(api.createWarehouse, action.payload)

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully created warehouse', 'success')
        yield put(getWarehouses())
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type UpdateWarehouseSagaRes = SagaReturnType<typeof api.updateWarehouse>

export function* updateWarehouseSaga(action: UpdateWarehouse) {
    try {
        const res: UpdateWarehouseSagaRes = yield call(api.updateWarehouse, action.payload)

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully updated warehouse', 'success')
        yield put(getWarehouses())
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

type DeleteWarehouseSagaRes = SagaReturnType<typeof api.deleteWarehouses>

export function* deleteWarehouseSaga(action: DeleteWarehouse) {
    try {
        const res: DeleteWarehouseSagaRes = yield call(api.deleteWarehouses, [action.payload])

        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Successfully deleted warehouse', 'success')
        yield put(getWarehouses())
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* getPinnedWarehousesSaga(_: GetPinnedWarehouses) {
    try {
        const storedPinnedWarehouses: string | null = yield call(
            [localStorage, localStorage.getItem],
            LOCAL_STORAGE_KEY,
        )
        const pinnedWarehouses: string[] = (storedPinnedWarehouses && JSON.parse(storedPinnedWarehouses)) ?? []

        yield put(setPinnedWarehouses(pinnedWarehouses))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export function* togglePinnedWarehouseSaga(action: TogglePinnedWarehouse) {
    try {
        const warehouseId = action.payload
        let pinnedWarehouses: string[] = yield select(selectPinnedWarehouses)
        const isWarehousePinned = pinnedWarehouses.includes(warehouseId)

        if (isWarehousePinned) {
            pinnedWarehouses = pinnedWarehouses.filter(w => w !== warehouseId)
        } else {
            pinnedWarehouses = [...pinnedWarehouses, warehouseId]
        }

        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(pinnedWarehouses))

        yield put(setPinnedWarehouses(pinnedWarehouses))
    } catch (e) {
        yield call(notifyUser, e, 'error')
    }
}

export default function* watcher(): any {
    yield takeEvery(GET_WAREHOUSES, getWarehousesSaga)
    yield takeEvery(CREATE_WAREHOUSE, createWarehouseSaga)
    yield takeEvery(UPDATE_WAREHOUSE, updateWarehouseSaga)
    yield takeEvery(DELETE_WAREHOUSE, deleteWarehouseSaga)
    yield takeEvery(GET_PINNED_WAREHOUSES, getPinnedWarehousesSaga)
    yield takeEvery(TOGGLE_PINNED_WAREHOUSE, togglePinnedWarehouseSaga)
}
