import { type UserParkingHistory } from 'src/api/fm/safetyControlSystem/safetyControlSystem.model'
import {
    CLEAR_USER_PARKING_HISTORY,
    SAFETY_CONTROL_CLEAR,
    SAFETY_CONTROL_USER_PARKING_HISTORY,
    SAFETY_CONTROL_USER_STATS,
    type SafetyControlSystemActions,
    type SafetyControlSystemState,
    SET_SAFETY_CONTROL_SYSTEM_INFO,
    USER_INFO_MARK_NO_MORE,
} from 'src/redux/safetyControlSystem/safetyControlSystem.types'

export const INITIAL_STATE: SafetyControlSystemState = {
    areAllReviewed: false,
    userStats: null,
    userParkingHistory: [],
    userInfo: null,
}

export function safetyControlSystemReducer(
    state: SafetyControlSystemState = INITIAL_STATE,
    action: SafetyControlSystemActions,
): SafetyControlSystemState {
    switch (action.type) {
        case SAFETY_CONTROL_USER_STATS:
            return { ...state, userStats: action.payload }

        case SET_SAFETY_CONTROL_SYSTEM_INFO:
            return { ...state, userInfo: action.payload }

        case SAFETY_CONTROL_USER_PARKING_HISTORY:
            return { ...state, userParkingHistory: sortParkingHistoryInDescendingOrder(action.payload) }

        case CLEAR_USER_PARKING_HISTORY:
            return { ...state, userParkingHistory: [] }

        case SAFETY_CONTROL_CLEAR:
            return { ...state, ...INITIAL_STATE }

        case USER_INFO_MARK_NO_MORE:
            return { ...state, areAllReviewed: true }

        default:
            return state
    }
}

const sortParkingHistoryInDescendingOrder = (userParkingHistory: UserParkingHistory[]) =>
    userParkingHistory.sort((prev, curr) => curr.reviewCompletedAt - prev.reviewCompletedAt)
