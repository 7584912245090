import FuzzySearch from 'fuzzy-search'
import { createSelector } from 'reselect'
import { type AppState } from 'src/redux/app/app.store'
import { type LicensePlatesDeactivationResult } from 'src/models/licensePlate'
import { type BulkActionLoading } from 'src/redux/scooterBulk/scooterBulk.types'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'

export const selectVehicleBulkList = (state: AppState) => state.scooterBulk.vehicleBulkList
export const selectVehiclesNotFound = (state: AppState) => state.scooterBulk.vehiclesNotFound
export const selectInvalidQRs = (state: AppState) => state.scooterBulk.invalidQRs
export const selectDuplicatedSearchItems = (state: AppState) => state.scooterBulk.duplicatedSearchItems

export const selectVehicleBulkListFilter = (state: AppState) => state.scooterBulk.filter

export function selectFilteredVehicleBulkList(state: AppState): BulkListVehicle[] | null {
    const filter = selectVehicleBulkListFilter(state)
    const bulk = selectVehicleBulkList(state)
    if (!Array.isArray(bulk)) {
        return null
    }

    const searcher = new FuzzySearch(
        bulk,
        [
            'short',
            'category',
            'battery',
            'vehicle_state',
            'availability.tasks',
            'zone',
            'licensePlate.deactivationStatus',
        ],
        { caseSensitive: false },
    )

    return searcher.search(filter)
}

export const selectVehicleBulkListErrors = (state: AppState) => state.scooterBulk.vehicleBulkListErrors
export const selectLicensePlatesDeactivationResult = (state: AppState): LicensePlatesDeactivationResult =>
    state.scooterBulk.licensePlatesDeactivationResult

export const selectBulkListCurrentRepairs = createSelector([selectVehicleBulkList], vehicleBulkList =>
    vehicleBulkList.filter(v => v.owner === 'repairs'),
)

export const selectVehicleBulkHasHandledRepairs = (state: AppState) => state.scooterBulk.hasHandledRepairs

export const selectVehicleBulkLoadingState = (state: AppState): BulkActionLoading | null => {
    return state.scooterBulk.loadingState
}
