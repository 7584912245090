import styled, { createGlobalStyle } from 'styled-components'
import { useEffect, useRef, type PropsWithChildren } from 'react'
import { useAppSelector } from 'src/redux/app/app.hooks'
import { Card, CardHeader, CardBody, CardHeaderActions } from 'src/ui-kit/card/Card'
import { zIndex } from 'src/constants/zIndex'
import { selectIsNavOpen } from 'src/redux/nav'
import { BUTTON_TYPES } from 'src/ui-kit/button/constants'
import { Button } from 'src/ui-kit/button/Button'

interface Props {
    close: () => any
    title?: string
    noScroll?: boolean
    dataTestId?: string
    removeCancel?: boolean
    cancelText?: string
    subHeader?: string
    autoWidth?: boolean
    maxWidth?: string
}

export const Popup = (props: PropsWithChildren<Props>) => {
    const { close } = props

    const isNavOpen = useAppSelector(selectIsNavOpen)

    const bodyRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const targetEl = e.target as HTMLElement

            // Check if click is inside component
            if (
                bodyRef.current!.contains(targetEl) ||
                targetEl.classList.contains('MuiAutocomplete-option') ||
                targetEl.classList.contains('MuiAutocomplete-listbox')
            ) {
                return
            }

            // TODO: This is currently a workaround in order to catch click events inside MuiTolltip that is inside the Popup.
            //
            // How it works without this workaround:
            // A click in a MuiToolTip that is embedded inside this Popup component will treat clicks as outside
            // this Popup component by default, leading to closing the Popup component and thus not propagating a clickevent
            // to the "Child".
            if ((e.target! as Element).classList.contains('CustomTooltip-child')) {
                return
            }

            close()
        }
        // Nice hack to see if click is within component, see link for expl.
        // https://medium.com/@pitipatdop/little-neat-trick-to-capture-click-outside-with-react-hook-ba77c37c7e82
        document.addEventListener('mousedown', handleClick)
        return () => document.removeEventListener('mousedown', handleClick)
    }, [close])

    return (
        <>
            <GlobalStyle />
            <PopupWrapper $isNavOpen={isNavOpen} data-testid={props.dataTestId}>
                <PopupBody ref={bodyRef} $autoWidth={props.autoWidth} $maxWidth={props.maxWidth}>
                    <Card>
                        <CardHeader title={props.title} subHeader={props.subHeader}>
                            {!props.removeCancel && (
                                <CardHeaderActions>
                                    <Button
                                        type={BUTTON_TYPES.TEXT_REGULAR}
                                        onClick={() => props.close()}
                                        dataTestId='popup-close-button'
                                    >
                                        {props.cancelText ? props.cancelText.toUpperCase() : 'CANCEL'}
                                    </Button>
                                </CardHeaderActions>
                            )}
                        </CardHeader>
                        <CardBody $padding='0 24px' $scrollable={!props.noScroll}>
                            {props.children}
                        </CardBody>
                    </Card>
                </PopupBody>
            </PopupWrapper>
        </>
    )
}

const GlobalStyle = createGlobalStyle`
    body {
        overflow-y: hidden;
    }
`

const PopupWrapper = styled.div<{ $isNavOpen: boolean }>`
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #0003;
    z-index: ${zIndex.highest};
    position: fixed;
    right: 0;
    top: 0;
    width: ${props => (props.$isNavOpen ? 'calc(100% - 260px)' : 'calc(100% - 70px)')};

    @media (width <= 1030px) {
        width: 100%;
    }
`

const PopupBody = styled.div<{ $maxWidth: string | undefined; $autoWidth: boolean | undefined }>`
    max-width: ${props => (props.$maxWidth ? props.$maxWidth : '600px')};
    width: ${props => (props.$autoWidth ? 'auto' : 'calc(100% - 20px)')};
    margin: auto;
    max-height: 700px;
    position: relative;
`
