import styled from 'styled-components'
import * as colors from 'src/ui-kit/colors/colors'

export const H1 = styled.h1`
    font-size: 60px;
    color: ${colors.black};
    line-height: 1.72;
    font-weight: 200;
    margin: 0;
`

export const H2 = styled.h2`
    font-size: 32px;
    color: ${colors.black};
    line-height: 1.48;
    font-weight: 700;
    margin: 0;
`

export const H3 = styled.h3`
    font-size: 18px;
    color: ${colors.black};
    line-height: 1.32;
    font-weight: 600;
    margin: 0;
`

export const H5 = styled.h5`
    font-size: 1.57em;
    line-height: 1.4em;
    margin: 0;
    font-weight: 400;
`
