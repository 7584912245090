export const GET_HUNTER_PARKING_PHOTO_BY_TASK_ID = 'GET_HUNTER_PARKING_PHOTO_BY_TASK_ID'
export const GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS = 'GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS'
export const APPEND_TASK_IDS_WITH_PARKING_PHOTO = 'APPEND_TASK_IDS_WITH_PARKING_PHOTO'
export const SHOW_PARKING_PHOTO_FOR_TASK = 'SHOW_PARKING_PHOTO_FOR_TASK'
export const CLOSE_SHOW_PARKING_PHOTO_FOR_TASK = 'CLOSE_SHOW_PARKING_PHOTO_FOR_TASK'
export const SHOW_NEXT_HUNTER_PARKING_PHOTO = 'SHOW_NEXT_HUNTER_PARKING_PHOTO'
export const SHOW_PREVIOUS_HUNTER_PARKING_PHOTO = 'SHOW_PREVIOUS_HUNTER_PARKING_PHOTO'
export const SET_IS_FETCHING_MORE_TASKS_START = 'SET_IS_FETCHING_MORE_TASKS_START'
export const SET_IS_FETCHING_MORE_TASKS_DONE = 'SET_IS_FETCHING_MORE_TASKS_DONE'
export const RESET_HUNTER_PARKING_PHOTO_STATE = 'RESET_HUNTER_PARKING_PHOTO_STATE'

export interface HunterParkingPhotos {
    photoUrlsByTaskId: Record<string, string>
    taskIdsWithParkingPhoto: string[]
    taskIdsWithParkingPhotoIndex: number | null
    currentPage: HunterParkingPhotosCurrentPage | null
    isFetchingMoreTasks: boolean
}

export type HunterParkingPhotosCurrentPage = 'hunter' | 'organization' | 'vehicle'

export interface GetHunterParkingPhotoByTaskId {
    type: typeof GET_HUNTER_PARKING_PHOTO_BY_TASK_ID
    payload: string
}

export interface GetHunterParkingPhotoByTaskIdSuccess {
    type: typeof GET_HUNTER_PARKING_PHOTO_BY_TASK_ID_SUCCESS
    payload: { taskId: string; photoUrl: string }
}

export interface AppendTaskIdsWithParkingPhoto {
    type: typeof APPEND_TASK_IDS_WITH_PARKING_PHOTO
    payload: { taskIds: string[]; currentPage: HunterParkingPhotosCurrentPage }
}

export interface ShowParkingPhotoForTask {
    type: typeof SHOW_PARKING_PHOTO_FOR_TASK
    payload: { taskId: string }
}

export interface CloseShowParkingPhotoForTask {
    type: typeof CLOSE_SHOW_PARKING_PHOTO_FOR_TASK
}

export interface ShowNextHunterParkingPhoto {
    type: typeof SHOW_NEXT_HUNTER_PARKING_PHOTO
}

export interface ShowPreviousHunterParkingPhoto {
    type: typeof SHOW_PREVIOUS_HUNTER_PARKING_PHOTO
}

export interface SetIsFetchingMoreTasksStart {
    type: typeof SET_IS_FETCHING_MORE_TASKS_START
}

export interface SetIsFetchingMoreTasksDone {
    type: typeof SET_IS_FETCHING_MORE_TASKS_DONE
}

export interface ResetHunterParkingPhotoState {
    type: typeof RESET_HUNTER_PARKING_PHOTO_STATE
}

export type HunterParkingPhotosActions =
    | GetHunterParkingPhotoByTaskId
    | GetHunterParkingPhotoByTaskIdSuccess
    | AppendTaskIdsWithParkingPhoto
    | ShowParkingPhotoForTask
    | CloseShowParkingPhotoForTask
    | ShowNextHunterParkingPhoto
    | ShowPreviousHunterParkingPhoto
    | SetIsFetchingMoreTasksStart
    | SetIsFetchingMoreTasksDone
    | ResetHunterParkingPhotoState
