import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type FineItem, type FineHistoryResponseItem } from 'src/api/fm/fineHistory/fines.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/fines/getUserFines API documentation}
 */
export const getRiderFineHistoryById = async (rideId: string): Promise<FineItem[] | Error> => {
    const url = encodeURI(`v1/fm/fines/users/${rideId}`)
    const headers = await getHeaders()
    return ins
        .get(url, { headers })
        .then(res => mapFineHistoryItem(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

interface FineHistoryResponse {
    fines: FineHistoryResponseItem[]
}

const mapFineHistoryItem = (response: FineHistoryResponse): FineItem[] => {
    return response.fines.map(fineItem => ({
        ...fineItem,
        fineId: fineItem.fineID,
        sourceId: fineItem.sourceID,
        userId: fineItem.userID,
        zoneId: fineItem.zoneID,
        receiptUrl: fineItem.receiptURL,
    }))
}
