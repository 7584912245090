export interface LicensePlate {
    vehicleId: string
    number: string
}

export interface LicensePlateDeactivationError {
    vehicleId: string
    reason: string
}

export enum LicensePlateDeactivationStatus {
    Failed = 'Failed',
    Succeeded = 'Succeeded',
}

export interface LicensePlatesDeactivationResult {
    successfulVehicles: string[]
    failedVehicles: string[]
}
