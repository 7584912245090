import { type Color } from '@deck.gl/core'

/*
 * VOI Dezign system colour palettes
 *
 * https://www.figma.com/file/AdVtbai502auhIzIUTtboY/Reef---The-design-system?node-id=2681%3A7088&t=mpCL9UQyGmx3H7rM-0
 */

// Core palette
export const coreBlack = '#000000'

// Supporting neutral palette
export const supportingNeutralGrey04 = '#787475'

// Alert colours
export const alertSuccess = '#008545'
export const alertWarning = '#FFB100'
export const alertError = '#ED0A02'
export const alertInfo = '#0075DB'

// TODO: Remove variables below successively as new dezign system variables (above) are added

// Primary
export const coral = '#F46C63'
export const black = '#231F20'
export const lightBlack = '#00000029'
export const white = '#FFFFFF'
export const steelGrey = '#97A5C3'
export const lightGrey = '#FAFAFA'
export const bgColor = '#f7f7f7'
export const mediumGrey = 'rgb(105,117,141, 0.1)'
export const grey = '#69758d'
export const grey50 = 'rgba(144, 142, 143, 0.51)'
export const darkGrey = '#6B758B'
export const dividerGrey = '#F2F2F2'
export const darkDividerGrey = '#E3E3E3'

// Secondary
export const purple = '#8567F5'
export const blue = '#2892D7'
export const green = '#16BF90'
export const brown = '#984C00'
export const pink = '#FDE7E6'
export const red = '#FA4300'
export const disabledHeaderGray = '#69758D'
export const cardShadow = '#231F2029'
export const statusYellow = '#F4A000'
export const homeBlue = '#004F93'
export const repairPink = '#FF3683'

// Neutral
export const grey005 = '#F2F2F6'

// Hover
export const hoverCoral = '#e4665e'
export const hoverBlack = '#322f30'
export const hoverTransparent = 'rgba(35,31,32,0.08)'

// Map
export const OPERATIONS_COLOR: Color = [124, 159, 119]

export const SOFT_MPZ_FILL_COLOR: Color = [0, 117, 219, 100]
export const SOFT_MPZ_BORDER_COLOR: Color = [0, 117, 219, 255]

export const FREE_FLOATING_FILL_COLOR: Color = [177, 222, 242, 100]
export const FREE_FLOATING_BORDER_COLOR: Color = [113, 197, 235, 255]

export const BOUNTY_COLOR: Color = [247, 181, 0]
export const PARKING_SPOT_COLOR: Color = [0, 202, 233]
export const NO_PARKING_COLOR: Color = [244, 108, 99]
export const SLOW_ZONE_COLOR: Color = [35, 31, 32]
export const NO_RIDING_COLOR: Color = [35, 31, 32]
export const INCENTIVE_PARKING_COLOR: Color = [0, 202, 233]
export const FLEET_CAPACITY_COLOR: Color = [0, 79, 147]

export const DEFAULT_COLOR: Color = [35, 31, 32]
export const GUIDE_HANDLE_BORDER_COLOR: Color = [0, 0, 255]
export const GUIDE_HANDLE_FILL_COLOR: Color = [144, 157, 255]
export const SELECTED_AREA_FILL_COLOR: Color = [255, 219, 69]
export const SELECTED_AREA_BORDER_COLOR: Color = [255, 169, 69]

export const editAreaYellow = '#ffdb4533'
