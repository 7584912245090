import { type Availability, type AvailabilityTask } from 'src/api/fm/vehicles/vehicles.model'

export const getVehicleStatusIcon = (
    vehicleCategory: string | undefined,
    availability: Availability,
    isSleeping = false,
    isHovered?: boolean,
): string => {
    if (!vehicleCategory) {
        return ''
    }

    if (isHovered) {
        return `hover-${vehicleCategory}`.toLowerCase()
    }

    if (isSleeping) {
        return `sleep_${vehicleCategory}`.toLowerCase()
    }

    const { status, tasks } = availability

    if (tasks) {
        const isSingleTaskVehicle = tasks.length === 1
        const isMultiTasksVehicle = tasks.length === 2

        if (isSingleTaskVehicle) {
            const { type, executionPriority: taskExecutionPriority } = tasks[0]
            if (status === 'storage') {
                return `storage_${vehicleCategory}`.toLowerCase()
            } else if (status === 'unavailable') {
                return `bounty_${type}_${vehicleCategory}_${taskExecutionPriority}`.toLowerCase()
            }

            return `${status}_${type}_${vehicleCategory}_${taskExecutionPriority}`.toLowerCase()
        }

        if (isMultiTasksVehicle) {
            const keywordForMultiTasksIcon = getKeywordForMultiTasksIcon(tasks)

            if (status === 'unavailable') {
                return `bounty_${vehicleCategory}_${keywordForMultiTasksIcon}`.toLowerCase()
            }

            return `${status}_${vehicleCategory}_${keywordForMultiTasksIcon}`.toLowerCase()
        }
    }

    return `${status}_${vehicleCategory}`.toLowerCase()
}

const getKeywordForMultiTasksIcon = (tasks: AvailabilityTask[]): string => {
    const keywordForMultiTasksIcon = tasks
        .map(task => `${task.type}_${task.executionPriority}_${task.status}`)
        .join('_')

    switch (keywordForMultiTasksIcon) {
        // Rebalance + Battery swap
        case 'battery_swap_normal_new_rebalance_normal_new':
        case 'rebalance_normal_new_battery_swap_normal_new':
            return 'battery_swap_normal_new_rebalance_normal_new'
        case 'battery_swap_normal_pending_rebalance_normal_new':
        case 'rebalance_normal_new_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_rebalance_normal_new'
        case 'battery_swap_normal_new_rebalance_normal_pending':
        case 'rebalance_normal_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_rebalance_normal_pending'
        case 'battery_swap_normal_pending_rebalance_normal_pending':
        case 'rebalance_normal_pending_battery_swap_normal_pending':
            return 'battery_swap_pending_normal_rebalance_normal_pending'

        case 'battery_swap_high_new_rebalance_high_new':
        case 'rebalance_high_new_battery_swap_high_new':
            return 'battery_swap_high_new_rebalance_high_new'
        case 'battery_swap_high_pending_rebalance_high_new':
        case 'rebalance_high_new_battery_swap_high_pending':
            return 'battery_swap_high_pending_rebalance_high_new'
        case 'battery_swap_high_new_rebalance_high_pending':
        case 'rebalance_high_pending_battery_swap_high_new':
            return 'battery_swap_high_new_rebalance_high_pending'
        case 'battery_swap_high_pending_rebalance_high_pending':
        case 'rebalance_high_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_rebalance_high_pending'

        case 'battery_swap_normal_new_rebalance_high_new':
        case 'rebalance_high_new_battery_swap_normal_new':
            return 'battery_swap_normal_new_rebalance_high_new'
        case 'battery_swap_normal_pending_rebalance_high_new':
        case 'rebalance_high_new_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_rebalance_high_new'
        case 'battery_swap_normal_new_rebalance_high_pending':
        case 'rebalance_high_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_rebalance_high_pending'
        case 'battery_swap_normal_pending_rebalance_high_pending':
        case 'rebalance_high_pending_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_rebalance_high_pending'

        case 'battery_swap_high_new_rebalance_normal_new':
        case 'rebalance_normal_new_battery_swap_high_new':
            return 'battery_swap_high_new_rebalance_normal_new'
        case 'battery_swap_high_pending_rebalance_normal_new':
        case 'rebalance_normal_new_battery_swap_high_pending':
            return 'battery_swap_high_pending_rebalance_normal_new'
        case 'battery_swap_high_new_rebalance_normal_pending':
        case 'rebalance_normal_pending_battery_swap_high_new':
            return 'battery_swap_high_new_rebalance_normal_pending'
        case 'battery_swap_high_pending_rebalance_normal_pending':
        case 'rebalance_normal_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_rebalance_normal_pending'

        case 'battery_swap_low_new_rebalance_high_new':
        case 'rebalance_high_new_battery_swap_low_new':
            return 'battery_swap_low_new_rebalance_high_new'
        case 'battery_swap_low_new_rebalance_normal_new':
        case 'rebalance_normal_new_battery_swap_low_new':
            return 'battery_swap_low_new_rebalance_normal_new'
        case 'battery_swap_low_pending_rebalance_high_pending':
        case 'rebalance_high_pending_battery_swap_low_pending':
            return 'battery_swap_low_pending_rebalance_high_pending'
        case 'rebalance_normal_new_battery_swap_low_pending':
        case 'battery_swap_low_pending_rebalance_normal_new':
            return 'rebalance_normal_new_battery_swap_low_pending'
        case 'rebalance_high_new_battery_swap_low_pending':
        case 'battery_swap_low_pending_rebalance_high_new':
            return 'rebalance_high_new_battery_swap_low_pending'
        case 'rebalance_normal_pending_battery_swap_low_pending':
        case 'battery_swap_low_pending_rebalance_normal_pending':
            return 'rebalance_normal_pending_battery_swap_low_pending'
        case 'rebalance_high_pending_battery_swap_low_new':
        case 'battery_swap_low_new_rebalance_high_pending':
            return 'rebalance_high_pending_battery_swap_low_new'

        // In field quality check + Battery swap
        case 'battery_swap_normal_new_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_quality_check_normal_new'
        case 'battery_swap_normal_pending_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_quality_check_normal_new'
        case 'battery_swap_normal_new_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_quality_check_normal_pending'
        case 'battery_swap_normal_pending_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_quality_check_normal_pending'

        case 'battery_swap_high_new_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_quality_check_high_new'
        case 'battery_swap_high_pending_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_quality_check_high_new'
        case 'battery_swap_high_new_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_quality_check_high_pending'
        case 'battery_swap_high_pending_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_quality_check_high_pending'

        case 'battery_swap_normal_new_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_quality_check_high_new'
        case 'battery_swap_normal_pending_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_quality_check_high_new'
        case 'battery_swap_normal_new_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_quality_check_high_pending'
        case 'battery_swap_normal_pending_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_quality_check_high_pending'

        case 'battery_swap_high_new_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_quality_check_normal_new'
        case 'battery_swap_high_pending_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_quality_check_normal_new'
        case 'battery_swap_high_new_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_quality_check_normal_pending'
        case 'battery_swap_high_pending_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_quality_check_normal_pending'

        case 'battery_swap_low_new_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_quality_check_high_new'
        case 'battery_swap_low_new_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_quality_check_normal_new'
        case 'battery_swap_low_pending_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_quality_check_high_pending'
        case 'battery_swap_low_pending_in_field_quality_check_normal_new':
        case 'in_field_quality_check_normal_new_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_quality_check_normal_new'
        case 'battery_swap_low_pending_in_field_quality_check_high_new':
        case 'in_field_quality_check_high_new_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_quality_check_high_new'
        case 'battery_swap_low_pending_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_quality_check_normal_pending'
        case 'battery_swap_low_new_in_field_quality_check_high_pending':
        case 'in_field_quality_check_high_pending_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_quality_check_high_pending'
        case 'battery_swap_low_new_in_field_quality_check_normal_pending':
        case 'in_field_quality_check_normal_pending_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_quality_check_normal_pending'

        // In field repair + Battery swap
        case 'battery_swap_normal_new_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_repair_normal_pending'
        case 'battery_swap_normal_pending_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_repair_normal_pending'

        case 'battery_swap_high_new_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_repair_high_pending'
        case 'battery_swap_high_pending_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_repair_high_pending'

        case 'battery_swap_normal_new_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_normal_new':
            return 'battery_swap_normal_new_in_field_repair_high_pending'
        case 'battery_swap_normal_pending_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_normal_pending':
            return 'battery_swap_normal_pending_in_field_repair_high_pending'

        case 'battery_swap_high_new_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_high_new':
            return 'battery_swap_high_new_in_field_repair_normal_pending'
        case 'battery_swap_high_pending_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_high_pending':
            return 'battery_swap_high_pending_in_field_repair_normal_pending'

        case 'battery_swap_low_pending_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_repair_high_pending'
        case 'battery_swap_low_pending_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_low_pending':
            return 'battery_swap_low_pending_in_field_repair_normal_pending'
        case 'battery_swap_low_new_in_field_repair_high_pending':
        case 'in_field_repair_high_pending_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_repair_high_pending'
        case 'battery_swap_low_new_in_field_repair_normal_pending':
        case 'in_field_repair_normal_pending_battery_swap_low_new':
            return 'battery_swap_low_new_in_field_repair_normal_pending'

        default:
            return ''
    }
}
