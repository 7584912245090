import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { type PermissionsForRoles, type PermissionsForRolesResponse } from 'src/api/fm/permissions/permissions.model'
import { getError } from 'src/api/utils'

export const getPermissionsForRoles = async (): Promise<PermissionsForRoles | Error> => {
    const link = encodeURI('/v1/fm/permissions')
    const headers = await getHeaders()

    return ins
        .get<PermissionsForRolesResponse>(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
