import { useState } from 'react'
import { type CloseTaskStatus, type Task } from 'src/api/fm/tasks/tasks.model'
import { Popup } from 'src/ui-kit/popup/Popup'
import { getDropTaskTitle } from 'src/components/parts/active-tasks/Utils'
import { Button } from 'src/ui-kit/button/Button'
import { WarehouseSelectionModal } from 'src/components/warehouseSelectionModal/WarehouseSelectionModal'
import { TaskDeclineReasonModal } from 'src/components/taskDeclineReasonModal/TaskDeclineReasonModal'

export interface UpdateTaskPayload {
    taskId: string
    vehicleId: string
    status: CloseTaskStatus
    accountId: string
    warehouseId: string | undefined
    declineReason: string | undefined
}

export interface UpdateTaskData {
    task: Task
    status: CloseTaskStatus
}

interface Props {
    close: () => void
    data: UpdateTaskData
    updateTask: (data: UpdateTaskPayload) => void
}

export const DropScooter = (props: Props) => {
    const { close, data, updateTask } = props

    const [declineReason, setDeclineReason] = useState<string | undefined>(undefined)

    const handleSelectDeclineReason = (declineReason: string) => {
        if (requiresWarehouseSelection) {
            setDeclineReason(declineReason)
            return
        }

        updateTaskAndCloseModal(declineReason)
    }

    const handleSelectWarehouse = (warehouseId: string) => {
        updateTaskAndCloseModal(declineReason, warehouseId)
    }

    const updateTaskAndCloseModal = (reason?: string, warehouseId?: string) => {
        updateTask({
            taskId: data.task.id,
            vehicleId: data.task.vehicleId,
            status: data.status,
            accountId: data.task.accountId!,
            warehouseId,
            declineReason: reason,
        })
        close()
    }

    const requiresDeclineReasonSelection = data.task.status === 'pending' && data.status === 'declined'
    const requiresWarehouseSelection = data.task.type === 'transport'

    const showDeclineReasonSelectionModal = requiresDeclineReasonSelection && !declineReason
    const showWarehouseSelectionModal =
        requiresWarehouseSelection &&
        (!requiresDeclineReasonSelection || (requiresDeclineReasonSelection && declineReason))
    const showConfirmModal = !requiresDeclineReasonSelection && !requiresWarehouseSelection

    return (
        <>
            {showDeclineReasonSelectionModal && (
                <TaskDeclineReasonModal select={handleSelectDeclineReason} cancel={close} />
            )}
            {showWarehouseSelectionModal && (
                <WarehouseSelectionModal select={warehouse => handleSelectWarehouse(warehouse.id)} cancel={close} />
            )}
            {showConfirmModal && (
                <Popup title={getDropTaskTitle(data.status)} close={close} dataTestId='drop-scooter-confirm-modal'>
                    <Button
                        dataTestId='drop-scooter-confirm-modal-button'
                        isStretched
                        onClick={() => updateTaskAndCloseModal()}
                    >
                        Confirm
                    </Button>
                </Popup>
            )}
        </>
    )
}
