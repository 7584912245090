import { ins } from 'src/api/axios'
import {
    type CreateHunterResponse,
    type GetHunterOrganizationAllowedAreasResponse,
    type GetHunterResponse,
    type UpdateHunterResponse,
} from 'src/api/fm/hunters/hunters.model'
import type { TaskType } from 'src/api/fm/tasks/tasks.model'
import type { VehicleCategory } from 'src/api/fm/vehicles/vehicles.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { type Hunter } from 'src/redux/hunter/hunter.types'
import type { OrganizationAllowedArea } from 'src/redux/organization/organization.types'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/hunters%20gateway/createHunter API documentation}
 */
export const createNewHunter = async (hunter: Omit<Hunter, 'accountId'>): Promise<Hunter | Error> => {
    const { email, name, phone, organizationId } = hunter

    const link = encodeURI('v1/fm/hunters')
    const headers = await getHeaders()

    const payload = {
        email,
        name,
        phone,
        organizationId,
    }

    return ins
        .post<CreateHunterResponse>(link, payload, { headers })
        .then(res => mapCreateHunterResponseToHunter(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/hunters%20gateway/getHunter API documentation}
 */
export const fetchHunterDetails = async (id: string): Promise<Hunter | Error> => {
    const link = encodeURI(`v1/fm/hunters/${id}`)
    const headers = await getHeaders()

    return ins
        .get<GetHunterResponse>(link, { headers })
        .then(res => mapGetHunterResponseToHunter(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/hunters%20gateway/deleteHunter API documentation}
 */
export const deleteHunter = async (id: string, reason: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/hunters/${id}`)
    const headers = await getHeaders()

    return ins
        .delete(link, { data: { reason }, headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/hunters%20gateway/updateHunter API documentation}
 */
export const updateHunterDetails = async (hunter: Hunter): Promise<Hunter | Error> => {
    const { accountId, name, phone, organizationId } = hunter

    const link = encodeURI(`v1/fm/hunters/${accountId}`)
    const headers = await getHeaders()
    const payload = {
        name,
        phone,
        organizationID: organizationId,
    }

    return ins
        .put<UpdateHunterResponse>(link, payload, { headers })
        .then(res => mapUpdateHunterResponseToHunter(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const getHunterOrganizationAllowedAreas = async (
    organizationId: string,
): Promise<OrganizationAllowedArea[] | Error> => {
    const link = encodeURI(`v1/fm/hunters/org/${organizationId}/allowed-area`)
    const headers = await getHeaders()

    return ins
        .get<GetHunterOrganizationAllowedAreasResponse>(link, { headers })
        .then(res => mapGetHunterOrganizationAllowedAreas(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const createHunterOrganizationAllowedArea = async (
    allowedArea: OrganizationAllowedArea,
): Promise<void | Error> => {
    const { organizationId } = allowedArea
    const link = encodeURI(`v1/fm/hunters/org/${organizationId}/allowed-area`)
    const headers = await getHeaders()

    return ins
        .post(link, allowedArea, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const editHunterOrganizationAllowedArea = async (
    allowedArea: OrganizationAllowedArea,
): Promise<void | Error> => {
    const { organizationId, id } = allowedArea
    const link = encodeURI(`v1/fm/hunters/org/${organizationId}/allowed-area/${id}`)
    const headers = await getHeaders()

    return ins
        .put(link, allowedArea, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const deleteHunterOrganizationAllowedArea = async (
    allowedArea: OrganizationAllowedArea,
): Promise<void | Error> => {
    const { organizationId, id } = allowedArea
    const link = encodeURI(`v1/fm/hunters/org/${organizationId}/allowed-area/${id}`)
    const headers = await getHeaders()

    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapCreateHunterResponseToHunter = (response: CreateHunterResponse): Hunter => {
    const { accountId, email, name, organizationId, phone } = response.data

    return { accountId, email, name, organizationId, phone }
}

const mapGetHunterResponseToHunter = (response: GetHunterResponse): Hunter => {
    const { accountId, email, name, organizationId, phone } = response

    return { accountId, email, name, organizationId, phone }
}

const mapUpdateHunterResponseToHunter = (response: UpdateHunterResponse): Hunter => {
    const { accountId, email, name, organizationId, phone } = response.data

    return { accountId, email, name, organizationId, phone }
}

const mapGetHunterOrganizationAllowedAreas = (
    response: GetHunterOrganizationAllowedAreasResponse,
): OrganizationAllowedArea[] => {
    return response.organizationAreas.map(
        ({ id, organizationId, zoneId, name, area, taskTypes, vehicleCategories, updatedAt }) => ({
            id,
            organizationId,
            zoneId,
            name,
            area,
            taskTypes: taskTypes as TaskType[],
            vehicleCategories: (vehicleCategories ?? []) as VehicleCategory[],
            updatedAt,
        }),
    )
}
