import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getVehiclesByLongIds } from 'src/api'
import { type Vehicle } from 'src/api/fm/vehicles/vehicles.model'
import { addVehicleIds } from 'src/redux/vehicleIds/vehicleIds.actions'
import { selectAllVehicleIds } from 'src/redux/vehicleIds/vehicleIds.selectors'
import { FETCH_VEHICLE_IDS, type FetchVehicleIds } from 'src/redux/vehicleIds/vehicleIds.types'

export function* fetchVehicleIds(action: FetchVehicleIds) {
    try {
        const ids = action.payload
        const existingIds: ReturnType<typeof selectAllVehicleIds> = yield select(selectAllVehicleIds)
        const newIds = ids.filter(id => !existingIds[id])
        if (newIds.length === 0) {
            return
        }
        const res: Vehicle[] | Error = yield call(getVehiclesByLongIds, newIds)
        if (res instanceof Error) {
            throw res
        }

        const vehicleIds = res.map(({ id, short }) => ({ id, short }))

        yield put(addVehicleIds(vehicleIds))
    } catch (err) {
        console.log('Error when fetching vehicle ids: ', err)
    }
}

export default function* watcher() {
    yield takeEvery(FETCH_VEHICLE_IDS, fetchVehicleIds)
}
