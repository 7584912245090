import { put, takeEvery, call, select, fork, take, cancel, cancelled, type SagaReturnType } from 'redux-saga/effects'
import { type SagaIterator } from '@redux-saga/core'
import { validate as uuidValidate } from 'uuid'
import {
    BULK_CREATE_SCOOTER_TASKS,
    BULK_DELETE_SCOOTER_TASKS,
    BULK_UPDATE_SCOOTER_TASK_STATUS,
    BULK_UPDATE_SCOOTER_ZONE,
    BULK_UPDATE_TASK_PRIORITY,
    FETCH_BULK_LIST_VEHICLES,
    BULK_DEACTIVATE_LICENSE_PLATES,
    BULK_DECOMMISSION_VEHICLES,
    CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    BULK_SELL_VEHICLES,
    BULK_UNDO_SELL_VEHICLES,
    BULK_UNDO_DECOMMISSION_VEHICLES,
    FETCH_BULK_LIST_BY_QUERY_QRS,
} from 'src/redux/scooterBulk/scooterBulk.types'
import {
    createTasksBulk,
    deleteTasksBulk as deleteTasksBulkRequest,
    closeTasksBulk,
    getActiveTasksForVehicleBulk,
    updateVehicleZoneBulk,
    updateTasksBulk,
    getVehiclesByLongIds,
    getVehiclesByShortIds,
    getLicensePlatesBulk,
    deleteActiveLicensePlatesBulk,
    decommissionVehicles,
    sellVehicles,
    undoSellVehicles,
    undoDecommissionVehicles,
    getVehiclesByVins,
} from 'src/api'
import {
    clearBulkListErrors,
    fetchBulkListVehicles,
    setVehicleBulkList,
    setVehicleBulkListErrors,
    setBulkActionLoading,
    setBulkActionDone,
    bulkSetDeactivateLicensePlatesResult,
    bulkClearDeactivateLicensePlatesResult,
    setBulkActionLoadingMultipleRequests,
    incrementBulkActionLoadingMultipleRequests,
    type bulkUpdateScooterTaskStatus,
    setVehiclesNotFound,
    type GetScooterBulkAction,
    setInvalidQRs,
} from 'src/redux/scooterBulk/scooterBulk.actions'
import { notifyUser } from 'src/components/parts/notifications/notifications'
import { selectZoneOptions } from 'src/redux/zone'
import * as utils from 'src/utils/scooterBulk'
import { chunk } from 'src/utils/arrayChunk/arrayChunk'
import { selectLicensePlatesDeactivationResult } from 'src/redux/scooterBulk/scooterBulk.selectors'
import {
    type LicensePlate,
    type LicensePlateDeactivationError,
    type LicensePlatesDeactivationResult,
} from 'src/models/licensePlate'
import {
    type DecommissionVehicleResult,
    type UndoDecommissionVehicleResult,
} from 'src/api/fm/decommission/decommission.model'
import { type Warehouse } from 'src/api/fm/warehouse/warehouse.model'
import { type BulkListVehicle } from 'src/utils/scooterBulk/types'
import { type CreateTasksBulkResponse, type CreateTasksBulkPayload } from 'src/api/hunters/hunters.model'
import { type ExecutionPriority, type Task } from 'src/api/fm/tasks/tasks.model'
import { selectWarehouses } from 'src/redux/warehouse'
import { type SellVehicleResult, type UndoSellVehicleResult } from 'src/api/fm/vehicleSeller/vehicleSeller.model'
import { type VehicleIdentifierType } from 'src/utils/scooterBulk'

export const BULK_TASKS_BATCH_SIZE = 30
export const BULK_SELL_VEHICLES_BATCH_SIZE = 30
export const BULK_UNDO_DECOMMISSION_VEHICLES_BATCH_SIZE = 30

type GetVehiclesByShortIdsRes = SagaReturnType<typeof getVehiclesByShortIds>
export function* fetchBulkListByQueryQRsSaga(action: GetScooterBulkAction<typeof FETCH_BULK_LIST_BY_QUERY_QRS>) {
    const queryQRs = action.payload.split(',')
    const invalidQueryQRs = queryQRs.filter(qr => qr.length !== 4)
    const validQueryQRs = queryQRs.filter(qr => qr.length === 4)

    try {
        const bulkList: GetVehiclesByShortIdsRes = yield call(getVehiclesByShortIds, validQueryQRs)

        if (bulkList instanceof Error) {
            throw bulkList
        }

        const zoneOptions: ReturnType<typeof selectZoneOptions> = yield select(selectZoneOptions)

        let licensePlates: LicensePlate[] | Error = yield call(getLicensePlatesBulk, validQueryQRs)

        if (licensePlates instanceof Error) {
            licensePlates = []
        }

        const licensePlatesDeactivationResult: ReturnType<typeof selectLicensePlatesDeactivationResult> = yield select(
            selectLicensePlatesDeactivationResult,
        )

        const bulkListVehicles = bulkList.map(s =>
            utils.vehicleToBulkListVehicle(
                s,
                zoneOptions,
                licensePlates as LicensePlate[],
                licensePlatesDeactivationResult,
            ),
        )

        yield put(setVehicleBulkList(bulkListVehicles))

        const vehiclesNotFound = utils.getVehiclesNotFound(validQueryQRs, bulkList, 'short')
        yield put(setVehiclesNotFound(vehiclesNotFound))

        yield put(setInvalidQRs(invalidQueryQRs))
    } catch (_) {
        yield call(notifyUser, { message: 'Failed to fetch bulk list by query QRs' }, 'error')
    } finally {
        yield put(bulkClearDeactivateLicensePlatesResult())
    }
}

type GetVehiclesByIdentifiersRes = SagaReturnType<
    typeof getVehiclesByLongIds | typeof getVehiclesByShortIds | typeof getVehiclesByVins
>

export function* fetchBulkList(action: GetScooterBulkAction<typeof FETCH_BULK_LIST_VEHICLES>) {
    try {
        const firstVehicleIdentifier = action.payload[0]
        let bulkList: GetVehiclesByIdentifiersRes
        const identifierType: VehicleIdentifierType =
            firstVehicleIdentifier.length === 4 ? 'short' : uuidValidate(firstVehicleIdentifier) ? 'id' : 'vin'

        switch (identifierType) {
            case 'id':
                bulkList = yield call(getVehiclesByLongIds, action.payload)
                break
            case 'short':
                bulkList = yield call(getVehiclesByShortIds, action.payload)
                break
            case 'vin':
                bulkList = yield call(getVehiclesByVins, action.payload)
                break
        }

        if (bulkList instanceof Error) {
            throw bulkList
        }
        const zoneOptions: ReturnType<typeof selectZoneOptions> = yield select(selectZoneOptions)

        const vehicleIds = bulkList.map(v => v.id)
        let licensePlates: LicensePlate[] | Error = yield call(getLicensePlatesBulk, vehicleIds)

        if (licensePlates instanceof Error) {
            licensePlates = []
        }

        const licensePlatesDeactivationResult: ReturnType<typeof selectLicensePlatesDeactivationResult> = yield select(
            selectLicensePlatesDeactivationResult,
        )

        const bulkListVehicles = bulkList.map(s =>
            utils.vehicleToBulkListVehicle(
                s,
                zoneOptions,
                licensePlates as LicensePlate[],
                licensePlatesDeactivationResult,
            ),
        )
        yield put(setVehicleBulkList(bulkListVehicles))

        const vehiclesNotFound = utils.getVehiclesNotFound(action.payload, bulkList, identifierType)

        yield put(setVehiclesNotFound(vehiclesNotFound))
        yield put(setInvalidQRs([]))
    } catch (_) {
        yield call(notifyUser, { message: 'Failed to fetch bulk list' }, 'error')
    } finally {
        yield put(bulkClearDeactivateLicensePlatesResult())
    }
}

type UpdateVehicleZoneBulkResponse = SagaReturnType<typeof updateVehicleZoneBulk>

export function* updateVehicleZoneBulkSaga(action: GetScooterBulkAction<typeof BULK_UPDATE_SCOOTER_ZONE>) {
    try {
        yield put(setBulkActionLoading())
        yield put(clearBulkListErrors())
        const { scooters, zone } = action.payload
        const scooterIds = scooters.map(s => s.id)

        const res: UpdateVehicleZoneBulkResponse = yield call(updateVehicleZoneBulk, scooterIds, zone)
        if (res instanceof Error) {
            throw res
        }

        yield call(notifyUser, 'Zone updated successfully', 'success')
        yield put(fetchBulkListVehicles(scooterIds))
    } catch (e) {
        yield call(notifyUser, e)
    } finally {
        yield put(setBulkActionDone())
    }
}

type UpdateTasksBulkRes = SagaReturnType<typeof updateTasksBulk>

export function* updateBulkTasksPriority(action: GetScooterBulkAction<typeof BULK_UPDATE_TASK_PRIORITY>): SagaIterator {
    const { vehicles, taskIds, executionPriority } = action.payload

    yield put(clearBulkListErrors())
    yield put(setBulkActionLoading())
    const bulkRequestsTask = yield fork(updateBulkTasksPriorityRequests, vehicles, taskIds, executionPriority)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsTask)
}

export function* updateBulkTasksPriorityRequests(
    vehicles: BulkListVehicle[],
    taskIds: string[],
    executionPriority: ExecutionPriority,
): SagaIterator {
    let requestError = false
    let noTasksError = false
    const responses = []

    try {
        if (taskIds.length > 0) {
            const taskIdsBulks = chunk(taskIds, BULK_TASKS_BATCH_SIZE)
            if (taskIdsBulks.length > 1) {
                yield put(setBulkActionLoadingMultipleRequests(taskIdsBulks.length))
            }

            for (const taskIdsBulk of taskIdsBulks) {
                const res: UpdateTasksBulkRes = yield call(updateTasksBulk, taskIdsBulk, { executionPriority })

                // Break the loop and the saga if one request fails
                if (res instanceof Error) {
                    requestError = true
                    throw new Error(`Something went wrong with one of the requests: ${res.message}`)
                }

                yield put(incrementBulkActionLoadingMultipleRequests())
                responses.push(res)
            }
        } else {
            yield call(notifyUser, { message: 'No tasks to update' }, 'error')
            noTasksError = true
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        // Merge the errors from all requests into one array with errors
        const vehicleIds = vehicles.map(vehicle => vehicle.id)
        const combinedErrors = responses.filter(res => Boolean(res?.errors?.length)).flatMap(res => res.errors)
        const serverErrors = utils.getServerErrors(vehicles, combinedErrors)
        const errorsByScooterId = {
            ...utils.getVehicleMissingTasksErrors(vehicles),
            ...serverErrors,
        }

        yield put(setVehicleBulkListErrors(errorsByScooterId))
        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicleIds })
        yield put(setBulkActionDone())

        if (!requestError && !noTasksError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled update task priority' }, 'error')
            } else if (combinedErrors.length === 0) {
                yield call(notifyUser, 'Successfully updated task priority', 'success')
            } else {
                yield call(notifyUser, { message: 'Failed to update priority for some tasks' }, 'error')
            }
        }
    }
}

type GetActiveTasksForVehicleBulkRes = SagaReturnType<typeof getActiveTasksForVehicleBulk>

export function* deleteTasksBulk(action: GetScooterBulkAction<typeof BULK_DELETE_SCOOTER_TASKS>): SagaIterator {
    const vehicleIds = action.payload.scooters.map(scooter => scooter.id)

    yield put(setBulkActionLoading())
    const bulkRequestsTask = yield fork(deleteTasksBulkRequests, vehicleIds)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsTask)
}

type DeleteTasksBulkRequestRes = SagaReturnType<typeof deleteTasksBulkRequest>

export function* deleteTasksBulkRequests(vehicleIds: string[]): SagaIterator {
    let requestError = false
    let noTasksError = false
    let tasks: Task[] = []
    const responses = []

    try {
        const tasksRes: GetActiveTasksForVehicleBulkRes = yield call(getActiveTasksForVehicleBulk, vehicleIds)
        tasks = tasksRes instanceof Error ? [] : tasksRes
        if (tasks.length > 0) {
            const taskIds = tasks.map(t => t.id)
            const taskIdsBulks = chunk(taskIds, BULK_TASKS_BATCH_SIZE)
            if (taskIdsBulks.length > 1) {
                yield put(setBulkActionLoadingMultipleRequests(taskIdsBulks.length))
            }

            for (const taskIdsBulk of taskIdsBulks) {
                const res: DeleteTasksBulkRequestRes = yield call(deleteTasksBulkRequest, taskIdsBulk)

                // Break the loop and the saga if one request fails
                if (res instanceof Error) {
                    requestError = true
                    throw new Error(`Something went wrong with one of the requests: ${res.message}`)
                }

                yield put(incrementBulkActionLoadingMultipleRequests())
                responses.push(res)
            }
        } else {
            yield call(notifyUser, { message: 'No tasks to delete' }, 'error')
            noTasksError = true
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        // Merge the errors from all requests into one array with errors
        const combinedErrors = responses.filter(res => Boolean(res?.errors?.length)).flatMap(res => res.errors)
        const serverErrors = utils.serverErrorsByVehicleIds(vehicleIds, tasks, combinedErrors)
        const errorsByScooterId = {
            ...utils.collectVehicleMissingTaskErrors(vehicleIds, tasks),
            ...serverErrors,
        }

        yield put(setVehicleBulkListErrors(errorsByScooterId))
        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicleIds })
        yield put(setBulkActionDone())

        if (!requestError && !noTasksError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled delete tasks' }, 'error')
            } else if (combinedErrors.length === 0) {
                yield call(notifyUser, 'Successfully deleted tasks for vehicles', 'success')
            } else {
                yield call(notifyUser, { message: 'Failed to delete tasks for some vehicles' }, 'error')
            }
        }
    }
}

export function* submitBulkTasks(action: GetScooterBulkAction<typeof BULK_CREATE_SCOOTER_TASKS>): SagaIterator {
    const { scooters, task } = action.payload
    const vehicleIds = scooters.map(scooter => scooter.id)

    yield put(clearBulkListErrors())
    yield put(setBulkActionLoading())
    const bulkRequestsTask = yield fork(submitBulkTasksRequests, vehicleIds, task)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsTask)
}

type CreateTasksBulkRes = SagaReturnType<typeof createTasksBulk>

export function* submitBulkTasksRequests(vehicleIds: string[], task: CreateTasksBulkPayload): SagaIterator {
    let requestError = false
    const responses: CreateTasksBulkResponse[] = []
    const bulks = chunk(vehicleIds, BULK_TASKS_BATCH_SIZE)
    if (bulks.length > 1) {
        yield put(setBulkActionLoadingMultipleRequests(bulks.length))
    }

    try {
        for (const bulk of bulks) {
            const res: CreateTasksBulkRes = yield call(createTasksBulk, bulk, task)

            // Break the loop and the saga if one request fails
            if (res instanceof Error) {
                requestError = true
                throw new Error(`Something went wrong with one of the requests: ${res.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            responses.push(res as CreateTasksBulkResponse)
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        // Merge the errors from all requests into one array with errors
        const combinedErrors = responses.filter(res => Boolean(res.errors.length)).flatMap(res => res.errors)

        const errorsByScooterId = utils.serverErrorsByVehicleIds(vehicleIds, [], combinedErrors)
        yield put(setVehicleBulkListErrors(errorsByScooterId))

        if (!requestError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled create tasks' }, 'error')
            } else if (combinedErrors.length === 0) {
                yield call(notifyUser, 'Successfully created tasks for vehicles', 'success')
            } else {
                yield call(notifyUser, { message: 'Failed to create tasks for some vehicles' }, 'error')
            }
        }

        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicleIds })
        yield put(setBulkActionDone())
    }
}

export function* updateTaskStatusBulk(action: ReturnType<typeof bulkUpdateScooterTaskStatus>): SagaIterator {
    const { scooters, status, warehouseId, declineReason } = action.payload
    const vehicleIds = scooters.map(s => s.id)

    try {
        yield put(clearBulkListErrors())
        yield put(setBulkActionLoading())
        const tasks: GetActiveTasksForVehicleBulkRes = yield call(getActiveTasksForVehicleBulk, vehicleIds)
        if (tasks instanceof Error) {
            throw new Error('Failed to update task status for some vehicles')
        }

        if (tasks.length) {
            const tasksData = tasks.map(t => ({
                id: t.id,
                warehouseId: t.type === 'transport' ? warehouseId : undefined,
            }))
            const res = yield call(closeTasksBulk, tasksData, status, declineReason)
            if (res instanceof Error) {
                throw res
            }

            if (res.data && res.data.errors && res.data.errors.length) {
                const errorsByScooterId = utils.serverErrorsByVehicleIds(vehicleIds, tasks, res.data.errors)

                yield put(setVehicleBulkListErrors(errorsByScooterId))
                throw new Error('Failed to update task status for some vehicles')
            } else {
                // Show notification for transport tasks with information about at which warehouse the vehicles were dropped at
                if (warehouseId) {
                    const warehouses: Warehouse[] = yield select(selectWarehouses)
                    const warehouse = warehouses.find(w => w.id === warehouseId)
                    const warehouseName = warehouse?.name ?? 'warehouse'

                    yield call(notifyUser, `Vehicles successfully dropped at ${warehouseName}`, 'success')
                } else {
                    yield call(notifyUser, 'Successfully updated task status for vehicles', 'success')
                }
            }
        }
    } catch (e) {
        yield call(notifyUser, e)
    } finally {
        yield put(fetchBulkListVehicles(vehicleIds))
        yield put(setBulkActionDone())
    }
}

export function* deactivateLicensePlatesBulk(action: GetScooterBulkAction<typeof BULK_DEACTIVATE_LICENSE_PLATES>) {
    const vehicles: BulkListVehicle[] = action.payload

    try {
        yield put(clearBulkListErrors())
        yield put(setBulkActionLoading())

        const vehiclesWithLicensePlate = vehicles.filter(v => v.licensePlate?.number)

        if (vehiclesWithLicensePlate.length === 0) {
            throw new Error('No vehicles with license plates selected')
        }

        const licensePlateDeactivationErrors: LicensePlateDeactivationError[] | Error = yield call(
            deleteActiveLicensePlatesBulk,
            vehiclesWithLicensePlate.map(v => v.short),
        )

        const licensePlatesDeactivationResult: LicensePlatesDeactivationResult = {
            successfulVehicles: [],
            failedVehicles: [],
        }

        for (const vehicle of vehiclesWithLicensePlate) {
            const hasError =
                licensePlateDeactivationErrors instanceof Error ||
                licensePlateDeactivationErrors.some(e => e.vehicleId === vehicle.id)

            if (hasError) {
                licensePlatesDeactivationResult.failedVehicles.push(vehicle.id)
            } else {
                licensePlatesDeactivationResult.successfulVehicles.push(vehicle.id)
            }
        }

        yield put(bulkSetDeactivateLicensePlatesResult(licensePlatesDeactivationResult))

        switch (licensePlatesDeactivationResult.failedVehicles.length) {
            case 0:
                yield call(notifyUser, "LP's deactivation succeeded", 'success')
                break
            case vehiclesWithLicensePlate.length:
                yield call(notifyUser, { message: "LP's deactivation failed" }, 'error')
                break
            default:
                yield call(notifyUser, { message: "LP's deactivation failed" }, 'error')
                yield call(notifyUser, "LP's deactivation succeeded", 'success')
                break
        }
    } catch (e) {
        yield call(notifyUser, e)
    } finally {
        yield put(fetchBulkListVehicles(vehicles.map(v => v.short)))
        yield put(setBulkActionDone())
    }
}

export function* decommissionVehiclesBulk(action: GetScooterBulkAction<typeof BULK_DECOMMISSION_VEHICLES>) {
    const { vehicles, reason } = action.payload

    try {
        yield put(clearBulkListErrors())
        yield put(setBulkActionLoading())

        const eligibleVehicleIds = vehicles.filter(v => v.availability.status === 'storage').map(v => v.id)

        if (eligibleVehicleIds.length === 0) {
            throw new Error('No vehicles eligible for decommissioning selected')
        }

        const results: DecommissionVehicleResult[] = yield call(decommissionVehicles, eligibleVehicleIds, reason)

        if (results instanceof Error) {
            throw results
        }

        let successfulVehiclesCount = 0

        for (const vehicleId of eligibleVehicleIds) {
            if (results.find(r => r.vehicleId === vehicleId)?.isSuccessful) {
                successfulVehiclesCount++
            }
        }

        switch (successfulVehiclesCount) {
            case eligibleVehicleIds.length:
                yield call(notifyUser, 'Decommission succeeded', 'success')
                break
            case 0:
                yield call(notifyUser, { message: 'Decommission failed' }, 'error')
                break
            default:
                yield call(notifyUser, { message: 'Decommission failed' }, 'error')
                yield call(notifyUser, 'Decommission succeeded', 'success')
                break
        }
    } catch (e) {
        yield call(notifyUser, e)
    } finally {
        yield put(fetchBulkListVehicles(vehicles.map(v => v.id)))
        yield put(setBulkActionDone())
    }
}

export function* undoDecommissionVehiclesBulk(
    action: GetScooterBulkAction<typeof BULK_UNDO_DECOMMISSION_VEHICLES>,
): SagaIterator {
    const vehicles = action.payload

    yield put(clearBulkListErrors())
    yield put(setBulkActionLoading())
    const bulkRequestsUndoDecommissionVehicles = yield fork(undoDecommissionVehiclesBulkRequests, vehicles)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsUndoDecommissionVehicles)
}

type UndoDecommissionVehiclesBulkRes = SagaReturnType<typeof undoDecommissionVehicles>

export function* undoDecommissionVehiclesBulkRequests(vehicles: BulkListVehicle[]): SagaIterator {
    const eligibleVehicleIds = vehicles.filter(v => v.availability.status === 'decommissioned').map(v => v.id)
    let fatalError = false
    const allResults: UndoDecommissionVehicleResult[] = []

    try {
        if (eligibleVehicleIds.length === 0) {
            fatalError = true
            throw new Error('No vehicles eligible for undoing decommission selected')
        }

        const bulks = chunk(eligibleVehicleIds, BULK_UNDO_DECOMMISSION_VEHICLES_BATCH_SIZE)

        if (bulks.length > 1) {
            yield put(setBulkActionLoadingMultipleRequests(bulks.length))
        }

        for (const bulk of bulks) {
            const results: UndoDecommissionVehiclesBulkRes = yield call(undoDecommissionVehicles, bulk)

            // Break the loop and the saga if one request fails
            if (results instanceof Error) {
                fatalError = true
                throw new Error(`Something went wrong with one of the requests: ${results.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            allResults.push(...results)
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        let successfulVehiclesCount = 0

        if (!fatalError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled undo decommission' }, 'error')
            } else {
                for (const vehicleId of eligibleVehicleIds) {
                    if (allResults.find(r => r.vehicleId === vehicleId)?.isSuccessful) {
                        successfulVehiclesCount++
                    }
                }

                switch (successfulVehiclesCount) {
                    case eligibleVehicleIds.length:
                        yield call(notifyUser, 'Undo decommission succeeded', 'success')
                        break
                    case 0:
                        yield call(notifyUser, { message: 'Undo decommission failed' }, 'error')
                        break
                    default:
                        yield call(notifyUser, { message: 'Undo decommission failed' }, 'error')
                        yield call(notifyUser, 'Undo decommission succeeded', 'success')
                        break
                }
            }
        }

        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicles.map(v => v.id) })
        yield put(setBulkActionDone())
    }
}

export function* sellVehiclesBulk(action: GetScooterBulkAction<typeof BULK_SELL_VEHICLES>): SagaIterator {
    const vehicles = action.payload

    yield put(clearBulkListErrors())
    yield put(setBulkActionLoading())
    const bulkRequestsSellVehicles = yield fork(sellVehiclesBulkRequests, vehicles)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsSellVehicles)
}

type SellVehiclesBulkRes = SagaReturnType<typeof sellVehicles>

export function* sellVehiclesBulkRequests(vehicles: BulkListVehicle[]): SagaIterator {
    const eligibleVehicleIds = vehicles.filter(v => v.availability.status === 'storage').map(v => v.id)
    let fatalError = false
    const allResults: SellVehicleResult[] = []

    try {
        if (eligibleVehicleIds.length === 0) {
            fatalError = true
            throw new Error('No vehicles eligible for marking as sold selected')
        }

        const bulks = chunk(eligibleVehicleIds, BULK_SELL_VEHICLES_BATCH_SIZE)

        if (bulks.length > 1) {
            yield put(setBulkActionLoadingMultipleRequests(bulks.length))
        }

        for (const bulk of bulks) {
            const results: SellVehiclesBulkRes = yield call(sellVehicles, bulk)

            // Break the loop and the saga if one request fails
            if (results instanceof Error) {
                fatalError = true
                throw new Error(`Something went wrong with one of the requests: ${results.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            allResults.push(...results)
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        let successfulVehiclesCount = 0

        if (!fatalError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled mark as sold' }, 'error')
            } else {
                for (const vehicleId of eligibleVehicleIds) {
                    if (allResults.find(r => r.vehicleId === vehicleId)?.isSuccessful) {
                        successfulVehiclesCount++
                    }
                }

                switch (successfulVehiclesCount) {
                    case eligibleVehicleIds.length:
                        yield call(notifyUser, 'Mark as sold succeeded', 'success')
                        break
                    case 0:
                        yield call(notifyUser, { message: 'Mark as sold failed' }, 'error')
                        break
                    default:
                        yield call(notifyUser, { message: 'Mark as sold failed' }, 'error')
                        yield call(notifyUser, 'Mark as sold succeeded', 'success')
                        break
                }
            }
        }

        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicles.map(v => v.id) })
        yield put(setBulkActionDone())
    }
}

export function* undoSellVehiclesBulk(action: GetScooterBulkAction<typeof BULK_UNDO_SELL_VEHICLES>): SagaIterator {
    const vehicles = action.payload

    yield put(clearBulkListErrors())
    yield put(setBulkActionLoading())
    const bulkRequestsUndoSellVehicles = yield fork(undoSellVehiclesBulkRequests, vehicles)
    yield take(CANCEL_BULK_ACTION_LOADING_MULTIPLE_REQUESTS)
    yield cancel(bulkRequestsUndoSellVehicles)
}

type UndoSellVehiclesBulkRes = SagaReturnType<typeof undoSellVehicles>

export function* undoSellVehiclesBulkRequests(vehicles: BulkListVehicle[]): SagaIterator {
    const eligibleVehicleIds = vehicles.filter(v => v.availability.status === 'sold').map(v => v.id)
    let fatalError = false
    const allResults: UndoSellVehicleResult[] = []

    try {
        if (eligibleVehicleIds.length === 0) {
            fatalError = true
            throw new Error('No vehicles eligible for undoing sold action selected')
        }

        const bulks = chunk(eligibleVehicleIds, BULK_SELL_VEHICLES_BATCH_SIZE)

        if (bulks.length > 1) {
            yield put(setBulkActionLoadingMultipleRequests(bulks.length))
        }

        for (const bulk of bulks) {
            const results: UndoSellVehiclesBulkRes = yield call(undoSellVehicles, bulk)

            // Break the loop and the saga if one request fails
            if (results instanceof Error) {
                fatalError = true
                throw new Error(`Something went wrong with one of the requests: ${results.message}`)
            }

            yield put(incrementBulkActionLoadingMultipleRequests())
            allResults.push(...results)
        }
    } catch (e) {
        yield call(notifyUser, e, 'error')
    } finally {
        let successfulVehiclesCount = 0

        if (!fatalError) {
            if (yield cancelled()) {
                yield call(notifyUser, { message: 'Cancelled undo sold action' }, 'error')
            } else {
                for (const vehicleId of eligibleVehicleIds) {
                    if (allResults.find(r => r.vehicleId === vehicleId)?.isSuccessful) {
                        successfulVehiclesCount++
                    }
                }

                switch (successfulVehiclesCount) {
                    case eligibleVehicleIds.length:
                        yield call(notifyUser, 'Undo sold action succeeded', 'success')
                        break
                    case 0:
                        yield call(notifyUser, { message: 'Undo sold action failed' }, 'error')
                        break
                    default:
                        yield call(notifyUser, { message: 'Undo sold action failed' }, 'error')
                        yield call(notifyUser, 'Undo sold action succeeded', 'success')
                        break
                }
            }
        }

        yield call(fetchBulkList, { type: FETCH_BULK_LIST_VEHICLES, payload: vehicles.map(v => v.id) })
        yield put(setBulkActionDone())
    }
}

export default function* watcher() {
    yield takeEvery(FETCH_BULK_LIST_VEHICLES, fetchBulkList)
    yield takeEvery(FETCH_BULK_LIST_BY_QUERY_QRS, fetchBulkListByQueryQRsSaga)
    yield takeEvery(BULK_UPDATE_SCOOTER_ZONE, updateVehicleZoneBulkSaga)
    yield takeEvery(BULK_UPDATE_TASK_PRIORITY, updateBulkTasksPriority)
    yield takeEvery(BULK_CREATE_SCOOTER_TASKS, submitBulkTasks)
    yield takeEvery(BULK_DELETE_SCOOTER_TASKS, deleteTasksBulk)
    yield takeEvery(BULK_UPDATE_SCOOTER_TASK_STATUS, updateTaskStatusBulk)
    yield takeEvery(BULK_DEACTIVATE_LICENSE_PLATES, deactivateLicensePlatesBulk)
    yield takeEvery(BULK_DECOMMISSION_VEHICLES, decommissionVehiclesBulk)
    yield takeEvery(BULK_UNDO_DECOMMISSION_VEHICLES, undoDecommissionVehiclesBulk)
    yield takeEvery(BULK_SELL_VEHICLES, sellVehiclesBulk)
    yield takeEvery(BULK_UNDO_SELL_VEHICLES, undoSellVehiclesBulk)
}
