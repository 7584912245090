import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import { type VehiclePolicy } from 'src/api/fm/vehiclePolicy/vehiclePolicy.model'

export const getVehiclePolicy = async (zoneId: string | number): Promise<VehiclePolicy | Error> => {
    const link = encodeURI(`/v1/fm/vehicles/policy/${zoneId}`)
    const headers = await getHeaders()
    return ins
        .get(link, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

export const editVehiclePolicy = async (zoneId: string | number, data: VehiclePolicy): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/vehicles/policy/${zoneId}`)
    const headers = await getHeaders()
    return ins
        .put(link, data, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}
