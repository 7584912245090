export const ADD_VEHICLE_IDS = 'ADD_VEHICLE_IDS'
export const FETCH_VEHICLE_IDS = 'FETCH_VEHICLE_IDS'

export interface VehicleId {
    id: string
    short: string
}

export interface VehicleIdsState {
    [uuid: string]: string
}

export interface AddVehicleIds {
    type: typeof ADD_VEHICLE_IDS
    payload: VehicleId[]
}

export interface FetchVehicleIds {
    type: typeof FETCH_VEHICLE_IDS
    payload: string[]
}

export type VehicleActions = AddVehicleIds | FetchVehicleIds
