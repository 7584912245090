import { useState } from 'react'
import styled from 'styled-components'
import VoiLogo from 'src/assets/images/nav/ic_voi.svg'
import { RolesAndLogout } from 'src/components/parts/navigation/RolesAndLogout'
import { zIndex } from 'src/constants/zIndex'
import { useAppSelector } from 'src/redux/app/app.hooks'
import { selectIsNavOpen } from 'src/redux/nav'
import * as colors from 'src/ui-kit/colors/colors'
import { isAppEnv, BUILD_SHORT_SHA } from 'src/utils/env'

export const DesktopHeader = () => {
    const [isStageEnv] = useState(isAppEnv('stage'))
    const isNavOpen = useAppSelector(selectIsNavOpen)

    return (
        <HeaderWrapper $staging={isStageEnv}>
            <NavigationHeader expanded={isNavOpen} />
            <HeaderContent>
                <HeaderText $staging={isStageEnv}>
                    {isStageEnv ? 'FM STAGE' : 'FLEET MANAGEMENT'} (build {BUILD_SHORT_SHA})
                </HeaderText>
                <RolesAndLogout useDarkIcon={!isStageEnv} />
            </HeaderContent>
        </HeaderWrapper>
    )
}

const HeaderText = styled.p<{ $staging: boolean }>`
    font-size: 16px;
    font-weight: 500;
    color: ${props => (props.$staging ? colors.white : colors.black)};
`

const HeaderWrapper = styled.div<{ $staging: boolean }>`
    color: ${colors.black};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 70px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${zIndex.priority};
    background-color: ${props => (props.$staging ? colors.black : colors.white)};
    width: 100vw;
    box-shadow: 0 6px 38px -31px rgb(77 77 77 / 100%);
`

const HeaderContent = styled.div`
    box-sizing: border-box;
    padding: 0 25px;
    flex-grow: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const NavHeaderWrapper = styled.div<{ $expanded: boolean }>`
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
    background-color: ${colors.coral};
    height: 100%;
    width: ${props => (props.$expanded ? '260px' : '70px')};
    align-items: center;
    display: flex;
    padding-left: 10px;
`

const NavItemLogo = styled.div<{ $expanded: boolean }>`
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: row;
    padding: 15px 10px;
    cursor: pointer;
    align-items: center;
    color: ${colors.white};
    ${props => !props.$expanded && 'margin: 0;'}
    ${props => !props.$expanded && 'padding: 0;'}
`

const NavIcon = styled.img<{ $expanded: boolean }>`
    margin-right: ${props => (props.$expanded ? '20px' : '0')};
    width: 20px;
    height: 20px;
`

function NavigationHeader({ expanded }: { expanded: boolean }) {
    return (
        <NavHeaderWrapper $expanded={expanded}>
            <NavItemLogo $expanded={expanded}>
                <NavIcon $expanded={expanded} src={VoiLogo} style={{ minWidth: '50px' }} alt='' />
            </NavItemLogo>
        </NavHeaderWrapper>
    )
}
