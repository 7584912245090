import { omit } from 'lodash-es'
import {
    SET_VEHICLE_BULK_LIST,
    CLEAR_VEHICLE_BULK_LIST,
    SET_VEHICLE_BULK_LIST_ERRORS,
    REMOVE_VEHICLES_FROM_BULK_LIST,
    SET_HAS_HANDLED_REPAIRS,
    REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST,
    UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS,
    CLEAR_VEHICLE_BULK_LIST_ERRORS,
    SET_BULK_ACTION_LOADING,
    SET_BULK_ACTION_DONE,
    SET_BULK_LIST_FILTER,
    BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT,
    BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT,
    SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS,
    type ScooterBulkState,
    SET_VEHICLES_NOT_FOUND,
    SET_INVALID_QRS,
    SET_DUPLICATED_SEARCH_ITEMS,
} from 'src/redux/scooterBulk/scooterBulk.types'
import { type ScooterBulkAction } from 'src/redux/scooterBulk/scooterBulk.actions'
import { type LicensePlatesDeactivationResult } from 'src/models/licensePlate'
import { type VehicleStatus } from 'src/api/fm/vehicles/vehicles.model'

export const INITIAL_STATE: ScooterBulkState = {
    hasHandledRepairs: false,
    vehicleBulkList: [],
    vehicleBulkListErrors: {},
    licensePlatesDeactivationResult: {
        successfulVehicles: [],
        failedVehicles: [],
    } as LicensePlatesDeactivationResult,
    loadingState: null,
    filter: '',
    vehiclesNotFound: [],
    invalidQRs: [],
    duplicatedSearchItems: [],
}

export function scooterBulkReducer(
    state: ScooterBulkState = INITIAL_STATE,
    action: ScooterBulkAction,
): ScooterBulkState {
    switch (action.type) {
        case SET_VEHICLE_BULK_LIST:
            return {
                ...state,
                hasHandledRepairs: false,
                vehicleBulkList: action.payload,
            }

        case SET_VEHICLES_NOT_FOUND:
            return {
                ...state,
                vehiclesNotFound: action.payload,
            }

        case SET_INVALID_QRS:
            return {
                ...state,
                invalidQRs: action.payload,
            }

        case SET_DUPLICATED_SEARCH_ITEMS:
            return {
                ...state,
                duplicatedSearchItems: action.payload,
            }

        case CLEAR_VEHICLE_BULK_LIST:
            return {
                ...state,
                vehicleBulkList: [],
                vehicleBulkListErrors: {},
                filter: '',
                vehiclesNotFound: [],
                invalidQRs: [],
                duplicatedSearchItems: [],
            }

        case SET_VEHICLE_BULK_LIST_ERRORS:
            return {
                ...state,
                vehicleBulkListErrors: action.payload,
            }

        case REMOVE_VEHICLES_FROM_BULK_LIST: {
            const scooterIds = action.payload
            const vehicleBulkList = state.vehicleBulkList
                ? state.vehicleBulkList.filter(s => !scooterIds.includes(s.id))
                : []
            const vehicleBulkListErrors = omit(state.vehicleBulkListErrors, scooterIds)

            if (vehicleBulkList.length === 0) {
                return {
                    ...state,
                    vehicleBulkList: [],
                    vehicleBulkListErrors: {},
                }
            }

            return {
                ...state,
                vehicleBulkList,
                vehicleBulkListErrors,
            }
        }

        case SET_HAS_HANDLED_REPAIRS: {
            return {
                ...state,
                hasHandledRepairs: true,
            }
        }

        case REMOVE_REPAIR_VEHICLES_FROM_BULK_LIST: {
            const vehicleBulkList = state.vehicleBulkList
                ? state.vehicleBulkList.filter(s => s.owner !== 'repairs')
                : []

            if (vehicleBulkList.length === 0) {
                return {
                    ...state,
                    hasHandledRepairs: true,
                    vehicleBulkList: [],
                    vehicleBulkListErrors: {},
                }
            }

            return {
                ...state,
                hasHandledRepairs: true,
                vehicleBulkList,
            }
        }

        case UPDATE_SCOOTERS_REMOVED_FROM_REPAIRS: {
            const { scooterIds } = action.payload

            if (!state.vehicleBulkList) {
                return {
                    ...state,
                    hasHandledRepairs: true,
                }
            }

            const vehicleBulkList = state.vehicleBulkList.map(vehicle => {
                return scooterIds.includes(vehicle.id)
                    ? {
                          ...vehicle,
                          owner: '',
                          availability: {
                              status: 'ready' as VehicleStatus,
                              tasks: [],
                          },
                      }
                    : vehicle
            })

            return {
                ...state,
                hasHandledRepairs: true,
                vehicleBulkList,
            }
        }

        case CLEAR_VEHICLE_BULK_LIST_ERRORS:
            return {
                ...state,
                vehicleBulkListErrors: {},
            }

        case SET_BULK_ACTION_LOADING:
            return {
                ...state,
                loadingState: {
                    totalRequests: 1,
                    finishedRequests: 0,
                },
            }

        case SET_BULK_ACTION_LOADING_MULTIPLE_REQUESTS: {
            return {
                ...state,
                loadingState: {
                    totalRequests: action.payload,
                    finishedRequests: 0,
                },
            }
        }

        case INCREMENT_BULK_ACTION_LOADING_MULTIPLE_REQUESTS: {
            if (!state.loadingState) {
                return state
            }

            return {
                ...state,
                loadingState: {
                    ...state.loadingState,
                    finishedRequests: state.loadingState.finishedRequests + 1,
                },
            }
        }

        case SET_BULK_ACTION_DONE:
            return {
                ...state,
                loadingState: null,
            }

        case SET_BULK_LIST_FILTER:
            return {
                ...state,
                filter: action.payload,
            }

        case BULK_SET_DEACTIVATE_LICENSE_PLATES_RESULT:
            return {
                ...state,
                licensePlatesDeactivationResult: action.payload,
            }

        case BULK_CLEAR_DEACTIVATE_LICENSE_PLATES_RESULT:
            return {
                ...state,
                licensePlatesDeactivationResult: INITIAL_STATE.licensePlatesDeactivationResult,
            }

        default:
            return state
    }
}
