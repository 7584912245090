import {
    IS_AUTHENTICATED,
    SET_AUTH_ERROR,
    SET_ROLES,
    SET_USER,
    LOGOUT_USER,
    LOGIN_USER,
    type CurrentUser,
    type LoginUser,
    type LogoutUser,
    type SetAuth,
    type SetAuthError,
    type SetRoles,
    type SetUser,
} from 'src/redux/auth/auth.types'
import { type User } from 'firebase/auth'

export const setAuth = (condition: boolean | null): SetAuth => {
    return {
        type: IS_AUTHENTICATED,
        payload: condition,
    }
}

export const setAuthError = (reason: string): SetAuthError => {
    return {
        type: SET_AUTH_ERROR,
        payload: reason,
    }
}

export const setRoles = (roles: string[] | null): SetRoles => {
    return {
        type: SET_ROLES,
        payload: roles,
    }
}

export const setUser = (user: CurrentUser | null): SetUser => {
    return {
        type: SET_USER,
        payload: user,
    }
}

export const logoutUser = (): LogoutUser => {
    return {
        type: LOGOUT_USER,
    }
}

export const loginUser = (user: User): LoginUser => {
    return {
        type: LOGIN_USER,
        payload: user,
    }
}
