import { ADMIN_OWNER_ROLE_OPTIONS, OWNER_ROLE_OPTIONS } from 'src/pages/permissions/updateRoles/constants'

export const getStringifiedOwnerRoles = (roles: string[] | undefined): string => {
    if (!roles) {
        return ''
    }

    return roles
        .map(role => OWNER_ROLE_OPTIONS[role])
        .filter(Boolean)
        .join(', ')
}

export const getStringifiedAdminOwnerRoles = (roles: string[]): string => {
    if (!roles) {
        return ''
    }

    return roles
        .map(role => ADMIN_OWNER_ROLE_OPTIONS[role])
        .filter(Boolean)
        .join(', ')
}
